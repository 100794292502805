import { Component } from '@angular/core';
import { NavigateService } from '@core/services/navigate.service';

@Component({
  selector: 'bnl-page-not-fund',
  templateUrl: './page-not-fund.component.html',
  styleUrls: ['./page-not-fund.component.scss']
})
export class PageNotFundComponent {
  constructor(public n: NavigateService) {
  }
}
