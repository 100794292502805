import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { TicketsService } from '@core/services/tickets.service';
import { PathwaysListElementGraphql, TicketGraphql } from '@modules/graphql/graphql.service';
import { RentalTab } from '@modules/tabs/bus-rental-tab/pages/bus-rental-page/bus-rental-page.component';
import { TicketMapService } from '@modules/tabs/tickets-tab/shared/services/ticket-map.service';
import { BaseComponent } from '@shared/components/base-component';
import { SnackBarState } from '@shared/enums/snack-bar-state.enum';
import { TopBarConfig } from '@shared/interfaces/top-bar-config.interface';
import { Observable } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'bnl-tickets-view',
  templateUrl: './tickets-page.component.html',
  styleUrls: ['./tickets-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TicketsPageComponent extends BaseComponent implements OnInit {
  isMapPreviewOpen = false;
  pathwayPreview: PathwaysListElementGraphql;
  private lastScrollPos: number;

  topBarConfig: TopBarConfig = {
    title: '',
    subtitle: '',
    leftIcon: {
      src: '/app/shared/assets/svg/back.svg',
      alt: this.t.instant('Global.IconAlts.backIcon'),
      clickHandler: () => this.onMapClose(),
    },
  };

  tabs: RentalTab[] = [
    {
      link: 'list',
      name: 'ProfileTickets.CurrentTickets.title',
    },
    {
      link: 'history',
      name: 'ProfileTickets.HistoryTickets.title',
    },
  ];

  activeLink = this.tabs[0];

  get unpaidTickets$(): Observable<TicketGraphql[]> {
    return this.ticketsService.unpaidTickets$;
  }

  constructor(
    public ticketsService: TicketsService,
    private ticketMapService: TicketMapService,
    private ref: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit(): void {
    this.loadProfileTickets();

    this.ticketMapService.openTicketMapPreview
      .pipe(takeUntil(this.destroyed))
      .subscribe((pathway: PathwaysListElementGraphql) => {
        this.onMapOpen(pathway);
      });

    this.auth.onLogout.pipe(takeUntil(this.destroyed)).subscribe(() => {
      this.n.navigate('search');
    });
  }

  onMapOpen(pathway: PathwaysListElementGraphql): void {
    this.pathwayPreview = pathway;
    this.topBarConfig.title = `${pathway.start.busStop.name || pathway.start.busStop.address} - ${
      pathway.end.busStop.name || pathway.end.busStop.address
    }`;
    this.topBarConfig.subtitle = this.moment(pathway.rideDate).format(this.config.TICKET_DATE_FORMAT);
    this.isMapPreviewOpen = true;
    this.lastScrollPos = window.scrollY;
    window.scrollTo({ left: 0, top: 0, behavior: 'auto' });
  }

  onMapClose(): void {
    this.isMapPreviewOpen = false;
    this.pathwayPreview = null;
    window.scrollTo({ left: 0, top: this.lastScrollPos, behavior: 'auto' });
  }

  private loadProfileTickets(): void {
    this.isRequestPending = true;
    this.spinner.show('tickets-loader');
    this.ticketsService
      .fetchProfileTickets()
      .pipe(
        takeUntil(this.destroyed),
        finalize(() => {
          this.isRequestPending = false;
          this.spinner.hide('tickets-loader');
        })
      )
      .subscribe(
        () => {
          this.ref.detectChanges();
        },
        (error) => {
          this.snackbar.open(this.t.instant('ProfileTickets.unableToLoad'), SnackBarState.ERROR);
        }
      );
  }
}
