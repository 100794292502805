import { Component, OnInit } from '@angular/core';
import { TopBarConfig } from '@shared/interfaces/top-bar-config.interface';
import { GetTicketByTokenQuery, TicketGraphql } from '@modules/graphql/graphql.service';
import { ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { BaseComponent } from '@shared/components/base-component';
import { FetchResult } from 'apollo-link';
import { Location } from '@angular/common';
import { SnackBarState } from '@shared/enums/snack-bar-state.enum';
import { ShareService } from '@shared/services/share.service';
import { TicketStatus } from '@shared/enums/ticket-status.enum';
import { TicketsService } from '@core/services/tickets.service';
import { SearchService } from '@core/services/search.service';
import { WindowHelper } from '@shared/helpers/window.helper';

@Component({
  selector: 'bnl-ticket-page',
  templateUrl: './ticket-page.component.html',
  styleUrls: ['./ticket-page.component.scss']
})
export class TicketPageComponent extends BaseComponent implements OnInit {
  WindowHelper = WindowHelper;

  topBarConfig: TopBarConfig;
  ticket: TicketGraphql;

  isHistoryTicket = false;

  constructor(
    private ticketsService: TicketsService,
    private searchService: SearchService,
    private route: ActivatedRoute,
    private location: Location,
    private shareService: ShareService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.auth.onLogout.pipe(
      takeUntil(this.destroyed)
    ).subscribe(() => {
      this.n.navigate('search');
    });

    const ticketToken = this.route.snapshot.paramMap.get('ticketToken');
    this.ticketsService.getTicketByToken(ticketToken).pipe(
      takeUntil(this.destroyed)
    ).subscribe((res: FetchResult<GetTicketByTokenQuery>) => {
      this.ticket = res.data.getTicketByToken as TicketGraphql;
      if (this.ticket.status === TicketStatus.PURCHASED) {
        if (this.ticketsService.isHistoryTicket(this.ticket)) {
          this.isHistoryTicket = true;
        }

        this.setupTopBar();
      } else {
        this.snackbar.open(this.t.instant('TicketPage.ticketMustBePurchased'), SnackBarState.ERROR);
        this.n.navigate('summary', {}, {ticketToken: this.ticket.token});
      }
    }, (error) => {
      this.snackbar.open(this.t.instant('TicketPage.noSuchTicket'), SnackBarState.ERROR);
      this.n.navigate('search');
    });
  }

  onTicketShare(): void {
    this.shareService.shareTicket(this.ticket).subscribe();
  }

  onReturnPathSearch(): void {
    this.searchService.initSearchFromTicket(this.ticket);
    this.n.navigate('search');
  }

  private setupTopBar(): void {
    const rideDate = this.moment(this.ticket.departureData.rideDate).format('dddd, D MMMM');
    const rideTime = this.moment(this.ticket.departureData.rideDate).format('HH:mm');

    this.topBarConfig = {
      title: `${this.t.instant('TicketPage.ticket')} - ${this.ticket.departureData.pathway.company.name}`,
      subtitle: this.t.instant('TicketPage.tripDate', {date: rideDate, time: rideTime}),
      leftIcon: {
        src: '/app/shared/assets/svg/back.svg',
        alt: this.t.instant('Global.IconAlts.backIcon'),
        clickHandler: () => this.location.back()
      },
      ...!this.isHistoryTicket && {
        rightIcon: {
          src: '/app/shared/assets/svg/share.svg',
          alt: this.t.instant('Global.IconAlts.share'),
          clickHandler: () => this.onTicketShare()
        }
      }
    };
  }
}
