import {
  Component,
  OnInit,
  OnDestroy,
  ChangeDetectionStrategy,
} from "@angular/core";
import { AuthService } from "@core/modules/authorization/shared/services/auth.service";
import { Subscription } from "rxjs";
import { TicketForReviewService } from "./ticket-for-review.service";

@Component({
  selector: "bnl-ticket-for-review",
  template: "",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TicketForReviewComponent implements OnInit, OnDestroy {
  private readonly subscription: Subscription = new Subscription();

  constructor(
    private readonly authService: AuthService,
    private readonly ticketForReviewService: TicketForReviewService
  ) {}

  ngOnInit(): void {
    if (this.authService.isSignedIn()) {
      this.ticketForReviewService.initializeService();
    }
    this.initalizeLogInSubscription();
    this.initalizeLogOutSubscription();
  }

  private initalizeLogInSubscription(): void {
    this.authService.onLogin.subscribe(() => {
      this.ticketForReviewService.initializeService();
    });
  }

  private initalizeLogOutSubscription(): void {
    this.authService.onLogout.subscribe(() => {
      this.ticketForReviewService.terminateService();
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
