<div class="addLuggage dialog">
  <bnl-top-bar [config]="topBarConfig" class="d-md-none"></bnl-top-bar>

  <div class="dialog-close d-none d-md-block">
    <img (click)="dialogRef.close()" src="/app/shared/assets/svg/close.svg" alt="{{'Global.IconAlts.closeIcon' | translate}}">
  </div>

  <div class="dialog-content">
    <h2 class="dialog-content-title">{{'Ticket.addLuggage' | translate}}</h2>
    <div class="addLuggage-list">
      <div class="addLuggage-list-item" *ngIf="ticketCreator.availableBikes > 0">
        <select class="addLuggage-list-item-select d-md-none" [(ngModel)]="additionalLuggage.bikesCount">
          <option *ngFor="let i of getRange(0, ticketCreator.availableBikes + 1)" [value]="i">{{i}}</option>
        </select>
        <mat-select class="addLuggage-list-item-select d-none d-md-block" [(value)]="additionalLuggage.bikesCount">
          <mat-option *ngFor="let i of getRange(0, ticketCreator.availableBikes + 1)" [value]="i">
            {{i}}
          </mat-option>
        </mat-select>
        <bnl-button
          buttonStyle="secondary"
          size="lg"
          align="between"
          iconSrc="/app/shared/assets/svg/caret-down-navy.svg"
          [iconAlt]="'Global.IconAlts.dropdownIcon' | translate"
          [text]="(additionalLuggage.bikesCount == 0 ?
            ('Ticket.Luggage.noBikes' | translate) :
            ('Ticket.Luggage.bikesCount' | translate) + ': ' + additionalLuggage.bikesCount)
            + ' (+ ' + (ticketCreator.getAdditionalBikesPrice(additionalLuggage) | price) + ')'"
          [reverse]="true">
        </bnl-button>
      </div>

      <div class="addLuggage-list-item" *ngIf="ticketCreator.availableAnimals > 0">
        <select class="addLuggage-list-item-select d-md-none" [(ngModel)]="additionalLuggage.animalsCount">
          <option *ngFor="let i of getRange(0, ticketCreator.availableAnimals + 1)" [value]="i">{{i}}</option>
        </select>
        <mat-select class="addLuggage-list-item-select d-none d-md-block" [(value)]="additionalLuggage.animalsCount">
          <mat-option *ngFor="let i of getRange(0, ticketCreator.availableAnimals + 1)" [value]="i">
            {{i}}
          </mat-option>
        </mat-select>
        <bnl-button
          buttonStyle="secondary"
          size="lg"
          align="between"
          iconSrc="/app/shared/assets/svg/caret-down-navy.svg"
          [iconAlt]="'Global.IconAlts.dropdownIcon' | translate"
          [text]="(additionalLuggage.animalsCount == 0 ?
            ('Ticket.Luggage.noAnimals' | translate) :
            ('Ticket.Luggage.animalsCount' | translate) + ': ' + additionalLuggage.animalsCount)
            + ' (+ ' + (ticketCreator.getAdditionalAnimalsPrice(additionalLuggage) | price) + ')'"
          [reverse]="true">
        </bnl-button>
      </div>

      <div class="addLuggage-list-item" *ngIf="ticketCreator.selectedPathway.pathway.bus.options.extraBaggage">
        <select class="addLuggage-list-item-select d-md-none" [(ngModel)]="additionalLuggage.luggageCount">
          <option *ngFor="let i of getRange(0, 10)" [value]="i">{{i}}</option>
        </select>
        <mat-select class="addLuggage-list-item-select d-none d-md-block" [(value)]="additionalLuggage.luggageCount">
          <mat-option *ngFor="let i of getRange(0, 10)" [value]="i">
            {{i}}
          </mat-option>
        </mat-select>
        <bnl-button
          class="addLuggage-list-item"
          buttonStyle="secondary"
          size="lg"
          align="between"
          iconSrc="/app/shared/assets/svg/caret-down-navy.svg"
          [iconAlt]="'Global.IconAlts.dropdownIcon' | translate"
          [text]="(additionalLuggage.luggageCount == 0 ?
            ('Ticket.Luggage.noLuggage' | translate) :
            ('Ticket.Luggage.luggageCount' | translate) + ': ' + additionalLuggage.luggageCount)
            + ' (+ ' + (ticketCreator.getAdditionalLuggagePrice(additionalLuggage) | price) + ')'"
          [reverse]="true">
        </bnl-button>
      </div>
    </div>

    <div class="dialog-checkout">
      <h3 class="dialog-checkout-title">{{'Ticket.Luggage.summary' | translate}}</h3>

      <div class="dialog-checkout-summary">
        <div class="dialog-checkout-summary-discount">
          <p class="dialog-checkout-summary-discount-desc">{{'Ticket.Luggage.luggage' | translate}}</p>
          <p class="dialog-checkout-summary-discount-value">
            {{ticketCreator.getTotalAdditionalLuggagePrice(additionalLuggage) | price}}
          </p>
        </div>

        <div class="dialog-checkout-summary-total">
          <p class="dialog-checkout-summary-total-desc">{{'Ticket.Luggage.total' | translate}}</p>
          <p class="dialog-checkout-summary-total-value">
            {{ticketCreator.getTotalTicketPrice(this.ticketCreator.additionalPassengers, additionalLuggage) | price}}
          </p>
        </div>
      </div>
    </div>

    <div class="addLuggage-buttons">
      <bnl-button
        class="addLuggage-buttons-button d-none d-md-block"
        (click)="dialogRef.close()"
        buttonStyle="secondary"
        [text]="'Ticket.Luggage.dismissLuggage' | translate"
        align="center"
        size="md">
      </bnl-button>

      <bnl-button
        class="addLuggage-buttons-button"
        (click)="onLuggageSubmit()"
        buttonStyle="primary"
        [text]="'Ticket.Luggage.saveLuggage' | translate"
        align="center"
        size="md">
      </bnl-button>
    </div>
  </div>
</div>
