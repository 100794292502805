<ng-container *ngIf="isAuthDialogVisible">
  <div class="backdrop" @fade></div>

  <div class="bnl-auth-dialog" @fade>
    <bnl-top-bar [config]="topBarConfig" class="d-md-none"></bnl-top-bar>

    <div class="container container--flat">
      <h2 class="bnl-auth-dialog-title d-none d-md-block">{{'AuthDialog.login' | translate}}</h2>
      <h2 class="bnl-auth-dialog-title d-md-none" *ngIf="activeStep === Step.FORM">{{'AuthDialog.enterPhone' | translate}}</h2>
    </div>

    <div class="bnl-auth-dialog-close d-none d-md-block" [class.disabled]="isFormPending || isResendPending">
      <img (click)="auth.closeAuthDialog()" src="/app/shared/assets/svg/close.svg" alt="{{'Global.IconAlts.closeIcon' | translate}}">
    </div>

    <ng-container [ngSwitch]="activeStep">
      <div class="container container--flat" *ngSwitchCase="Step.FORM">
        <bnl-form-part
          [loginForm]="loginForm"
          [isFormPending]="isFormPending"
          (phoneNumberSubmit)="onPhoneNumberSubmit()">
        </bnl-form-part>
      </div>

      <div class="container container--flat" *ngSwitchCase="Step.CONFIRM">
        <bnl-confirm-part
          [phoneNumber]="loginForm.get('userPhoneNumber').value"
          [code]="loginForm.get('confirmCode').value"
          [isFormPending]="isFormPending"
          [isResendPending]="isResendPending"
          [resendTimeLeft]="resendTimeLeft"
          (verificationCodeSubmit)="onVerificationCodeSubmit($event)"
          (resendCode)="onResendCode()"
        >
        </bnl-confirm-part>
      </div>
    </ng-container>
  </div>
</ng-container>
