import { Component, OnInit } from '@angular/core';
import { OrdersService } from '@core/services/orders.service';
import { BaseComponent } from '@shared/components/base-component';
import { SnackBarState } from '@shared/enums/snack-bar-state.enum';
import { BehaviorSubject, Subscription, interval } from 'rxjs';
import { finalize, switchMap, takeUntil } from 'rxjs/operators';

const FIRST_REQ_DELAY = 1000;
const NEXT_REQ_DELAY = 10000;

@Component({
  selector: 'bnl-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent extends BaseComponent implements OnInit {
  newOffersCounter = 0;
  isFirstCounterRequest = true;
  intervalSub: Subscription = new Subscription();
  private _intervalSubject$ = new BehaviorSubject<number>(FIRST_REQ_DELAY);
  private interval$ = this._intervalSubject$.pipe(switchMap((duration) => interval(duration)));

  redirectToHomeUrls: string[] = [
    '/bus-rental/my-orders',
    '/bus-rental/confirmed-orders',
    '/bus-rental/orders-history',
  ];

  constructor(private ordersService: OrdersService) {
    super();
  }

  ngOnInit(): void {
    if (this.auth.isSignedIn()) {
      this.initCounterInterval();
    } else {
      this.auth.onLogin.pipe(takeUntil(this.destroyed)).subscribe(() => {
        this.initCounterInterval();
      });
    }
  }

  initCounterInterval(): void {
    this.intervalSub = this.interval$
      .pipe(
        takeUntil(this.destroyed),
        switchMap(() => this.ordersService.getAllNewOffersCount())
      )
      .subscribe(
        (offersCount: number) => {
          this._intervalSubject$.next(NEXT_REQ_DELAY);
          this.newOffersCounter = offersCount;
        },
        () => {
          this.destroyed.next();
          this.destroyed.complete();
        }
      );
  }

  onLoginButtonClick(): void {
    this.auth.isSignedIn() ? this.logout() : this.login();
  }

  private login(): void {
    this.auth.openAuthDialog();
  }

  private logout(): void {
    this.isRequestPending = true;

    this.auth
      .logout()
      .pipe(finalize(() => (this.isRequestPending = false)))
      .subscribe(
        () => {
          this.intervalSub.unsubscribe();
          this.isFirstCounterRequest = true;
          this.snackbar.open(this.t.instant('Global.Messages.successfulLogout'), SnackBarState.SUCCESS);
          if (this.router.url === this.n.getPath('profile-edit')) {
            this.n.navigate('account');
          }
          for (const url of this.redirectToHomeUrls) {
            if (this.router.url.includes(url)) {
              this.n.navigate('search');
            }
          }
        },
        () => {
          this.snackbar.open(this.t.instant('Global.Messages.logoutError'), SnackBarState.ERROR);
        }
      );
  }
}
