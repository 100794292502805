import { Pipe, PipeTransform } from "@angular/core";
import * as moment from "moment";

export enum RemainingOfferTimeStatus {
  SUCCESS = "success",
  WARNING = "warning",
  DANGER = "danger",
}

const SUCCESS_REMAING_HOURS = 20;
const WARNING_REMAING_HOURS = 12;
const OFFER_AVAILABILITY_IN_DAYS = 2;

interface RemainingOfferTime {
  time: string;
  status: RemainingOfferTimeStatus;
}

@Pipe({
  name: "remainigOfferTimePipe",
})
export class RemainigOfferTimePipe implements PipeTransform {
  transform(createdAt: string, departureDate: string): RemainingOfferTime {
    let offerDateToShow = null;

    const createdAtMoment = moment(createdAt);
    const departureDateMoment = moment(departureDate);

    const offerAvailability = createdAtMoment.clone().add(OFFER_AVAILABILITY_IN_DAYS, "days");

    offerDateToShow = departureDateMoment.isBefore(offerAvailability) ? departureDateMoment : offerAvailability;

    const duration = moment.duration(offerDateToShow.diff(moment()));
    const formattedResult = `${Math.floor(duration.asDays())}d ${duration.hours()}h ${duration.minutes()}m`;
    const hoursLeft = parseInt(duration.asHours().toFixed(0));
    const minutesLeft = parseInt(duration.asMinutes().toFixed(0));

    let status = RemainingOfferTimeStatus.DANGER;

    if (hoursLeft < WARNING_REMAING_HOURS && minutesLeft <= 0) {
      status = RemainingOfferTimeStatus.DANGER;
    }
    if (hoursLeft >= WARNING_REMAING_HOURS && hoursLeft < SUCCESS_REMAING_HOURS && minutesLeft > 0) {
      status = RemainingOfferTimeStatus.WARNING;
    }
    if (hoursLeft >= SUCCESS_REMAING_HOURS && minutesLeft > 0) {
      status = RemainingOfferTimeStatus.SUCCESS;
    }

    return {
      time: hoursLeft <= 0 && minutesLeft <= 0 ? "00:00" : `${formattedResult}`,
      status,
    };
  }
}
