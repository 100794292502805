<div class="order-card-preview-header" *ngIf="isOrderCard" [class.is-selected]="isPaymentCard">
  <div class="order-card-preview-header-icon">
    <img
      [src]="isPaymentCard ? '/app/shared/assets/svg/check-blue.svg' : '/app/shared/assets/svg/check-yellow.svg'"
      alt="{{ 'Global.IconAlts.checkIcon' | translate }}"
    />
  </div>
  <p class="order-card-preview-header-title">
    {{ "Ticket.selectedPath" | translate }}
  </p>
</div>
<div
  class="order-card"
  [class.is-confirmed]="isConfirmed && !isHistoryOrder"
  [class.is-selected]="isPaymentCard"
  [class.has-offer]="
    orderCarrier?.offer?.id && !isConfirmed && orderCarrier?.offer?.status !== RentOfferStatus.Canceled
  "
>
  <div class="departure-time-wrapper" [class.is-carrier-view]="isCarrierView">
    <div class="order-card-departure" *ngIf="!isOrderCard && ordersService.getArrivalTimeTo(order.departureDate)">
      <div class="order-card-departure-icon">
        <img src="/app/shared/assets/svg/clock-red.svg" alt="{{ 'Global.IconAlts.departureTime' | translate }}" />
      </div>
      <span class="order-card-departure-time"
        >{{ "BusRentalPage.DepartureIn" | translate }} {{ ordersService.getArrivalTimeTo(order.departureDate) }}</span
      >
    </div>
    <div class="order-card-time-left" *ngIf="isCarrierView && !isConfirmed">
      <img
        class="order-card-time-left-time-icon"
        src="/app/shared/assets/svg/clock.svg"
        [alt]="'OrdersPage.OfferTime' | translate"
      />
      <span class="order-card-time-left-time-title">{{ "OrdersPage.OfferTime" | translate }}:</span>
      <span
        class="order-card-time-left-time-left"
        [ngClass]="
          'order-card-time-left-time-left-' + (order.createdAt | remainigOfferTimePipe : order.departureDate).status
        "
        >{{ (order.createdAt | remainigOfferTimePipe : order.departureDate).time }}</span
      >
    </div>
  </div>
  <div class="order-card-content">
    <div class="order-card-content-date order-card-content-date--start">
      {{ order.departureDate | formatDate : "D MMMM" }}
    </div>
    <div class="order-card-content-date order-card-content-date--end">
      {{ ordersService.getArrivalDateTime(order, "D MMMM") }}
    </div>

    <div class="order-card-content-hour order-card-content-hour--start">
      <span>{{ order.departureDate | formatDate : "HH:mm" }}</span>
      <div
        class="order-card-content-hour-diff order-card-content-duration d-lg-none"
        *ngIf="ordersService.distanceMatrix$ | async as matrix"
      >
        <ng-container
          [ngTemplateOutlet]="durationMatrixTemplate"
          [ngTemplateOutletContext]="{ matrix: matrix }"
        ></ng-container>
      </div>
    </div>
    <div class="order-card-content-hour order-card-content-hour--end">
      {{ ordersService.getArrivalDateTime(order, "HH:mm") }}
    </div>

    <div class="order-card-content-duration d-none d-lg-flex" *ngIf="ordersService.distanceMatrix$ | async as matrix">
      <ng-container
        [ngTemplateOutlet]="durationMatrixTemplate"
        [ngTemplateOutletContext]="{ matrix: matrix }"
      ></ng-container>
    </div>

    <div class="order-card-content-station order-card-content-station--start">
      {{ order.departure?.name }}
    </div>
    <div class="order-card-content-station order-card-content-station--end">
      {{ order.destination?.name }}
    </div>

    <div
      class="order-card-content-carrier"
      *ngIf="confirmedOfferCarrier && (isConfirmed || isHistoryOrder) && !isCarrierView"
    >
      <div class="order-card-content-carrier-container">
        <bnl-carrier-info
          [carrier]="confirmedOfferCarrier"
          [showCarrierInfo]="confirmedOfferCarrier"
        ></bnl-carrier-info>
      </div>
    </div>

    <div class="order-card-content-actions d-none d-lg-flex">
      <ng-container [ngTemplateOutlet]="actionsTemplate" [ngTemplateOutletContext]="{ order: order }"></ng-container>
    </div>

    <div class="order-card-content-empty"></div>
  </div>

  <div class="order-card-payments" *ngIf="isCarrierView && isConfirmed">
    <bnl-order-card-payments [order]="orderCarrier"></bnl-order-card-payments>
  </div>

  <div class="order-card-footer">
    <bnl-order-card-footer [isCarrierView]="isCarrierView" [order]="order"></bnl-order-card-footer>
    <div class="order-card-info order-card-info-end">
      <bnl-order-status [status]="order.status"></bnl-order-status>
      <div class="order-card-id">#{{ order.id }}</div>
    </div>
  </div>

  <div
    class="order-card-details"
    *ngIf="isDetailsPartOpen"
    [class.is-carier-confirmed-order]="isCarrierView && isConfirmed"
  >
    <bnl-order-path-details
      class="order-card-details-content"
      [order]="order"
      (mapPreviewOpen)="mapPreviewOpen.emit($event)"
    ></bnl-order-path-details>

    <div class="order-card-details-wrapper">
      <div class="order-card-details-wrapper-contact" *ngIf="isCarrierView && isConfirmed">
        <div class="order-card-details-wrapper-contact-title">
          {{ "OrdersPage.Contact.Contact" | translate }}
        </div>
        <div class="order-card-details-wrapper-contact-name">
          {{ order.contactFirstName }} {{ order.contactLastName }}
        </div>
        <div class="order-card-details-wrapper-contact-details">
          <div class="order-card-details-wrapper-contact-details-item">
            <div class="order-card-details-wrapper-contact-details-label">
              {{ "OrdersPage.Contact.Name" | translate }}:
            </div>
            <div class="order-card-details-wrapper-contact-details-value">
              {{ order.contactFirstName }} {{ order.contactLastName }}
            </div>
          </div>
          <div class="order-card-details-wrapper-contact-details-item">
            <div class="order-card-details-wrapper-contact-details-label">
              {{ "OrdersPage.Contact.Phone" | translate }}:
            </div>
            <div class="order-card-details-wrapper-contact-details-value">
              {{ order.contactPhoneNumber }}
            </div>
          </div>
          <div class="order-card-details-wrapper-contact-details-item">
            <div class="order-card-details-wrapper-contact-details-label">
              {{ "OrdersPage.Contact.Email" | translate }}:
            </div>
            <div class="order-card-details-wrapper-contact-details-value">
              {{ order.contactEmail }}
            </div>
          </div>
          <div
            class="order-card-details-wrapper-contact-details-item"
            *ngIf="orderCarrier.offer?.secondTimePaymentMethod"
          >
            <div class="order-card-details-wrapper-contact-details-label">
              {{ "OrdersPage.Contact.PaymentMethod" | translate }}:
            </div>
            <div class="order-card-details-wrapper-contact-details-value">
              {{ "SecondPaymentMethod." + orderCarrier.offer.secondTimePaymentMethod | translate }}
            </div>
          </div>
        </div>
      </div>
      <div class="order-card-details-wrapper-alerts">
        <bnl-order-info-alert
          *ngIf="order.note && isCarrierView"
          [title]="'OrdersPage.ClientInfo' | translate"
          [description]="order.note"
        ></bnl-order-info-alert>

        <bnl-order-info-alert
          *ngIf="order.adminNote"
          class="order-card-details-administration"
          [title]="'OrdersPage.AdministrationInfo' | translate"
          [description]="order.adminNote"
        ></bnl-order-info-alert>
      </div>
    </div>
  </div>

  <div class="order-card-content-actions d-lg-none">
    <ng-container [ngTemplateOutlet]="actionsTemplate" [ngTemplateOutletContext]="{ order: order }"></ng-container>
  </div>
</div>

<ng-template #actionsTemplate let-order="order">
  <div class="order-card-content-actions-container">
    <div class="button-wrappar" *ngIf="!isCarrierView">
      <bnl-button
        (click)="showOffers(); $event.stopPropagation()"
        class="order-card-content-actions-container-button"
        [text]="'OrdersPage.ShowOffers' | translate"
        [badgeValue]="order.totalOffers + '/4'"
        [showBadge]="true"
        size="sm"
        align="center"
        [rounded]="true"
        [class.is-disabled]="order.status === BusRentStatus.Outdated"
        *ngIf="!isConfirmed && !isOrderCard && !isPaymentCard && !isHistoryOrder"
      ></bnl-button>

      <bnl-button
        (click)="mapPreviewOpen.emit(order); $event.stopPropagation()"
        class="order-card-content-map-container-button"
        [text]="'OrdersPage.ShowOnMap' | translate"
        [iconSrc]="'/app/shared/assets/svg/google-maps-pin.svg'"
        [reverse]="false"
        size="sm"
        align="center"
        [rounded]="true"
        *ngIf="(isOrderCard || isConfirmed) && !isPaymentCard"
      ></bnl-button>

      <div class="reservation-time-wrapper">
        <bnl-button
          (click)="payReservationEmit.emit(); $event.stopPropagation()"
          class="order-card-payment-button"
          [text]="'OrderPaymentPage.PayReservation' | translate"
          size="sm"
          align="center"
          [rounded]="true"
          *ngIf="isPaymentCard"
        ></bnl-button>
        <div class="order-card-departure order-card-departure-reservation-time" *ngIf="isPaymentCard && showTimer">
          <div class="order-card-departure-icon">
            <img src="/app/shared/assets/svg/clock-red.svg" alt="{{ 'Global.IconAlts.departureTime' | translate }}" />
          </div>
          <span class="order-card-departure-time"
            >{{ "OrderPaymentPage.TimeToPayReservation" | translate }}:
            <span class="order-card-departure-reservation-time-value">{{
              reservationTimerService.remainingTime$ | async
            }}</span>
          </span>
        </div>
      </div>

      <bnl-button
        (click)="onPathDetailsOpen(); $event.stopPropagation()"
        class="order-card-content-open-container-button"
        [class.is-open]="isDetailsPartOpen"
        [iconSrc]="'/app/shared/assets/svg/chevron-down.svg'"
        size="sm"
        align="center"
        [rounded]="true"
      ></bnl-button>

      <bnl-button
        (click)="showOrderDetails(); $event.stopPropagation()"
        class="order-card-content-open-container-button is-eye"
        [iconSrc]="'/app/shared/assets/svg/eye.svg'"
        size="sm"
        align="center"
        [rounded]="true"
        *ngIf="(isOrderCard || isConfirmed || isHistoryOrder) && !isPaymentCard && showOpenDetailsButton"
      ></bnl-button>
      <bnl-button
        (click)="onShowInvoiceEmit.emit(order.id); $event.stopPropagation()"
        class="order-card-content-open-container-button is-eye"
        [iconSrc]="'/app/shared/assets/svg/file.svg'"
        size="sm"
        align="center"
        [rounded]="true"
        *ngIf="
          (isOrderCard || isConfirmed || isHistoryOrder) &&
          !isPaymentCard &&
          showOpenDetailsButton &&
          order.status === RentOfferStatus.Confirmed
        "
      ></bnl-button>
    </div>
    <div class="button-wrappar" *ngIf="isCarrierView">
      <div
        class="button-wrappar-price"
        *ngIf="order.offer.id && !isConfirmed && order.offer.status !== RentOfferStatus.Canceled"
      >
        {{ order.offer?.price | price }}
      </div>

      <bnl-button
        (click)="onDiscardOfferEmit.emit(order.offer.id); $event.stopPropagation()"
        class="reject-offer-button"
        [text]="'BusRentalPage.DiscardOffer' | translate"
        size="sm"
        align="center"
        [rounded]="true"
        buttonStyle="danger"
        *ngIf="order.offer.id && !isConfirmed && order.offer.status !== RentOfferStatus.Canceled"
      ></bnl-button>

      <bnl-button
        (click)="onRejectOfferEmit.emit(order.id); $event.stopPropagation()"
        class="reject-offer-button"
        [text]="'BusRentalPage.RejectOffer' | translate"
        size="sm"
        align="center"
        [rounded]="true"
        buttonStyle="danger"
        *ngIf="
          (!order.offer.id || order.offer.status === RentOfferStatus.Canceled) &&
          !isConfirmed &&
          order.status !== BusRentStatus.Outdated
        "
      ></bnl-button>

      <bnl-button
        (click)="onMakeOfferEmit.emit(order); $event.stopPropagation()"
        class="make-offer-button"
        [text]="'BusRentalPage.MakeOffer' | translate"
        size="sm"
        align="center"
        [showBadge]="true"
        [badgeValue]="order.totalOffers + '/4'"
        [rounded]="true"
        *ngIf="
          (!order.offer.id || order.offer.status === RentOfferStatus.Canceled) &&
          !isConfirmed &&
          order.status !== BusRentStatus.Outdated
        "
      ></bnl-button>

      <bnl-button
        (click)="!reportChangesButtonDisabled ? onReportChangesEmit.emit(order.id) : null; $event.stopPropagation()"
        class="make-offer-button"
        [text]="'BusRentalPage.ReportChanges' | translate"
        size="sm"
        align="center"
        [rounded]="true"
        [disabled]="reportChangesButtonDisabled"
        *ngIf="order.offer.id && isConfirmed && !isHistoryOrder"
      ></bnl-button>

      <bnl-button
        (click)="mapPreviewOpen.emit(order); $event.stopPropagation()"
        class="order-card-content-map-container-button"
        [text]="'OrdersPage.ShowOnMap' | translate"
        [iconSrc]="'/app/shared/assets/svg/google-maps-pin.svg'"
        [reverse]="false"
        size="sm"
        align="center"
        [rounded]="true"
        *ngIf="order.offer.id && isConfirmed && isHistoryOrder"
      ></bnl-button>

      <bnl-button
        (click)="onPathDetailsOpen(); $event.stopPropagation()"
        class="order-card-content-open-container-button"
        [class.is-open]="isDetailsPartOpen"
        [iconSrc]="'/app/shared/assets/svg/chevron-down.svg'"
        size="sm"
        align="center"
        [rounded]="true"
      ></bnl-button>
    </div>
  </div>
</ng-template>

<ng-template #durationMatrixTemplate>
  <span class="order-card-content-duration-line"></span>
  <div class="order-card-content-duration-inner">
    <p class="order-card-content-duration-inner-time">
      {{ ordersService.getDuration(order)?.text }}
    </p>
    <p class="order-card-content-duration-inner-stops">
      {{ ordersService.getDistance(order)?.text }}
    </p>
    <div
      class="order-card-content-duration-map"
      *ngIf="isCarrierView"
      (click)="mapPreviewOpen.emit(order); $event.stopPropagation()"
    >
      <img src="/app/shared/assets/svg/google-maps-pin.svg" alt="{{ ordersService.getDistance(order)?.text }}" />
      <span>{{ "BusRentalPage.ShowRoad" | translate }}</span>
    </div>
  </div>
  <span class="order-card-content-duration-line"></span>
</ng-template>
