import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { BaseComponent } from '@shared/components/base-component';
import { FormGroup } from '@angular/forms';
import { GbxsoftInputConfig } from '@form/src/lib/gbxsoft-input/interfaces/gbxsoft-input.interface';
import { GbxsoftInputTypes } from '@form/src/lib/gbxsoft-input/gbxsoft-input.types';
import { Subscription } from 'rxjs';
import { handlePhoneNumberIllegalChars } from '@shared/helpers/phone-number-replace.helper';

@Component({
  selector: 'bnl-form-part',
  templateUrl: './form-part.component.html',
})
export class FormPartComponent
  extends BaseComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input() loginForm: FormGroup;
  @Input() isFormPending = false;
  @Output() phoneNumberSubmit = new EventEmitter<void>();

  @ViewChild('phoneInput', { read: ElementRef }) phoneInputRef: ElementRef;

  private readonly subscription: Subscription = new Subscription();

  inputConfig: GbxsoftInputConfig = {
    name: this.t.instant('AuthDialog.enterPhoneNumber'),
    placeholder: this.t.instant('AuthDialog.enterPhoneNumber'),
    type: GbxsoftInputTypes.NUMBER,
  };

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.initializePhoneNumberChangeSub();
  }

  private initializePhoneNumberChangeSub(): void {
    const sub = this.loginForm
      .get('userPhoneNumber')
      ?.valueChanges.subscribe((value) =>
        handlePhoneNumberIllegalChars(
          this.loginForm.get('userPhoneNumber'),
          value
        )
      );
    this.subscription.add(sub);
  }

  ngAfterViewInit(): void {
    this.phoneInputRef.nativeElement
      .querySelector('.gbxsoft-phonepicker-form-input input')
      .focus();
  }

  onSubmit(): void {
    if (this.loginForm.get('userPhoneNumber').valid) {
      this.phoneNumberSubmit.emit();
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
