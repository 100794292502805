import { MapsAPILoader } from '@agm/core';
import { HttpClient } from '@angular/common/http';
import { APP_INITIALIZER, Injector, NgModule } from '@angular/core';
import { Config } from '@core/config';
import { CoreModule } from '@core/core.module';
import { Routes } from '@core/routes';
import { NavigateService } from '@core/services/navigate.service';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppInjectorService } from '@shared/services/app-injector.service';
import { NgcCookieConsentModule } from 'ngx-cookieconsent';
import { appInitializerFactory } from './app-initializer.factory';
import { AppComponent } from './app.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    CoreModule,
    NgcCookieConsentModule.forRoot(Config.cookieConfig),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService, MapsAPILoader, Injector],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(
    private injector: Injector,
    private navigateService: NavigateService
  ) {
    AppInjectorService.setInjector(injector);
    this.navigateService.setAppRoutes(Routes);
  }
}
