import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { OrdersService } from '@core/services/orders.service';
import { GbxsoftInputAutocompleteTypes } from '@form/src/lib/gbxsoft-input/gbxsoft-input-autocomplete.types';
import { GbxsoftInputTypes } from '@form/src/lib/gbxsoft-input/gbxsoft-input.types';
import {
  AcceptRentOfferInput,
  BusRentGraphql,
  CarrierCompanyPaymentMethods,
  RentOfferGraphql,
  RentOfferStatus,
  RentPurchaseOutput,
} from '@modules/graphql/graphql.service';
import { BaseComponent } from '@shared/components/base-component';
import { CarrierInfoDialogComponent } from '@shared/components/carrier-info-dialog/carrier-info-dialog.component';
import { InfoAlertEnum } from '@shared/components/order-info-alert/order-info-alert.component';
import { SnackBarState } from '@shared/enums/snack-bar-state.enum';
import { FormsHelper } from '@shared/helpers/forms.helper';
import { TopBarConfig } from '@shared/interfaces/top-bar-config.interface';
import { ReservationTimerService } from '@shared/services/reservation-timer.service';
import { CustomValidators } from '@shared/validators/custom-validators';
import { finalize, takeUntil } from 'rxjs/operators';

const NOT_FOUND_MESSAGE: string = 'OrdersPage.OrderNotFound';
const SET_INVOICE_ERROR_MESSAGE: string = 'OrderPaymentPage.SetInvoiceErrorMessage';

export enum OrderPaymentType {
  PARTIAL = 'partial',
  FULL = 'full',
}

@Component({
  selector: 'bnl-bus-order-payment-page',
  templateUrl: './bus-order-payment-page.component.html',
  styleUrls: ['./bus-order-payment-page.component.scss'],
})
export class BusOrderPaymentPageComponent extends BaseComponent implements OnInit {
  readonly ORDER_LOADER = 'fetching-order-loader';
  topBarConfig: TopBarConfig;
  order: BusRentGraphql;
  form: FormGroup;
  invoiceForm: FormGroup;
  InfoAlertEnum = InfoAlertEnum;
  OrderPaymentType = OrderPaymentType;
  CarrierCompanyPaymentMethods = CarrierCompanyPaymentMethods;
  FormsHelper = FormsHelper;
  GbxsoftInputTypes = GbxsoftInputTypes;
  GbxsoftInputAutocompleteTypes = GbxsoftInputAutocompleteTypes;

  isMapPreviewOpen = false;
  needInvoice = false;
  isPaying = false;
  selectedOffer: RentOfferGraphql;
  RentOfferStatus = RentOfferStatus;

  get currentOffer(): RentOfferGraphql {
    return this.order?.offers.find((offer) => offer.id === this.getOfferId());
  }

  get advance(): number {
    return this.currentOffer?.price * (this.currentOffer?.carrier.options.generalMargin / 100) || 100;
  }

  get availablePaymentMethods(): CarrierCompanyPaymentMethods[] {
    return this.currentOffer?.carrier.options.allowedPaymentMethods || [];
  }

  constructor(
    private cd: ChangeDetectorRef,
    private dialog: MatDialog,
    private ordersService: OrdersService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    public reservationTimerService: ReservationTimerService
  ) {
    super();
  }

  ngOnInit() {
    this.getOrder();
  }

  initTimer(): void {
    if (this.currentOffer) {
      this.reservationTimerService.startCountdown(
        this.reservationTimerService.getReservationTimeDiff(this.currentOffer.canBePaidTo)
      );
    }
  }

  setupPaymentForm(): void {
    this.form = this.fb.group({
      paymentType: [OrderPaymentType.PARTIAL, Validators.requiredTrue],
      secondPayment: this.fb.group({
        type: [this.availablePaymentMethods[0], Validators.required],
      }),
    });
    this.invoiceForm = this.fb.group({
      invoiceCompanyName: [null, []],
      invoiceTaxNumber: [null, []],
      invoiceAddress: [null, []],
    });
  }

  getOrderId(): number | null {
    return parseInt(this.route.snapshot.paramMap.get('id'));
  }

  getOfferId(): number | null {
    return parseInt(this.route.snapshot.paramMap.get('offerId'));
  }

  getOrder(): void {
    if (!this.getOrderId()) {
      this.router.navigate(['/bus-rental/my-orders']);
      this.snackbar.open(this.t.instant(NOT_FOUND_MESSAGE), SnackBarState.ERROR);
      return;
    }
    this.isRequestPending = true;
    this.spinner.show(this.ORDER_LOADER);
    this.ordersService
      .getOrder(this.getOrderId())
      .pipe(
        takeUntil(this.destroyed),
        finalize(() => {
          setTimeout(() => {
            this.isRequestPending = false;
            this.spinner.hide(this.ORDER_LOADER);
          }, 120);
        })
      )
      .subscribe((result: BusRentGraphql) => {
        this.order = result;
        this.setupPaymentForm();
        this.setupTopBar();
        this.initTimer();
        this.cd.detectChanges();
      });
  }

  private setupTopBar(): void {
    const departure = this.order.departure.name;
    const destination = this.order.destination.name;
    const departureDate = this.moment(this.order.departureDate).format(this.config.TICKET_DATE_FORMAT);

    this.topBarConfig = {
      title: `${departure} - ${destination}`,
      subtitle: departureDate,
      leftIcon: {
        src: '/app/shared/assets/svg/back.svg',
        alt: this.t.instant('Global.IconAlts.backIcon'),
        clickHandler: () => (this.isMapPreviewOpen ? this.onMapClose() : this.goToOffers()),
      },
    };
  }

  goToOffers(): void {
    this.router.navigate([`/bus-rental/my-orders/${this.getOrderId()}`]);
  }

  onMapClose(): void {
    this.isMapPreviewOpen = false;
  }

  onMapOpen(): void {
    this.isMapPreviewOpen = true;
  }

  onCarrierInfoDialogOpen(): void {
    this.dialog.open(CarrierInfoDialogComponent, {
      panelClass: 'carrier-info-dialog',
      data: { carrierInfo: null },
    });
  }

  onPayReservation(): void {
    document.querySelector('#payment-section').scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });
  }

  getPayRentOfferInput(type: OrderPaymentType): AcceptRentOfferInput {
    const input: AcceptRentOfferInput = {
      offerId: this.getOfferId(),
    };
    return type === OrderPaymentType.FULL
      ? input
      : Object.assign(input, { secondTimePaymentMethod: this.form.get('secondPayment').get('type').value });
  }

  payOrder(type: OrderPaymentType): void {
    if (this.invoiceForm.invalid) {
      Object.keys(this.invoiceForm.controls).forEach((key: string) => this.invoiceForm.get(key).markAsTouched());
      return;
    }
    this.isPaying = true;
    this.ordersService
      .payRentOffer(this.getPayRentOfferInput(type))
      .pipe(takeUntil(this.destroyed))
      .subscribe(
        (response: RentPurchaseOutput) => {
          if (this.needInvoice) {
            this.setInvoiceDataToOffer(response.paymentUrl);
          } else {
            window.open(response.paymentUrl, '_self');
            this.isPaying = false;
          }
        },
        () => {
          this.snackbar.open(this.t.instant('OrderPaymentPage.PaymentError'), SnackBarState.ERROR);
          this.isPaying = false;
        }
      );
  }

  selectInvoice(event: MatCheckboxChange): void {
    this.needInvoice = event.checked;
    if (this.needInvoice) {
      this.invoiceForm.get('invoiceCompanyName').setValidators([Validators.required, Validators.maxLength(100)]);
      this.invoiceForm.get('invoiceCompanyName').updateValueAndValidity();
      this.invoiceForm
        .get('invoiceTaxNumber')
        .setValidators([
          Validators.required,
          Validators.minLength(9),
          Validators.maxLength(12),
          CustomValidators.isNumberValidator,
        ]);
      this.invoiceForm.get('invoiceTaxNumber').updateValueAndValidity();
      this.invoiceForm.get('invoiceAddress').setValidators([Validators.required, Validators.maxLength(100)]);
      this.invoiceForm.get('invoiceAddress').updateValueAndValidity();
    } else {
      Object.keys(this.invoiceForm.controls).forEach((key: string) => {
        this.invoiceForm.get(key).clearValidators();
        this.invoiceForm.get(key).markAsUntouched();
        this.invoiceForm.get(key).updateValueAndValidity();
      });
    }
  }

  setInvoiceDataToOffer(paymentUrl: string): void {
    const incoiceData = this.invoiceForm.value;
    this.ordersService
      .setInvoiceDataToOffer(
        {
          invoiceCompanyName: incoiceData.invoiceCompanyName,
          invoiceTaxNumber: incoiceData.invoiceTaxNumber,
          invoiceAddress: incoiceData.invoiceAddress,
        },
        this.getOfferId()
      )
      .pipe(
        takeUntil(this.destroyed),
        finalize(() => {
          this.isPaying = false;
        })
      )
      .subscribe(
        () => window.open(paymentUrl, '_self'),
        () => this.snackbar.open(this.t.instant(SET_INVOICE_ERROR_MESSAGE), SnackBarState.ERROR)
      );
  }
}
