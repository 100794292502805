import { Pipe, PipeTransform } from "@angular/core";
import { Config } from "@shared/configs/config";
import * as moment from "moment";

// '+0100' === 'Europe/Warsaw'

@Pipe({
  name: "formatDate",
})
export class FormatDatePipe implements PipeTransform {
  transform(value: string, ...args: any[]): any {
    return moment(value)
      .utcOffset("+0100")
      .format(args.length === 0 ? Config.DISPLAY_DATETIME_FORMAT : args[0]);
  }
}
