<div class="pathMapDetails">
  <div class="pathMapDetails-path">
    <p class="pathMapDetails-path-title">{{'Ticket.path' | translate}}</p>
    <bnl-ride-path [pathway]="pathway"></bnl-ride-path>

    <bnl-button
      class="pathMapDetails-path-button"
      (click)="mapPreviewOpen.emit(pathway)"
      size="sm"
      [rounded]="true"
      buttonStyle="blue"
      [text]="'Ticket.openFullMap' | translate"
      iconSrc="/app/shared/assets/svg/folded-map.svg"
      [iconAlt]="'IconAlts.mapIcon' | translate">
    </bnl-button>
  </div>

  <div class="pathMapDetails-map">
    <bnl-map-ride-path [pathway]="pathway" class="pathMapDetails-map-details"></bnl-map-ride-path>
  </div>
</div>
