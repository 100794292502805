import { Injectable } from '@angular/core';
import { fromPromise } from 'rxjs/internal-compatibility';
import { SnackBarState } from '@shared/enums/snack-bar-state.enum';
import { ShareData } from '@shared/interfaces/share-data.interface';
import { TranslateService } from '@ngx-translate/core';
import { SnackbarService } from '@shared/services/snackbar.service';
import { Observable } from 'rxjs';
import { TicketGraphql } from '@modules/graphql/graphql.service';

@Injectable()
export class ShareService {
  private wNavigator = window.navigator as any;

  constructor(private translateService: TranslateService, private snackbar: SnackbarService) {
  }

  public shareTicket(ticket: TicketGraphql): Observable<any> {
    return this.share({
      title: `${this.translateService.instant('TicketPage.ticket')} - ${ticket.departureData.pathway.company.name}`,
      text: `${this.translateService.instant('TicketPage.shareText')}`,
      url: `${window.location.origin}/tickets/ticket/${ticket.token}`
    });
  }

  public share(shareData: ShareData): Observable<any> {
    if (this.isShareApiAvailable) {
      return fromPromise(this.wNavigator.share(shareData));
    } else {
      this.snackbar.open(this.translateService.instant('Global.Messages.shareNotSupported'), SnackBarState.ERROR);
    }
  }

  public get isShareApiAvailable(): boolean {
    return 'share' in this.wNavigator;
  }
}
