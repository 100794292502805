import { CustomRoutes } from '@shared/consts/custom-routes.const';
import { AccountPageComponent } from '@modules/tabs/account-tab/pages/account-page/account-page.component';
import { PrivacyPolicyPageComponent } from '@modules/tabs/account-tab/pages/privacy-policy-page/privacy-policy-page.component';
import { RegulationsPageComponent } from '@modules/tabs/account-tab/pages/regulations-page/regulations-page.component';
import { ProfileEditPageComponent } from '@modules/tabs/account-tab/pages/profile-edit-page/profile-edit-page.component';
import { ProfileEditFormComponent } from '@modules/tabs/account-tab/shared/components/profile-edit-form/profile-edit-form.component';
import { AuthGuard } from '@core/modules/authorization/shared/guards/auth-guard';

export const AccountTabRoutes: CustomRoutes = [
  {
    path: '',
    component: AccountPageComponent,
    data: { metaTitle: 'MetaTitle.My account' },
  },
  {
    path: 'privacy-policy',
    name: 'privacy-policy',
    component: PrivacyPolicyPageComponent,
    data: {
      isBottomMenuHidden: true,
      metaTitle: 'MetaTitle.Privacy policy',
    },
  },
  {
    path: 'regulations',
    name: 'regulations',
    component: RegulationsPageComponent,
    data: {
      isBottomMenuHidden: true,
      metaTitle: 'MetaTitle.Regulations',
    },
  },
  {
    path: 'profile-edit',
    name: 'profile-edit',
    component: ProfileEditPageComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: ProfileEditFormComponent,
        data: { isBottomMenuHidden: true, metaTitle: 'MetaTitle.Edit profile' },
      },
    ],
  },
];
