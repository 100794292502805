import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { Country } from '@shared/models/country.model';
import { phoneCountries } from './phone-countries';
import { MatMenuTrigger } from '@angular/material/menu';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'bnl-country-picker',
  templateUrl: './country-picker.component.html',
  styleUrls: ['./country-picker.component.scss']
})
export class CountryPickerComponent implements OnInit {
  @ViewChild('countriesContainer', {read: ViewContainerRef}) container: ViewContainerRef;
  @ViewChild('item', {read: TemplateRef}) template: TemplateRef<any>;
  @ViewChild('countriesList') countriesList: ElementRef;
  @ViewChild('searchInput') searchInput: ElementRef;
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  @Input() phoneFlagName: string;
  @Input() form: FormGroup;
  @Input() disabled: boolean;
  @Input() emitter: EventEmitter<CountryPickerEvent>;

  countries: Country[] = phoneCountries;
  query = '';
  searchTimeout;
  chosenCountry: Country = this.countries[0];

  constructor(public ref: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    if (this.form) {
      const val = this.form.get(this.phoneFlagName).value;
      if (val) {
        this.setInitCountry(val);
      } else {
        this.form.get(this.phoneFlagName).setValue(this.chosenCountry.dialCode);
      }
    }
  }

  private buildCountryList(): void {
    this.container.clear();
    this.countries.forEach(country => {
      if (this.query !== '' && !country.searched) {
        return;
      }
      this.container.createEmbeddedView(this.template, {
        country
      });
    });
    this.ref.detectChanges();
  }

  setInitCountry(dialCode: string): void {
    const country = this.countries[this.countries.findIndex((x) => x.dialCode === dialCode)];
    country ? this.choose(country) : this.choose(this.countries[0]);
  }

  search(): void {
    clearTimeout(this.searchTimeout);
    this.searchTimeout = setTimeout(() => {
      this.countries.map((country: Country) => {
        const name = country.name.toLowerCase();
        const dialCode = country.dialCode.toLowerCase();
        const query = this.query.trim().toLowerCase();
        country.searched = name.indexOf(query) !== -1 || dialCode.indexOf(query) !== -1;
      });
      this.buildCountryList();
    }, 200);
  }

  choose(country: Country): void {
    const current = this.countries[this.countries.findIndex((x) => x.chosen)];
    if (current) {
      current.chosen = false;
    }

    country.chosen = true;
    this.chosenCountry = country;

    if (this.trigger) {
      this.trigger.closeMenu();
    }

    this.form.get(this.phoneFlagName).setValue(this.chosenCountry.dialCode);
    this.emitter.emit({eventType: CountryPickerEventType.CHOSE});
  }

  openPicker(): void {
    setTimeout(() => {
      this.buildCountryList();
    });
    this.searchInput.nativeElement.focus();
  }

  closePicker(): void {
    this.query = '';
    this.buildCountryList();
    this.countriesList.nativeElement.scrollTop = 0;
  }
}

export interface CountryPickerEvent {
  eventType: CountryPickerEventType;
}

export enum CountryPickerEventType {
  OPEN,
  CLOSE,
  CHOSE
}
