import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TopBarConfig } from '@shared/interfaces/top-bar-config.interface';
import { BaseComponent } from '@shared/components/base-component';
import { CarrierCompanyGraphql } from '@modules/graphql/graphql.service';

@Component({
  selector: 'bnl-carrier-info-dialog',
  templateUrl: './carrier-info-dialog.component.html',
  styleUrls: ['./carrier-info-dialog.component.scss']
})
export class CarrierInfoDialogComponent extends BaseComponent implements OnInit {
  carrierInfo: CarrierCompanyGraphql;

  topBarConfig: TopBarConfig = {
    title: this.t.instant('Ticket.carrierName'),
    subtitle: this.t.instant('Ticket.aboutCarrier'),
    leftIcon: {
      src: '/app/shared/assets/svg/back.svg',
      alt: this.t.instant('Global.IconAlts.backIcon'),
      clickHandler: () => this.dialogRef.close()
    }
  };

  get hasAverageReview(): boolean {
    return this.carrierInfo.avgReview !== null && this.carrierInfo.avgReview !== undefined; 
  }

  get averageReview(): number {
    return this.carrierInfo.avgReview;
  }

  constructor(
    public dialogRef: MatDialogRef<CarrierInfoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    super();
  }

  ngOnInit(): void {
    this.carrierInfo = this.data.carrierInfo;
  }
}
