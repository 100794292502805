<div class="paymentPage">
  <div class="background">
    <div class="paymentPage-loader">
      <ngx-spinner
        class="results-page-results-loader"
        [name]="PAYMENT_LOADER"
        [fullScreen]="false"
        size="default"
        type="ball-clip-rotate"
        bdColor="rgba(0, 0, 0, 0)"
        color="#FFF"
      ></ngx-spinner>
    </div>

    <div class="paymentPage-content" *ngIf="!isRequestPending">
      <div class="paymentPage-brand d-md-none">
        <img src="/app/shared/assets/svg/bnl-logo.svg" alt="Busynalotniska">
      </div>

      <h1 class="paymentPage-title" [class.paymentPage-title--error]="paymentError">
        {{!paymentError ? ('Ticket.Payment.Success.title' | translate) : ('Ticket.Payment.Error.title' | translate)}}
      </h1>

      <p class="paymentPage-text">{{!paymentError ? ('Ticket.Payment.Success.thanks' | translate) : ('Ticket.Payment.Error.info' | translate)}}</p>
      <p class="paymentPage-text">{{!paymentError ? ('Ticket.Payment.Success.info' | translate) : ('Ticket.Payment.Error.contact' | translate)}}</p>
      <p class="paymentPage-text" *ngIf="!paymentError">{{'Ticket.Payment.Success.sign' | translate}}</p>

      <div class="paymentPage-errorButtons">
        <bnl-button
          *ngIf="paymentError && ticket"
          (click)="n.navigate('summary', {}, {ticketToken: ticket.token})"
          class="paymentPage-errorButtons-button"
          size="md"
          [boldText]="'Ticket.Payment.Error.retry' | translate"
          align="center">
        </bnl-button>

        <bnl-button
          *ngIf="paymentError"
          (click)="n.navigate('search')"
          class="paymentPage-errorButtons-button"
          buttonStyle="secondary"
          size="md"
          [boldText]="'Ticket.Payment.Error.search' | translate"
          iconSrc="/app/shared/assets/svg/search-navy.svg"
          [iconAlt]="'Global.IconAlts.searchIcon' | translate"
          align="center">
        </bnl-button>
      </div>

      <div class="paymentPage-actions" *ngIf="!paymentError">
        <bnl-button
          *ngIf="shareService.isShareApiAvailable"
          (click)="onTicketShare()"
          class="paymentPage-actions-button d-md-none"
          buttonStyle="white"
          size="md"
          [text]="'Ticket.Payment.Success.share' | translate"
          align="center">
        </bnl-button>
        <bnl-button
          (click)="n.navigate('ticket-pdf', {}, {ticketToken: ticket.token})"
          class="paymentPage-actions-button"
          buttonStyle="white"
          size="md"
          [text]="'Ticket.Payment.Success.open' | translate"
          align="center">
        </bnl-button>
        <bnl-button
          (click)="onReturnPathSearch()"
          class="paymentPage-actions-button"
          buttonStyle="primary"
          size="md"
          [text]="'Ticket.Payment.Success.search' | translate"
          align="center">
        </bnl-button>
      </div>
    </div>
  </div>
</div>
