import { Injectable } from '@angular/core';
import { StorageItem } from '@shared/enums/storage-item.enum';
import { PassengerGraphql } from '@modules/graphql/graphql.service';

@Injectable()
export class StorageService {
  public setItem(key: StorageItem, value: any, isJson: boolean = false): void {
    localStorage.setItem(key, isJson ? JSON.stringify(value) : value);
  }

  public getItem(key: StorageItem, isJson: boolean = false): any {
    return isJson ? JSON.parse(localStorage.getItem(key)) : localStorage.getItem(key);
  }

  public removeItem(key: StorageItem): void {
    localStorage.removeItem(key);
  }

  public clearStorage(): void {
    localStorage.clear();
  }

  public getUserData(): PassengerGraphql {
    return this.getItem(StorageItem.USER_DATA, true);
  }

  public saveUserData(userData: PassengerGraphql): void {
    this.setItem(StorageItem.USER_DATA, userData, true);
  }
}
