import { Injectable } from '@angular/core';
import {
  AcceptRentOfferGQL,
  AcceptRentOfferInput,
  AcceptRentOfferMutation,
  BusRentGraphql,
  CategorizedBusRentsGraphql,
  DownloadRentPdfGQL,
  DownloadRentPdfQuery,
  GetAllNewOffersCountGQL,
  GetAllNewOffersCountQuery,
  GetConfirmedRentRequestsGQL,
  GetHistoryRentRequestsGQL,
  GetMyRentRequestsCountGQL,
  GetMyRentRequestsGQL,
  GetMyRentRequestsQuery,
  GetRentByIdGQL,
  GetRentByIdQuery,
  GetRentPaymentGQL,
  GetRentPaymentQuery,
  PayForRentOfferGQL,
  PayForRentOfferMutation,
  RentOfferGraphql,
  RentPaymentGraphql,
  RentPurchaseOutput,
  SetInvoiceDataToOfferGQL,
  SetInvoiceDataToOfferMutation,
  UpdateOfferInvoiceDataInput,
} from '@modules/graphql/graphql.service';
import { TranslateService } from '@ngx-translate/core';
import { SnackBarState } from '@shared/enums/snack-bar-state.enum';
import { MapsHelper } from '@shared/helpers/maps.helper';
import { DistanceMatrix } from '@shared/interfaces/distance-matrix.interface';
import { SnackbarService } from '@shared/services/snackbar.service';
import { FetchResult } from 'apollo-link';
import moment from 'moment';
import { BehaviorSubject, Observable, Subscriber } from 'rxjs';
import { map } from 'rxjs/operators';

const GET_ORDER_ERROR_MESSAGE: string = 'OrdersPage.CantGetOrder';

@Injectable()
export class OrdersService {
  private readonly _distanceMatrix$: BehaviorSubject<DistanceMatrix[]> = new BehaviorSubject<DistanceMatrix[]>([]);
  readonly distanceMatrix$: Observable<DistanceMatrix[]> = this._distanceMatrix$.asObservable();

  private readonly _selectedOrder$: BehaviorSubject<BusRentGraphql> = new BehaviorSubject<BusRentGraphql>(null);
  readonly selectedOrder$: Observable<BusRentGraphql> = this._selectedOrder$.asObservable();

  private readonly mapsHelper: MapsHelper;

  constructor(
    private getMyRentRequestsGQL: GetMyRentRequestsGQL,
    private getMyRentRequestsCountGQL: GetMyRentRequestsCountGQL,
    private getHistoryRentRequestsGQL: GetHistoryRentRequestsGQL,
    private getConfirmedRentRequestsGQL: GetConfirmedRentRequestsGQL,
    private getRentByIdGQL: GetRentByIdGQL,
    private snackbar: SnackbarService,
    private t: TranslateService,
    private getAllNewOffersCountGQL: GetAllNewOffersCountGQL,
    private acceptRentOfferGQL: AcceptRentOfferGQL,
    private payForRentOfferGQL: PayForRentOfferGQL,
    private getRentPaymentGQL: GetRentPaymentGQL,
    private setInvoiceDataToOfferGQL: SetInvoiceDataToOfferGQL,
    private downloadRentPdfGQL: DownloadRentPdfGQL
  ) {
    this.mapsHelper = new MapsHelper(this);
  }

  fetchMyRentRequests(): Observable<FetchResult<GetMyRentRequestsQuery>> {
    return this.getMyRentRequestsGQL.fetch({}, { fetchPolicy: 'no-cache' });
  }

  fetchMyRentRequestsCount(): Observable<FetchResult<GetMyRentRequestsQuery>> {
    return this.getMyRentRequestsCountGQL.fetch({}, { fetchPolicy: 'no-cache' });
  }

  fetchDownloadRentPdfGQL(rentId: number): Observable<FetchResult<DownloadRentPdfQuery>> {
    return this.downloadRentPdfGQL.fetch({ rentId }, { fetchPolicy: 'no-cache' });
  }

  fetchRentPayment(token: string): Observable<FetchResult<GetRentPaymentQuery>> {
    return this.getRentPaymentGQL.fetch({ token }, { fetchPolicy: 'no-cache' });
  }

  fetchHistoryRentRequests(): Observable<FetchResult<GetMyRentRequestsQuery>> {
    return this.getHistoryRentRequestsGQL.fetch({}, { fetchPolicy: 'no-cache' });
  }

  fetchConfirmedRentRequests(): Observable<FetchResult<GetMyRentRequestsQuery>> {
    return this.getConfirmedRentRequestsGQL.fetch({}, { fetchPolicy: 'no-cache' });
  }

  fetchRentById(rentId: number): Observable<FetchResult<GetRentByIdQuery>> {
    return this.getRentByIdGQL.fetch({ rentId }, { fetchPolicy: 'no-cache' });
  }

  fetchAllNewOffersCount(): Observable<FetchResult<GetAllNewOffersCountQuery>> {
    return this.getAllNewOffersCountGQL.fetch({}, { fetchPolicy: 'no-cache' });
  }

  fetchAcceptRentOffer(offerId: number): Observable<FetchResult<AcceptRentOfferMutation>> {
    return this.acceptRentOfferGQL.mutate({ offerId });
  }

  fetchInvoiceDataToOffer(
    input: UpdateOfferInvoiceDataInput,
    offerId: number
  ): Observable<FetchResult<SetInvoiceDataToOfferMutation>> {
    return this.setInvoiceDataToOfferGQL.mutate({ input, offerId });
  }

  fetchPayRentOffer(acceptRentOfferInput: AcceptRentOfferInput): Observable<FetchResult<PayForRentOfferMutation>> {
    return this.payForRentOfferGQL.mutate({ acceptRentOfferInput });
  }

  acceptRentOffer(offerId: number): Observable<RentOfferGraphql> {
    return this.fetchAcceptRentOffer(offerId).pipe(
      map((res: FetchResult<AcceptRentOfferMutation>) => {
        return res.data.acceptRentOffer;
      })
    );
  }

  payRentOffer(acceptRentOfferInput: AcceptRentOfferInput): Observable<RentPurchaseOutput> {
    return this.fetchPayRentOffer(acceptRentOfferInput).pipe(
      map((res: FetchResult<PayForRentOfferMutation>) => {
        return res.data.payForRentOffer;
      })
    );
  }

  downloadRentPdf(rentId: number): Observable<string> {
    return this.fetchDownloadRentPdfGQL(rentId).pipe(
      map((res: FetchResult<DownloadRentPdfQuery>) => {
        return res.data.downloadRentPdf;
      })
    );
  }

  setInvoiceDataToOffer(input: UpdateOfferInvoiceDataInput, offerId: number): Observable<boolean> {
    return this.fetchInvoiceDataToOffer(input, offerId).pipe(
      map((res: FetchResult<SetInvoiceDataToOfferMutation>) => {
        return res.data.setInvoiceDataToOffer;
      })
    );
  }

  getMyRentRequests(): Observable<CategorizedBusRentsGraphql> {
    return this.fetchMyRentRequests().pipe(
      map((res: FetchResult<GetMyRentRequestsQuery>) => {
        return res.data.getMyRentRequests;
      })
    );
  }

  getMyRentRequestsCount(): Observable<CategorizedBusRentsGraphql> {
    return this.fetchMyRentRequestsCount().pipe(
      map((res: FetchResult<GetMyRentRequestsQuery>) => {
        return res.data.getMyRentRequests;
      })
    );
  }

  getRentPayment(token: string): Observable<RentPaymentGraphql> {
    return this.fetchRentPayment(token).pipe(
      map((res: FetchResult<GetRentPaymentQuery>) => {
        return res.data.getRentPayment;
      })
    );
  }

  getHistoryRentRequests(): Observable<CategorizedBusRentsGraphql> {
    return this.fetchHistoryRentRequests().pipe(
      map((res: FetchResult<GetMyRentRequestsQuery>) => {
        return res.data.getMyRentRequests;
      })
    );
  }

  getConfirmedRentRequests(): Observable<CategorizedBusRentsGraphql> {
    return this.fetchConfirmedRentRequests().pipe(
      map((res: FetchResult<GetMyRentRequestsQuery>) => {
        return res.data.getMyRentRequests;
      })
    );
  }

  getRentById(rentId: number): Observable<BusRentGraphql> {
    return this.fetchRentById(rentId).pipe(
      map((res: FetchResult<GetRentByIdQuery>) => {
        return res.data.getRentById;
      })
    );
  }

  getAllNewOffersCount(): Observable<number> {
    return this.fetchAllNewOffersCount().pipe(
      map((res: FetchResult<GetAllNewOffersCountQuery>) => {
        return res.data.getAllNewOffersCount;
      })
    );
  }

  createDistanceMatrix(originPlaceId: string, destinationPlaceId: string): void {
    this.mapsHelper.getDistanceMatrix(originPlaceId, destinationPlaceId);
  }

  setDistanceMatrix(matrix: DistanceMatrix): void {
    this._distanceMatrix$.next(this._distanceMatrix$.getValue().concat(matrix));
  }

  getDistanceMatrix(): DistanceMatrix[] {
    return this._distanceMatrix$.getValue();
  }

  setSelectedOrder(order: BusRentGraphql): void {
    this._selectedOrder$.next(order);
  }

  getSelectedOrder(): BusRentGraphql {
    return this._selectedOrder$.getValue();
  }

  getCurrentMatrix(order: BusRentGraphql): DistanceMatrix {
    const matrixCollection = this.getDistanceMatrix();
    return matrixCollection.find(
      (item) =>
        item.departurePlaceId === order.departure.googlePlaceId &&
        item.destinationPlaceId === order.destination.googlePlaceId
    );
  }

  getDuration(order: BusRentGraphql): google.maps.Duration {
    if (!this.getCurrentMatrix(order)) return;
    return this.getCurrentMatrix(order).duration;
  }

  getDistance(order: BusRentGraphql): google.maps.Distance {
    if (!this.getCurrentMatrix(order)) return;
    return this.getCurrentMatrix(order).distance;
  }

  getArrivalDateTime(order: BusRentGraphql, format: string, isReturn = false): string {
    if (!this.getCurrentMatrix(order)) return;
    return moment(isReturn ? order.returnDate : order.departureDate)
      .add(this.getCurrentMatrix(order).duration?.value, 'seconds')
      .format(format);
  }

  getArrivalTimeTo(date: string): string | null {
    const givenDate = moment(date);
    const currentDate = moment();
    const duration = moment.duration(givenDate.diff(currentDate));

    const totalDays = duration.asDays();
    const days = Math.floor(totalDays);
    const remainingHours = (totalDays - days) * 24;

    const hours = Math.floor(remainingHours);
    const minutes = Math.floor((remainingHours - hours) * 60);

    if (minutes <= 0) {
      return null;
    }

    if (hours <= 0) {
      return `${minutes}m`;
    }

    if (days <= 0) {
      return `${hours <= 0 ? '' : hours + 'h'} ${minutes}min`;
    } else {
      return `${days}d ${hours}h ${minutes}m`;
    }
  }

  getOrder(orderId: number): Observable<BusRentGraphql> {
    return new Observable<BusRentGraphql>((subscriber: Subscriber<BusRentGraphql>) => {
      this.getRentById(orderId).subscribe(
        (result: BusRentGraphql) => {
          this.setSelectedOrder(result);
          subscriber.next(result);
          subscriber.complete();
        },
        () => this.snackbar.open(this.t.instant(GET_ORDER_ERROR_MESSAGE), SnackBarState.ERROR)
      );
    });
  }

  downloadInvoice(orderId: number): void {
    this.downloadRentPdf(orderId).subscribe((url: string) => {
      if (url) {
        window.open(url, '_blank');
      }
    });
  }
}
