import { OrdersService } from "@core/services/orders.service";
import { LatLng } from "@shared/interfaces/lat-lng.interface";

export class MapsHelper {
  constructor(private ordersService?: OrdersService) {}

  public getDistanceMatrix(departurePlaceId: string, destinationPlaceId: string): void {
    const service = new google.maps.DistanceMatrixService();
    service.getDistanceMatrix(
      {
        origins: [{ placeId: departurePlaceId }],
        destinations: [
          {
            placeId: destinationPlaceId,
          },
        ],
        travelMode: google.maps.TravelMode.DRIVING,
        transitOptions: {
          modes: [google.maps.TransitMode.BUS],
        },
      },
      (matrix: google.maps.DistanceMatrixResponse) => {
        this.ordersService.setDistanceMatrix({
          distance: matrix?.rows[0].elements[0].distance,
          duration: matrix?.rows[0].elements[0].duration,
          departurePlaceId,
          destinationPlaceId,
        });
      }
    );
  }

  public getLatLngFromAddress(placeId: string): Promise<LatLng> {
    const geocoder = new google.maps.Geocoder();
    return new Promise((resolve, reject) => {
      geocoder.geocode({ placeId }, (results, status) => {
        if (status == google.maps.GeocoderStatus.OK) {
          const location = results[0].geometry.location;
          resolve({ lat: location.lat(), lng: location.lng() });
        } else {
          reject("Geocode was not successful for the following reason: " + status);
        }
      });
    });
  }
}
