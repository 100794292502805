import { AgmCoreModule } from "@agm/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatRippleModule } from "@angular/material/core";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatMenuModule } from "@angular/material/menu";
import { MatSelectModule } from "@angular/material/select";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { RouterModule } from "@angular/router";
import { environment } from "@environments/environment";
import { GbxsoftFormModule } from "@form/src/lib/gbxsoft-form.module";
import { TranslateModule } from "@ngx-translate/core";
import { BottomMenuComponent } from "@shared/components/bottom-menu/bottom-menu.component";
import { BusOrderCardComponent } from "@shared/components/bus-order-card/bus-order-card.component";
import { ButtonComponent } from "@shared/components/button/button.component";
import { CarrierInfoDialogComponent } from "@shared/components/carrier-info-dialog/carrier-info-dialog.component";
import { CarrierInfoComponent } from "@shared/components/carrier-info/carrier-info.component";
import { ConfirmDialogComponent } from "@shared/components/confirm-dialog/confirm-dialog.component";
import { ConnectionCardComponent } from "@shared/components/connection-card/connection-card.component";
import { CounterComponent } from "@shared/components/counter/counter.component";
import { DateTimePickerComponent } from "@shared/components/date-time-picker/date-time-picker.component";
import { FullScreenMapComponent } from "@shared/components/full-screen-map/full-screen-map.component";
import { InfoBoxComponent } from "@shared/components/info-box/info-box.component";
import { ListComponent } from "@shared/components/list/list.component";
import { BusStopInfoWindowComponent } from "@shared/components/map-ride-path/bus-stop-info-window/bus-stop-info-window.component";
import { MapRidePathComponent } from "@shared/components/map-ride-path/map-ride-path.component";
import { OrderCardFooterComponent } from "@shared/components/order-card-footer/order-card-footer.component";
import { OrderCardPaymentsComponent } from "@shared/components/order-card-payments /order-card-payments.component";
import { OrderInfoAlertComponent } from "@shared/components/order-info-alert/order-info-alert.component";
import { OrderPathDetailsComponent } from "@shared/components/order-path-details/order-path-details.component";
import { OrderPreviewComponent } from "@shared/components/order-preview/order-preview.component";
import { OrderRidePathComponent } from "@shared/components/order-ride-path /order-ride-path.component";
import { OrderStatusComponent } from "@shared/components/order-status/order-status.component";
import { PageNotFundComponent } from "@shared/components/page-not-fund/page-not-fund.component";
import { PassengerTypeSelectComponent } from "@shared/components/passenger-type-select/passenger-type-select.component";
import { PathMapDetailsComponent } from "@shared/components/path-map-details/path-map-details.component";
import { CountryPickerComponent } from "@shared/components/phone-input/components/country-picker/country-picker.component";
import { PhoneInputComponent } from "@shared/components/phone-input/phone-input.component";
import { RateYourRideDialogComponent } from "@shared/components/rate-your-ride-dialog/rate-your-ride-dialog.component";
import { RidePathComponent } from "@shared/components/ride-path/ride-path.component";
import { ScanTicketDetailsComponent } from "@shared/components/scan-ticket-details/scan-ticket-details.component";
import { SnackbarComponent } from "@shared/components/snackbar/snackbar.component";
import { SplashScreenComponent } from "@shared/components/splash-screen/splash-screen.component";
import { TicketForReviewComponent } from "@shared/components/ticket-for-review/ticket-for-review.component";
import { TicketPdfPreviewComponent } from "@shared/components/ticket-pdf-preview/ticket-pdf-preview.component";
import { TicketPreviewComponent } from "@shared/components/ticket-preview/ticket-preview.component";
import { TicketRatingComponent } from "@shared/components/ticket-rating/ticket-rating.component";
import { TopBarComponent } from "@shared/components/top-bar/top-bar.component";
import { ValidationErrorComponent } from "@shared/components/validation-error/validation-error.component";
import { Config } from "@shared/configs/config";
import { CustomRouterLinkActiveDirective } from "@shared/directives/custom-router-link-active/custom-router-link-active.directive";
import { FormatDatePipe } from "@shared/pipes/format-date.pipe";
import { PricePipe } from "@shared/pipes/price.pipe";
import { RemainigOfferTimePipe } from "@shared/pipes/remaining-offer-time.pipe";
import { ReservationTimerPipe } from "@shared/pipes/reservation-timer.pipe";
import { SanitizeHTMLPipe } from "@shared/pipes/sanitize-html.pipe";
import { AnimationsService } from "@shared/services/animations.service";
import { MapService } from "@shared/services/map.service";
import { NavbarService } from "@shared/services/navbar.service";
import { ReservationTimerService } from "@shared/services/reservation-timer.service";
import { ShareService } from "@shared/services/share.service";
import { SnackbarService } from "@shared/services/snackbar.service";
import { SplashScreenService } from "@shared/services/splash-screen.service";
import { StorageService } from "@shared/services/storage.service";
import {
  DlDateTimeDateModule,
  DlDateTimePickerModule,
} from "angular-bootstrap-datetimepicker";
import { CodeInputModule } from "angular-code-input";
import { NgxCaptchaModule } from "ngx-captcha";
import { NgxSpinnerModule } from "ngx-spinner";
import { NgwWowModule } from "ngx-wow";

export const SHARED_MODULES = [
  CommonModule,
  ReactiveFormsModule,
  FormsModule,
  TranslateModule,
  MatFormFieldModule,
  RouterModule,
  MatRippleModule,
  MatMenuModule,
  GbxsoftFormModule,
  CodeInputModule,
  NgxSpinnerModule,
  MatSnackBarModule,
  MatCheckboxModule,
  MatMenuModule,
  MatDialogModule,
  MatIconModule,
  MatSlideToggleModule,
  MatSelectModule,
  NgwWowModule,
  DlDateTimeDateModule,
  DlDateTimePickerModule,
  NgxCaptchaModule,
  AgmCoreModule.forRoot({
    apiKey: environment.googleApiKey,
    libraries: ["places", "geometry"],
    region: Config.GOOGLE_MAPS_DEFAULT_REGION,
  }),
];

export const SHARED_COMPONENTS = [
  BottomMenuComponent,
  PageNotFundComponent,
  ButtonComponent,
  ListComponent,
  TopBarComponent,
  PhoneInputComponent,
  CountryPickerComponent,
  InfoBoxComponent,
  SplashScreenComponent,
  ConnectionCardComponent,
  RidePathComponent,
  TicketForReviewComponent,
  SnackbarComponent,
  ConfirmDialogComponent,
  TicketRatingComponent,
  MapRidePathComponent,
  BusStopInfoWindowComponent,
  TicketPreviewComponent,
  CarrierInfoDialogComponent,
  RateYourRideDialogComponent,
  CounterComponent,
  FullScreenMapComponent,
  ValidationErrorComponent,
  TicketPdfPreviewComponent,
  PathMapDetailsComponent,
  ScanTicketDetailsComponent,
  PassengerTypeSelectComponent,
  DateTimePickerComponent,
  BusOrderCardComponent,
  OrderPathDetailsComponent,
  OrderInfoAlertComponent,
  OrderPreviewComponent,
  OrderRidePathComponent,
  OrderStatusComponent,
  CarrierInfoComponent,
  OrderCardFooterComponent,
  OrderCardPaymentsComponent,
];

export const SHARED_PIPES = [
  SanitizeHTMLPipe,
  FormatDatePipe,
  PricePipe,
  RemainigOfferTimePipe,
];

export const SHARED_DIRECTIVES = [CustomRouterLinkActiveDirective];

export const SHARED_PROVIDERS = [
  SnackbarService,
  StorageService,
  SplashScreenService,
  AnimationsService,
  MapService,
  NavbarService,
  ShareService,
  ReservationTimerService,
  ReservationTimerPipe,
];
