<bnl-navbar class="navbar d-none d-md-block"></bnl-navbar>
<div class="content">
  <router-outlet></router-outlet>
</div>
<bnl-footer class="footer d-none d-md-block"></bnl-footer>
<bnl-bottom-menu
  class="bottom-nav d-md-none"
  [class.d-none]="!navbarService.isBottomNavVisible"
  [menuTabs]="menuTabs"
></bnl-bottom-menu>
<bnl-auth-dialog></bnl-auth-dialog>
<bnl-ticket-for-review></bnl-ticket-for-review>
