<div class="profile-edit-form">
  <h2 class="profile-edit-form-title" *ngIf="!WindowHelper.isMobile">{{'AccountPage.myData' | translate}}</h2>

  <bnl-info-box class="profile-edit-form-infobox" [textContent]="'ProfileEdit.infoBox' | translate"></bnl-info-box>
  <h4 class="profile-edit-form-title" *ngIf="WindowHelper.isMobile">{{'ProfileEdit.formTitle' | translate}}</h4>

  <div class="profile-edit-form" [formGroup]="userDataForm">
    <div class="profile-edit-form-group">
      <h5 class="profile-edit-form-group-title">{{'ProfileEdit.basicData' | translate}}</h5>
      <div class="profile-edit-form-group-container">
        <div>
          <gbxsoft-input
            class="profile-edit-form-group-container-input"
            formControlName="email"
            [class.disabled]="isRequestPending"
            [invalid]="FormsHelper.isTouchedAndInvalid(userDataForm.get('email'))"
            [config]="{
              name: this.t.instant('Global.Inputs.email'),
              placeholder: this.t.instant('Global.Inputs.email'),
              type: GbxsoftInputTypes.TEXT,
              autocomplete: GbxsoftInputAutocompleteTypes.EMAIL
            }">
          </gbxsoft-input>
          <bnl-validation-error [control]="userDataForm.get('email')"></bnl-validation-error>
        </div>
        <div>
          <gbxsoft-input
            class="profile-edit-form-group-container-input"
            formControlName="name"
            [class.disabled]="isRequestPending"
            [invalid]="FormsHelper.isTouchedAndInvalid(userDataForm.get('name'))"
            [config]="{
              name: this.t.instant('Global.Inputs.firstName'),
              placeholder: this.t.instant('Global.Inputs.firstName'),
              type: GbxsoftInputTypes.TEXT,
              autocomplete: GbxsoftInputAutocompleteTypes.FIRST_NAME
            }">
          </gbxsoft-input>
          <bnl-validation-error [control]="userDataForm.get('name')"></bnl-validation-error>
        </div>
        <div>
          <gbxsoft-input
            class="profile-edit-form-group-container-input"
            formControlName="lastName"
            [class.disabled]="isRequestPending"
            [invalid]="FormsHelper.isTouchedAndInvalid(userDataForm.get('lastName'))"
            [config]="{
              name: this.t.instant('Global.Inputs.lastName'),
              placeholder: this.t.instant('Global.Inputs.lastName'),
              type: GbxsoftInputTypes.TEXT,
              autocomplete: GbxsoftInputAutocompleteTypes.LAST_NAME
            }">
          </gbxsoft-input>
          <bnl-validation-error [control]="userDataForm.get('lastName')"></bnl-validation-error>
        </div>
        <bnl-phone-input
          class="profile-edit-form-group-container-input pointer-events-none"
          [form]="phoneInputForm"
          phoneName="phone"
          phoneFlagName="phoneCountryCode"
          [class.disabled]="isRequestPending"
          [inputConfig]="{
              name: this.t.instant('Global.Inputs.phoneNumber'),
              placeholder: this.t.instant('Global.Inputs.phoneNumber'),
              type: GbxsoftInputTypes.TEXT,
              autocomplete: GbxsoftInputAutocompleteTypes.PHONE_WITHOUT_COUNTRY_CODE
            }">
        </bnl-phone-input>
      </div>
    </div>

    <div class="profile-edit-form-group">
      <mat-checkbox formControlName="sendInvoice" [class.disabled]="isRequestPending">{{'ProfileEdit.companyDataCheckbox' | translate}}</mat-checkbox>
    </div>

    <div class="profile-edit-form-group" [class.profile-edit-form-group--collapsed]="!userDataForm.get('sendInvoice').value">
      <h5 class="profile-edit-form-group-title">{{'ProfileEdit.companyData' | translate}}</h5>
      <div class="profile-edit-form-group-container">
        <gbxsoft-input
          class="profile-edit-form-group-container-input"
          formControlName="invoiceCompanyName"
          [class.disabled]="isRequestPending"
          [config]="{
            name: this.t.instant('Global.Inputs.companyName'),
            placeholder: this.t.instant('Global.Inputs.companyName'),
            type: GbxsoftInputTypes.TEXT,
            autocomplete: GbxsoftInputAutocompleteTypes.OFF
          }">
        </gbxsoft-input>
        <gbxsoft-input
          class="profile-edit-form-group-container-input"
          formControlName="invoiceTaxNumber"
          [class.disabled]="isRequestPending"
          [config]="{
            name: this.t.instant('Global.Inputs.nip'),
            placeholder: this.t.instant('Global.Inputs.nip'),
            type: GbxsoftInputTypes.TEXT,
            autocomplete: GbxsoftInputAutocompleteTypes.OFF
          }">
        </gbxsoft-input>
        <gbxsoft-input
          class="profile-edit-form-group-container-input"
          formControlName="invoiceAddress"
          [class.disabled]="isRequestPending"
          [config]="{
            name: this.t.instant('Global.Inputs.address'),
            placeholder: this.t.instant('Global.Inputs.companyAddressPlaceholder'),
            type: GbxsoftInputTypes.TEXT,
            autocomplete: GbxsoftInputAutocompleteTypes.OFF
          }">
        </gbxsoft-input>
      </div>
    </div>

    <div class="profile-edit-form-buttons">
      <bnl-button
        (click)="onAccountDelete()"
        class="profile-edit-form-buttons-button"
        [class.disabled]="isRequestPending"
        text="{{'ProfileEdit.deleteAccount' | translate}}"
        align="center"
        size="md"
        buttonStyle="danger">
      </bnl-button>

      <bnl-button
        class="profile-edit-form-buttons-button"
        text="{{'ProfileEdit.saveData' | translate}}"
        align="center"
        size="md"
        [class.disabled]="userDataForm.invalid"
        [showLoader]="isRequestPending"
        (click)="onUserDataFormSubmit()">
      </bnl-button>
    </div>
  </div>
</div>
