import {
  AfterContentChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
} from "@angular/core";
import { OrdersService } from "@core/services/orders.service";
import { BusRentGraphql } from "@modules/graphql/graphql.service";

@Component({
  selector: "bnl-order-card-footer",
  templateUrl: "./order-card-footer.component.html",
  styleUrls: ["./order-card-footer.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderCardFooterComponent implements AfterContentChecked {
  @Input() isCarrierView = false;
  @Input() order: BusRentGraphql;
  isChecked = false;

  constructor(
    public ordersService: OrdersService,
    private cd: ChangeDetectorRef
  ) {}

  ngAfterContentChecked(): void {
    if (!this.isChecked) {
      this.cd.markForCheck();
    }
  }
}
