import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { SEOService } from "@shared/services/seo.service";
import { filter, map, mergeMap } from "rxjs/operators";
import { Injector } from "@angular/core";
import { AppInjectorService } from "@shared/services/app-injector.service";
import { environment } from "@environments/environment";

export class RouterNavigationController {
  protected readonly injector: Injector;
  private readonly router: Router;
  private readonly route: ActivatedRoute;
  private readonly seoService: SEOService;
  private readonly translate: TranslateService;

  constructor() {
    this.injector = AppInjectorService.getInjector();
    this.router = this.injector.get(Router);
    this.route = this.injector.get(ActivatedRoute);
    this.seoService = this.injector.get(SEOService);
    this.translate = this.injector.get(TranslateService);
    this.listenNavigationEnd();
  }

  listenNavigationEnd() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.route),
        map((route) => {
          while (route.children[route.children.length - 1]) {
            route = route.children[route.children.length - 1];
          }
          return route;
        }),
        mergeMap((route) => route.data)
      )
      .subscribe((routeData) => {
        this.setMetatitle(routeData?.["metaTitle"]);
      });
  }

  private setMetatitle(metaTitle: string): void {
    const title: string = metaTitle
      ? " | " + this.translate.instant(metaTitle)
      : "";
    this.seoService.updateTitle(`${environment.pageTitlePrefix} ${title}`);
  }
}
