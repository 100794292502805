import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { SnackBarState } from '@shared/enums/snack-bar-state.enum';
import { SnackbarComponent } from '@shared/components/snackbar/snackbar.component';

@Injectable()
export class SnackbarService {
  constructor(private snackbar: MatSnackBar) {
  }

  public open(message: string, state: SnackBarState, duration: number = 4000, position: MatSnackBarVerticalPosition = 'bottom'): void {
    this.snackbar.openFromComponent(SnackbarComponent, {
      panelClass: state,
      verticalPosition: position,
      duration,
      data: {
        message,
        state
      }
    });
  }
}

