<div class="ticket-page" [class.ticket-page--mapPreview]="isMapPreviewOpen">
  <bnl-top-bar *ngIf="topBarConfig" [config]="topBarConfig" class="sticky-top d-md-none"></bnl-top-bar>

  <div class="background d-none d-md-block">
    <div class="container container--flat ticket-page-header">
      <ngx-spinner
        class="order-loader"
        [class.is-loading]="isRequestPending"
        [name]="ORDER_LOADER"
        [fullScreen]="false"
        size="default"
        type="ball-clip-rotate"
        bdColor="rgba(0, 0, 0, 0)"
        color="#fff"
      ></ngx-spinner>
      <ng-container *ngIf="order && !isRequestPending">
        <div class="header-wrapper">
          <div class="header-wrapper-back" (click)="goToOffers()">
            <img src="/app/shared/assets/svg/arrow-back.svg" alt="Back" />
          </div>
          <div class="header-wrapper-content">
            <h3 class="ticket-page-header-stations">
              {{ order.departure.name }} -
              {{ order.destination.name }}
            </h3>
            <h2 class="ticket-page-header-date">
              {{ moment(order.departureDate).format(config.TICKET_DATE_FORMAT) }}
            </h2>
          </div>
        </div>
        <ng-container [ngTemplateOutlet]="orderPreviewTemplate" *ngIf="!isMapPreviewOpen"></ng-container>
      </ng-container>
    </div>
  </div>

  <ng-container *ngIf="!isRequestPending && order">
    <div class="ticket-page-preview d-md-none" *ngIf="!isMapPreviewOpen">
      <ng-container [ngTemplateOutlet]="orderPreviewTemplate"></ng-container>
    </div>
  </ng-container>

  <div class="container container--flat" [hidden]="isMapPreviewOpen" *ngIf="!isRequestPending">
    <div class="payments" id="payment-section">
      <bnl-order-info-alert
        *ngIf="reservationTimerService.timerStopped$ | async"
        [type]="InfoAlertEnum.DANGER"
        [description]="'OrderPaymentPage.ReservationTimeEnd' | translate"
      ></bnl-order-info-alert>

      <ng-container
        *ngIf="
          !(reservationTimerService.timerStopped$ | async) &&
            currentOffer?.status === RentOfferStatus.WaitingForPayment;
          else backToOffers
        "
      >
        <div class="payments-title">{{ "OrderPaymentPage.Title" | translate }}</div>

        <div class="payments-invoice">
          <div class="payments-form-controls-invoice">
            <div class="payments-form-controls-invoice-radio mb-3">
              <label class="d-inline-flex align-items-center">
                <mat-checkbox [class.disabled]="isRequestPending" (change)="selectInvoice($event)">
                  {{ "OrderPaymentPage.SelectInvoice" | translate }}
                </mat-checkbox>
              </label>
            </div>
            <div class="payments-form-controls-invoice-form" [formGroup]="invoiceForm" [hidden]="!needInvoice">
              <div class="payments-invoice-title">{{ "OrderPaymentPage.Invoice" | translate }}</div>
              <div class="row">
                <div class="col-md-3">
                  <div class="form-group">
                    <gbxsoft-input
                      class="profile-edit-form-group-container-input"
                      formControlName="invoiceCompanyName"
                      [class.disabled]="isRequestPending"
                      [invalid]="FormsHelper.isTouchedAndInvalid(invoiceForm.get('invoiceCompanyName'))"
                      [config]="{
                        name: this.t.instant('Global.Inputs.companyName'),
                        placeholder: this.t.instant('Global.Inputs.companyName'),
                        type: GbxsoftInputTypes.TEXT,
                        autocomplete: GbxsoftInputAutocompleteTypes.OFF
                      }"
                    >
                    </gbxsoft-input>
                    <bnl-validation-error [control]="invoiceForm.get('invoiceCompanyName')"></bnl-validation-error>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <gbxsoft-input
                      class="profile-edit-form-group-container-input"
                      formControlName="invoiceTaxNumber"
                      [class.disabled]="isRequestPending"
                      [invalid]="FormsHelper.isTouchedAndInvalid(invoiceForm.get('invoiceTaxNumber'))"
                      [config]="{
                        name: this.t.instant('Global.Inputs.nip'),
                        placeholder: this.t.instant('Global.Inputs.nip'),
                        type: GbxsoftInputTypes.TEXT,
                        autocomplete: GbxsoftInputAutocompleteTypes.OFF
                      }"
                    >
                    </gbxsoft-input>
                    <bnl-validation-error [control]="invoiceForm.get('invoiceTaxNumber')"></bnl-validation-error>
                  </div>
                </div>
                <div class="col-md-5">
                  <gbxsoft-input
                    class="profile-edit-form-group-container-input"
                    formControlName="invoiceAddress"
                    [class.disabled]="isRequestPending"
                    [invalid]="FormsHelper.isTouchedAndInvalid(invoiceForm.get('invoiceAddress'))"
                    [config]="{
                      name: this.t.instant('Global.Inputs.address'),
                      placeholder: this.t.instant('Global.Inputs.companyAddressPlaceholder'),
                      type: GbxsoftInputTypes.TEXT,
                      autocomplete: GbxsoftInputAutocompleteTypes.OFF
                    }"
                  >
                  </gbxsoft-input>
                  <bnl-validation-error [control]="invoiceForm.get('invoiceAddress')"></bnl-validation-error>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div [formGroup]="form">
          <div class="payments-form">
            <div>
              <mat-radio-group
                aria-label="Select an option"
                [value]="OrderPaymentType.PARTIAL"
                formControlName="paymentType"
              >
                <div class="payments-form-controls">
                  <div
                    class="payments-form-controls-item"
                    [class.is-selected]="form.get('paymentType').value === OrderPaymentType.PARTIAL"
                  >
                    <mat-radio-button [value]="OrderPaymentType.PARTIAL">
                      <div class="payments-form-controls-title">
                        {{ "OrderPaymentPage.OnlyReservation" | translate }}
                      </div>
                      <div class="payments-form-controls-method">
                        <div class="payments-form-controls-method-bar">
                          <div class="payments-form-controls-method-icon">
                            <img src="/assets/svg/payment-check.svg" alt="Przelewy24" />
                          </div>
                          <div class="payments-form-controls-method-logo">
                            <img src="/assets/img/przelewy24.png" alt="Przelewy24" />
                          </div>
                        </div>
                        <div class="payments-form-controls-method-info">
                          {{ "OrderPaymentPage.Info" | translate }}
                        </div>
                      </div>
                      <div class="payments-form-controls-rest">
                        <div class="payments-form-controls-rest-title">
                          {{ "OrderPaymentPage.RestPaymentMethod" | translate }}
                        </div>
                        <div formGroupName="secondPayment">
                          <mat-radio-group
                            formControlName="type"
                            [value]="availablePaymentMethods[0]"
                            class="is-default"
                          >
                            <div
                              class="payments-form-controls-rest-item"
                              *ngFor="let method of availablePaymentMethods"
                            >
                              <mat-radio-button class="is-default" [value]="method">{{
                                "OrderPaymentPage.SecondPayment" + method | translate
                              }}</mat-radio-button>
                            </div>
                          </mat-radio-group>
                        </div>
                      </div>
                      <div class="payments-form-controls-method-partial">
                        <div class="payments-form-controls-method-partial-title">
                          {{ "OrderPaymentPage.PaymentPriceTitle" | translate }}
                        </div>
                        <div class="payments-form-controls-method-partial-price">
                          <div class="payments-form-controls-method-partial-price-label">
                            {{ "OrderPaymentPage.ReservationPrice" | translate }}
                          </div>
                          <div class="payments-form-controls-method-partial-price-value">{{ advance | price }}</div>
                        </div>
                        <div class="payments-form-controls-method-partial-summary">
                          <div class="payments-form-controls-method-partial-summary-label">
                            <b>{{ "OrderPaymentPage.Total" | translate }}</b>
                            {{ "OrderPaymentPage.VatIncluded" | translate }}
                          </div>
                          <div class="payments-form-controls-method-partial-summary-value">{{ advance | price }}</div>
                        </div>
                      </div>
                      <div
                        class="payments-form-controls-method-action"
                        *ngIf="!(reservationTimerService.timerStopped$ | async)"
                      >
                        <div class="payments-form-controls-method-action-wrapper">
                          <bnl-button
                            class="order-pay-button"
                            [class.is-disabled]="isPaying"
                            [showLoader]="isRequestPending"
                            (click)="$event.stopPropagation(); payOrder(OrderPaymentType.PARTIAL)"
                            [text]="'OrderPaymentPage.Pay' | translate"
                            [boldText]="advance | price"
                            align="between"
                            size="md"
                            [disabled]="form.get('paymentType').value === OrderPaymentType.FULL || isPaying"
                          >
                          </bnl-button>
                          <div
                            class="payments-form-controls-method-action-info"
                            [innerHTML]="'OrderPaymentPage.PayTermsInfo' | translate"
                          ></div>
                        </div>
                      </div>
                    </mat-radio-button>
                  </div>
                  <div
                    class="payments-form-controls-item"
                    [class.is-selected]="form.get('paymentType').value === OrderPaymentType.FULL"
                  >
                    <mat-radio-button [value]="OrderPaymentType.FULL">
                      <div class="payments-form-controls-title">{{ "OrderPaymentPage.PayTotal" | translate }}</div>
                      <div class="payments-form-controls-method-full">
                        <div class="payments-form-controls-method-full-summary">
                          <div class="payments-form-controls-method-full-summary-label">
                            <b>{{ "OrderPaymentPage.Total" | translate }}</b>
                            {{ "OrderPaymentPage.VatIncluded" | translate }}
                          </div>
                          <div class="payments-form-controls-method-full-summary-value">
                            {{ currentOffer?.price | price }}
                          </div>
                        </div>
                      </div>
                      <div
                        class="payments-form-controls-method-action"
                        *ngIf="!(reservationTimerService.timerStopped$ | async)"
                      >
                        <div class="payments-form-controls-method-action-wrapper">
                          <bnl-button
                            class="order-pay-button"
                            [class.is-disabled]="isPaying"
                            [showLoader]="isRequestPending"
                            (click)="$event.stopPropagation(); payOrder(OrderPaymentType.FULL)"
                            [text]="'OrderPaymentPage.Pay' | translate"
                            [boldText]="currentOffer?.price | price"
                            align="between"
                            size="md"
                            [disabled]="form.get('paymentType').value === OrderPaymentType.PARTIAL || isPaying"
                          >
                          </bnl-button>
                          <div
                            class="payments-form-controls-method-action-info"
                            [innerHTML]="'OrderPaymentPage.PayTermsInfo' | translate"
                          ></div>
                        </div>
                      </div>
                    </mat-radio-button>
                  </div>
                </div>
              </mat-radio-group>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-template #backToOffers>
        <div class="go-to-offers">
          <bnl-button
            (click)="goToOffers(); $event.stopPropagation()"
            class="order-card-payment-button"
            [text]="'OrderPaymentPage.BackToOffers' | translate"
            size="sm"
            align="center"
            [rounded]="true"
          ></bnl-button>
        </div>
      </ng-template>
    </div>
  </div>
</div>

<bnl-full-screen-map
  *ngIf="isMapPreviewOpen && order"
  class="results-page-map"
  [order]="order"
  (mapClose)="onMapClose()"
>
</bnl-full-screen-map>

<ng-template #orderPreviewTemplate>
  <bnl-bus-order-card
    [order]="order"
    (mapPreviewOpen)="onMapOpen()"
    (payReservationEmit)="onPayReservation()"
    [isOrderCard]="true"
    [showTimer]="currentOffer?.status === RentOfferStatus.WaitingForPayment"
    [isPaymentCard]="true"
    [isDetailsPartOpen]="false"
  ></bnl-bus-order-card>
</ng-template>
