import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TopBarConfig } from '@shared/interfaces/top-bar-config.interface';
import { BaseComponent } from '@shared/components/base-component';
import { AdditionalPassenger } from '@modules/tabs/search-tab/shared/models/additional-passenger.model';
import { AdditionalPassengerAgeEnum, AdditionalPassengerAgeRangeEnum } from '@modules/graphql/graphql.service';
import { PassengersConfig } from '@modules/tabs/search-tab/shared/interfaces/passengers-config.interface';
import { TicketCreatorService } from '@modules/tabs/search-tab/shared/services/ticket-creator.service';

@Component({
  selector: 'bnl-add-passenger-dialog',
  templateUrl: './add-passenger-dialog.component.html',
  styleUrls: ['./add-passenger-dialog.component.scss']
})
export class AddPassengerDialogComponent extends BaseComponent implements OnInit {
  AdditionalPassengerAgeEnum = AdditionalPassengerAgeEnum;
  AdditionalPassengerAgeRangeEnum = AdditionalPassengerAgeRangeEnum;
  topBarConfig: TopBarConfig;

  additionalPassengers = JSON.parse(JSON.stringify(this.ticketCreator.additionalPassengers)) as PassengersConfig;

  get totalChildren(): number{
    return this.additionalPassengers.child.length;
  }

  get totalAdults(): number{
    return this.additionalPassengers.adult.length;
  }

  get totalPensioners(): number{
    return this.additionalPassengers.pensioner.length;
  }

  constructor(
    public dialogRef: MatDialogRef<AddPassengerDialogComponent>,
    public ticketCreator: TicketCreatorService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    super();
  }

  ngOnInit(): void {
    this.setupTopBar();
  }

  onPassengersSubmit(): void {
    this.ticketCreator.additionalPassengers = this.additionalPassengers;
    this.dialogRef.close(true);
  }

  onPassengerAdd(targetArray: AdditionalPassenger[], age: AdditionalPassengerAgeEnum): void {
    targetArray.push(new AdditionalPassenger(age, false, '', ''));
  }

  get seatsLeft(): number {
    return this.ticketCreator.selectedPathway.availableSeats - this.ticketCreator.getAdditionalPassengersCount(this.additionalPassengers) - 1;
  }

  get isPassengersConfigValid(): boolean {
    for (const passenger of this.additionalPassengers.child) {
      if (!passenger.ageRange) {
        return false;
      }
    }

    return true;
  }

  private setupTopBar(): void {
    this.topBarConfig = {
      title: this.t.instant('Ticket.pickPassengers'),
      isTransparent: true,
      leftIcon: {
        src: '/app/shared/assets/svg/close.svg',
        alt: this.t.instant('Global.IconAlts.closeIcon'),
        clickHandler: () => this.dialogRef.close()
      }
    };
  }
}
