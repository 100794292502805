import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PathwaysListElementGraphql } from '@modules/graphql/graphql.service';
import moment from 'moment';
import { MatDialog } from '@angular/material/dialog';
import { CarrierInfoDialogComponent } from '@shared/components/carrier-info-dialog/carrier-info-dialog.component';
import { TicketsService } from '@core/services/tickets.service';

@Component({
  selector: 'bnl-ticket-preview',
  templateUrl: './ticket-preview.component.html',
  styleUrls: ['./ticket-preview.component.scss']
})
export class TicketPreviewComponent {
  @Input() pathway: PathwaysListElementGraphql;
  @Input() isHistoryPreview = false;
  @Input() isDriverCard = false;
  @Output() mapOpen = new EventEmitter<void>();
  @Output() settingsOpen = new EventEmitter<void>();
  moment = moment;

  constructor(public ticketsService: TicketsService, private dialog: MatDialog) {
  }

  onCarrierInfoDialogOpen(): void {
    this.dialog.open(CarrierInfoDialogComponent, {
      panelClass: 'carrier-info-dialog',
      data: {carrierInfo: this.pathway.pathway.company}
    });
  }
}
