import { ChangeDetectorRef, Component } from '@angular/core';
import { Location } from '@angular/common';
import { TopBarConfig } from '@shared/interfaces/top-bar-config.interface';
import { BaseComponent } from '@shared/components/base-component';

@Component({
  selector: 'bnl-privacy-policy-page',
  templateUrl: './privacy-policy-page.component.html',
  styleUrls: ['./privacy-policy-page.component.scss']
})
export class PrivacyPolicyPageComponent extends BaseComponent {
  topBarConfig: TopBarConfig = {
    title: this.t.instant('AccountPage.privacyPolicy'),
    leftIcon: {
      src: '/app/shared/assets/svg/back.svg',
      alt: this.t.instant('Global.IconAlts.backIcon'),
      clickHandler: () => this.location.back()
    }
  };

  constructor(private location: Location, private ref: ChangeDetectorRef) {
    super();
  }
}
