import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '@core/modules/authorization/shared/services/auth.service';
import { NavigateService } from '@core/services/navigate.service';
import { Injectable } from '@angular/core';
import { SnackbarService } from '@shared/services/snackbar.service';
import { SnackBarState } from '@shared/enums/snack-bar-state.enum';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private navigateService: NavigateService,
    private snackbarService: SnackbarService,
    private translateService: TranslateService
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.isAuthenticated();
  }

  private isAuthenticated(): boolean {
    if (this.authService.isSignedIn()) {
      return true;
    } else {
      this.snackbarService.open(this.translateService.instant('Global.Messages.loginToAccess'), SnackBarState.ERROR);
      this.navigateService.navigate('search');
      return false;
    }
  }
}
