<div class="ticket-page" [class.ticket-page--mapPreview]="isMapPreviewOpen">
  <bnl-top-bar *ngIf="topBarConfig" [config]="topBarConfig" class="sticky-top d-md-none"></bnl-top-bar>

  <div class="background d-none d-md-block">
    <div class="container container--flat ticket-page-header">
      <ngx-spinner
        class="order-loader"
        [class.is-loading]="isRequestPending"
        [name]="ORDER_LOADER"
        [fullScreen]="false"
        size="default"
        type="ball-clip-rotate"
        bdColor="rgba(0, 0, 0, 0)"
        color="#fff"
      ></ngx-spinner>
      <ng-container *ngIf="order && !isRequestPending">
        <div class="header-wrapper">
          <div class="header-wrapper-back" (click)="goToOrders()">
            <img src="/app/shared/assets/svg/arrow-back.svg" alt="Back" />
          </div>
          <div class="header-wrapper-content">
            <h3 class="ticket-page-header-stations">
              {{ order.departure.name }} -
              {{ order.destination.name }}
            </h3>
            <h2 class="ticket-page-header-date">
              {{ moment(order.departureDate).format(config.TICKET_DATE_FORMAT) }}
            </h2>
          </div>
        </div>
        <ng-container [ngTemplateOutlet]="orderPreviewTemplate" *ngIf="!isMapPreviewOpen"></ng-container>
      </ng-container>
    </div>
  </div>

  <ng-container *ngIf="!isRequestPending && order">
    <div class="ticket-page-preview d-md-none" *ngIf="!isMapPreviewOpen">
      <ng-container [ngTemplateOutlet]="orderPreviewTemplate"></ng-container>
    </div>

    <div *ngIf="order.offers.length; else noOffers">
      <div class="bus-offers" *ngIf="!order.confirmedOffer; else confirmedOffer">
        <div class="container container--flat">
          <div class="bus-offers-info">
            <bnl-order-info-alert
              *ngIf="order.note"
              [type]="InfoAlertEnum.INFO"
              [description]="'OrdersPage.OfferTimeInfo' | translate"
            ></bnl-order-info-alert>
          </div>
          <div class="bus-offers-list">
            <div class="bus-offers-list-item" *ngFor="let offer of order.offers">
              <bnl-order-offer
                [offer]="offer"
                [departureDate]="order.departureDate"
                [createdAt]="order.createdAt"
              ></bnl-order-offer>
            </div>
          </div>
        </div>
      </div>
      <ng-template #confirmedOffer>
        <div class="bus-offers">
          <div class="container container--flat">
            <div class="bus-offers-list">
              <div class="bus-offers-list-item">
                <bnl-order-offer [offer]="order.confirmedOffer" [createdAt]="order.createdAt"></bnl-order-offer>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </div>
    <ng-template #noOffers>
      <div class="results-page-empty wow fadeIn">
        <div class="results-page-empty-icon">
          <img src="/app/shared/assets/svg/no-results.svg" alt="{{ 'Global.ImagesAlts.busStop' | translate }}" />
        </div>
        <p class="results-page-empty-title" [innerHTML]="'OrdersPage.Order.Offers.Empty.title' | translate"></p>
        <p class="results-page-empty-desc" [innerHTML]="'OrdersPage.Order.Offers.Empty.subtitle' | translate"></p>
      </div>
    </ng-template>
  </ng-container>
</div>

<bnl-full-screen-map
  *ngIf="isMapPreviewOpen && order"
  class="results-page-map"
  [order]="order"
  (mapClose)="onMapClose()"
>
</bnl-full-screen-map>

<ng-template #orderPreviewTemplate>
  <bnl-bus-order-card [order]="order" (mapPreviewOpen)="onMapOpen()" [isOrderCard]="true"></bnl-bus-order-card>
</ng-template>
