import { Injectable } from '@angular/core';
import {
  BusStopGraphql,
  GetAirportsGQL,
  GetAirportsQuery,
  GetNearestPathwaysGQL,
  GetNearestPathwaysQuery,
  GetPassengerSearchHistoryGQL,
  GetPassengerSearchHistoryQuery,
  PathwaysSearchListInput,
  SearchQueryGraphql,
  TicketGraphql,
} from '@modules/graphql/graphql.service';
import { Observable } from 'rxjs';
import { FetchResult } from 'apollo-link';
import { SearchResult } from '@modules/tabs/search-tab/shared/interfaces/search-result.interface';
import { map } from 'rxjs/operators';

@Injectable()
export class SearchService {
  private _searchHistory: SearchQueryGraphql[];
  private _searchParamsFormState: any;
  private _searchFromState: any;

  constructor(
    private getAirportsGQL: GetAirportsGQL,
    private getNearestPathwaysGQL: GetNearestPathwaysGQL,
    private getPassengerSearchHistoryGQL: GetPassengerSearchHistoryGQL
  ) {}

  public getAirports(): Observable<FetchResult<GetAirportsQuery>> {
    return this.getAirportsGQL.fetch();
  }

  public getAirportsAsSearchResults(): Observable<SearchResult[]> {
    return this.getAirports().pipe(
      map((res: FetchResult<GetAirportsQuery>) => {
        return res.data.getAirports.map((busStop: BusStopGraphql) => ({
          isSearched: true,
          name: busStop.name,
          busStopId: busStop.id,
        }));
      })
    );
  }

  public getPathways(
    listInput: PathwaysSearchListInput
  ): Observable<FetchResult<GetNearestPathwaysQuery>> {
    return this.getNearestPathwaysGQL.fetch(
      { listInput },
      {
        fetchPolicy: 'network-only',
      }
    );
  }

  public getSearchHistory(): Observable<
    FetchResult<GetPassengerSearchHistoryQuery>
  > {
    return this.getPassengerSearchHistoryGQL.fetch(
      {},
      {
        fetchPolicy: 'network-only',
      }
    );
  }

  public filterBusStops(
    searchResults: SearchResult[],
    searchVal: string
  ): SearchResult[] {
    return searchResults.map((result: SearchResult) => ({
      ...result,
      isSearched:
        result.name.toLowerCase().search(searchVal.toLowerCase()) !== -1,
    }));
  }

  public initSearchFromTicket(ticket: TicketGraphql): void {
    this.searchParamsFormState = {
      airportId: ticket.departureData.end.busStop.id,
      toAirport: ticket.departureData.pathway.toAirport,
      localization: {
        address: ticket.departureData.start.busStop.address,
      },
    };

    this.searchFromState = {
      place: ticket.departureData.start.busStop.address,
      airport: ticket.departureData.end.busStop.name,
      tripDate: '',
    };
  }

  public swapSearchDirection(ticket: TicketGraphql): void {
    const toAirport = !ticket.departureData.pathway.toAirport;
    const airportId = toAirport
      ? ticket.departureData.start.busStop.id
      : ticket.departureData.end.busStop.id;
    const address = toAirport
      ? ticket.departureData.end.busStop.address
      : ticket.departureData.start.busStop.address;

    this.searchParamsFormState = {
      airportId: airportId,
      toAirport: toAirport,
      localization: {
        address: address,
      },
    };

    const place = toAirport
      ? ticket.departureData.end.busStop.name
      : ticket.departureData.start.busStop.name;
    const airport = toAirport
      ? ticket.departureData.start.busStop.name
      : ticket.departureData.end.busStop.name;

    this.searchFromState = {
      place: place,
      airport: airport,
      tripDate: '',
    };
  }

  get searchHistory(): SearchQueryGraphql[] {
    return this._searchHistory;
  }

  set searchHistory(value: SearchQueryGraphql[]) {
    this._searchHistory = value;
  }

  get searchParamsFormState(): any {
    return this._searchParamsFormState;
  }

  set searchParamsFormState(value: any) {
    this._searchParamsFormState = value;
  }

  get searchFromState(): any {
    return this._searchFromState;
  }

  set searchFromState(value: any) {
    this._searchFromState = value;
  }
}
