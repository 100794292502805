import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { NavigateService } from '@core/services/navigate.service';
import { SearchService } from '@core/services/search.service';
import { TicketsService } from '@core/services/tickets.service';
import { environment } from '@environments/environment';
import { GraphQLModule } from '@modules/graphql/graphql.module';
import { SharedModule } from '@shared/shared.module';
import { AppRoutingModule } from '../app-routing.module';
import { FooterComponent } from './components/footer/footer.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { RentalCounterGuard } from './guards/rental-counter.guard';
import { AuthorizationModule } from './modules/authorization/authorization.module';
import { OrdersService } from './services/orders.service';

const CORE_MODULES = [
  BrowserModule,
  BrowserAnimationsModule,
  AppRoutingModule,
  GraphQLModule,
  AuthorizationModule,
  SharedModule,
  ServiceWorkerModule.register('ngsw-worker.js', {
    enabled: environment.production,
    registrationStrategy: 'registerImmediately',
  }),
];

const CORE_COMPONENTS = [NavbarComponent, FooterComponent];

const CORE_PROVIDERS = [SearchService, TicketsService, NavigateService, OrdersService, RentalCounterGuard];

@NgModule({
  imports: [CORE_MODULES],
  exports: [CORE_MODULES, CORE_COMPONENTS],
  declarations: [CORE_COMPONENTS],
  providers: [CORE_PROVIDERS],
})
export class CoreModule {}
