import { AbstractControl } from "@angular/forms";
import { isEmail } from "class-validator";

// number with comma and two numbers after comma
const numberWithComma = /^[1-9][0-9]*(?:.[0-9]{2})?$/;
const MAX_OFFER_VALUE = 10000;

export class CustomValidators {
  static isEmailValidator(control: AbstractControl): { [key: string]: any } {
    if (!control?.value) {
      return null;
    }

    if (!isEmail(control.value)) {
      return { isEmail: true };
    }

    return null;
  }

  static isNumberValidator(control: AbstractControl): { [key: string]: any } {
    if (!control?.value) {
      return null;
    }

    const value = control?.value.replace(",", ".");
    const isValidNumber = !isNaN(parseFloat(value)) && isFinite(value);
    const isInvalid = !numberWithComma.test(value) || !isValidNumber;

    if (isInvalid) {
      return { isNumber: true };
    }

    return null;
  }

  static isMaxOfferPrice(control: AbstractControl): { [key: string]: any } {
    if (!control?.value) {
      return null;
    }

    const value = parseFloat(control.value.replace(",", "."));

    if (value > MAX_OFFER_VALUE) {
      return { maxOffer: true, actual: MAX_OFFER_VALUE };
    }

    return null;
  }
}
