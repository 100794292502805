import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OrdersService } from '@core/services/orders.service';
import { PaymentStatusEnum, RentPaymentGraphql } from '@modules/graphql/graphql.service';
import { BaseComponent } from '@shared/components/base-component';
import { Subject, merge } from 'rxjs';
import { delay, finalize, repeatWhen, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'bnl-order-payment-page',
  templateUrl: './order-payment-page.component.html',
  styleUrls: ['./order-payment-page.component.scss'],
})
export class OrderPaymentPageComponent extends BaseComponent implements OnInit {
  paymentError = false;

  readonly PAYMENT_LOADER = 'payment-loader';

  constructor(private ordersService: OrdersService, private route: ActivatedRoute) {
    super();
  }

  ngOnInit(): void {
    this.auth.onLogout.pipe(takeUntil(this.destroyed)).subscribe(() => {
      this.n.navigate('search');
    });

    this.checkPayment(this.route.snapshot.paramMap.get('paymentToken'));
  }

  backToMyOrders(): void {
    this.router.navigate(['/bus-rental/my-orders']);
  }

  backToConfirmedOrders(): void {
    this.router.navigate(['/bus-rental/confirmed-orders']);
  }

  private checkPayment(token: string): void {
    const stop = new Subject<void>();

    this.isRequestPending = true;
    this.spinner.show(this.PAYMENT_LOADER);
    this.ordersService
      .getRentPayment(token)
      .pipe(
        repeatWhen((obs) => obs.pipe(delay(1000))),
        takeUntil(merge(stop, this.destroyed)),
        finalize(() => {
          this.isRequestPending = false;
          this.spinner.hide(this.PAYMENT_LOADER);
        })
      )
      .subscribe(
        (response: RentPaymentGraphql) => {
          const { status } = response;

          if (status === PaymentStatusEnum.Completed) {
            stop.next();
            stop.complete();
          } else if (status === PaymentStatusEnum.Failed) {
            this.paymentError = true;
            stop.next();
            stop.complete();
          }
        },
        () => {
          this.paymentError = true;
          stop.next();
          stop.complete();
        }
      );
  }
}
