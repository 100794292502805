import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TicketsService } from '@core/services/tickets.service';
import { TicketMapService } from '@modules/tabs/tickets-tab/shared/services/ticket-map.service';
import { NavigateService } from '@core/services/navigate.service';
import { Observable } from 'rxjs';
import { TicketGraphql } from '@modules/graphql/graphql.service';

@Component({
  selector: 'bnl-history-tickets-list',
  templateUrl: './history-tickets-list.component.html',
  styleUrls: ['./history-tickets-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HistoryTicketsListComponent {
  get historyTickets$(): Observable<TicketGraphql[]> {
    return this.ticketsService.historyTickets$;
  }

  get loading$(): Observable<boolean> {
    return this.ticketsService.loading$;
  }

  constructor(
    public ticketsService: TicketsService,
    public ticketMapService: TicketMapService,
    public n: NavigateService
  ) {}
}
