import { Component, EventEmitter, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CountryPickerEvent, } from './components/country-picker/country-picker.component';
import { BaseComponent } from '@shared/components/base-component';
import { GbxsoftInputComponent } from '@form/src/lib/gbxsoft-input/gbxsoft-input.component';
import { GbxsoftInputConfig } from '@form/src/lib/gbxsoft-input/interfaces/gbxsoft-input.interface';
import { GbxsoftInputTypes } from '@form/src/lib/gbxsoft-input/gbxsoft-input.types';

@Component({
  selector: 'bnl-phone-input',
  templateUrl: './phone-input.component.html',
  styleUrls: ['phone-input.component.scss']
})
export class PhoneInputComponent extends BaseComponent implements OnInit {
  @ViewChild('input') input: GbxsoftInputComponent;

  @Input() form: FormGroup;
  @Input() phoneFlagName: string;
  @Input() phoneName: string;
  @Input() inputConfig: GbxsoftInputConfig;
  @Input() invalid = false;

  changesEmitter = new EventEmitter<CountryPickerEvent>();

  constructor() {
    super();
  }

  ngOnInit(): void {
    if (this.config) {
      this.inputConfig.type = GbxsoftInputTypes.TEXT;
    }
  }
}
