import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { Observable, Subscriber } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LocalizationService {
  private readonly _LOCALIZATION_TIMEOUT = 5000;

  constructor(private translateService: TranslateService) {}

  public getCurrentPosition(enableHighAccuracy: boolean = false, timeout: number = this.LOCALIZATION_TIMEOUT): Observable<Position> {
    return new Observable(((subscriber: Subscriber<Position>) => {
      navigator.geolocation.getCurrentPosition((position: Position) => {
        subscriber.next(position);
        subscriber.complete();
      }, (error: PositionError) => {
        subscriber.error(error);
      }, {
        enableHighAccuracy,
        timeout
      });
    }));
  }

  public getLocalizationErrorMessage(error: PositionError): string {
    switch (error.code) {
      case error.PERMISSION_DENIED: {
        return this.translateService.instant('SearchPage.locationPermissionDenied');
      }

      case error.POSITION_UNAVAILABLE: {
        return this.translateService.instant('SearchPage.locationUnavailable');
      }

      case error.TIMEOUT: {
        return this.translateService.instant('SearchPage.locationTimedOut');
      }
    }
  }

  get LOCALIZATION_TIMEOUT(): number {
    return this._LOCALIZATION_TIMEOUT;
  }
}
