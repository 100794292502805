import { NgModule } from "@angular/core";
import {
  SHARED_COMPONENTS,
  SHARED_DIRECTIVES,
  SHARED_MODULES,
  SHARED_PIPES,
  SHARED_PROVIDERS,
} from "@shared/shared-consts";

@NgModule({
  declarations: [SHARED_COMPONENTS, SHARED_PIPES, SHARED_DIRECTIVES],
  imports: [SHARED_MODULES],
  exports: [SHARED_MODULES, SHARED_COMPONENTS, SHARED_DIRECTIVES, SHARED_PIPES],
  providers: [SHARED_PROVIDERS],
})
export class SharedModule {}
