import { PathwaysListElementGraphql } from '@modules/graphql/graphql.service';

export class PathwayWrapper {
  constructor(private _pathway: PathwaysListElementGraphql, private _isSeparatorVisible, private _isEmpty: boolean = false) {
  }

  get pathway(): PathwaysListElementGraphql {
    return this._pathway;
  }

  set pathway(value: PathwaysListElementGraphql) {
    this._pathway = value;
  }

  get isSeparatorVisible() {
    return this._isSeparatorVisible;
  }

  set isSeparatorVisible(value) {
    this._isSeparatorVisible = value;
  }

  get isEmpty(): boolean {
    return this._isEmpty;
  }
}
