<div class="order-card-info" [class.is-carrier-view]="isCarrierView">
  <div class="order-card-info-item" *ngIf="isCarrierView">
    <div class="order-card-info-item-icon">
      <img
        [src]="
          '/app/shared/assets/svg/' +
          (order.returnDate ? 'both-ways.svg' : 'one-way.svg')
        "
        alt="{{
          order.returnDate
            ? ('BusRentalPage.BothWays' | translate)
            : ('BusRentalPage.OneWay' | translate)
        }}"
      />
    </div>
    <div class="order-card-info-item-value">
      <div class="order-card-info-item-title">
        {{
          order.returnDate
            ? ("BusRentalPage.BothWays" | translate)
            : ("BusRentalPage.OneWay" | translate)
        }}
      </div>
    </div>
  </div>
  <div class="order-card-info-item" *ngIf="isCarrierView">
    <div class="order-card-info-item-icon">
      <img
        src="/app/shared/assets/svg/map-summary.svg"
        alt="{{ 'BusRentalPage.ImageAlt.Distance' | translate }}"
      />
    </div>
    <div class="order-card-info-item-value">
      <div class="order-card-info-item-title">
        {{ "BusRentalPage.Distance" | translate }}
        {{
          (
            (ordersService.getDistance(order)?.value / 1000) *
            (order.returnDate ? 2 : 1)
          ).toFixed(1)
        }}
        km
      </div>
    </div>
  </div>
  <div class="order-card-info-item">
    <div class="order-card-info-item-icon">
      <img
        src="/app/shared/assets/svg/users.svg"
        alt="{{ 'BusRentalPage.ImageAlt.Passengers' | translate }}"
      />
    </div>
    <div class="order-card-info-item-value">
      <div class="order-card-info-item-title">
        {{ "BusRentalPage.Passengers" | translate }}:
      </div>
      <div class="order-card-info-item-count">
        {{ order.numberOfPassengers }}
      </div>
    </div>
  </div>
  <div class="order-card-info-item">
    <div class="order-card-info-item-icon">
      <img
        src="/app/shared/assets/svg/bus.svg"
        alt="{{ 'BusRentalPage.ImageAlt.TransportType' | translate }}"
      />
    </div>
    <div class="order-card-info-item-value">
      <div class="order-card-info-item-title">
        {{ "BusRentalPage.TransportType." + order.transportType | translate }}
      </div>
    </div>
  </div>
  <div class="order-card-info-item">
    <div class="order-card-info-item-icon">
      <img
        src="/app/shared/assets/svg/briefcase.svg"
        alt="{{ 'BusRentalPage.ImageAlt.LuggageSize' | translate }}"
      />
    </div>
    <div class="order-card-info-item-value">
      <div class="order-card-info-item-title">
        {{ "BusRentalPage.BaggageSize." + order.baggageSize | translate }}
      </div>
    </div>
  </div>
</div>
