<div class="picker">
  <div class="picker-chosen" mat-button [matMenuTriggerFor]="phonePicker" (menuClosed)="closePicker()" (menuOpened)="openPicker()" [ngClass]="{ 'disabled' :disabled }">
    <div class="picker-chosen-flag">
      <div class="iti-flag" [ngClass]="chosenCountry.flagClass"></div>
    </div>
    <div class="picker-chosen-dialCode">+{{ chosenCountry.dialCode }}</div>
    <div class="picker-chosen-arr">
      <svg width="7" height="4" viewBox="0 0 7 4" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.29451 0L6.99951 0.67063L4.20451 3.32937L3.49951 4L2.79451 3.32937L-0.000488281 0.67063L0.704512 0L3.49951 2.65874L6.29451 0Z" fill="#63ADF2"/>
      </svg>
    </div>
  </div>
  <mat-menu #phonePicker="matMenu" [class]="'countryPicker'">
    <div class="picker-wrapper" (click)="$event.stopPropagation()">
      <div class="picker-wrapper-search">
        <input
          type="text"
          placeholder="Szukaj kraju"
          [(ngModel)]="query"
          (input)="search()"
          #searchInput
        />
      </div>
      <div class="picker-wrapper-content">
        <ul #countriesList>
          <ng-container #countriesContainer></ng-container>
        </ul>
      </div>
    </div>
  </mat-menu>
</div>
<ng-template #item let-country="country">
  <li (click)="choose(country)" [ngClass]="{ chosen: country.chosen }">
    <div class="iti-flag" [ngClass]="country.flagClass"></div>
    <span><b>+{{ country.dialCode }}</b> {{ country.name }}</span>
  </li>
</ng-template>
