import { NgcCookieConsentConfig } from 'ngx-cookieconsent';

export class Config {
  public static readonly REGULATIONS_PDF_URL =
    '/assets/docs/Bus_Na_Lotnisko_regulamin.pdf';
  public static readonly PRIVACY_PDF_URL =
    '/assets/docs/Polityka_Prywatnosci_Bus_na_Lotnisko.pdf';
  public static readonly APP_STORE_URL =
    'https://apps.apple.com/us/app/busynalotniska/id6446489159';
  public static readonly GOOGLE_PLAY_URL =
    'https://play.google.com/store/apps/details?id=pl.busynalotniska.twa';
  public static readonly cookieConfig: NgcCookieConsentConfig = {
    cookie: {
      domain: window.location.hostname,
    },
    position: 'bottom-right',
    theme: 'classic',
    palette: {
      popup: {
        background: '#14025f',
        text: '#ffffff',
        link: '#ffffff',
      },
      button: {
        background: '#62FFBC',
        text: '#220B83',
        border: 'transparent',
      },
    },
    type: 'info',
    content: {
      message:
        'Ta strona korzysta z plików cookie, aby zapewnić najlepszą jakość korzystania z naszej witryny.',
      dismiss: 'Rozumiem!',
      link: 'Dowiedz się więcej',
      href: '/account/privacy-policy',
      policy: 'Cookie Policy',
    },
  };
}
