<div matRipple [class.is-disabled]="disabled">
  <div
    [ngClass]="[
      'bnl-button',
      'bnl-button--' + buttonStyle,
      'bnl-button--align-' + align,
      'bnl-button--size-' + size
    ]"
    [class.bnl-button--reverse]="reverse"
    [class.bnl-button--transparent]="transparent"
    [class.bnl-button--rounded]="rounded"
    [class.bnl-button--padding-left]="leftPadding"
    [class.pointer-events-none]="showLoader"
    [class.disabled]="disabled"
  >
    <div class="bnl-button-icon bnl-button-item" *ngIf="iconSrc">
      <img [src]="iconSrc" [alt]="iconAlt" />
    </div>
    <div class="bnl-button-text bnl-button-item" *ngIf="text">
      {{ text }}
    </div>
    <div class="bnl-button-badge bnl-button-item" *ngIf="showBadge">
      {{ badgeValue }}
    </div>
    <div class="bnl-button-bold bnl-button-item" *ngIf="boldText">
      {{ boldText }}
    </div>

    <div
      class="bnl-button-text bnl-button-item bnl-button-secondarywrap"
      *ngIf="secondaryTitle || secondaryText"
    >
      <div
        class="bnl-button-secondaryTitle"
        [ngClass]="{ 'bnl-button-secondaryTitle--active': !!secondaryText }"
        *ngIf="secondaryTitle"
      >
        {{ secondaryTitle }}
      </div>
      <div *ngIf="secondaryText" class="bnl-button-secondary-text">
        {{ secondaryText }}
      </div>
    </div>

    <ngx-spinner
      class="bnl-button-loader"
      [name]="buttonLoaderId"
      size="small"
      type="ball-clip-rotate"
      [bdColor]="getButtonBackgroundColor()"
      color="#220B83"
    ></ngx-spinner>
  </div>
</div>
