import { Component, EventEmitter, Input, Output } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { TicketsService } from "@core/services/tickets.service";
import { PathwaysListElementGraphql } from "@modules/graphql/graphql.service";
import { CarrierInfoDialogComponent } from "@shared/components/carrier-info-dialog/carrier-info-dialog.component";
import * as moment from "moment";

@Component({
  selector: "bnl-order-preview",
  templateUrl: "./order-preview.component.html",
  styleUrls: ["./order-preview.component.scss"],
})
export class OrderPreviewComponent {
  @Input() pathway: PathwaysListElementGraphql;
  @Input() isHistoryPreview = false;
  @Input() isDriverCard = false;
  @Output() mapOpen = new EventEmitter<void>();
  @Output() settingsOpen = new EventEmitter<void>();
  moment = moment;

  constructor(
    public ticketsService: TicketsService,
    private dialog: MatDialog
  ) {}

  onCarrierInfoDialogOpen(): void {
    this.dialog.open(CarrierInfoDialogComponent, {
      panelClass: "carrier-info-dialog",
      data: { carrierInfo: this.pathway.pathway.company },
    });
  }
}
