import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'price'
})
export class PricePipe implements PipeTransform {
  constructor() {
  }

  transform(value: number): string {
    return ((value / 100).toFixed(2) + ' PLN').replace('.', ',');
  }
}
