import { Injectable } from '@angular/core';
import { AppRoute } from '@core/routes';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { CustomRoute } from '@shared/interfaces/custom-route.interface';

@Injectable()
export class NavigateService {
  private routesTree: RoutePathName = {};
  // tslint:disable-next-line:variable-name
  private _url = {
    current: '',
    previous: ''
  };

  constructor(private router: Router, private route: ActivatedRoute) {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.pushPreviousUrl(event.url);
      });
  }

  /// @name navigate
  ///
  ///
  /// @param {string} name -
  /// @param {*} queryParams -
  /// @param {*} params - you can add route params in object which could be replaced, e.x. {id: 23}
  navigate(name: string, queryParams: any = {}, params?: any, clearCurrentParams?: boolean): void {
    if (!name || name === '') {
      this.router.navigate(['/'], {queryParams});
    }
    if (!this.routesTree[name]) {
      return;
    }
    // tslint:disable-next-line:no-unused-expression
    !clearCurrentParams ? queryParams = Object.assign(queryParams, this.route.snapshot.queryParams) : null;
    const routePath = this.getRouteWithRouteParams(this.routesTree[name], params);
    this.router.navigate([routePath], {queryParams});

  }

  getRouteWithRouteParams(route: string, params: any): string {
    for (const key in params) {
      if (!params.hasOwnProperty(key)) {
        continue;
      }
      route = route.replace(`:${key}`, params[key]);
    }
    return route;
  }

  /// @name getPath
  ///
  ///
  /// @param {string} name -
  /// @returns {any} -
  getPath(name: string): string {
    return this.routesTree[name] ? this.routesTree[name] : '';
  }

  /// @name setAppRoutes
  /// method to create all app routes list to navigate to component by name
  ///
  setAppRoutes(routes): void {
    for (const key of Object.keys(routes)) {
      const routesObj: AppRoute = routes[key];
      routesObj.routes.forEach((routeObj) => this.createPathName([], routeObj, routesObj.startPath));
    }
  }

  /// @name createPathName
  ///
  ///
  /// @param {CustomRoute[]} arr -
  /// @param {CustomRoute} routeObject -
  /// @param {string} startPath -
  /// @returns {any} -
  createPathName(arr: CustomRoute[], routeObject: CustomRoute, startPath: string): void {
    const newArr = arr.slice();
    newArr.push(routeObject);

    // tslint:disable-next-line:no-shadowed-variable
    const pushPath = (startPath, arr, routeObject) => {
      let path = '';
      // tslint:disable-next-line:no-unused-expression
      startPath !== '' ? (path = `/${startPath}`) : null;

      arr.forEach((element, i) => {
        // tslint:disable-next-line:no-unused-expression
        element.path !== '' ? (path += `/${element.path}`) : null;
      });

      // tslint:disable-next-line:no-unused-expression
      routeObject.name ? (this.routesTree[routeObject.name] = path) : null;
    };

    if (!routeObject.children) {
      pushPath(startPath, newArr, routeObject);
      return;
    } else {
      // tslint:disable-next-line:no-shadowed-variable
      routeObject.children.forEach((routeObject: CustomRoute) => {
        this.createPathName(newArr, routeObject, startPath);
      });
    }

    if (routeObject.name) {
      pushPath(startPath, newArr, routeObject);
    }
  }

  pushPreviousUrl(url: string): void {
    this._url.previous = this._url.current;
    this._url.current = url;
  }

  openFile(url: string): void {
    window.open(url, '_blank');
  }

  get previousUrl(): string {
    return this._url.previous;
  }
}

export interface RoutePathName {
  [key: string]: string;
}
