import { Injectable } from '@angular/core';
import { filter } from 'rxjs/operators';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { RouterUtils } from '@shared/utils/router.utils';
import { Subscription } from 'rxjs';
import { RouteData } from '@shared/interfaces/route-data.interface';

@Injectable()
export class NavbarService {
  private routerSub: Subscription;
  private _isBottomNavVisible: boolean;

  constructor(private router: Router, private route: ActivatedRoute) {
  }

  public listenRoutesData(): void {
    if (!!this.routerSub) {
      console.warn('Navbar service is already listening for routes data');
      return;
    }

    this.routerSub = this.router.events.pipe(
      filter((event: RouterEvent) => event instanceof NavigationEnd)
    ).subscribe(() => {
      const data = RouterUtils.getDeepestChild(this.route).data as RouteData;
      this._isBottomNavVisible = !data.isBottomMenuHidden;
    });
  }

  set isBottomNavVisible(isVisible: boolean) {
    this._isBottomNavVisible = isVisible;
  }

  get isBottomNavVisible(): boolean {
    return this._isBottomNavVisible;
  }
}
