import { ChangeDetectorRef, Component, HostListener } from '@angular/core';
import { BaseComponent } from '@shared/components/base-component';
import { ListItem } from '@shared/interfaces/list-item.interface';
import { WindowHelper } from '@shared/helpers/window.helper';
import { debounce } from '@shared/decorators/debounce.decodator';
import { SnackBarState } from '@shared/enums/snack-bar-state.enum';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'bnl-account-view',
  templateUrl: './account-page.component.html'
})
export class AccountPageComponent extends BaseComponent {
  userDetailsList: ListItem[] = [{
    iconUrl: '/app/shared/assets/svg/user.svg',
    label: this.t.instant('ProfileDetailsPage.myData'),
    clickHandler: () => {
      this.n.navigate('profile-edit');
    }
  }];

  listItems: ListItem[] = [{
    iconUrl: '/app/shared/assets/svg/info.svg',
    label: this.t.instant('AccountPage.privacyPolicy'),
    clickHandler: () => {
      this.n.navigate('privacy-policy');
    }
  }, {
    iconUrl: '/app/shared/assets/svg/document.svg',
    label: this.t.instant('AccountPage.regulations'),
    clickHandler: () => {
      this.n.navigate('regulations');
    }
  }];

  WindowHelper = WindowHelper;

  @HostListener('window:resize')
  @debounce(30)
  onWindowResize() {
    this.ref.detectChanges();
  }

  constructor(private ref: ChangeDetectorRef) {
    super();
  }

  onLogout(): void {
    this.isRequestPending = true;

    this.auth.logout().pipe(
      finalize(() => this.isRequestPending = false)
    ).subscribe(() => {
      this.snackbar.open(this.t.instant('Global.Messages.successfulLogout'), SnackBarState.SUCCESS);
    }, (error) => {
      this.snackbar.open(this.t.instant('Global.Messages.logoutError'), SnackBarState.ERROR);
    });
  }
}
