import { Component, OnInit } from '@angular/core';
import { AuthService } from '@core/modules/authorization/shared/services/auth.service';

export interface RentalTab {
  link: string;
  name: string;
}

@Component({
  selector: 'bnl-bus-rental-page',
  templateUrl: './bus-rental-page.component.html',
  styleUrls: ['./bus-rental-page.component.scss'],
})
export class BusRentalPageComponent implements OnInit {
  tabs: RentalTab[] = [
    {
      link: 'order-bus',
      name: 'BusRentalPage.Tabs.OrderBus',
    },
    {
      link: 'my-orders',
      name: 'BusRentalPage.Tabs.MyOrders',
    },
    {
      link: 'confirmed-orders',
      name: 'BusRentalPage.Tabs.ConfirmedOrders',
    },
    {
      link: 'orders-history',
      name: 'BusRentalPage.Tabs.OrdersHistory',
    },
  ];

  activeLink = this.tabs[0];

  constructor(public authService: AuthService) {}

  ngOnInit() {}
}
