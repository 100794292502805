import { Component, Input } from '@angular/core';
import { BottomMenuTab } from '@shared/interfaces/bottom-menu-tab.interface';

@Component({
  selector: 'bnl-bottom-menu',
  templateUrl: './bottom-menu.component.html',
  styleUrls: ['./bottom-menu.component.scss']
})
export class BottomMenuComponent {
  @Input() menuTabs: BottomMenuTab[];
}
