export class Config {
  public static readonly DEFAULT_LANGUAGE = "pl";
  public static readonly GOOGLE_MAPS_DEFAULT_REGION = "pl";

  public static readonly DISPLAY_DATETIME_FORMAT = "DD.MM.YYYY, HH:mm";
  public static readonly DISPLAY_DATE_FORMAT = "DD.MM.YYYY";
  public static readonly DISPLAY_TIME_FORMAT = "HH:mm";

  public static readonly TICKET_DATE_FORMAT = "dddd, D MMMM, HH:mm";
  public static readonly ORDER_DATE_FORMAT = "dddd, D MMM, HH:mm";
  public static readonly DATETIME_LOCAL_INPUT_FORMAT = "YYYY-MM-DDTHH:mm";

  public static readonly DEFAULT_TEXT_INPUT_LENGTH = 100;
  public static readonly FIRST_NAME_DEFAULT_INPUT_LENGTH = 20;
  public static readonly LAST_NAME_DEFAULT_INPUT_LENGTH = 30;
}
