<p class="inputError" *ngIf="FormsHelper.isTouchedAndInvalid(control)">
  <ng-container *ngIf="FormsHelper.hasRequiredError(control)">
    {{ "Global.Inputs.Errors.required" | translate }} <br />
  </ng-container>
  <ng-container *ngIf="FormsHelper.hasMinlengthError(control)">
    {{ "Global.Inputs.Errors.minlengthRequired" | translate }}
    {{ FormsHelper.getRequiredMinLength(control) }} <br />
  </ng-container>
  <ng-container *ngIf="FormsHelper.hasMaxlengthError(control)">
    {{ "Global.Inputs.Errors.maxLength" | translate }}
    {{ FormsHelper.getRequiredMaxLength(control) }} <br />
  </ng-container>
  <ng-container *ngIf="FormsHelper.hasEmailError(control)">
    {{ "Global.Inputs.Errors.isEmail" | translate }} <br />
  </ng-container>
  <ng-container *ngIf="FormsHelper.hasLowerDateError(control)">
    {{ "Global.Inputs.Errors.isLowerDate" | translate }} <br />
  </ng-container>
  <ng-container *ngIf="FormsHelper.hasLowerHourError(control)">
    {{ "Global.Inputs.Errors.isHourLower" | translate }} <br />
  </ng-container>
  <ng-container *ngIf="FormsHelper.hasNumberError(control)">
    {{ "Global.Inputs.Errors.isNumber" | translate }} <br />
  </ng-container>
  <ng-container *ngIf="FormsHelper.hasMinError(control)">
    {{ "Global.Inputs.Errors.min" | translate }}
    {{ control.errors.min.min * 100 | price }}<br />
  </ng-container>
  <ng-container *ngIf="FormsHelper.hasMaxError(control)">
    {{ "Global.Inputs.Errors.max" | translate }}
    {{ control.errors.max.max * 100 | price }}<br />
  </ng-container>
  <ng-container *ngIf="FormsHelper.hasMaxOfferError(control)">
    {{ "Global.Inputs.Errors.maxOffer" | translate }}
    {{ control.errors.actual * 100 | price }}<br />
  </ng-container>
</p>
