<div class="search-page-form-input search-page-form-input--datetime">
  <ng-container *ngIf="!DeviceHelper.isMobile">
    <div class="search-page-form-input-trigger" (click)="isCalendarOpen = true"></div>
    <div
      class="search-page-form-input-backdrop"
      [class.search-page-form-input-backdrop--active]="isCalendarOpen"
      (click)="isCalendarOpen = false"
    ></div>
    <dl-date-time-picker
      #dateTimePicker
      class="search-page-form-input-calendar"
      [class.search-page-form-input-calendar--active]="isCalendarOpen"
      [selectFilter]="dateTimeSelectFilter"
      startView="day"
      maxView="day"
      minView="hour"
      minuteStep="5"
      (change)="onDatePick($event)"
    >
    </dl-date-time-picker>
  </ng-container>

  <div class="datetime-input d-md-none" *ngIf="DeviceHelper.isMobile">
    <input type="datetime-local" [formControl]="nativeDatetimePickerControl" [min]="nativeDatetimePickerMinValue" />
  </div>

  <gbxsoft-input
    class="search-page-form-input"
    [class.disabled]="loading"
    [invalid]="form.get(controlName).touched && form.get(controlName).invalid"
    [formControl]="tripDisplayDate"
    [disableTabIndex]="WindowHelper.isMobile"
    [config]="{
      name: this.t.instant(placeholder),
      placeholder: this.t.instant(placeholder),
      iconUrl: '/app/shared/assets/svg/calendar.svg',
      type: GbxsoftInputTypes.TEXT
    }"
  >
  </gbxsoft-input>
</div>
