import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { TopBarConfig } from "@shared/interfaces/top-bar-config.interface";
import { BaseComponent } from "@shared/components/base-component";
import {
  PathwaysListElementGraphql,
  TicketGraphql,
} from "@modules/graphql/graphql.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "bnl-rate-your-ride-dialog",
  templateUrl: "./rate-your-ride-dialog.component.html",
  styleUrls: ["./rate-your-ride-dialog.component.scss"],
})
export class RateYourRideDialogComponent
  extends BaseComponent
  implements OnInit
{
  ticket: TicketGraphql;
  showLoader: boolean = false;
  newRating: number = null;
  topBarConfig: TopBarConfig = {
    title: this.t.instant("RideRating.Leave opinion"),
    leftIcon: {
      src: "/app/shared/assets/svg/back.svg",
      alt: this.t.instant("Global.IconAlts.backIcon"),
      clickHandler: () => this.dialogRef.close(),
    },
  };

  get companyName(): string {
    return this.ticket?.departureData?.pathway?.company?.name ?? "";
  }

  get hasLogo(): boolean {
    return !!this.ticket?.departureData?.pathway?.company?.logotypeUrl;
  }

  get companyLogo(): string {
    return (
      environment.companyLogotypes +
        "/" +
        this.ticket?.departureData?.pathway?.company?.logotypeUrl ?? ""
    );
  }

  get departureData(): PathwaysListElementGraphql {
    return this.ticket.departureData;
  }

  constructor(
    public dialogRef: MatDialogRef<RateYourRideDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    super();
  }

  ngOnInit(): void {
    this.ticket = this.data.ticket;
  }

  onTicketRated(rate: number): void {
    this.newRating = rate;
  }

  submit(): void {
    if (this.newRating === null) return;
    this.dialogRef.close(this.newRating);
  }
}
