import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SearchService } from '@core/services/search.service';
import { TicketsService } from '@core/services/tickets.service';
import { GetPaymentQuery, TicketGraphql } from '@modules/graphql/graphql.service';
import { BaseComponent } from '@shared/components/base-component';
import { TicketStatus } from '@shared/enums/ticket-status.enum';
import { ShareService } from '@shared/services/share.service';
import { FetchResult } from 'apollo-link';
import { Subject, merge } from 'rxjs';
import { delay, finalize, repeatWhen, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'bnl-payment-page',
  templateUrl: './payment-page.component.html',
  styleUrls: ['./payment-page.component.scss'],
})
export class PaymentPageComponent extends BaseComponent implements OnInit {
  paymentError = false;
  ticket: TicketGraphql;

  readonly PAYMENT_LOADER = 'payment-loader';
  private readonly PAYMENT_MAX_CHECKS = 5;

  constructor(
    public shareService: ShareService,
    private searchService: SearchService,
    private ticketsService: TicketsService,
    private route: ActivatedRoute
  ) {
    super();
  }

  ngOnInit(): void {
    this.auth.onLogout.pipe(takeUntil(this.destroyed)).subscribe(() => {
      this.n.navigate('search');
    });

    this.checkPayment(this.route.snapshot.paramMap.get('paymentToken'));
  }

  onReturnPathSearch(): void {
    this.searchService.swapSearchDirection(this.ticket);
    this.n.navigate('search');
  }

  onTicketShare(): void {
    this.shareService.shareTicket(this.ticket).subscribe();
  }

  private checkPayment(ticketToken: string): void {
    const stop = new Subject<void>();

    this.isRequestPending = true;
    this.spinner.show(this.PAYMENT_LOADER);
    this.ticketsService
      .getPayment(ticketToken)
      .pipe(
        repeatWhen((obs) => obs.pipe(delay(1000))),
        takeUntil(merge(stop, this.destroyed)),
        finalize(() => {
          this.isRequestPending = false;
          this.spinner.hide(this.PAYMENT_LOADER);
        })
      )
      .subscribe(
        (res: FetchResult<GetPaymentQuery>) => {
          const { status } = res.data.getPayment.ticket;

          if (status === TicketStatus.PURCHASED) {
            this.ticket = res.data.getPayment.ticket as TicketGraphql;
            stop.next();
            stop.complete();
          } else if (status === TicketStatus.FAILED) {
            this.paymentError = true;
            stop.next();
            stop.complete();
          }
        },
        (error) => {
          this.paymentError = true;
          stop.next();
          stop.complete();
        }
      );
  }
}
