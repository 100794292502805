import { Pipe, PipeTransform } from '@angular/core';

export const END_REMAINING_TIME = '00:00';

@Pipe({
  name: 'reservationTimer',
})
export class ReservationTimerPipe implements PipeTransform {
  transform(remainingTime: number | null): string {
    if (!remainingTime) return END_REMAINING_TIME;
    const minutes: number = Math.floor(remainingTime / 1000 / 60);
    const seconds: number = (remainingTime / 1000) % 60;
    return `${this.fixValue(minutes)}:${this.fixValue(parseInt(seconds.toFixed()))}`;
  }

  private fixValue(value: number): string {
    if (value < 0) return '00';
    if (value > 9) return `${value}`;
    return `0${value}`;
  }
}
