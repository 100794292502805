import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, ViewChild, ViewRef } from '@angular/core';
import { GbxsoftInputConfig } from './interfaces/gbxsoft-input.interface';
import { GbxsoftBaseFormComponent } from '../gbxsoft-base.component';
import { GbxsoftValidatorAccessor, GbxsoftValueAccessor } from '../gbxsoft-accessors';
import { GbxsoftInputTypes } from './gbxsoft-input.types';
import { GbxsoftDatepickerController } from './controllers/gbxsoft-datepicker.controller';

@Component({
  selector: 'gbxsoft-input',
  templateUrl: './gbxsoft-input.component.html',
  styleUrls: ['./gbxsoft-input.component.scss'],
  providers: [GbxsoftValueAccessor(GbxsoftInputComponent), GbxsoftValidatorAccessor(GbxsoftInputComponent)],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GbxsoftInputComponent extends GbxsoftBaseFormComponent implements AfterViewInit {
  _config: GbxsoftInputConfig;

  @Input('config')
  get config() {
    return this._config;
  }

  set config(conf: GbxsoftInputConfig) {
    this._config = Object.assign(this.defaultConfig, conf);
  }

  _disabled: boolean;
  @Input('disabled')
  get disabled() {
    return this._disabled;
  }

  @Input() invalid = false;
  @Input() disableTabIndex = false;

  set disabled(disabled: boolean) {
    this._disabled = disabled;
  }

  @ViewChild('gbxsoftInput') gbxsoftInput: ElementRef;
  inputElement: HTMLInputElement;
  isFocused: boolean = false;

  private datePickerCtrl: GbxsoftDatepickerController = null;

  @Output('keyupEmit') keyupEmit: EventEmitter<number> = new EventEmitter();

  constructor(public changes: ChangeDetectorRef) {
    super(changes);
  }

  ngAfterViewInit() {
    this.initInput();
    this.applyListeners();
  }

  applyListeners() {
    this.inputElement = this.gbxsoftInput.nativeElement;
    this.inputElement.addEventListener('focus', () => this.updateFocusState());
    this.inputElement.addEventListener('input', ($event: any) => this.input($event.target.value));
    this.inputElement.addEventListener('blur', ($event: any) => this.blur($event.target.value));
    this.inputElement.addEventListener('keyup', ($event: any) => this.keyup($event.which || $event.keyCode));
    this.detectChanges();
  }


  updateFocusState() {
    this.isFocused = true;
    this.detectChanges();
  }

  /**
   *Init input by it`s type
   *
   * @memberof GbxsoftInputComponent
   */
  initInput(): void {
    this.initDatePicker();
    this.detectChanges();
  }

  /**
   *Overwrite method from base component
   *
   * @param {*} value
   * @memberof GbxsoftInputComponent
   */
  writeValue(value: any) {
    this.value = value;
    this.detectChanges();
  }

  /**
   *Generate datepicker by type
   *
   * @memberof GbxsoftInputComponent
   */
  initDatePicker(): void {
    this.isDatePicker ? (this.datePickerCtrl = new GbxsoftDatepickerController(this)) : null;
  }

  /**
   *Value change on type
   *
   * @param {*} value
   * @memberof GbxsoftInputComponent
   */
  input(value: any): void {
    if (this.disabled) {
      return;
    }

    if (this.config.type === GbxsoftInputTypes.NUMBER) {
      value = value?.trim();
      const intValue = parseInt(value, 10);
      if (isNaN(intValue) || intValue <= 0 || !Number.isInteger(intValue)) {
        value = '';
      }
      this.inputElement.value = value;
    }

    this.writeValue(value);
    // Check if FormControl is defined in parent
    this.fnOnChange ? this.fnOnChange(value) : null;
    this.detectChanges();
  }

  /**
   *Blur event on input
   *
   * @param {*} value
   * @memberof GbxsoftInputComponent
   */
  blur(value): void {
    if (this.disabled) {
      return;
    }
    this.isFocused = false;
    this.config?.updateOnFocusOut ? this.writeValue(value) : null;
    //Check if FormControl is defined in parent
    this.fnOnTouched ? this.fnOnTouched(value) : null;
    this.detectChanges();
  }

  /**
 *Keyup event on input
 *
 * @param {*} value
 * @memberof GbxsoftInputComponent
 */
  keyup(value: number): void {
    if (this.disabled) {
      return;
    }
    this.keyupEmit.emit(value);
  }


  /**
   *Get Default config
   *
   * @readonly
   * @type {GbxsoftInputConfig}
   * @memberof GbxsoftInputComponent
   */
  get defaultConfig(): GbxsoftInputConfig {
    return {
      name: 'Input name',
      type: GbxsoftInputTypes.TEXT,
      placeholder: '',
      updateOnFocusOut: false,
      autocapitalize: false,
      spellcheck: true,
      autocomplete: 'off'
    };
  }

  get placeholder() {
    if (!this.config?.showPlaceholderOnFocus) {
      return this.isFocused ? this.config.placeholder : '';
    }
    return this.config?.placeholder;
  }

  /**
   *Style Input States
   *
   * @readonly
   * @memberof GbxsoftInputComponent
   */
  get inputCSS(): { [key: string]: boolean } {
    return {
      disabled: !!this.disabled,
      'has-value': this.hasValue,
      'has-error': this.errorCtrl.isError && this.errorCtrl.errorMessage ? true : false,
    };
  }

  get hasValue(): boolean {
    return (this.value && this.value.toString().length);
  }

  /**
   *Check if type of input is GbxsoftInputTypes.CALENDAR
   *
   * @readonly
   * @memberof GbxsoftInputComponent
   */
  get isDatePicker(): boolean {
    return this.config?.type === GbxsoftInputTypes.CALENDAR;
  }

  detectChanges() {
    !(this.changes as ViewRef).destroyed ? this.changes.detectChanges() : null;
  }
}
