<div class="paymentPage">
  <div class="background">
    <div class="paymentPage-content" *ngIf="isRequestPending">
      <h1 class="paymentPage-title paymentPage-title-checking">
        {{ "OrderPaymentPage.LoadingPaymentStatus" | translate }}
      </h1>
      <p class="paymentPage-text">{{ "OrderPaymentPage.PleaseWait" | translate }}</p>
      <ngx-spinner
        [name]="PAYMENT_LOADER"
        class="loading-spinner"
        [fullScreen]="false"
        size="default"
        type="ball-clip-rotate"
        bdColor="rgba(0, 0, 0, 0)"
        color="#FFF"
      ></ngx-spinner>
    </div>
    <div class="paymentPage-content" *ngIf="!isRequestPending">
      <div class="paymentPage-brand d-md-none">
        <img src="/app/shared/assets/svg/bnl-logo.svg" alt="Busynalotniska" />
      </div>

      <h1 class="paymentPage-title" [class.paymentPage-title--error]="paymentError">
        {{ !paymentError ? ("Ticket.Payment.Success.title" | translate) : ("Ticket.Payment.Error.title" | translate) }}
      </h1>

      <p class="paymentPage-text">
        {{
          !paymentError
            ? ("OrderPaymentPage.Payment.SuccessMessage" | translate)
            : ("Ticket.Payment.Error.info" | translate)
        }}
      </p>

      <p class="paymentPage-text" *ngIf="!paymentError">{{ "Ticket.Payment.Success.sign" | translate }}</p>

      <div class="paymentPage-errorButtons">
        <bnl-button
          *ngIf="paymentError"
          (click)="backToMyOrders()"
          class="paymentPage-errorButtons-button"
          [buttonStyle]="paymentError ? 'secondary' : 'primary'"
          size="md"
          [boldText]="'OrderPaymentPage.Payment.BackToMyOrders' | translate"
          align="center"
        >
        </bnl-button>
        <bnl-button
          *ngIf="!paymentError"
          (click)="backToConfirmedOrders()"
          class="paymentPage-errorButtons-button"
          buttonStyle="primary"
          size="md"
          [boldText]="'OrderPaymentPage.Payment.BackToMyOrders' | translate"
          align="center"
        >
        </bnl-button>
      </div>
    </div>
  </div>
</div>
