<div
  [ngClass]="['bnl-confirm-dialog', 'bnl-confirm-dialog--' + dialogData.style]"
>
  <div
    class="bnl-confirm-dialog-close d-none d-md-block"
    (click)="dialogRef.close(false)"
  >
    <img
      src="/app/shared/assets/svg/close.svg"
      alt="{{ 'Global.IconAlts.closeIcon' | translate }}"
    />
  </div>

  <div class="bnl-confirm-dialog-title">
    <h2 class="bnl-confirm-dialog-title-text">{{ dialogData.title }}</h2>
    <div
      class="bnl-confirm-dialog-title-close d-md-none"
      (click)="dialogRef.close(false)"
    >
      <img
        src="/app/shared/assets/svg/close.svg"
        alt="{{ 'Global.IconAlts.closeIcon' | translate }}"
      />
    </div>
  </div>

  <div class="bnl-confirm-dialog-description">
    <p class="bnl-confirm-dialog-description-text">
      {{ dialogData.actionDesc }}
    </p>
    <p
      class="bnl-confirm-dialog-description-text"
      [innerHTML]="dialogData.resultDesc"
    ></p>
  </div>

  <div class="bnl-confirm-dialog-alertDesc" *ngIf="dialogData.alertDesc">
    <p class="bnl-confirm-dialog-alertDesc-text">{{ dialogData.alertDesc }}</p>
  </div>

  <div
    class="bnl-confirm-dialog-buttons"
    [class.bnl-confirm-dialog-buttons--reversed]="dialogData.reverseButtons"
  >
    <bnl-button
      class="bnl-confirm-dialog-buttons-button"
      (click)="dialogRef.close(true)"
      [buttonStyle]="dialogData.style"
      [text]="dialogData.confirmText"
      align="center"
      size="md"
    ></bnl-button>

    <bnl-button
      class="bnl-confirm-dialog-buttons-button"
      (click)="dialogRef.close(false)"
      buttonStyle="secondary"
      [text]="dialogData.cancelText"
      align="center"
      size="md"
    ></bnl-button>
  </div>
</div>
