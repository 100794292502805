import { Country } from '@shared/models/country.model';

export const phoneCountries: Country[] = [
  {
    name: 'Poland (Polska)',
    flagClass: 'pl',
    dialCode: '48'
  },
  {
    name: 'Germany (Deutschland)',
    flagClass: 'de',
    dialCode: '49'
  },
  {
    name: 'United Kingdom (Anglia)',
    flagClass: 'gb',
    dialCode: '44'
  },
  {
    name: 'Ukraine (Україна)',
    flagClass: 'ua',
    dialCode: '380'
  },
  {
    name: 'Netherlands (Holandia)',
    flagClass: 'nl',
    dialCode: '31'
  },
  {
    name: 'Italy (Italia)',
    flagClass: 'it',
    dialCode: '39'
  },
  {
    name: 'Greece (Ελλάδα)',
    flagClass: 'gr',
    dialCode: '30'
  },
  {
    name: 'Slovakia (Slovensko)',
    flagClass: 'sk',
    dialCode: '421'
  },
  {
    name: 'Slovenia (Slovenija)',
    flagClass: 'si',
    dialCode: '386'
  },
  {
    name: 'United States',
    flagClass: 'us',
    dialCode: '1'
  },
  {
    name: 'Afghanistan (‫افغانستان‬‎)',
    flagClass: 'af',
    dialCode: '93'
  },
  {
    name: 'Albania (Shqipëri)',
    flagClass: 'al',
    dialCode: '355'
  },
  {
    name: 'Algeria (‫الجزائر‬‎)',
    flagClass: 'dz',
    dialCode: '213'
  },
  {
    name: 'American Samoa',
    flagClass: 'as',
    dialCode: '1'
  },
  {
    name: 'Andorra',
    flagClass: 'ad',
    dialCode: '376'
  },
  {
    name: 'Angola',
    flagClass: 'ao',
    dialCode: '244'
  },
  {
    name: 'Anguilla',
    flagClass: 'ai',
    dialCode: '1'
  },
  {
    name: 'Antigua and Barbuda',
    flagClass: 'ag',
    dialCode: '1'
  },
  {
    name: 'Argentina',
    flagClass: 'ar',
    dialCode: '54'
  },
  {
    name: 'Armenia (Հայաստան)',
    flagClass: 'am',
    dialCode: '374'
  },
  {
    name: 'Aruba',
    flagClass: 'aw',
    dialCode: '297'
  },
  {
    name: 'Australia',
    flagClass: 'au',
    dialCode: '61'
  },
  {
    name: 'Austria (Österreich)',
    flagClass: 'at',
    dialCode: '43'
  },
  {
    name: 'Azerbaijan (Azərbaycan)',
    flagClass: 'az',
    dialCode: '994'
  },
  {
    name: 'Bahamas',
    flagClass: 'bs',
    dialCode: '1'
  },
  {
    name: 'Bahrain (‫البحرين‬‎)',
    flagClass: 'bh',
    dialCode: '973'
  },
  {
    name: 'Bangladesh (বাংলাদেশ)',
    flagClass: 'bd',
    dialCode: '880'
  },
  {
    name: 'Barbados',
    flagClass: 'bb',
    dialCode: '1'
  },
  {
    name: 'Belarus (Беларусь)',
    flagClass: 'by',
    dialCode: '375'
  },
  {
    name: 'Belgium (België)',
    flagClass: 'be',
    dialCode: '32'
  },
  {
    name: 'Belize',
    flagClass: 'bz',
    dialCode: '501'
  },
  {
    name: 'Benin (Bénin)',
    flagClass: 'bj',
    dialCode: '229'
  },
  {
    name: 'Bermuda',
    flagClass: 'bm',
    dialCode: '1'
  },
  {
    name: 'Bhutan (འབྲུག)',
    flagClass: 'bt',
    dialCode: '975'
  },
  {
    name: 'Bolivia',
    flagClass: 'bo',
    dialCode: '591'
  },
  {
    name: 'Bosnia and Herzegovina (Босна и Херцеговина)',
    flagClass: 'ba',
    dialCode: '387'
  },
  {
    name: 'Botswana',
    flagClass: 'bw',
    dialCode: '267'
  },
  {
    name: 'Brazil (Brasil)',
    flagClass: 'br',
    dialCode: '55'
  },
  {
    name: 'British Indian Ocean Territory',
    flagClass: 'io',
    dialCode: '246'
  },
  {
    name: 'British Virgin Islands',
    flagClass: 'vg',
    dialCode: '1'
  },
  {
    name: 'Brunei',
    flagClass: 'bn',
    dialCode: '673'
  },
  {
    name: 'Bulgaria (България)',
    flagClass: 'bg',
    dialCode: '359'
  },
  {
    name: 'Burkina Faso',
    flagClass: 'bf',
    dialCode: '226'
  },
  {
    name: 'Burundi (Uburundi)',
    flagClass: 'bi',
    dialCode: '257'
  },
  {
    name: 'Cambodia (កម្ពុជា)',
    flagClass: 'kh',
    dialCode: '855'
  },
  {
    name: 'Cameroon (Cameroun)',
    flagClass: 'cm',
    dialCode: '237'
  },
  {
    name: 'Canada',
    flagClass: 'ca',
    dialCode: '1'
  },
  {
    name: 'Cape Verde (Kabu Verdi)',
    flagClass: 'cv',
    dialCode: '238'
  },
  {
    name: 'Caribbean Netherlands',
    flagClass: 'bq',
    dialCode: '599'
  },
  {
    name: 'Cayman Islands',
    flagClass: 'ky',
    dialCode: '1'
  },
  {
    name: 'Central African Republic (République centrafricaine)',
    flagClass: 'cf',
    dialCode: '236'
  },
  {
    name: 'Chad (Tchad)',
    flagClass: 'td',
    dialCode: '235'
  },
  {
    name: 'Chile',
    flagClass: 'cl',
    dialCode: '56'
  },
  {
    name: 'China (中国)',
    flagClass: 'cn',
    dialCode: '86'
  },
  {
    name: 'Christmas Island',
    flagClass: 'cx',
    dialCode: '61'
  },
  {
    name: 'Cocos (Keeling) Islands',
    flagClass: 'cc',
    dialCode: '61'
  },
  {
    name: 'Colombia',
    flagClass: 'co',
    dialCode: '57'
  },
  {
    name: 'Comoros (‫جزر القمر‬‎)',
    flagClass: 'km',
    dialCode: '269'
  },
  {
    name: 'Congo (DRC) (Jamhuri ya Kidemokrasia ya Kongo)',
    flagClass: 'cd',
    dialCode: '243'
  },
  {
    name: 'Congo (Republic) (Congo-Brazzaville)',
    flagClass: 'cg',
    dialCode: '242'
  },
  {
    name: 'Cook Islands',
    flagClass: 'ck',
    dialCode: '682'
  },
  {
    name: 'Costa Rica',
    flagClass: 'cr',
    dialCode: '506'
  },
  {
    name: 'Côte d’Ivoire',
    flagClass: 'ci',
    dialCode: '225'
  },
  {
    name: 'Croatia (Hrvatska)',
    flagClass: 'hr',
    dialCode: '385'
  },
  {
    name: 'Cuba',
    flagClass: 'cu',
    dialCode: '53'
  },
  {
    name: 'Curaçao',
    flagClass: 'cw',
    dialCode: '599'
  },
  {
    name: 'Cyprus (Κύπρος)',
    flagClass: 'cy',
    dialCode: '357'
  },
  {
    name: 'Czech Republic (Česká republika)',
    flagClass: 'cz',
    dialCode: '420'
  },
  {
    name: 'Denmark (Danmark)',
    flagClass: 'dk',
    dialCode: '45'
  },
  {
    name: 'Djibouti',
    flagClass: 'dj',
    dialCode: '253'
  },
  {
    name: 'Dominica',
    flagClass: 'dm',
    dialCode: '1767'
  },
  {
    name: 'Dominican Republic (República Dominicana)',
    flagClass: 'do',
    dialCode: '1'
  },
  {
    name: 'Ecuador',
    flagClass: 'ec',
    dialCode: '593'
  },
  {
    name: 'Egypt (‫مصر‬‎)',
    flagClass: 'eg',
    dialCode: '20'
  },
  {
    name: 'El Salvador',
    flagClass: 'sv',
    dialCode: '503'
  },
  {
    name: 'Equatorial Guinea (Guinea Ecuatorial)',
    flagClass: 'gq',
    dialCode: '240'
  },
  {
    name: 'Eritrea',
    flagClass: 'er',
    dialCode: '291'
  },
  {
    name: 'Estonia (Eesti)',
    flagClass: 'ee',
    dialCode: '372'
  },
  {
    name: 'Ethiopia',
    flagClass: 'et',
    dialCode: '251'
  },
  {
    name: 'Falkland Islands (Islas Malvinas)',
    flagClass: 'fk',
    dialCode: '500'
  },
  {
    name: 'Faroe Islands (Føroyar)',
    flagClass: 'fo',
    dialCode: '298'
  },
  {
    name: 'Fiji',
    flagClass: 'fj',
    dialCode: '679'
  },
  {
    name: 'Finland (Suomi)',
    flagClass: 'fi',
    dialCode: '358'
  },
  {
    name: 'France',
    flagClass: 'fr',
    dialCode: '33'
  },
  {
    name: 'French Guiana (Guyane française)',
    flagClass: 'gf',
    dialCode: '594'
  },
  {
    name: 'French Polynesia (Polynésie française)',
    flagClass: 'pf',
    dialCode: '689'
  },
  {
    name: 'Gabon',
    flagClass: 'ga',
    dialCode: '241'
  },
  {
    name: 'Gambia',
    flagClass: 'gm',
    dialCode: '220'
  },
  {
    name: 'Georgia (საქართველო)',
    flagClass: 'ge',
    dialCode: '995'
  },
  {
    name: 'Ghana (Gaana)',
    flagClass: 'gh',
    dialCode: '233'
  },
  {
    name: 'Gibraltar',
    flagClass: 'gi',
    dialCode: '350'
  },
  {
    name: 'Greenland (Kalaallit Nunaat)',
    flagClass: 'gl',
    dialCode: '299'
  },
  {
    name: 'Grenada',
    flagClass: 'gd',
    dialCode: '1473'
  },
  {
    name: 'Guadeloupe',
    flagClass: 'gp',
    dialCode: '590'
  },
  {
    name: 'Guam',
    flagClass: 'gu',
    dialCode: '1'
  },
  {
    name: 'Guatemala',
    flagClass: 'gt',
    dialCode: '502'
  },
  {
    name: 'Guernsey',
    flagClass: 'gg',
    dialCode: '44'
  },
  {
    name: 'Guinea (Guinée)',
    flagClass: 'gn',
    dialCode: '224'
  },
  {
    name: 'Guinea-Bissau (Guiné Bissau)',
    flagClass: 'gw',
    dialCode: '245'
  },
  {
    name: 'Guyana',
    flagClass: 'gy',
    dialCode: '592'
  },
  {
    name: 'Haiti',
    flagClass: 'ht',
    dialCode: '509'
  },
  {
    name: 'Honduras',
    flagClass: 'hn',
    dialCode: '504'
  },
  {
    name: 'Hong Kong (香港)',
    flagClass: 'hk',
    dialCode: '852'
  },
  {
    name: 'Hungary (Magyarország)',
    flagClass: 'hu',
    dialCode: '36'
  },
  {
    name: 'Iceland (Ísland)',
    flagClass: 'is',
    dialCode: '354'
  },
  {
    name: 'India (भारत)',
    flagClass: 'in',
    dialCode: '91'
  },
  {
    name: 'Indonesia',
    flagClass: 'id',
    dialCode: '62'
  },
  {
    name: 'Iran (‫ایران‬‎)',
    flagClass: 'ir',
    dialCode: '98'
  },
  {
    name: 'Iraq (‫العراق‬‎)',
    flagClass: 'iq',
    dialCode: '964'
  },
  {
    name: 'Ireland',
    flagClass: 'ie',
    dialCode: '353'
  },
  {
    name: 'Isle of Man',
    flagClass: 'im',
    dialCode: '44'
  },
  {
    name: 'Israel (‫ישראל‬‎)',
    flagClass: 'il',
    dialCode: '972'
  },
  {
    name: 'Jamaica',
    flagClass: 'jm',
    dialCode: '1'
  },
  {
    name: 'Japan (日本)',
    flagClass: 'jp',
    dialCode: '81'
  },
  {
    name: 'Jersey',
    flagClass: 'je',
    dialCode: '44'
  },
  {
    name: 'Jordan (‫الأردن‬‎)',
    flagClass: 'jo',
    dialCode: '962'
  },
  {
    name: 'Kazakhstan (Казахстан)',
    flagClass: 'kz',
    dialCode: '7'
  },
  {
    name: 'Kenya',
    flagClass: 'ke',
    dialCode: '254'
  },
  {
    name: 'Kiribati',
    flagClass: 'ki',
    dialCode: '686'
  },
  {
    name: 'Kosovo',
    flagClass: 'xk',
    dialCode: '383'
  },
  {
    name: 'Kuwait (‫الكويت‬‎)',
    flagClass: 'kw',
    dialCode: '965'
  },
  {
    name: 'Kyrgyzstan (Кыргызстан)',
    flagClass: 'kg',
    dialCode: '996'
  },
  {
    name: 'Laos (ລາວ)',
    flagClass: 'la',
    dialCode: '856'
  },
  {
    name: 'Latvia (Latvija)',
    flagClass: 'lv',
    dialCode: '371'
  },
  {
    name: 'Lebanon (‫لبنان‬‎)',
    flagClass: 'lb',
    dialCode: '961'
  },
  {
    name: 'Lesotho',
    flagClass: 'ls',
    dialCode: '266'
  },
  {
    name: 'Liberia',
    flagClass: 'lr',
    dialCode: '231'
  },
  {
    name: 'Libya (‫ليبيا‬‎)',
    flagClass: 'ly',
    dialCode: '218'
  },
  {
    name: 'Liechtenstein',
    flagClass: 'li',
    dialCode: '423'
  },
  {
    name: 'Lithuania (Lietuva)',
    flagClass: 'lt',
    dialCode: '370'
  },
  {
    name: 'Luxembourg',
    flagClass: 'lu',
    dialCode: '352'
  },
  {
    name: 'Macau (澳門)',
    flagClass: 'mo',
    dialCode: '853'
  },
  {
    name: 'Macedonia (FYROM) (Македонија)',
    flagClass: 'mk',
    dialCode: '389'
  },
  {
    name: 'Madagascar (Madagasikara)',
    flagClass: 'mg',
    dialCode: '261'
  },
  {
    name: 'Malawi',
    flagClass: 'mw',
    dialCode: '265'
  },
  {
    name: 'Malaysia',
    flagClass: 'my',
    dialCode: '60'
  },
  {
    name: 'Maldives',
    flagClass: 'mv',
    dialCode: '960'
  },
  {
    name: 'Mali',
    flagClass: 'ml',
    dialCode: '223'
  },
  {
    name: 'Malta',
    flagClass: 'mt',
    dialCode: '356'
  },
  {
    name: 'Marshall Islands',
    flagClass: 'mh',
    dialCode: '692'
  },
  {
    name: 'Martinique',
    flagClass: 'mq',
    dialCode: '596'
  },
  {
    name: 'Mauritania (‫موريتانيا‬‎)',
    flagClass: 'mr',
    dialCode: '222'
  },
  {
    name: 'Mauritius (Moris)',
    flagClass: 'mu',
    dialCode: '230'
  },
  {
    name: 'Mayotte',
    flagClass: 'yt',
    dialCode: '262'
  },
  {
    name: 'Mexico (México)',
    flagClass: 'mx',
    dialCode: '52'
  },
  {
    name: 'Micronesia',
    flagClass: 'fm',
    dialCode: '691'
  },
  {
    name: 'Moldova (Republica Moldova)',
    flagClass: 'md',
    dialCode: '373'
  },
  {
    name: 'Monaco',
    flagClass: 'mc',
    dialCode: '377'
  },
  {
    name: 'Mongolia (Монгол)',
    flagClass: 'mn',
    dialCode: '976'
  },
  {
    name: 'Montenegro (Crna Gora)',
    flagClass: 'me',
    dialCode: '382'
  },
  {
    name: 'Montserrat',
    flagClass: 'ms',
    dialCode: '1'
  },
  {
    name: 'Morocco (‫المغرب‬‎)',
    flagClass: 'ma',
    dialCode: '212'
  },
  {
    name: 'Mozambique (Moçambique)',
    flagClass: 'mz',
    dialCode: '258'
  },
  {
    name: 'Myanmar (Burma) (မြန်မာ)',
    flagClass: 'mm',
    dialCode: '95'
  },
  {
    name: 'Namibia (Namibië)',
    flagClass: 'na',
    dialCode: '264'
  },
  {
    name: 'Nauru',
    flagClass: 'nr',
    dialCode: '674'
  },
  {
    name: 'Nepal (नेपाल)',
    flagClass: 'np',
    dialCode: '977'
  },
  {
    name: 'New Caledonia (Nouvelle-Calédonie)',
    flagClass: 'nc',
    dialCode: '687'
  },
  {
    name: 'New Zealand',
    flagClass: 'nz',
    dialCode: '64'
  },
  {
    name: 'Nicaragua',
    flagClass: 'ni',
    dialCode: '505'
  },
  {
    name: 'Niger (Nijar)',
    flagClass: 'ne',
    dialCode: '227'
  },
  {
    name: 'Nigeria',
    flagClass: 'ng',
    dialCode: '234'
  },
  {
    name: 'Niue',
    flagClass: 'nu',
    dialCode: '683'
  },
  {
    name: 'Norfolk Island',
    flagClass: 'nf',
    dialCode: '672'
  },
  {
    name: 'North Korea (조선 민주주의 인민 공화국)',
    flagClass: 'kp',
    dialCode: '850'
  },
  {
    name: 'Northern Mariana Islands',
    flagClass: 'mp',
    dialCode: '1670'
  },
  {
    name: 'Norway (Norge)',
    flagClass: 'no',
    dialCode: '47'
  },
  {
    name: 'Oman (‫عُمان‬‎)',
    flagClass: 'om',
    dialCode: '968'
  },
  {
    name: 'Pakistan (‫پاکستان‬‎)',
    flagClass: 'pk',
    dialCode: '92'
  },
  {
    name: 'Palau',
    flagClass: 'pw',
    dialCode: '680'
  },
  {
    name: 'Palestine (‫فلسطين‬‎)',
    flagClass: 'ps',
    dialCode: '970'
  },
  {
    name: 'Panama (Panamá)',
    flagClass: 'pa',
    dialCode: '507'
  },
  {
    name: 'Papua New Guinea',
    flagClass: 'pg',
    dialCode: '675'
  },
  {
    name: 'Paraguay',
    flagClass: 'py',
    dialCode: '595'
  },
  {
    name: 'Peru (Perú)',
    flagClass: 'pe',
    dialCode: '51'
  },
  {
    name: 'Philippines',
    flagClass: 'ph',
    dialCode: '63'
  },
  {
    name: 'Portugal',
    flagClass: 'pt',
    dialCode: '351'
  },
  {
    name: 'Puerto Rico',
    flagClass: 'pr',
    dialCode: '1'
  },
  {
    name: 'Qatar (‫قطر‬‎)',
    flagClass: 'qa',
    dialCode: '974'
  },
  {
    name: 'Réunion (La Réunion)',
    flagClass: 're',
    dialCode: '262'
  },
  {
    name: 'Romania (România)',
    flagClass: 'ro',
    dialCode: '40'
  },
  {
    name: 'Russia (Россия)',
    flagClass: 'ru',
    dialCode: '7'
  },
  {
    name: 'Rwanda',
    flagClass: 'rw',
    dialCode: '250'
  },
  {
    name: 'Saint Barthélemy (Saint-Barthélemy)',
    flagClass: 'bl',
    dialCode: '590'
  },
  {
    name: 'Saint Helena',
    flagClass: 'sh',
    dialCode: '290'
  },
  {
    name: 'Saint Kitts and Nevis',
    flagClass: 'kn',
    dialCode: '1869'
  },
  {
    name: 'Saint Lucia',
    flagClass: 'lc',
    dialCode: '1'
  },
  {
    name: 'Saint Martin (Saint-Martin (partie française))',
    flagClass: 'mf',
    dialCode: '590'
  },
  {
    name: 'Saint Pierre and Miquelon (Saint-Pierre-et-Miquelon)',
    flagClass: 'pm',
    dialCode: '508'
  },
  {
    name: 'Saint Vincent and the Grenadines',
    flagClass: 'vc',
    dialCode: '1'
  },
  {
    name: 'Samoa',
    flagClass: 'ws',
    dialCode: '685'
  },
  {
    name: 'San Marino',
    flagClass: 'sm',
    dialCode: '378'
  },
  {
    name: 'São Tomé and Príncipe (São Tomé e Príncipe)',
    flagClass: 'st',
    dialCode: '239'
  },
  {
    name: 'Saudi Arabia (‫المملكة العربية السعودية‬‎)',
    flagClass: 'sa',
    dialCode: '966'
  },
  {
    name: 'Senegal (Sénégal)',
    flagClass: 'sn',
    dialCode: '221'
  },
  {
    name: 'Serbia (Србија)',
    flagClass: 'rs',
    dialCode: '381'
  },
  {
    name: 'Seychelles',
    flagClass: 'sc',
    dialCode: '248'
  },
  {
    name: 'Sierra Leone',
    flagClass: 'sl',
    dialCode: '232'
  },
  {
    name: 'Singapore',
    flagClass: 'sg',
    dialCode: '65'
  },
  {
    name: 'Sint Maarten',
    flagClass: 'sx',
    dialCode: '1'
  },
  {
    name: 'Solomon Islands',
    flagClass: 'sb',
    dialCode: '677'
  },
  {
    name: 'Somalia (Soomaaliya)',
    flagClass: 'so',
    dialCode: '252'
  },
  {
    name: 'South Africa',
    flagClass: 'za',
    dialCode: '27'
  },
  {
    name: 'South Korea (대한민국)',
    flagClass: 'kr',
    dialCode: '82'
  },
  {
    name: 'South Sudan (‫جنوب السودان‬‎)',
    flagClass: 'ss',
    dialCode: '211'
  },
  {
    name: 'Spain (España)',
    flagClass: 'es',
    dialCode: '34'
  },
  {
    name: 'Sri Lanka (ශ්‍රී ලංකාව)',
    flagClass: 'lk',
    dialCode: '94'
  },
  {
    name: 'Sudan (‫السودان‬‎)',
    flagClass: 'sd',
    dialCode: '249'
  },
  {
    name: 'Suriname',
    flagClass: 'sr',
    dialCode: '597'
  },
  {
    name: 'Svalbard and Jan Mayen',
    flagClass: 'sj',
    dialCode: '47'
  },
  {
    name: 'Swaziland',
    flagClass: 'sz',
    dialCode: '268'
  },
  {
    name: 'Sweden (Sverige)',
    flagClass: 'se',
    dialCode: '46'
  },
  {
    name: 'Switzerland (Schweiz)',
    flagClass: 'ch',
    dialCode: '41'
  },
  {
    name: 'Syria (‫سوريا‬‎)',
    flagClass: 'sy',
    dialCode: '963'
  },
  {
    name: 'Taiwan (台灣)',
    flagClass: 'tw',
    dialCode: '886'
  },
  {
    name: 'Tajikistan',
    flagClass: 'tj',
    dialCode: '992'
  },
  {
    name: 'Tanzania',
    flagClass: 'tz',
    dialCode: '255'
  },
  {
    name: 'Thailand (ไทย)',
    flagClass: 'th',
    dialCode: '66'
  },
  {
    name: 'Timor-Leste',
    flagClass: 'tl',
    dialCode: '670'
  },
  {
    name: 'Togo',
    flagClass: 'tg',
    dialCode: '228'
  },
  {
    name: 'Tokelau',
    flagClass: 'tk',
    dialCode: '690'
  },
  {
    name: 'Tonga',
    flagClass: 'to',
    dialCode: '676'
  },
  {
    name: 'Trinidad and Tobago',
    flagClass: 'tt',
    dialCode: '1'
  },
  {
    name: 'Tunisia (‫تونس‬‎)',
    flagClass: 'tn',
    dialCode: '216'
  },
  {
    name: 'Turkey (Türkiye)',
    flagClass: 'tr',
    dialCode: '90'
  },
  {
    name: 'Turkmenistan',
    flagClass: 'tm',
    dialCode: '993'
  },
  {
    name: 'Turks and Caicos Islands',
    flagClass: 'tc',
    dialCode: '1649'
  },
  {
    name: 'Tuvalu',
    flagClass: 'tv',
    dialCode: '688'
  },
  {
    name: 'U.S. Virgin Islands',
    flagClass: 'vi',
    dialCode: '1'
  },
  {
    name: 'Uganda',
    flagClass: 'ug',
    dialCode: '256'
  },
  {
    name: 'United Arab Emirates (‫الإمارات العربية المتحدة‬‎)',
    flagClass: 'ae',
    dialCode: '971'
  },
  {
    name: 'Uruguay',
    flagClass: 'uy',
    dialCode: '598'
  },
  {
    name: 'Uzbekistan (Oʻzbekiston)',
    flagClass: 'uz',
    dialCode: '998'
  },
  {
    name: 'Vanuatu',
    flagClass: 'vu',
    dialCode: '678'
  },
  {
    name: 'Vatican City (Città del Vaticano)',
    flagClass: 'va',
    dialCode: '39'
  },
  {
    name: 'Venezuela',
    flagClass: 've',
    dialCode: '58'
  },
  {
    name: 'Vietnam (Việt Nam)',
    flagClass: 'vn',
    dialCode: '84'
  },
  {
    name: 'Wallis and Futuna',
    flagClass: 'wf',
    dialCode: '681'
  },
  {
    name: 'Western Sahara (‫الصحراء الغربية‬‎)',
    flagClass: 'eh',
    dialCode: '212'
  },
  {
    name: 'Yemen (‫اليمن‬‎)',
    flagClass: 'ye',
    dialCode: '967'
  },
  {
    name: 'Zambia',
    flagClass: 'zm',
    dialCode: '260'
  },
  {
    name: 'Zimbabwe',
    flagClass: 'zw',
    dialCode: '263'
  },
  {
    name: 'Åland Islands',
    flagClass: 'ax',
    dialCode: '358'
  }
];
