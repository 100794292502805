<form [formGroup]="form" class="passenger-age-select">
  <mat-form-field>
    <mat-select [formControl]="form.get(AgeFormField.AGE)">
      <mat-option *ngFor="let age of dropdownOptions" [value]="age">
        <span *ngIf="AdditionalPassengerAgeEnum.Adult == age">{{
          "Ticket.adult" | translate
        }}</span>
        <span *ngIf="AdditionalPassengerAgeEnum.Pensioner == age">{{
          "Ticket.PensionerLabel" | translate
        }}</span>
      </mat-option>
    </mat-select>
  </mat-form-field>
</form>
