<div class="account-page has-bottom-nav" [class.account-page--full-height]="!WindowHelper.isMobile && !auth.isSignedIn()">
  <div class="background">
    <div class="container container--flat">
      <div class="header header--big" *ngIf="WindowHelper.isMobile">
        <div class="header-brand">
          <img src="/app/shared/assets/svg/bnl-logo.svg" alt="Busynalotniska">
        </div>
      </div>

      <h1 class="account-page-title">
        {{WindowHelper.isMobile && auth.isSignedIn() ? ('AccountPage.welcome' | translate) : 'AccountPage.title' | translate}}
      </h1>

      <p class="account-page-description text-primary-white">
        <span *ngIf="!auth.isSignedIn()" [innerHTML]="'AccountPage.description' | translate"></span>
        <span *ngIf="!WindowHelper.isMobile && auth.isSignedIn()" [innerHTML]="'AccountPage.fillData' | translate"></span>
      </p>

      <bnl-button
        *ngIf="!auth.isSignedIn()"
        (click)="auth.openAuthDialog()"
        class="account-page-button d-inline-block"
        buttonStyle="primary"
        iconSrc="/app/shared/assets/svg/user.svg"
        size="md"
        boldText="{{'AccountPage.loginButton' | translate}}">
      </bnl-button>
    </div>
  </div>

  <div class="container" *ngIf="!WindowHelper.isMobile && auth.isSignedIn()">
    <bnl-profile-edit-form class="account-page-form"></bnl-profile-edit-form>
  </div>

  <div class="container" *ngIf="WindowHelper.isMobile">
    <bnl-list [listItems]="userDetailsList" *ngIf="auth.isSignedIn()"></bnl-list>
    <bnl-list [listItems]="listItems"></bnl-list>

    <bnl-button
      class="account-page-logout"
      *ngIf="auth.isSignedIn()"
      buttonStyle="danger"
      align="center"
      [showLoader]="isRequestPending"
      [text]="'ProfileDetailsPage.logoff' | translate"
      (click)="onLogout()">
    </bnl-button>
  </div>
</div>
