import { Injectable } from '@angular/core';
import {
  AdditionalPassengerAgeEnum,
  AdditionalPassengerAgeRangeEnum,
  CreateTicketGQL,
  CreateTicketMutation,
  GetBaggageCountGQL,
  GetBaggageCountQuery,
  PathwaysListElementGraphql,
  TicketInput,
} from '@modules/graphql/graphql.service';
import { PassengersConfig } from '@modules/tabs/search-tab/shared/interfaces/passengers-config.interface';
import { LuggageConfig } from '@modules/tabs/search-tab/shared/interfaces/luggage-config.interface';
import { GlobalHelper } from '@shared/helpers/global.helper';
import { AdditionalPassenger } from '@modules/tabs/search-tab/shared/models/additional-passenger.model';
import { Observable, Subscriber } from 'rxjs';
import { FetchResult } from 'apollo-link';

@Injectable()
export class TicketCreatorService {
  private _selectedPathway: PathwaysListElementGraphql;

  public additionalPassengers: PassengersConfig;
  public additionalLuggage: LuggageConfig;

  public availableBikes: number;
  public availableAnimals: number;
  public isBaggageAvailable: boolean;

  private _mainPassengerAge: AdditionalPassengerAgeEnum;

  get mainPassengerAge(): AdditionalPassengerAgeEnum {
    return this._mainPassengerAge;
  }

  constructor(
    private createTicketGQL: CreateTicketGQL,
    private getBaggageCountGQL: GetBaggageCountGQL
  ) {}

  setMainPassengerAge(age: AdditionalPassengerAgeEnum): void {
    this._mainPassengerAge = age;
  }

  public submitTicket(
    ticket: TicketInput
  ): Observable<FetchResult<CreateTicketMutation>> {
    return this.createTicketGQL.mutate({ ticket });
  }

  public initTicket(pathway: PathwaysListElementGraphql): Observable<boolean> {
    this.clearTicketData();
    this._selectedPathway = pathway;

    return new Observable<boolean>((sub: Subscriber<boolean>) => {
      this.getBaggageCountGQL
        .fetch(
          {
            departureId: this._selectedPathway.start.id,
            term: this._selectedPathway.rideDate,
          },
          { fetchPolicy: 'network-only' }
        )
        .subscribe(
          (res: FetchResult<GetBaggageCountQuery>) => {
            const { options } = this._selectedPathway.pathway.bus;
            this.availableAnimals =
              options.animalsNumber - res.data.getBaggageCount.animalsCount;
            this.availableBikes =
              options.bikesNumber - res.data.getBaggageCount.bikesCount;
            this.isBaggageAvailable = !(
              this.availableAnimals + this.availableBikes <= 0 &&
              !options.extraBaggage
            );

            sub.next(true);
            sub.complete();
          },
          (error) => {
            sub.error(error);
            sub.complete();
          }
        );
    });
  }

  public clearTicketData(): void {
    this._selectedPathway = undefined;
    this.availableBikes = undefined;
    this.availableBikes = undefined;

    this.additionalPassengers = {
      adult: [],
      child: [],
      pensioner: [],
    };

    this.additionalLuggage = {
      bikesCount: 0,
      animalsCount: 0,
      luggageCount: 0,
    };
  }

  get selectedPathway(): PathwaysListElementGraphql {
    return this._selectedPathway;
  }

  get singleTicketPrice(): number {
    return this._selectedPathway.price;
  }

  getTotalTicketPrice(
    passengersConfig: PassengersConfig,
    luggageConfig: LuggageConfig
  ): number {
    return (
      this.getAdditionalPassengersPrice(passengersConfig) +
      this.getTotalAdditionalLuggagePrice(luggageConfig) +
      this.getMainPassengerPrice()
    );
  }

  getMainPassengerPrice(): number {
    const { discChild04, discChild46, discChild618, discPensioner } =
      this.selectedPathway.pathway.company.options;
    return AdditionalPassengerAgeEnum.Pensioner === this._mainPassengerAge
      ? GlobalHelper.discount(this.singleTicketPrice, discPensioner)
      : this.singleTicketPrice;
  }

  getAdditionalPassengersPrice(passengersConfig: PassengersConfig): number {
    const { discChild04, discChild46, discChild618, discPensioner } =
      this.selectedPathway.pathway.company.options;
    const adultsPrice = passengersConfig.adult.length * this.singleTicketPrice;
    const pensionersPrice =
      passengersConfig.pensioner.length *
      GlobalHelper.discount(this.singleTicketPrice, discPensioner);
    const childsPrice = passengersConfig.child.reduce(
      (total: number, passenger: AdditionalPassenger) => {
        if (!passenger.ageRange) {
          return total;
        }

        switch (passenger.ageRange) {
          case AdditionalPassengerAgeRangeEnum.Age_0_4: {
            return (total += GlobalHelper.discount(
              this.singleTicketPrice,
              discChild04
            ));
          }
          case AdditionalPassengerAgeRangeEnum.Age_4_6: {
            return (total += GlobalHelper.discount(
              this.singleTicketPrice,
              discChild46
            ));
          }
          case AdditionalPassengerAgeRangeEnum.Age_6_18: {
            return (total += GlobalHelper.discount(
              this.singleTicketPrice,
              discChild618
            ));
          }
        }
      },
      0
    );

    return adultsPrice + pensionersPrice + childsPrice;
  }

  getTotalAdditionalLuggagePrice(luggageConfig: LuggageConfig): number {
    return (
      luggageConfig.bikesCount * this.additionalSingleBikePrice +
      luggageConfig.animalsCount * this.additionalSingleAnimalPrice +
      luggageConfig.luggageCount * this.additionalSingleLuggagePrice
    );
  }

  getDisplayAge(age: AdditionalPassengerAgeRangeEnum): string {
    switch (age) {
      case AdditionalPassengerAgeRangeEnum.Age_0_4:
        return '0-4';
      case AdditionalPassengerAgeRangeEnum.Age_4_6:
        return '4-6';
      case AdditionalPassengerAgeRangeEnum.Age_6_18:
        return '6-18';
    }
  }

  get additionalSingleBikePrice(): number {
    return this._selectedPathway.pathway.company.options.bikesFee;
  }

  get additionalSingleAnimalPrice(): number {
    return this._selectedPathway.pathway.company.options.animalsFee;
  }

  get additionalSingleLuggagePrice(): number {
    return this._selectedPathway.pathway.company.options.baggageFee;
  }

  getAdditionalBikesPrice(luggageConfig: LuggageConfig): number {
    return luggageConfig.bikesCount * this.additionalSingleBikePrice;
  }

  getAdditionalAnimalsPrice(luggageConfig: LuggageConfig): number {
    return luggageConfig.animalsCount * this.additionalSingleAnimalPrice;
  }

  getAdditionalLuggagePrice(luggageConfig: LuggageConfig): number {
    return luggageConfig.luggageCount * this.additionalSingleLuggagePrice;
  }

  getAdditionalPassengersCount(passengersConfig: PassengersConfig): number {
    return (
      passengersConfig.adult.length +
      passengersConfig.child.length +
      passengersConfig.pensioner.length
    );
  }

  getAdditionalLuggageCount(luggageConfig: LuggageConfig): number {
    return (
      Number(luggageConfig.bikesCount) +
      Number(luggageConfig.animalsCount) +
      Number(luggageConfig.luggageCount)
    );
  }
}
