import { isDevMode, NgModule } from '@angular/core';
import { APOLLO_OPTIONS } from 'apollo-angular';
import { ApolloClientOptions, ApolloLink, InMemoryCache } from '@apollo/client/core';
import { HttpLink } from 'apollo-angular/http';
import { environment } from '../../../environments/environment';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { HttpLinkModule } from 'apollo-angular-link-http';
import { HttpClientModule } from '@angular/common/http';
import { StorageService } from '@shared/services/storage.service';
import { StorageItem } from '@shared/enums/storage-item.enum';

const uri = environment.graphqlApi; // <-- add the URL of the GraphQL server here
export function createApollo(httpLink: HttpLink, storageService: StorageService): ApolloClientOptions<any> {
  const basic = setContext((operation, context) => ({
    headers: {
      Accept: 'charset=utf-8'
    }
  }));

  const auth = setContext(async (_, { headers }) => {
    const token = storageService.getItem(StorageItem.AUTH_TOKEN);
    const _headers = {}

    if (!!_?.variables?.input?.recaptcha) {
      _headers['recaptcha'] = _.variables?.input?.recaptcha;
      delete _.variables?.input?.recaptcha;
    }

    if (!!token) {
      _headers['Authorization'] = `Bearer ${token}`;
    }

    return {
      headers: _headers
    };
  });

  const error = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach((graphQLError) => {
        if (isDevMode()) {
          console.error(`[Graphql error]: ${JSON.stringify(graphQLError)}`);
        }
      });
    }

    if (networkError) {
      if (isDevMode()) {
        console.error(`[Network error]: ${JSON.stringify(networkError)}`);
      }
    }
  });

  const link = ApolloLink.from([basic, auth, error, httpLink.create({ uri })]);
  const cache = new InMemoryCache();

  return {
    link,
    cache
  };
}

@NgModule({
  exports: [
    HttpClientModule,
    HttpLinkModule
  ],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink, StorageService]
    }
  ]
})
export class GraphQLModule {
}
