import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from "@angular/core";
import { OrdersService } from "@core/services/orders.service";
import { BusRentGraphql } from "@modules/graphql/graphql.service";
import { Config } from "@shared/configs/config";

@Component({
  selector: "bnl-order-path-details",
  templateUrl: "./order-path-details.component.html",
  styleUrls: ["./order-path-details.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderPathDetailsComponent {
  @Input() order: BusRentGraphql;
  @Input() showMapButtons = true;
  @Output() mapPreviewOpen = new EventEmitter<BusRentGraphql>();
  Config = Config;

  constructor(public ordersService: OrdersService) {}
}
