import { Component, Inject, OnInit } from '@angular/core';
import { TopBarConfig } from '@shared/interfaces/top-bar-config.interface';
import { BaseComponent } from '@shared/components/base-component';
import { GbxsoftInputTypes } from '@form/src/lib/gbxsoft-input/gbxsoft-input.types';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SearchService } from '@core/services/search.service';
import { SearchType } from '@modules/tabs/search-tab/shared/enums/search-type.enum';
import { FormControl } from '@angular/forms';
import { MapService } from '@shared/services/map.service';
import { SearchResult } from '@modules/tabs/search-tab/shared/interfaces/search-result.interface';
import { AgmGeocoder } from '@agm/core';
import { SnackBarState } from '@shared/enums/snack-bar-state.enum';
import { takeUntil } from 'rxjs/operators';
import { LocalizationService } from '@modules/tabs/search-tab/shared/services/localization.service';
import GeocoderResult = google.maps.GeocoderResult;

@Component({
  selector: 'bnl-search-dialog',
  templateUrl: './search-dialog.component.html',
  styleUrls: ['./search-dialog.component.scss']
})
export class SearchDialogComponent extends BaseComponent implements OnInit {
  GbxsoftInputTypes = GbxsoftInputTypes;
  SearchType = SearchType;

  readonly LOCALIZE_LOADER = 'localization-pending-loader';

  topBarConfig: TopBarConfig = {
    title: '',
    isTransparent: true,
    leftIcon: {
      src: '/app/shared/assets/svg/close.svg',
      alt: this.t.instant('Global.IconAlts.closeIcon'),
      clickHandler: () => this.dialogRef.close()
    }
  };

  searchField = new FormControl('');
  filteredSearchResults: SearchResult[];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<SearchDialogComponent>,
    private searchService: SearchService,
    private mapService: MapService,
    private geocoder: AgmGeocoder,
    private localizationService: LocalizationService
  ) {
    super();
  }

  ngOnInit(): void {
    this.topBarConfig.title = this.data.searchDialogTitle;
    this.searchField.valueChanges.pipe(takeUntil(this.destroyed)).subscribe((val: string) => this.handleSearch(val));

    if (this.data.searchType === SearchType.AIRPORT) {
      this.searchService.getAirportsAsSearchResults().pipe(takeUntil(this.destroyed)).subscribe((res: SearchResult[]) => {
        this.filteredSearchResults = res;
      });
    }
  }

  onSearchResultPick(searchResult: SearchResult): void {
    this.dialogRef.close(searchResult);
  }

  onLocalize(): void {
    this.spinner.show(this.LOCALIZE_LOADER);
    this.localizationService.getCurrentPosition(true).subscribe((position: Position) => {
      this.handleLocalizationResult(position);
    }, (error: PositionError) => {
      this.handleLocalizationError(error);
    });
  }

  private handleLocalizationResult(position: Position): void {
    this.geocoder.geocode({
      location: new google.maps.LatLng(position.coords.latitude, position.coords.longitude)
    }).pipe(takeUntil(this.destroyed)).subscribe((geocoderResults: GeocoderResult[]) => {
      const searchResult: SearchResult = {
        isSearched: true,
        name: geocoderResults[0].formatted_address.split(',').slice(0, -1).join(','),
        placeId: geocoderResults[0].place_id
      };

      this.dialogRef.close(searchResult);
      this.spinner.hide(this.LOCALIZE_LOADER);
    });
  }

  private handleLocalizationError(error: PositionError): void {
    this.snackbar.open(this.localizationService.getLocalizationErrorMessage(error), SnackBarState.ERROR);
    this.spinner.hide(this.LOCALIZE_LOADER);
  }

  private handleSearch(searchPhrase: string): void {
    switch (this.data.searchType) {
      case SearchType.AIRPORT: {
        this.filteredSearchResults = this.searchService.filterBusStops(this.filteredSearchResults, searchPhrase);
        break;
      }

      case SearchType.LOCALIZATION: {
        if (!!searchPhrase) {
          this.mapService.getAutocomplete().getPlacePredictions(
            {input: searchPhrase},
            (res: google.maps.places.AutocompletePrediction[]) => {
              this.filteredSearchResults = res.map((prediction: google.maps.places.AutocompletePrediction) => ({
                isSearched: true,
                name: prediction.description,
                placeId: prediction.place_id
              }));
            });
        }

        break;
      }
    }
  }
}
