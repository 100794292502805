import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { CarrierCompanyGraphql } from "@modules/graphql/graphql.service";
import { CarrierInfoDialogComponent } from "../carrier-info-dialog/carrier-info-dialog.component";

@Component({
  selector: "bnl-carrier-info",
  templateUrl: "./carrier-info.component.html",
  styleUrls: ["./carrier-info.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CarrierInfoComponent {
  @Input() carrier: CarrierCompanyGraphql;
  @Input() showCarrierInfo = false;

  constructor(private dialog: MatDialog) {}

  onCarrierInfoDialogOpen(): void {
    this.dialog.open(CarrierInfoDialogComponent, {
      panelClass: "carrier-info-dialog",
      data: { carrierInfo: this.carrier },
    });
  }
}
