import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from "@angular/core";
import {
  PathwaysListElementGraphql,
  TicketGraphql,
} from "@modules/graphql/graphql.service";
import { BaseComponent } from "@shared/components/base-component";
import { WindowHelper } from "@shared/helpers/window.helper";
import { debounce } from "@shared/decorators/debounce.decodator";
import { TicketsService } from "@core/services/tickets.service";

@Component({
  selector: "bnl-connection-card",
  templateUrl: "./connection-card.component.html",
  styleUrls: ["./connection-card.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConnectionCardComponent extends BaseComponent {
  @Input() background: "white" | "gray" = "white";
  @Input() pathway: PathwaysListElementGraphql;
  @Input() isProfileTicket = false;
  @Input() ticket: TicketGraphql;

  @Output() ridePathOpen = new EventEmitter<PathwaysListElementGraphql>();
  @Output() ticketRidePathOpen = new EventEmitter<TicketGraphql>();
  @Output() mapPreviewOpen = new EventEmitter<PathwaysListElementGraphql>();
  @Output() ticketBuy = new EventEmitter<PathwaysListElementGraphql>();
  @Output() ticketPay = new EventEmitter<TicketGraphql>();
  @Output() ticketDelete = new EventEmitter<TicketGraphql>();

  WindowHelper = WindowHelper;
  isDetailsPartOpen = false;

  @HostListener("window:resize")
  @debounce(30)
  onWindowResize() {
    this.ref.detectChanges();
  }

  get availableSeats(): number {
    return this.pathway.availableSeats;
  }

  get hasAverageReview(): boolean {
    return (
      this.pathway.pathway.company.avgReview !== null &&
      this.pathway.pathway.company.avgReview !== undefined
    );
  }

  get averageReview(): number {
    return this.pathway.pathway.company.avgReview;
  }

  constructor(
    public ticketsService: TicketsService,
    private ref: ChangeDetectorRef
  ) {
    super();
  }

  onPathDetailsOpen(): void {
    if (WindowHelper.isMobile) {
      this.isProfileTicket
        ? this.ticketRidePathOpen.emit(this.ticket)
        : this.ridePathOpen.emit(this.pathway);
    } else {
      this.isDetailsPartOpen = !this.isDetailsPartOpen;
    }
  }
}
