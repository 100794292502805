<div class="ticket-preview" [class.ticket-preview--historyPreview]="isHistoryPreview">
  <div class="ticket-preview-header" *ngIf="!isHistoryPreview">
    <div class="ticket-preview-header-icon">
      <img src="/app/shared/assets/svg/check-yellow.svg" alt="{{'Global.IconAlts.checkIcon' | translate}}">
    </div>
    <p class="ticket-preview-header-title">{{(isDriverCard ? 'Ticket.selectedRide' : 'Ticket.selectedPath') | translate}}</p>
  </div>

  <div class="ticket-preview-content" *ngIf="pathway">
    <div class="ticket-preview-content-title">
      <p class="ticket-preview-content-title-text">
        <span>{{moment(pathway.rideDate).format('dddd, D MMM')}}
          {{ticketsService.getDisplayDepartureTime(pathway.start.departureTime)}}</span> -
        <span>{{ticketsService.getArrivalDate(pathway) | formatDate:'dddd, D MMM'}}
          {{ticketsService.getDisplayDepartureTime(pathway.end.departureTime)}}</span>
      </p>
    </div>
    <div class="ticket-preview-content-description">
      <p class="ticket-preview-content-description-text">
        {{pathway.start.busStop.name || pathway.start.busStop.address}}, {{pathway.start.busStop.city}} -
        {{pathway.end.busStop.name || pathway.end.busStop.address}}, {{pathway.end.busStop.city}}
      </p>
    </div>
    <div class="ticket-preview-content-carrier" [class.disabled]="isHistoryPreview && isDriverCard" (click)="isDriverCard ? settingsOpen.emit() : onCarrierInfoDialogOpen()">
      <ng-container *ngIf="isDriverCard">
        <p class="ticket-preview-content-carrier-text">{{'Ticket.openSettings' | translate}}</p>
      </ng-container>
      <ng-container *ngIf="!isDriverCard">
        <p class="ticket-preview-content-carrier-name">{{pathway.pathway.company.name}}</p>
        <p class="ticket-preview-content-carrier-text">{{'Ticket.aboutCarrier' | translate}}</p>
      </ng-container>
    </div>
    <div class="ticket-preview-content-duration">
      <p class="ticket-preview-content-duration-text">
        <span>{{ticketsService.getDisplayRideDuration(pathway)}}</span>
        <span class="d-md-none"> • </span> <br class="d-none d-md-block">
        <span>{{ticketsService.getDisplayBusStopsNumber(pathway)}}</span>
      </p>
    </div>
    <div class="ticket-preview-content-preview">
      <button class="ticket-preview-content-preview-button" (click)="!isHistoryPreview && mapOpen.emit()">
        <img class="ticket-preview-content-preview-button-icon" src="/app/shared/assets/svg/map.svg" alt="{{'Global.IconAlts.mapIcon' | translate}}">
        <span class="ticket-preview-content-preview-button-text">{{'Ticket.showPath' | translate}}</span>
      </button>
    </div>
  </div>
</div>
