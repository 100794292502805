import { AbstractControl } from "@angular/forms";
const EXCLUDE_DIGITS_REGEX: RegExp = /[^0-9]+/gi;

export function handlePhoneNumberIllegalChars(
  formControl: AbstractControl,
  newValue: any
): void {
  if (!formControl) return;
  formControl?.setValue(newValue.replaceAll(EXCLUDE_DIGITS_REGEX, ""), {
    emitEvent: false,
  });
}
