<div class="currentTicketsList">
  <ngx-spinner
    name="tickets-loader"
    class="currentTicketsList-loader"
    [fullScreen]="false"
    size="default"
    type="ball-clip-rotate"
    bdColor="rgba(0, 0, 0, .15)"
    color="#5051FF"
    *ngIf="loading$ | async"
  ></ngx-spinner>

  <bnl-empty-current-tickets
    class="currentTicketsList-empty"
    *ngIf="!(unpaidTickets$ | async)?.length && !(paidTickets$ | async)?.length && !(loading$ | async)"
    [title]="'ProfileTickets.noReservations' | translate"
    [description]="'ProfileTickets.emptyCurrentTickets' | translate"
    [buttonText]="'ProfileTickets.searchTicket' | translate"
  ></bnl-empty-current-tickets>
  <ng-container *ngIf="(unpaidTickets$ | async)?.length && !(loading$ | async)">
    <h3 class="currentTicketsList-title currentTicketsList-title--unpaid">
      {{ "ProfileTickets.CurrentTickets.unpaid" | translate }}
    </h3>
    <ul class="currentTicketsList-list">
      <li
        class="currentTicketsList-list-item"
        *ngFor="let ticket of unpaidTickets$ | async; index as i"
      >
        <bnl-connection-card
          [background]="i % 2 === 0 ? 'white' : 'gray'"
          [pathway]="ticket.departureData"
          [isProfileTicket]="true"
          [ticket]="ticket"
          (ticketDelete)="onTicketDelete($event)"
          (ticketRidePathOpen)="onRidePathOpen($event, true)"
          (mapPreviewOpen)="onMapPreviewOpen($event)"
          (ticketPay)="onTicketPay($event)"
        ></bnl-connection-card>
      </li>
    </ul>
  </ng-container>

  <ng-container *ngIf="(paidTickets$ | async)?.length && !(loading$ | async)">
    <h3 class="currentTicketsList-title currentTicketsList-title--paid">
      {{ "ProfileTickets.CurrentTickets.paid" | translate }}
    </h3>
    <ul class="currentTicketsList-list">
      <li
        class="currentTicketsList-list-item"
        *ngFor="let ticket of paidTickets$ | async; index as i"
      >
        <bnl-paid-ticket
          [ticket]="ticket"
          [background]="i % 2 === 0 ? 'white' : 'gray'"
          (ticketOpen)="
            n.navigate('ticket-pdf', {}, { ticketToken: ticket.token })
          "
          (mapPreviewOpen)="onMapPreviewOpen($event)"
          [hideReview]="true"
        ></bnl-paid-ticket>
      </li>
    </ul>
  </ng-container>
</div>

<div class="ride-path-pane d-md-none">
  <div class="ride-path-pane-inner" *ngIf="selectedTicket">
    <div class="ride-path-pane-header">
      <div class="ride-path-pane-header-details">
        <h3 class="ride-path-pane-header-details-title">
          {{ "PathDetails.details" | translate }}
        </h3>
        <p class="ride-path-pane-header-details-subtitle">
          {{
            ticketsService.getDisplayBusStopsNumber(
              selectedTicket.departureData
            )
          }},
          {{
            ticketsService.getDisplayRideDuration(selectedTicket.departureData)
          }}
        </p>
      </div>
      <bnl-button
        (click)="onMapPreviewOpen(selectedTicket.departureData)"
        class="ride-path-pane-header-button"
        size="sm"
        buttonStyle="blue"
        [rounded]="true"
        [boldText]="'PathDetails.showOnMap' | translate"
        iconSrc="/app/shared/assets/svg/folded-map.svg"
        [iconAlt]="'IconAlts.mapIcon' | translate"
      >
      </bnl-button>
    </div>
    <div class="ride-path-pane-content">
      <bnl-ride-path [pathway]="selectedTicket.departureData"></bnl-ride-path>
    </div>
    <div class="ride-path-pane-action">
      <p class="ride-path-pane-action-description">
        {{ "ProfileTickets.ticketFor" | translate }}
        {{ selectedTicket.additionalPassengers.length }}
        {{
          selectedTicket.additionalPassengers.length === 1
            ? ("ProfileTickets.singlePassenger" | translate)
            : ("ProfileTickets.multiplePassengers" | translate)
        }}.
      </p>
      <bnl-button
        (click)="
          selectedTicket.status === TicketStatus.PURCHASED
            ? n.navigate(
                'ticket-pdf',
                {},
                { ticketToken: selectedTicket.token }
              )
            : onTicketPay(selectedTicket)
        "
        class="ride-path-pane-action-button"
        [text]="
          selectedTicket.status === TicketStatus.PURCHASED
            ? ('ProfileTickets.showTicket' | translate)
            : ('Ticket.buyTicket' | translate)
        "
        [boldText]="
          selectedTicket.status === TicketStatus.PURCHASED
            ? null
            : (selectedTicket.price | price)
        "
        align="center"
        size="md"
      >
      </bnl-button>
    </div>
  </div>
</div>
