import { Component, Input } from '@angular/core';
import { ListItem } from '@shared/interfaces/list-item.interface';

@Component({
  selector: 'bnl-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent {
  @Input() listItems: ListItem[];
}
