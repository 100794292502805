import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
};



export type PassengerGraphql = {
  __typename?: 'PassengerGraphql';
  id: Scalars['Int'];
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  sendInvoice: Scalars['Boolean'];
  invoiceCompanyName?: Maybe<Scalars['String']>;
  invoiceTaxNumber?: Maybe<Scalars['String']>;
  invoiceAddress?: Maybe<Scalars['String']>;
  phone: Scalars['String'];
  phoneCountryCode: Scalars['String'];
  age?: Maybe<AdditionalPassengerAgeEnum>;
};

export enum AdditionalPassengerAgeEnum {
  Child = 'CHILD',
  Adult = 'ADULT',
  Pensioner = 'PENSIONER'
}

export type LoginRequestOutput = {
  __typename?: 'LoginRequestOutput';
  /** Last resend time */
  lastResend?: Maybe<Scalars['DateTime']>;
  /** Login attempts count */
  codeAttempts: Scalars['Float'];
  /** Is request succeed */
  status: LoginRequestStatusEnum;
};


export enum LoginRequestStatusEnum {
  Success = 'SUCCESS',
  WrongCode = 'WRONG_CODE',
  NoMoreAttempts = 'NO_MORE_ATTEMPTS',
  TooManySms = 'TOO_MANY_SMS',
  NotRequested = 'NOT_REQUESTED',
  WrongEmailOrPassword = 'WRONG_EMAIL_OR_PASSWORD'
}

export type LoginOutput = {
  __typename?: 'LoginOutput';
  /** Last resend time */
  lastResend?: Maybe<Scalars['DateTime']>;
  /** Login attempts count */
  codeAttempts: Scalars['Float'];
  /** Is request succeed */
  status: LoginRequestStatusEnum;
  accessToken?: Maybe<Scalars['String']>;
  passenger?: Maybe<PassengerGraphql>;
};

export type CarrierCompanyOptionsGraphql = {
  __typename?: 'CarrierCompanyOptionsGraphql';
  discChild04?: Maybe<Scalars['Int']>;
  discChild46?: Maybe<Scalars['Int']>;
  discChild618?: Maybe<Scalars['Int']>;
  discPensioner?: Maybe<Scalars['Int']>;
  bikesFee?: Maybe<Scalars['Int']>;
  animalsFee?: Maybe<Scalars['Int']>;
  baggageFee?: Maybe<Scalars['Int']>;
  allowedPaymentMethods?: Maybe<Array<CarrierCompanyPaymentMethods>>;
  rentOffersSearchRadiusInKm?: Maybe<Scalars['Int']>;
  generalMargin?: Maybe<Scalars['Int']>;
};

export enum CarrierCompanyPaymentMethods {
  Card = 'CARD',
  Cash = 'CASH'
}

export type CarrierAccountGraphql = {
  __typename?: 'CarrierAccountGraphql';
  phone?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
};

export type CarrierCompanyGraphql = {
  __typename?: 'CarrierCompanyGraphql';
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  modifiedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  webPage?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  departureAddress?: Maybe<Scalars['String']>;
  addressPlaceId?: Maybe<Scalars['String']>;
  departureAddressPlaceId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  logotypeUrl?: Maybe<Scalars['String']>;
  users?: Maybe<Array<UserGraphql>>;
  account?: Maybe<CarrierAccountGraphql>;
  options?: Maybe<CarrierCompanyOptionsGraphql>;
  avgReview?: Maybe<Scalars['Float']>;
};

export type UserGraphql = {
  __typename?: 'UserGraphql';
  id?: Maybe<Scalars['Int']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  company?: Maybe<CarrierCompanyGraphql>;
  role?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
};

export type CarrierLoginOutput = {
  __typename?: 'CarrierLoginOutput';
  status?: Maybe<Scalars['String']>;
  accessToken?: Maybe<Scalars['String']>;
  user?: Maybe<UserGraphql>;
  address?: Maybe<Scalars['String']>;
  addressPlaceId?: Maybe<Scalars['String']>;
};

export type BusOptionsGraphql = {
  __typename?: 'BusOptionsGraphql';
  bikesNumber?: Maybe<Scalars['Int']>;
  animalsNumber?: Maybe<Scalars['Int']>;
  extraBaggage?: Maybe<Scalars['Boolean']>;
};

export type BusGraphql = {
  __typename?: 'BusGraphql';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  seats?: Maybe<Scalars['Int']>;
  options?: Maybe<BusOptionsGraphql>;
  /** List of drivers assigned to the bus */
  drivers: Array<UserGraphql>;
  /** number of rides assigned to bus */
  ridesNumber: Scalars['Int'];
};

export type PathwayGraphql = {
  __typename?: 'PathwayGraphql';
  id: Scalars['Int'];
  name: Scalars['String'];
  toAirport: Scalars['Boolean'];
  polyline: Scalars['String'];
  price: Scalars['Int'];
  additionalInformation?: Maybe<Scalars['String']>;
  departures: Array<DepartureGraphql>;
  company: CarrierCompanyGraphql;
  cancelBeforeTicket: Scalars['Int'];
  cancelBeforeHour: Scalars['Int'];
  bus: BusGraphql;
  blockPaymentBeforeHour?: Maybe<Scalars['Int']>;
  pickupNoteEnabled: Scalars['Boolean'];
  endsAt?: Maybe<Scalars['String']>;
};

export type DepartureGraphql = {
  __typename?: 'DepartureGraphql';
  id: Scalars['Int'];
  departureTime: Scalars['Int'];
  number: Scalars['Int'];
  price?: Maybe<Scalars['Int']>;
  busStop: BusStopGraphql;
  pathway: PathwayGraphql;
  company: CarrierCompanyGraphql;
  dayOfTheWeek?: Maybe<Scalars['Int']>;
};

export type BusStopGraphql = {
  __typename?: 'BusStopGraphql';
  id: Scalars['Int'];
  name: Scalars['String'];
  city: Scalars['String'];
  address: Scalars['String'];
  longitude: Scalars['Float'];
  latitude: Scalars['Float'];
  isAirport: Scalars['Boolean'];
  departures: Array<DepartureGraphql>;
};

export type AdditionalPassengerGraphql = {
  __typename?: 'AdditionalPassengerGraphql';
  id: Scalars['Float'];
  age: AdditionalPassengerAgeEnum;
  ageRange?: Maybe<AdditionalPassengerAgeRangeEnum>;
  name: Scalars['String'];
  lastName: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  phoneCountryCode?: Maybe<Scalars['String']>;
  isMain: Scalars['Boolean'];
};

export enum AdditionalPassengerAgeRangeEnum {
  Age_0_4 = 'AGE_0_4',
  Age_4_6 = 'AGE_4_6',
  Age_6_18 = 'AGE_6_18'
}

export type PathwaysListElementGraphql = {
  __typename?: 'PathwaysListElementGraphql';
  pathway?: Maybe<PathwayGraphql>;
  rideDate?: Maybe<Scalars['DateTime']>;
  allDepartures?: Maybe<Array<DepartureGraphql>>;
  start?: Maybe<DepartureGraphql>;
  end?: Maybe<DepartureGraphql>;
  availableSeats: Scalars['Float'];
  saleEnabled: Scalars['Boolean'];
  seatsLimit: Scalars['Int'];
  price: Scalars['Int'];
};

export type DiscountGraphql = {
  __typename?: 'DiscountGraphql';
  id?: Maybe<Scalars['Int']>;
  code?: Maybe<Scalars['String']>;
  allowedTo?: Maybe<Array<UserGraphql>>;
  discountValue?: Maybe<Scalars['Int']>;
  discountType?: Maybe<DiscountType>;
};

export enum DiscountType {
  Percent = 'PERCENT',
  Value = 'VALUE'
}

export type TicketGraphql = {
  __typename?: 'TicketGraphql';
  id: Scalars['Int'];
  createdAt?: Maybe<Scalars['DateTime']>;
  modifiedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  passenger?: Maybe<PassengerGraphql>;
  additionalPassengers: Array<AdditionalPassengerGraphql>;
  status: Scalars['String'];
  term: Scalars['DateTime'];
  bikesCount: Scalars['Float'];
  animalsCount: Scalars['Float'];
  sendInvoice: Scalars['Boolean'];
  invoiceCompanyName: Scalars['String'];
  invoiceTaxNumber: Scalars['String'];
  comments?: Maybe<Scalars['String']>;
  invoiceAddress: Scalars['String'];
  endDeparture?: Maybe<DepartureGraphql>;
  departureData: PathwaysListElementGraphql;
  price: Scalars['Float'];
  passengersPrice: Scalars['Float'];
  bikesPrice: Scalars['Float'];
  animalsPrice: Scalars['Float'];
  baggagePrice: Scalars['Float'];
  discount?: Maybe<DiscountGraphql>;
  baggageCount: Scalars['Float'];
  token?: Maybe<Scalars['String']>;
  qrToken?: Maybe<Scalars['String']>;
  review?: Maybe<Scalars['Int']>;
  pickupNote?: Maybe<Scalars['String']>;
};

export type TicketsPaginatorGraphql = {
  __typename?: 'TicketsPaginatorGraphql';
  records?: Maybe<Array<TicketGraphql>>;
  count: Scalars['Int'];
  page: Scalars['Int'];
  perPage: Scalars['Int'];
};

export type PathwaysListPaginatorGraphql = {
  __typename?: 'PathwaysListPaginatorGraphql';
  records?: Maybe<Array<PathwaysListElementGraphql>>;
  count: Scalars['Int'];
  page: Scalars['Int'];
  perPage: Scalars['Int'];
};

export type BaggageSumOutput = {
  __typename?: 'BaggageSumOutput';
  /** The number of bikes places reserved by passengers */
  bikesCount?: Maybe<Scalars['Float']>;
  /** The number of animals places reserved by passengers */
  animalsCount?: Maybe<Scalars['Float']>;
};

export type SearchQueryGraphql = {
  __typename?: 'SearchQueryGraphql';
  id: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  longitude: Scalars['Float'];
  latitude: Scalars['Float'];
  address: Scalars['String'];
  airport: BusStopGraphql;
  passenger: PassengerGraphql;
  toAirport: Scalars['Boolean'];
};

export type SeatsLimitGraphql = {
  __typename?: 'SeatsLimitGraphql';
  id: Scalars['Int'];
  pathway: PathwayGraphql;
  date: Scalars['DateTime'];
  limit: Scalars['Int'];
};

export type CarrierUserOutput = {
  __typename?: 'CarrierUserOutput';
  user?: Maybe<UserGraphql>;
  address?: Maybe<Scalars['String']>;
  addressPlaceId?: Maybe<Scalars['String']>;
};

export type PaymentGraphql = {
  __typename?: 'PaymentGraphql';
  ticket: TicketGraphql;
  token: Scalars['String'];
};

export type PurchaseOutput = {
  __typename?: 'PurchaseOutput';
  paymentUrl: Scalars['String'];
  payment: PaymentGraphql;
};

export type RentOfferGraphql = {
  __typename?: 'RentOfferGraphql';
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  modifiedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<RentOfferStatus>;
  price?: Maybe<Scalars['Int']>;
  carrier?: Maybe<CarrierCompanyGraphql>;
  canBePaidTo?: Maybe<Scalars['DateTime']>;
  invoiceCompanyName?: Maybe<Scalars['String']>;
  invoiceTaxNumber?: Maybe<Scalars['Float']>;
  invoiceAddress?: Maybe<Scalars['String']>;
};

export enum RentOfferStatus {
  NewOffer = 'NEW_OFFER',
  WaitingForPayment = 'WAITING_FOR_PAYMENT',
  Confirmed = 'CONFIRMED',
  Rejected = 'REJECTED',
  Canceled = 'CANCELED'
}

export type RentOfferWithPaymentMetadata = {
  __typename?: 'RentOfferWithPaymentMetadata';
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  modifiedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<RentOfferStatus>;
  price?: Maybe<Scalars['Int']>;
  carrier?: Maybe<CarrierCompanyGraphql>;
  canBePaidTo?: Maybe<Scalars['DateTime']>;
  invoiceCompanyName?: Maybe<Scalars['String']>;
  invoiceTaxNumber?: Maybe<Scalars['Float']>;
  invoiceAddress?: Maybe<Scalars['String']>;
  totalAmountPaid?: Maybe<Scalars['Int']>;
  secondTimePaymentMethod?: Maybe<CarrierCompanyPaymentMethods>;
};

export type RentPaymentGraphql = {
  __typename?: 'RentPaymentGraphql';
  offer?: Maybe<RentOfferGraphql>;
  status: PaymentStatusEnum;
  token?: Maybe<Scalars['String']>;
};

export enum PaymentStatusEnum {
  Created = 'CREATED',
  Completed = 'COMPLETED',
  Failed = 'FAILED'
}

export type RentPurchaseOutput = {
  __typename?: 'RentPurchaseOutput';
  paymentUrl: Scalars['String'];
  payment?: Maybe<RentPaymentGraphql>;
};

export type LocationPointGraphql = {
  __typename?: 'LocationPointGraphql';
  name?: Maybe<Scalars['String']>;
  googlePlaceId?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
};

export type BusRentGraphql = {
  __typename?: 'BusRentGraphql';
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  modifiedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  departure?: Maybe<LocationPointGraphql>;
  destination?: Maybe<LocationPointGraphql>;
  departureDate?: Maybe<Scalars['DateTime']>;
  returnDate?: Maybe<Scalars['DateTime']>;
  numberOfPassengers?: Maybe<Scalars['Float']>;
  baggageSize?: Maybe<BaggageSize>;
  transportType?: Maybe<TransportType>;
  note?: Maybe<Scalars['String']>;
  contactFirstName?: Maybe<Scalars['String']>;
  contactLastName?: Maybe<Scalars['String']>;
  contactPhoneNumber?: Maybe<Scalars['String']>;
  contactEmail?: Maybe<Scalars['String']>;
  offers?: Maybe<Array<RentOfferGraphql>>;
  confirmedOffer?: Maybe<RentOfferGraphql>;
  totalOffers?: Maybe<Scalars['Int']>;
  status?: Maybe<BusRentStatus>;
  adminNote?: Maybe<Scalars['String']>;
};

export enum BaggageSize {
  Small = 'SMALL',
  Large = 'LARGE',
  Custom = 'CUSTOM'
}

export enum TransportType {
  Airport = 'AIRPORT',
  Tourist = 'TOURIST',
  SportEvent = 'SPORT_EVENT',
  Business = 'BUSINESS',
  School = 'SCHOOL',
  Wedding = 'WEDDING',
  StageParty = 'STAGE_PARTY'
}

export enum BusRentStatus {
  WaitingForOffers = 'WAITING_FOR_OFFERS',
  HasOffer = 'HAS_OFFER',
  WaitingForPayment = 'WAITING_FOR_PAYMENT',
  Outdated = 'OUTDATED',
  Confirmed = 'CONFIRMED'
}

export type BusRentGraphqlForCarrier = {
  __typename?: 'BusRentGraphqlForCarrier';
  id?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  modifiedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  departure?: Maybe<LocationPointGraphql>;
  destination?: Maybe<LocationPointGraphql>;
  departureDate?: Maybe<Scalars['DateTime']>;
  returnDate?: Maybe<Scalars['DateTime']>;
  numberOfPassengers?: Maybe<Scalars['Float']>;
  baggageSize?: Maybe<BaggageSize>;
  transportType?: Maybe<TransportType>;
  note?: Maybe<Scalars['String']>;
  contactFirstName?: Maybe<Scalars['String']>;
  contactLastName?: Maybe<Scalars['String']>;
  contactPhoneNumber?: Maybe<Scalars['String']>;
  contactEmail?: Maybe<Scalars['String']>;
  status?: Maybe<BusRentStatus>;
  offer?: Maybe<RentOfferWithPaymentMetadata>;
  totalOffers?: Maybe<Scalars['Int']>;
  adminNote?: Maybe<Scalars['String']>;
  changesRequested?: Maybe<Scalars['Boolean']>;
};

export type CategorizedBusRentsGraphql = {
  __typename?: 'CategorizedBusRentsGraphql';
  history?: Maybe<Array<BusRentGraphql>>;
  new?: Maybe<Array<BusRentGraphql>>;
  confirmed?: Maybe<Array<BusRentGraphql>>;
  all?: Maybe<Array<BusRentGraphql>>;
};

export type CategorizedBusRentsForCarrierGraphql = {
  __typename?: 'CategorizedBusRentsForCarrierGraphql';
  history?: Maybe<Array<BusRentGraphqlForCarrier>>;
  new?: Maybe<Array<BusRentGraphqlForCarrier>>;
  confirmed?: Maybe<Array<BusRentGraphqlForCarrier>>;
  all?: Maybe<Array<BusRentGraphqlForCarrier>>;
  offered?: Maybe<Array<BusRentGraphqlForCarrier>>;
};

export type Query = {
  __typename?: 'Query';
  /** Get current passenger profile */
  getProfile: PassengerGraphql;
  /** Get all airports from db */
  getAirports: Array<BusStopGraphql>;
  getBusStops: Array<BusStopGraphql>;
  /** Get single ticket */
  getTicket: TicketGraphql;
  /** Get single ticket by token */
  getTicketByToken: TicketGraphql;
  /** Get tickets that can be reviewed */
  getTicketsToReview: Array<TicketGraphql>;
  /**
   * Get my tickets
   * Available filters:
   * - a.status - ticket status [CREATED|PURCHASED|PENDING|FAILED]
   * - a.arrivalDate - Arrival date
   */
  getMyTickets: TicketsPaginatorGraphql;
  /** Get single ticket by qr token */
  getTicketByQrToken: TicketGraphql;
  /** Get list of tickets by term and pathway id */
  getTicketsByPathway: Array<TicketGraphql>;
  /** Get list of tickets by carrier id */
  getCarrierTickets: Array<TicketGraphql>;
  /**
   * Return nearest pathways.
   * Sort fields: 
   * "departure.time" - for earliest departures, 
   * "pathway.price" - for cheapest tickets, 
   * "travel.time" - for fastest run
   */
  getNearestPathways: PathwaysListPaginatorGraphql;
  /**
   * Return rides list for selected bus
   *  Sort fields: 
   * "departure.time" - for earliest departures, 
   * "pathway.price" - for cheapest tickets, 
   * "travel.time" - for fastest run
   */
  getBusRides: PathwaysListPaginatorGraphql;
  getPathway: PathwayGraphql;
  /** Returns number of bikes places and number of animals places reserved by passengers */
  getBaggageCount: BaggageSumOutput;
  /** Get single ride data by pathway id */
  getRide: PathwaysListElementGraphql;
  /** Get search history for current passenger */
  getPassengerSearchHistory: Array<SearchQueryGraphql>;
  /** Return buses list for current logged carrier user */
  getMyBuses: Array<BusGraphql>;
  /** Returns single bus data by its id. */
  getBus: BusGraphql;
  /** Get seats number by pathway and ride date */
  getSeatsNumber: Scalars['Int'];
  /** Returns basic user data */
  getUserProfile: CarrierUserOutput;
  /** Returns discount by its code */
  getDiscountByCode: DiscountGraphql;
  /** Get existsing payment */
  getPayment: PaymentGraphql;
  /** Get existing rent payment */
  getRentPayment: RentPaymentGraphql;
  getMyRentRequests: CategorizedBusRentsGraphql;
  downloadRentPdf: Scalars['String'];
  getRentById: BusRentGraphql;
  getAllNewOffersCount: Scalars['Int'];
  getNearestRentRequestsForCarrier: CategorizedBusRentsForCarrierGraphql;
  getCountOfConfirmedRentsForCarrier: Scalars['Float'];
};


export type QueryGetTicketArgs = {
  ticketId: Scalars['Int'];
};


export type QueryGetTicketByTokenArgs = {
  ticketToken: Scalars['String'];
};


export type QueryGetMyTicketsArgs = {
  query: ListInput;
};


export type QueryGetTicketByQrTokenArgs = {
  qrToken: Scalars['String'];
};


export type QueryGetTicketsByPathwayArgs = {
  date: Scalars['DateTime'];
  pathwayId: Scalars['Float'];
};


export type QueryGetCarrierTicketsArgs = {
  purchaseStatus?: Maybe<Scalars['String']>;
};


export type QueryGetNearestPathwaysArgs = {
  listInput: PathwaysSearchListInput;
};


export type QueryGetBusRidesArgs = {
  listInput: RidesSearchListInput;
};


export type QueryGetPathwayArgs = {
  id: Scalars['Float'];
};


export type QueryGetBaggageCountArgs = {
  term: Scalars['DateTime'];
  departureId: Scalars['Float'];
};


export type QueryGetRideArgs = {
  date: Scalars['DateTime'];
  pathwayId: Scalars['Float'];
};


export type QueryGetBusArgs = {
  busId: Scalars['Float'];
};


export type QueryGetSeatsNumberArgs = {
  date: Scalars['DateTime'];
  pathwayId: Scalars['Float'];
};


export type QueryGetDiscountByCodeArgs = {
  carrierId: Scalars['Float'];
  code: Scalars['String'];
};


export type QueryGetPaymentArgs = {
  token: Scalars['String'];
};


export type QueryGetRentPaymentArgs = {
  token: Scalars['String'];
};


export type QueryDownloadRentPdfArgs = {
  rentId: Scalars['Float'];
};


export type QueryGetRentByIdArgs = {
  rentId: Scalars['Float'];
};


export type QueryGetNearestRentRequestsForCarrierArgs = {
  searchAreaInput?: Maybe<SearchAreaInput>;
};

export type ListInput = {
  page?: Maybe<Scalars['Int']>;
  records?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  sortDirection?: Maybe<OrderTypes>;
  sortField?: Maybe<Scalars['String']>;
  filters?: Maybe<Array<ListFiltersFieldInput>>;
};

export enum OrderTypes {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type ListFiltersFieldInput = {
  field: Scalars['String'];
  operator: FilterTypes;
  value?: Maybe<Array<Scalars['String']>>;
};

export enum FilterTypes {
  Gt = 'gt',
  Gte = 'gte',
  Lt = 'lt',
  Lte = 'lte',
  In = 'in',
  NotIn = 'notIn',
  Eq = 'eq',
  Is = 'is',
  IsNot = 'isNot'
}

export type PathwaysSearchListInput = {
  page?: Maybe<Scalars['Int']>;
  records?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  sortDirection?: Maybe<OrderTypes>;
  sortField?: Maybe<Scalars['String']>;
  filters?: Maybe<Array<ListFiltersFieldInput>>;
  localization: LocalizationInput;
  airportId: Scalars['Int'];
  dateFrom: Scalars['DateTime'];
  dateTo: Scalars['DateTime'];
  toAirport: Scalars['Boolean'];
  saveHistory?: Maybe<Scalars['Boolean']>;
  /** if true, api will return records from 2 hours before planned departure date */
  loadEarlierDepartures?: Maybe<Scalars['Boolean']>;
};

export type LocalizationInput = {
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  address: Scalars['String'];
};

export type RidesSearchListInput = {
  page?: Maybe<Scalars['Int']>;
  records?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  sortDirection?: Maybe<OrderTypes>;
  sortField?: Maybe<Scalars['String']>;
  filters?: Maybe<Array<ListFiltersFieldInput>>;
  busId: Scalars['Int'];
  dateFrom: Scalars['DateTime'];
  dateTo: Scalars['DateTime'];
};

export type SearchAreaInput = {
  radius: Scalars['Int'];
  lat: Scalars['Float'];
  lng: Scalars['Float'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Send confirmation sms */
  login: LoginRequestOutput;
  /** Resend confirmation sms */
  resend: LoginRequestOutput;
  /** Confirm phone using sms code */
  confirmLogin: LoginOutput;
  /** Update logged passenger profile */
  updateProfile: PassengerGraphql;
  /** Update logged passenger age */
  updateAge: PassengerGraphql;
  /** Delete logged passenger account */
  deleteAccount: Scalars['Boolean'];
  /** Create new ticket */
  createTicket: TicketGraphql;
  /** Apply discount on ticket  */
  applyDiscountOnTicket: TicketGraphql;
  /** Add review base on selected ticket */
  addReview: Scalars['Boolean'];
  /** Mark ticket notification viewed */
  markTicketNotificationViewed: Scalars['Boolean'];
  /** Remove ticket */
  removeTicket: Scalars['Boolean'];
  /** Modify bus settings. */
  modifyRideSettings: SeatsLimitGraphql;
  /** Send reset password mail to given address. */
  resetPassword: Scalars['Boolean'];
  /** Change user password */
  changePassword: UserGraphql;
  /** Login to app by email and password (allowed only for carrier users) */
  loginCarrierUser: CarrierLoginOutput;
  /** Purchase ticket */
  purchaseTicketByToken: PurchaseOutput;
  /** Purchase ticket */
  payForRentOffer: RentPurchaseOutput;
  createBusRentRequest: Scalars['Boolean'];
  createOfferForRent: RentOfferGraphql;
  rejectRent: BusRentGraphql;
  addChangeToBusRent: Scalars['Boolean'];
  /** Accept rent offer */
  acceptRentOffer: RentOfferGraphql;
  /** Cancel rent offer */
  cancelOffer: RentOfferGraphql;
  setInvoiceDataToOffer: Scalars['Boolean'];
};


export type MutationLoginArgs = {
  phone: Scalars['String'];
  phoneCountryCode: Scalars['String'];
};


export type MutationResendArgs = {
  phone: Scalars['String'];
  phoneCountryCode: Scalars['String'];
};


export type MutationConfirmLoginArgs = {
  code: Scalars['String'];
  phoneCountryCode: Scalars['String'];
  phone: Scalars['String'];
};


export type MutationUpdateProfileArgs = {
  profileInput: PassengerProfileInput;
};


export type MutationUpdateAgeArgs = {
  age: AdditionalPassengerAgeEnum;
};


export type MutationCreateTicketArgs = {
  ticket: TicketInput;
};


export type MutationApplyDiscountOnTicketArgs = {
  discountCode?: Maybe<Scalars['String']>;
  ticketToken: Scalars['String'];
};


export type MutationAddReviewArgs = {
  input: TicketReviewInput;
};


export type MutationMarkTicketNotificationViewedArgs = {
  id: Scalars['Int'];
};


export type MutationRemoveTicketArgs = {
  ticketId: Scalars['Int'];
};


export type MutationModifyRideSettingsArgs = {
  date: Scalars['DateTime'];
  pathwayId: Scalars['Float'];
  seats: Scalars['Float'];
};


export type MutationResetPasswordArgs = {
  email: Scalars['String'];
};


export type MutationChangePasswordArgs = {
  oldPassword: Scalars['String'];
  newPassword: Scalars['String'];
};


export type MutationLoginCarrierUserArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationPurchaseTicketByTokenArgs = {
  ticketToken: Scalars['String'];
};


export type MutationPayForRentOfferArgs = {
  acceptRentOfferInput: AcceptRentOfferInput;
};


export type MutationCreateBusRentRequestArgs = {
  input: BusRentRequestInput;
};


export type MutationCreateOfferForRentArgs = {
  Offer: CreateRentOfferInput;
};


export type MutationRejectRentArgs = {
  OfferRejection: RejectRentOfferInput;
};


export type MutationAddChangeToBusRentArgs = {
  input: BusRentChangeInput;
};


export type MutationAcceptRentOfferArgs = {
  offerId: Scalars['Float'];
};


export type MutationCancelOfferArgs = {
  offerId: Scalars['Float'];
};


export type MutationSetInvoiceDataToOfferArgs = {
  input: UpdateOfferInvoiceDataInput;
  offerId: Scalars['Float'];
};

export type PassengerProfileInput = {
  /** Email address */
  email?: Maybe<Scalars['String']>;
  /** User first name */
  name?: Maybe<Scalars['String']>;
  /** User last name */
  lastName?: Maybe<Scalars['String']>;
  /** Should use invoice data */
  sendInvoice: Scalars['Boolean'];
  /** Company name for invoice */
  invoiceCompanyName?: Maybe<Scalars['String']>;
  /** Tax number for invoice */
  invoiceTaxNumber?: Maybe<Scalars['String']>;
  /** Address for invoice */
  invoiceAddress?: Maybe<Scalars['String']>;
};

export type TicketInput = {
  bikesCount: Scalars['Int'];
  animalsCount: Scalars['Int'];
  additionalPassengers: Array<AdditionalPassengerInput>;
  sendInvoice: Scalars['Boolean'];
  invoiceCompanyName: Scalars['String'];
  invoiceTaxNumber: Scalars['String'];
  invoiceAddress: Scalars['String'];
  departureId: Scalars['Int'];
  endDepartureId: Scalars['Int'];
  term: Scalars['DateTime'];
  additionalBaggage: Scalars['Int'];
  pickupNote?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
};

export type AdditionalPassengerInput = {
  age: AdditionalPassengerAgeEnum;
  ageRange?: Maybe<AdditionalPassengerAgeRangeEnum>;
  name?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  phoneCountryCode?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  isMain: Scalars['Boolean'];
};

export type TicketReviewInput = {
  ticketId: Scalars['Int'];
  review: Scalars['Int'];
};

export type AcceptRentOfferInput = {
  offerId: Scalars['Int'];
  secondTimePaymentMethod?: Maybe<CarrierCompanyPaymentMethods>;
};

export type BusRentRequestInput = {
  departure: LocationPointInputGraphql;
  destination: LocationPointInputGraphql;
  departureDate: Scalars['DateTime'];
  returnDate?: Maybe<Scalars['DateTime']>;
  numberOfPassengers: Scalars['Float'];
  baggageSize: BaggageSize;
  transportType: TransportType;
  note: Scalars['String'];
  contactFirstName: Scalars['String'];
  contactLastName: Scalars['String'];
  contactPhoneNumber: Scalars['String'];
  contactEmail: Scalars['String'];
};

export type LocationPointInputGraphql = {
  name: Scalars['String'];
  googlePlaceId: Scalars['String'];
  lat: Scalars['Float'];
  lng: Scalars['Float'];
};

export type CreateRentOfferInput = {
  price: Scalars['Int'];
  rentRequestId: Scalars['Int'];
};

export type RejectRentOfferInput = {
  rentRequestId: Scalars['Int'];
};

export type BusRentChangeInput = {
  note: Scalars['String'];
  busRentId: Scalars['Float'];
};

export type UpdateOfferInvoiceDataInput = {
  invoiceCompanyName: Scalars['String'];
  invoiceTaxNumber: Scalars['String'];
  invoiceAddress: Scalars['String'];
};

export type AcceptRentOfferMutationVariables = Exact<{
  offerId: Scalars['Float'];
}>;


export type AcceptRentOfferMutation = (
  { __typename?: 'Mutation' }
  & { acceptRentOffer: (
    { __typename?: 'RentOfferGraphql' }
    & Pick<RentOfferGraphql, 'id' | 'status'>
  ) }
);

export type AddReviewMutationVariables = Exact<{
  input: TicketReviewInput;
}>;


export type AddReviewMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'addReview'>
);

export type ApplyDiscountOnTicketMutationVariables = Exact<{
  discountCode?: Maybe<Scalars['String']>;
  ticketToken: Scalars['String'];
}>;


export type ApplyDiscountOnTicketMutation = (
  { __typename?: 'Mutation' }
  & { applyDiscountOnTicket: (
    { __typename?: 'TicketGraphql' }
    & Pick<TicketGraphql, 'id' | 'token' | 'status' | 'qrToken' | 'price' | 'passengersPrice' | 'bikesCount' | 'bikesPrice' | 'animalsCount' | 'animalsPrice' | 'baggageCount' | 'baggagePrice'>
    & { departureData: (
      { __typename?: 'PathwaysListElementGraphql' }
      & Pick<PathwaysListElementGraphql, 'rideDate'>
      & { allDepartures?: Maybe<Array<(
        { __typename?: 'DepartureGraphql' }
        & Pick<DepartureGraphql, 'id' | 'departureTime'>
        & { busStop: (
          { __typename?: 'BusStopGraphql' }
          & Pick<BusStopGraphql, 'id' | 'name' | 'city' | 'address' | 'latitude' | 'longitude' | 'isAirport'>
        ) }
      )>>, pathway?: Maybe<(
        { __typename?: 'PathwayGraphql' }
        & Pick<PathwayGraphql, 'id' | 'name' | 'price' | 'polyline' | 'toAirport'>
        & { company: (
          { __typename?: 'CarrierCompanyGraphql' }
          & Pick<CarrierCompanyGraphql, 'id' | 'name' | 'webPage' | 'address' | 'phone' | 'email' | 'logotypeUrl'>
        ) }
      )>, start?: Maybe<(
        { __typename?: 'DepartureGraphql' }
        & Pick<DepartureGraphql, 'id' | 'departureTime' | 'dayOfTheWeek'>
        & { busStop: (
          { __typename?: 'BusStopGraphql' }
          & Pick<BusStopGraphql, 'id' | 'name' | 'address' | 'city'>
        ) }
      )>, end?: Maybe<(
        { __typename?: 'DepartureGraphql' }
        & Pick<DepartureGraphql, 'id' | 'departureTime' | 'dayOfTheWeek'>
        & { busStop: (
          { __typename?: 'BusStopGraphql' }
          & Pick<BusStopGraphql, 'id' | 'name' | 'address' | 'city'>
        ) }
      )> }
    ), additionalPassengers: Array<(
      { __typename?: 'AdditionalPassengerGraphql' }
      & Pick<AdditionalPassengerGraphql, 'id' | 'name' | 'email' | 'phone' | 'lastName' | 'age' | 'ageRange' | 'isMain'>
    )> }
  ) }
);

export type ConfirmLoginMutationVariables = Exact<{
  phoneCountryCode: Scalars['String'];
  phone: Scalars['String'];
  code: Scalars['String'];
}>;


export type ConfirmLoginMutation = (
  { __typename?: 'Mutation' }
  & { confirmLogin: (
    { __typename?: 'LoginOutput' }
    & Pick<LoginOutput, 'accessToken' | 'status' | 'codeAttempts'>
    & { passenger?: Maybe<(
      { __typename?: 'PassengerGraphql' }
      & Pick<PassengerGraphql, 'id' | 'email' | 'name' | 'phone' | 'age' | 'phoneCountryCode' | 'lastName' | 'sendInvoice' | 'invoiceCompanyName' | 'invoiceAddress' | 'invoiceTaxNumber'>
    )> }
  ) }
);

export type CreateBusRentRequestMutationVariables = Exact<{
  input: BusRentRequestInput;
}>;


export type CreateBusRentRequestMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createBusRentRequest'>
);

export type CreateTicketMutationVariables = Exact<{
  ticket: TicketInput;
}>;


export type CreateTicketMutation = (
  { __typename?: 'Mutation' }
  & { createTicket: (
    { __typename?: 'TicketGraphql' }
    & Pick<TicketGraphql, 'id' | 'token'>
  ) }
);

export type DeleteAccountMutationVariables = Exact<{ [key: string]: never; }>;


export type DeleteAccountMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteAccount'>
);

export type LoginMutationVariables = Exact<{
  phoneCountryCode: Scalars['String'];
  phone: Scalars['String'];
}>;


export type LoginMutation = (
  { __typename?: 'Mutation' }
  & { login: (
    { __typename?: 'LoginRequestOutput' }
    & Pick<LoginRequestOutput, 'lastResend' | 'codeAttempts' | 'status'>
  ) }
);

export type MarkTicketNotificationViewedMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type MarkTicketNotificationViewedMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'markTicketNotificationViewed'>
);

export type PayForRentOfferMutationVariables = Exact<{
  acceptRentOfferInput: AcceptRentOfferInput;
}>;


export type PayForRentOfferMutation = (
  { __typename?: 'Mutation' }
  & { payForRentOffer: (
    { __typename?: 'RentPurchaseOutput' }
    & Pick<RentPurchaseOutput, 'paymentUrl'>
  ) }
);

export type ProfileUpdateMutationVariables = Exact<{
  profileInput: PassengerProfileInput;
}>;


export type ProfileUpdateMutation = (
  { __typename?: 'Mutation' }
  & { updateProfile: (
    { __typename?: 'PassengerGraphql' }
    & Pick<PassengerGraphql, 'id' | 'email' | 'name' | 'age' | 'lastName' | 'sendInvoice' | 'invoiceCompanyName' | 'invoiceTaxNumber' | 'invoiceAddress' | 'phone' | 'phoneCountryCode'>
  ) }
);

export type PurchaseTicketByTokenMutationVariables = Exact<{
  ticketToken: Scalars['String'];
}>;


export type PurchaseTicketByTokenMutation = (
  { __typename?: 'Mutation' }
  & { purchaseTicketByToken: (
    { __typename?: 'PurchaseOutput' }
    & Pick<PurchaseOutput, 'paymentUrl'>
  ) }
);

export type RemoveTicketMutationVariables = Exact<{
  ticketId: Scalars['Int'];
}>;


export type RemoveTicketMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeTicket'>
);

export type ResendCodeMutationVariables = Exact<{
  phoneCountryCode: Scalars['String'];
  phone: Scalars['String'];
}>;


export type ResendCodeMutation = (
  { __typename?: 'Mutation' }
  & { resend: (
    { __typename?: 'LoginRequestOutput' }
    & Pick<LoginRequestOutput, 'lastResend' | 'codeAttempts' | 'status'>
  ) }
);

export type SetInvoiceDataToOfferMutationVariables = Exact<{
  input: UpdateOfferInvoiceDataInput;
  offerId: Scalars['Float'];
}>;


export type SetInvoiceDataToOfferMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'setInvoiceDataToOffer'>
);

export type UpdateAgeMutationVariables = Exact<{
  age: AdditionalPassengerAgeEnum;
}>;


export type UpdateAgeMutation = (
  { __typename?: 'Mutation' }
  & { updateAge: (
    { __typename?: 'PassengerGraphql' }
    & Pick<PassengerGraphql, 'id' | 'email' | 'name' | 'age' | 'lastName' | 'sendInvoice' | 'invoiceCompanyName' | 'invoiceTaxNumber' | 'invoiceAddress' | 'phone' | 'phoneCountryCode'>
  ) }
);

export type DownloadRentPdfQueryVariables = Exact<{
  rentId: Scalars['Float'];
}>;


export type DownloadRentPdfQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'downloadRentPdf'>
);

export type GetAirportsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAirportsQuery = (
  { __typename?: 'Query' }
  & { getAirports: Array<(
    { __typename?: 'BusStopGraphql' }
    & Pick<BusStopGraphql, 'id' | 'name'>
  )> }
);

export type GetAllNewOffersCountQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllNewOffersCountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getAllNewOffersCount'>
);

export type GetBaggageCountQueryVariables = Exact<{
  term: Scalars['DateTime'];
  departureId: Scalars['Float'];
}>;


export type GetBaggageCountQuery = (
  { __typename?: 'Query' }
  & { getBaggageCount: (
    { __typename?: 'BaggageSumOutput' }
    & Pick<BaggageSumOutput, 'bikesCount' | 'animalsCount'>
  ) }
);

export type GetConfirmedRentRequestsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetConfirmedRentRequestsQuery = (
  { __typename?: 'Query' }
  & { getMyRentRequests: (
    { __typename?: 'CategorizedBusRentsGraphql' }
    & { confirmed?: Maybe<Array<(
      { __typename?: 'BusRentGraphql' }
      & Pick<BusRentGraphql, 'id' | 'createdAt' | 'modifiedAt' | 'adminNote' | 'departureDate' | 'returnDate' | 'numberOfPassengers' | 'baggageSize' | 'transportType' | 'note' | 'contactFirstName' | 'contactLastName' | 'contactPhoneNumber' | 'contactEmail' | 'totalOffers' | 'status'>
      & { departure?: Maybe<(
        { __typename?: 'LocationPointGraphql' }
        & Pick<LocationPointGraphql, 'lat' | 'lng' | 'googlePlaceId' | 'name'>
      )>, destination?: Maybe<(
        { __typename?: 'LocationPointGraphql' }
        & Pick<LocationPointGraphql, 'lat' | 'lng' | 'googlePlaceId' | 'name'>
      )>, offers?: Maybe<Array<(
        { __typename?: 'RentOfferGraphql' }
        & Pick<RentOfferGraphql, 'id' | 'status' | 'price' | 'createdAt' | 'canBePaidTo'>
        & { carrier?: Maybe<(
          { __typename?: 'CarrierCompanyGraphql' }
          & Pick<CarrierCompanyGraphql, 'id' | 'name' | 'webPage' | 'address' | 'email' | 'phone' | 'logotypeUrl' | 'avgReview'>
          & { options?: Maybe<(
            { __typename?: 'CarrierCompanyOptionsGraphql' }
            & Pick<CarrierCompanyOptionsGraphql, 'generalMargin'>
          )> }
        )> }
      )>> }
    )>> }
  ) }
);

export type GetDiscountByCodeQueryVariables = Exact<{
  carrierId: Scalars['Float'];
  code: Scalars['String'];
}>;


export type GetDiscountByCodeQuery = (
  { __typename?: 'Query' }
  & { getDiscountByCode: (
    { __typename?: 'DiscountGraphql' }
    & Pick<DiscountGraphql, 'id' | 'code' | 'discountValue' | 'discountType'>
  ) }
);

export type GetHistoryRentRequestsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetHistoryRentRequestsQuery = (
  { __typename?: 'Query' }
  & { getMyRentRequests: (
    { __typename?: 'CategorizedBusRentsGraphql' }
    & { history?: Maybe<Array<(
      { __typename?: 'BusRentGraphql' }
      & Pick<BusRentGraphql, 'id' | 'createdAt' | 'modifiedAt' | 'adminNote' | 'departureDate' | 'returnDate' | 'numberOfPassengers' | 'baggageSize' | 'transportType' | 'note' | 'contactFirstName' | 'contactLastName' | 'contactPhoneNumber' | 'contactEmail' | 'totalOffers' | 'status'>
      & { departure?: Maybe<(
        { __typename?: 'LocationPointGraphql' }
        & Pick<LocationPointGraphql, 'lat' | 'lng' | 'googlePlaceId' | 'name'>
      )>, destination?: Maybe<(
        { __typename?: 'LocationPointGraphql' }
        & Pick<LocationPointGraphql, 'lat' | 'lng' | 'googlePlaceId' | 'name'>
      )>, offers?: Maybe<Array<(
        { __typename?: 'RentOfferGraphql' }
        & Pick<RentOfferGraphql, 'id' | 'status' | 'price' | 'createdAt' | 'canBePaidTo'>
        & { carrier?: Maybe<(
          { __typename?: 'CarrierCompanyGraphql' }
          & Pick<CarrierCompanyGraphql, 'id' | 'name' | 'webPage' | 'address' | 'email' | 'phone' | 'logotypeUrl' | 'avgReview'>
          & { options?: Maybe<(
            { __typename?: 'CarrierCompanyOptionsGraphql' }
            & Pick<CarrierCompanyOptionsGraphql, 'generalMargin'>
          )> }
        )> }
      )>> }
    )>> }
  ) }
);

export type GetMyRentRequestsCountQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMyRentRequestsCountQuery = (
  { __typename?: 'Query' }
  & { getMyRentRequests: (
    { __typename?: 'CategorizedBusRentsGraphql' }
    & { new?: Maybe<Array<(
      { __typename?: 'BusRentGraphql' }
      & Pick<BusRentGraphql, 'id'>
    )>> }
  ) }
);

export type GetMyRentRequestsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMyRentRequestsQuery = (
  { __typename?: 'Query' }
  & { getMyRentRequests: (
    { __typename?: 'CategorizedBusRentsGraphql' }
    & { new?: Maybe<Array<(
      { __typename?: 'BusRentGraphql' }
      & Pick<BusRentGraphql, 'id' | 'createdAt' | 'modifiedAt' | 'adminNote' | 'departureDate' | 'returnDate' | 'numberOfPassengers' | 'baggageSize' | 'transportType' | 'note' | 'contactFirstName' | 'contactLastName' | 'contactPhoneNumber' | 'contactEmail' | 'totalOffers' | 'status'>
      & { departure?: Maybe<(
        { __typename?: 'LocationPointGraphql' }
        & Pick<LocationPointGraphql, 'lat' | 'lng' | 'googlePlaceId' | 'name'>
      )>, destination?: Maybe<(
        { __typename?: 'LocationPointGraphql' }
        & Pick<LocationPointGraphql, 'lat' | 'lng' | 'googlePlaceId' | 'name'>
      )> }
    )>> }
  ) }
);

export type GetMyTicketsQueryVariables = Exact<{
  query: ListInput;
}>;


export type GetMyTicketsQuery = (
  { __typename?: 'Query' }
  & { getMyTickets: (
    { __typename?: 'TicketsPaginatorGraphql' }
    & { records?: Maybe<Array<(
      { __typename?: 'TicketGraphql' }
      & Pick<TicketGraphql, 'id' | 'status' | 'price' | 'review' | 'comments' | 'token'>
      & { additionalPassengers: Array<(
        { __typename?: 'AdditionalPassengerGraphql' }
        & Pick<AdditionalPassengerGraphql, 'id'>
      )>, departureData: (
        { __typename?: 'PathwaysListElementGraphql' }
        & Pick<PathwaysListElementGraphql, 'rideDate' | 'availableSeats'>
        & { pathway?: Maybe<(
          { __typename?: 'PathwayGraphql' }
          & Pick<PathwayGraphql, 'id' | 'price' | 'polyline' | 'toAirport'>
          & { company: (
            { __typename?: 'CarrierCompanyGraphql' }
            & Pick<CarrierCompanyGraphql, 'id' | 'name' | 'webPage' | 'address' | 'email' | 'phone' | 'avgReview' | 'logotypeUrl'>
          ) }
        )>, start?: Maybe<(
          { __typename?: 'DepartureGraphql' }
          & Pick<DepartureGraphql, 'id' | 'departureTime' | 'dayOfTheWeek'>
          & { busStop: (
            { __typename?: 'BusStopGraphql' }
            & Pick<BusStopGraphql, 'id' | 'name' | 'city' | 'address' | 'latitude' | 'longitude' | 'isAirport'>
          ) }
        )>, end?: Maybe<(
          { __typename?: 'DepartureGraphql' }
          & Pick<DepartureGraphql, 'id' | 'departureTime' | 'dayOfTheWeek'>
          & { busStop: (
            { __typename?: 'BusStopGraphql' }
            & Pick<BusStopGraphql, 'id' | 'name' | 'city' | 'address' | 'latitude' | 'longitude' | 'isAirport'>
          ) }
        )>, allDepartures?: Maybe<Array<(
          { __typename?: 'DepartureGraphql' }
          & Pick<DepartureGraphql, 'id' | 'departureTime' | 'dayOfTheWeek'>
          & { busStop: (
            { __typename?: 'BusStopGraphql' }
            & Pick<BusStopGraphql, 'id' | 'name' | 'city' | 'address' | 'latitude' | 'longitude' | 'isAirport'>
          ) }
        )>> }
      ) }
    )>> }
  ) }
);

export type GetNearestPathwaysQueryVariables = Exact<{
  listInput: PathwaysSearchListInput;
}>;


export type GetNearestPathwaysQuery = (
  { __typename?: 'Query' }
  & { getNearestPathways: (
    { __typename?: 'PathwaysListPaginatorGraphql' }
    & Pick<PathwaysListPaginatorGraphql, 'count' | 'page' | 'perPage'>
    & { records?: Maybe<Array<(
      { __typename?: 'PathwaysListElementGraphql' }
      & Pick<PathwaysListElementGraphql, 'rideDate' | 'availableSeats' | 'price' | 'saleEnabled'>
      & { pathway?: Maybe<(
        { __typename?: 'PathwayGraphql' }
        & Pick<PathwayGraphql, 'id' | 'price' | 'polyline' | 'toAirport' | 'pickupNoteEnabled'>
        & { bus: (
          { __typename?: 'BusGraphql' }
          & Pick<BusGraphql, 'id'>
          & { options?: Maybe<(
            { __typename?: 'BusOptionsGraphql' }
            & Pick<BusOptionsGraphql, 'bikesNumber' | 'animalsNumber' | 'extraBaggage'>
          )> }
        ), company: (
          { __typename?: 'CarrierCompanyGraphql' }
          & Pick<CarrierCompanyGraphql, 'id' | 'name' | 'webPage' | 'address' | 'phone' | 'email' | 'avgReview' | 'logotypeUrl'>
          & { options?: Maybe<(
            { __typename?: 'CarrierCompanyOptionsGraphql' }
            & Pick<CarrierCompanyOptionsGraphql, 'discChild04' | 'discChild46' | 'discChild618' | 'discPensioner' | 'bikesFee' | 'animalsFee' | 'baggageFee'>
          )> }
        ) }
      )>, start?: Maybe<(
        { __typename?: 'DepartureGraphql' }
        & Pick<DepartureGraphql, 'id' | 'departureTime' | 'dayOfTheWeek'>
        & { busStop: (
          { __typename?: 'BusStopGraphql' }
          & Pick<BusStopGraphql, 'id' | 'name' | 'city' | 'address' | 'latitude' | 'longitude' | 'isAirport'>
        ) }
      )>, end?: Maybe<(
        { __typename?: 'DepartureGraphql' }
        & Pick<DepartureGraphql, 'id' | 'departureTime' | 'dayOfTheWeek'>
        & { busStop: (
          { __typename?: 'BusStopGraphql' }
          & Pick<BusStopGraphql, 'id' | 'name' | 'city' | 'address' | 'latitude' | 'longitude' | 'isAirport'>
        ) }
      )>, allDepartures?: Maybe<Array<(
        { __typename?: 'DepartureGraphql' }
        & Pick<DepartureGraphql, 'id' | 'departureTime' | 'dayOfTheWeek'>
        & { busStop: (
          { __typename?: 'BusStopGraphql' }
          & Pick<BusStopGraphql, 'id' | 'name' | 'city' | 'address' | 'latitude' | 'longitude' | 'isAirport'>
        ) }
      )>> }
    )>> }
  ) }
);

export type GetPassengerSearchHistoryQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPassengerSearchHistoryQuery = (
  { __typename?: 'Query' }
  & { getPassengerSearchHistory: Array<(
    { __typename?: 'SearchQueryGraphql' }
    & Pick<SearchQueryGraphql, 'createdAt' | 'toAirport' | 'address' | 'latitude' | 'longitude'>
    & { airport: (
      { __typename?: 'BusStopGraphql' }
      & Pick<BusStopGraphql, 'id' | 'name' | 'city'>
    ) }
  )> }
);

export type GetPaymentQueryVariables = Exact<{
  token: Scalars['String'];
}>;


export type GetPaymentQuery = (
  { __typename?: 'Query' }
  & { getPayment: (
    { __typename?: 'PaymentGraphql' }
    & { ticket: (
      { __typename?: 'TicketGraphql' }
      & Pick<TicketGraphql, 'id' | 'token' | 'status'>
      & { departureData: (
        { __typename?: 'PathwaysListElementGraphql' }
        & Pick<PathwaysListElementGraphql, 'rideDate'>
        & { start?: Maybe<(
          { __typename?: 'DepartureGraphql' }
          & Pick<DepartureGraphql, 'id'>
          & { busStop: (
            { __typename?: 'BusStopGraphql' }
            & Pick<BusStopGraphql, 'id' | 'name' | 'address' | 'latitude' | 'longitude'>
          ) }
        )>, end?: Maybe<(
          { __typename?: 'DepartureGraphql' }
          & Pick<DepartureGraphql, 'id'>
          & { busStop: (
            { __typename?: 'BusStopGraphql' }
            & Pick<BusStopGraphql, 'id' | 'name' | 'address' | 'latitude' | 'longitude'>
          ) }
        )>, pathway?: Maybe<(
          { __typename?: 'PathwayGraphql' }
          & Pick<PathwayGraphql, 'id' | 'toAirport'>
          & { company: (
            { __typename?: 'CarrierCompanyGraphql' }
            & Pick<CarrierCompanyGraphql, 'id' | 'name' | 'avgReview'>
          ) }
        )> }
      ) }
    ) }
  ) }
);

export type GetProfileDataQueryVariables = Exact<{ [key: string]: never; }>;


export type GetProfileDataQuery = (
  { __typename?: 'Query' }
  & { getProfile: (
    { __typename?: 'PassengerGraphql' }
    & Pick<PassengerGraphql, 'id' | 'email' | 'name' | 'phone' | 'phoneCountryCode' | 'lastName' | 'sendInvoice' | 'invoiceCompanyName' | 'invoiceAddress' | 'invoiceTaxNumber'>
  ) }
);

export type GetRentByIdQueryVariables = Exact<{
  rentId: Scalars['Float'];
}>;


export type GetRentByIdQuery = (
  { __typename?: 'Query' }
  & { getRentById: (
    { __typename?: 'BusRentGraphql' }
    & Pick<BusRentGraphql, 'id' | 'createdAt' | 'modifiedAt' | 'departureDate' | 'returnDate' | 'numberOfPassengers' | 'baggageSize' | 'transportType' | 'note' | 'contactFirstName' | 'contactLastName' | 'contactPhoneNumber' | 'contactEmail' | 'totalOffers' | 'status'>
    & { departure?: Maybe<(
      { __typename?: 'LocationPointGraphql' }
      & Pick<LocationPointGraphql, 'lat' | 'lng' | 'googlePlaceId' | 'name'>
    )>, destination?: Maybe<(
      { __typename?: 'LocationPointGraphql' }
      & Pick<LocationPointGraphql, 'lat' | 'lng' | 'googlePlaceId' | 'name'>
    )>, offers?: Maybe<Array<(
      { __typename?: 'RentOfferGraphql' }
      & Pick<RentOfferGraphql, 'id' | 'status' | 'price' | 'createdAt' | 'canBePaidTo'>
      & { carrier?: Maybe<(
        { __typename?: 'CarrierCompanyGraphql' }
        & Pick<CarrierCompanyGraphql, 'id' | 'name' | 'webPage' | 'address' | 'email' | 'phone' | 'logotypeUrl' | 'avgReview'>
        & { options?: Maybe<(
          { __typename?: 'CarrierCompanyOptionsGraphql' }
          & Pick<CarrierCompanyOptionsGraphql, 'generalMargin' | 'allowedPaymentMethods'>
        )> }
      )> }
    )>>, confirmedOffer?: Maybe<(
      { __typename?: 'RentOfferGraphql' }
      & Pick<RentOfferGraphql, 'id' | 'status' | 'price' | 'createdAt' | 'canBePaidTo'>
      & { carrier?: Maybe<(
        { __typename?: 'CarrierCompanyGraphql' }
        & Pick<CarrierCompanyGraphql, 'id' | 'name' | 'webPage' | 'address' | 'email' | 'phone' | 'logotypeUrl' | 'avgReview'>
        & { options?: Maybe<(
          { __typename?: 'CarrierCompanyOptionsGraphql' }
          & Pick<CarrierCompanyOptionsGraphql, 'generalMargin' | 'allowedPaymentMethods'>
        )> }
      )> }
    )> }
  ) }
);

export type GetRentPaymentQueryVariables = Exact<{
  token: Scalars['String'];
}>;


export type GetRentPaymentQuery = (
  { __typename?: 'Query' }
  & { getRentPayment: (
    { __typename?: 'RentPaymentGraphql' }
    & Pick<RentPaymentGraphql, 'status'>
  ) }
);

export type GetTicketByIdQueryVariables = Exact<{
  ticketId: Scalars['Int'];
}>;


export type GetTicketByIdQuery = (
  { __typename?: 'Query' }
  & { getTicket: (
    { __typename?: 'TicketGraphql' }
    & Pick<TicketGraphql, 'id' | 'token' | 'status' | 'comments' | 'review' | 'qrToken' | 'price' | 'passengersPrice' | 'bikesCount' | 'bikesPrice' | 'animalsCount' | 'animalsPrice' | 'baggageCount' | 'baggagePrice'>
    & { departureData: (
      { __typename?: 'PathwaysListElementGraphql' }
      & Pick<PathwaysListElementGraphql, 'rideDate'>
      & { allDepartures?: Maybe<Array<(
        { __typename?: 'DepartureGraphql' }
        & Pick<DepartureGraphql, 'id' | 'departureTime' | 'dayOfTheWeek'>
        & { busStop: (
          { __typename?: 'BusStopGraphql' }
          & Pick<BusStopGraphql, 'id' | 'name' | 'city' | 'address' | 'latitude' | 'longitude' | 'isAirport'>
        ) }
      )>>, pathway?: Maybe<(
        { __typename?: 'PathwayGraphql' }
        & Pick<PathwayGraphql, 'id' | 'name' | 'price' | 'polyline' | 'toAirport'>
        & { company: (
          { __typename?: 'CarrierCompanyGraphql' }
          & Pick<CarrierCompanyGraphql, 'id' | 'name' | 'webPage' | 'address' | 'email' | 'phone' | 'logotypeUrl' | 'avgReview'>
        ) }
      )>, start?: Maybe<(
        { __typename?: 'DepartureGraphql' }
        & Pick<DepartureGraphql, 'id' | 'departureTime' | 'dayOfTheWeek'>
        & { busStop: (
          { __typename?: 'BusStopGraphql' }
          & Pick<BusStopGraphql, 'id' | 'name' | 'address' | 'city'>
        ) }
      )>, end?: Maybe<(
        { __typename?: 'DepartureGraphql' }
        & Pick<DepartureGraphql, 'id' | 'departureTime' | 'dayOfTheWeek'>
        & { busStop: (
          { __typename?: 'BusStopGraphql' }
          & Pick<BusStopGraphql, 'id' | 'name' | 'address' | 'city'>
        ) }
      )> }
    ), additionalPassengers: Array<(
      { __typename?: 'AdditionalPassengerGraphql' }
      & Pick<AdditionalPassengerGraphql, 'id' | 'name' | 'email' | 'phone' | 'lastName' | 'age' | 'isMain'>
    )> }
  ) }
);

export type GetTicketByTokenQueryVariables = Exact<{
  ticketToken: Scalars['String'];
}>;


export type GetTicketByTokenQuery = (
  { __typename?: 'Query' }
  & { getTicketByToken: (
    { __typename?: 'TicketGraphql' }
    & Pick<TicketGraphql, 'id' | 'token' | 'status' | 'review' | 'comments' | 'qrToken' | 'price' | 'passengersPrice' | 'bikesCount' | 'bikesPrice' | 'animalsCount' | 'animalsPrice' | 'baggageCount' | 'baggagePrice'>
    & { discount?: Maybe<(
      { __typename?: 'DiscountGraphql' }
      & Pick<DiscountGraphql, 'id' | 'code' | 'discountValue' | 'discountType'>
    )>, departureData: (
      { __typename?: 'PathwaysListElementGraphql' }
      & Pick<PathwaysListElementGraphql, 'rideDate'>
      & { allDepartures?: Maybe<Array<(
        { __typename?: 'DepartureGraphql' }
        & Pick<DepartureGraphql, 'id' | 'departureTime' | 'dayOfTheWeek'>
        & { busStop: (
          { __typename?: 'BusStopGraphql' }
          & Pick<BusStopGraphql, 'id' | 'name' | 'city' | 'address' | 'latitude' | 'longitude' | 'isAirport'>
        ) }
      )>>, pathway?: Maybe<(
        { __typename?: 'PathwayGraphql' }
        & Pick<PathwayGraphql, 'id' | 'name' | 'price' | 'polyline' | 'toAirport' | 'additionalInformation'>
        & { company: (
          { __typename?: 'CarrierCompanyGraphql' }
          & Pick<CarrierCompanyGraphql, 'id' | 'name' | 'webPage' | 'address' | 'phone' | 'email' | 'logotypeUrl' | 'avgReview'>
        ) }
      )>, start?: Maybe<(
        { __typename?: 'DepartureGraphql' }
        & Pick<DepartureGraphql, 'id' | 'departureTime' | 'dayOfTheWeek'>
        & { busStop: (
          { __typename?: 'BusStopGraphql' }
          & Pick<BusStopGraphql, 'id' | 'name' | 'address' | 'city'>
        ) }
      )>, end?: Maybe<(
        { __typename?: 'DepartureGraphql' }
        & Pick<DepartureGraphql, 'id' | 'departureTime' | 'dayOfTheWeek'>
        & { busStop: (
          { __typename?: 'BusStopGraphql' }
          & Pick<BusStopGraphql, 'id' | 'name' | 'address' | 'city'>
        ) }
      )> }
    ), additionalPassengers: Array<(
      { __typename?: 'AdditionalPassengerGraphql' }
      & Pick<AdditionalPassengerGraphql, 'id' | 'name' | 'email' | 'phone' | 'lastName' | 'age' | 'ageRange' | 'isMain' | 'phoneCountryCode'>
    )> }
  ) }
);

export type GetTicketsToReviewQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTicketsToReviewQuery = (
  { __typename?: 'Query' }
  & { getTicketsToReview: Array<(
    { __typename?: 'TicketGraphql' }
    & Pick<TicketGraphql, 'id' | 'status' | 'price' | 'review' | 'token' | 'comments'>
    & { additionalPassengers: Array<(
      { __typename?: 'AdditionalPassengerGraphql' }
      & Pick<AdditionalPassengerGraphql, 'id'>
    )>, departureData: (
      { __typename?: 'PathwaysListElementGraphql' }
      & Pick<PathwaysListElementGraphql, 'rideDate' | 'availableSeats'>
      & { pathway?: Maybe<(
        { __typename?: 'PathwayGraphql' }
        & Pick<PathwayGraphql, 'id' | 'price' | 'polyline' | 'toAirport'>
        & { company: (
          { __typename?: 'CarrierCompanyGraphql' }
          & Pick<CarrierCompanyGraphql, 'id' | 'name' | 'webPage' | 'address' | 'email' | 'phone' | 'avgReview' | 'logotypeUrl'>
        ) }
      )>, start?: Maybe<(
        { __typename?: 'DepartureGraphql' }
        & Pick<DepartureGraphql, 'id' | 'departureTime' | 'dayOfTheWeek'>
        & { busStop: (
          { __typename?: 'BusStopGraphql' }
          & Pick<BusStopGraphql, 'id' | 'name' | 'city' | 'address' | 'latitude' | 'longitude' | 'isAirport'>
        ) }
      )>, end?: Maybe<(
        { __typename?: 'DepartureGraphql' }
        & Pick<DepartureGraphql, 'id' | 'departureTime' | 'dayOfTheWeek'>
        & { busStop: (
          { __typename?: 'BusStopGraphql' }
          & Pick<BusStopGraphql, 'id' | 'name' | 'city' | 'address' | 'latitude' | 'longitude' | 'isAirport'>
        ) }
      )>, allDepartures?: Maybe<Array<(
        { __typename?: 'DepartureGraphql' }
        & Pick<DepartureGraphql, 'id' | 'departureTime' | 'dayOfTheWeek'>
        & { busStop: (
          { __typename?: 'BusStopGraphql' }
          & Pick<BusStopGraphql, 'id' | 'name' | 'city' | 'address' | 'latitude' | 'longitude' | 'isAirport'>
        ) }
      )>> }
    ) }
  )> }
);

export const AcceptRentOfferDocument = gql`
    mutation acceptRentOffer($offerId: Float!) {
  acceptRentOffer(offerId: $offerId) {
    id
    status
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AcceptRentOfferGQL extends Apollo.Mutation<AcceptRentOfferMutation, AcceptRentOfferMutationVariables> {
    document = AcceptRentOfferDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AddReviewDocument = gql`
    mutation addReview($input: TicketReviewInput!) {
  addReview(input: $input)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AddReviewGQL extends Apollo.Mutation<AddReviewMutation, AddReviewMutationVariables> {
    document = AddReviewDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ApplyDiscountOnTicketDocument = gql`
    mutation applyDiscountOnTicket($discountCode: String, $ticketToken: String!) {
  applyDiscountOnTicket(discountCode: $discountCode, ticketToken: $ticketToken) {
    id
    token
    status
    departureData {
      rideDate
      allDepartures {
        id
        departureTime
        busStop {
          id
          name
          city
          address
          latitude
          longitude
          isAirport
        }
      }
      pathway {
        id
        name
        price
        polyline
        toAirport
        company {
          id
          name
          webPage
          address
          phone
          email
          logotypeUrl
        }
      }
      start {
        id
        departureTime
        dayOfTheWeek
        busStop {
          id
          name
          address
          city
        }
      }
      end {
        id
        departureTime
        dayOfTheWeek
        busStop {
          id
          name
          address
          city
        }
      }
    }
    additionalPassengers {
      id
      name
      email
      phone
      lastName
      age
      ageRange
      isMain
    }
    qrToken
    price
    passengersPrice
    bikesCount
    bikesPrice
    animalsCount
    animalsPrice
    baggageCount
    baggagePrice
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ApplyDiscountOnTicketGQL extends Apollo.Mutation<ApplyDiscountOnTicketMutation, ApplyDiscountOnTicketMutationVariables> {
    document = ApplyDiscountOnTicketDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ConfirmLoginDocument = gql`
    mutation confirmLogin($phoneCountryCode: String!, $phone: String!, $code: String!) {
  confirmLogin(phoneCountryCode: $phoneCountryCode, phone: $phone, code: $code) {
    accessToken
    status
    codeAttempts
    passenger {
      id
      email
      name
      phone
      age
      phoneCountryCode
      lastName
      sendInvoice
      invoiceCompanyName
      invoiceAddress
      invoiceTaxNumber
      invoiceAddress
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ConfirmLoginGQL extends Apollo.Mutation<ConfirmLoginMutation, ConfirmLoginMutationVariables> {
    document = ConfirmLoginDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateBusRentRequestDocument = gql`
    mutation createBusRentRequest($input: BusRentRequestInput!) {
  createBusRentRequest(input: $input)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateBusRentRequestGQL extends Apollo.Mutation<CreateBusRentRequestMutation, CreateBusRentRequestMutationVariables> {
    document = CreateBusRentRequestDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateTicketDocument = gql`
    mutation createTicket($ticket: TicketInput!) {
  createTicket(ticket: $ticket) {
    id
    token
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateTicketGQL extends Apollo.Mutation<CreateTicketMutation, CreateTicketMutationVariables> {
    document = CreateTicketDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteAccountDocument = gql`
    mutation deleteAccount {
  deleteAccount
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteAccountGQL extends Apollo.Mutation<DeleteAccountMutation, DeleteAccountMutationVariables> {
    document = DeleteAccountDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const LoginDocument = gql`
    mutation login($phoneCountryCode: String!, $phone: String!) {
  login(phoneCountryCode: $phoneCountryCode, phone: $phone) {
    lastResend
    codeAttempts
    status
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class LoginGQL extends Apollo.Mutation<LoginMutation, LoginMutationVariables> {
    document = LoginDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MarkTicketNotificationViewedDocument = gql`
    mutation markTicketNotificationViewed($id: Int!) {
  markTicketNotificationViewed(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MarkTicketNotificationViewedGQL extends Apollo.Mutation<MarkTicketNotificationViewedMutation, MarkTicketNotificationViewedMutationVariables> {
    document = MarkTicketNotificationViewedDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PayForRentOfferDocument = gql`
    mutation payForRentOffer($acceptRentOfferInput: AcceptRentOfferInput!) {
  payForRentOffer(acceptRentOfferInput: $acceptRentOfferInput) {
    paymentUrl
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PayForRentOfferGQL extends Apollo.Mutation<PayForRentOfferMutation, PayForRentOfferMutationVariables> {
    document = PayForRentOfferDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ProfileUpdateDocument = gql`
    mutation profileUpdate($profileInput: PassengerProfileInput!) {
  updateProfile(profileInput: $profileInput) {
    id
    email
    name
    age
    lastName
    sendInvoice
    invoiceCompanyName
    invoiceTaxNumber
    invoiceAddress
    phone
    phoneCountryCode
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ProfileUpdateGQL extends Apollo.Mutation<ProfileUpdateMutation, ProfileUpdateMutationVariables> {
    document = ProfileUpdateDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const PurchaseTicketByTokenDocument = gql`
    mutation purchaseTicketByToken($ticketToken: String!) {
  purchaseTicketByToken(ticketToken: $ticketToken) {
    paymentUrl
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PurchaseTicketByTokenGQL extends Apollo.Mutation<PurchaseTicketByTokenMutation, PurchaseTicketByTokenMutationVariables> {
    document = PurchaseTicketByTokenDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RemoveTicketDocument = gql`
    mutation removeTicket($ticketId: Int!) {
  removeTicket(ticketId: $ticketId)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RemoveTicketGQL extends Apollo.Mutation<RemoveTicketMutation, RemoveTicketMutationVariables> {
    document = RemoveTicketDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ResendCodeDocument = gql`
    mutation resendCode($phoneCountryCode: String!, $phone: String!) {
  resend(phoneCountryCode: $phoneCountryCode, phone: $phone) {
    lastResend
    codeAttempts
    status
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ResendCodeGQL extends Apollo.Mutation<ResendCodeMutation, ResendCodeMutationVariables> {
    document = ResendCodeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SetInvoiceDataToOfferDocument = gql`
    mutation setInvoiceDataToOffer($input: UpdateOfferInvoiceDataInput!, $offerId: Float!) {
  setInvoiceDataToOffer(input: $input, offerId: $offerId)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SetInvoiceDataToOfferGQL extends Apollo.Mutation<SetInvoiceDataToOfferMutation, SetInvoiceDataToOfferMutationVariables> {
    document = SetInvoiceDataToOfferDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateAgeDocument = gql`
    mutation updateAge($age: AdditionalPassengerAgeEnum!) {
  updateAge(age: $age) {
    id
    email
    name
    age
    lastName
    sendInvoice
    invoiceCompanyName
    invoiceTaxNumber
    invoiceAddress
    phone
    phoneCountryCode
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateAgeGQL extends Apollo.Mutation<UpdateAgeMutation, UpdateAgeMutationVariables> {
    document = UpdateAgeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DownloadRentPdfDocument = gql`
    query downloadRentPdf($rentId: Float!) {
  downloadRentPdf(rentId: $rentId)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DownloadRentPdfGQL extends Apollo.Query<DownloadRentPdfQuery, DownloadRentPdfQueryVariables> {
    document = DownloadRentPdfDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetAirportsDocument = gql`
    query getAirports {
  getAirports {
    id
    name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAirportsGQL extends Apollo.Query<GetAirportsQuery, GetAirportsQueryVariables> {
    document = GetAirportsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetAllNewOffersCountDocument = gql`
    query getAllNewOffersCount {
  getAllNewOffersCount
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAllNewOffersCountGQL extends Apollo.Query<GetAllNewOffersCountQuery, GetAllNewOffersCountQueryVariables> {
    document = GetAllNewOffersCountDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetBaggageCountDocument = gql`
    query getBaggageCount($term: DateTime!, $departureId: Float!) {
  getBaggageCount(term: $term, departureId: $departureId) {
    bikesCount
    animalsCount
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetBaggageCountGQL extends Apollo.Query<GetBaggageCountQuery, GetBaggageCountQueryVariables> {
    document = GetBaggageCountDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetConfirmedRentRequestsDocument = gql`
    query getConfirmedRentRequests {
  getMyRentRequests {
    confirmed {
      id
      createdAt
      modifiedAt
      adminNote
      departure {
        lat
        lng
        googlePlaceId
        name
      }
      destination {
        lat
        lng
        googlePlaceId
        name
      }
      departureDate
      returnDate
      numberOfPassengers
      baggageSize
      transportType
      note
      contactFirstName
      contactLastName
      contactPhoneNumber
      contactEmail
      totalOffers
      status
      offers {
        id
        status
        price
        createdAt
        canBePaidTo
        carrier {
          id
          name
          webPage
          address
          email
          phone
          logotypeUrl
          avgReview
          options {
            generalMargin
          }
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetConfirmedRentRequestsGQL extends Apollo.Query<GetConfirmedRentRequestsQuery, GetConfirmedRentRequestsQueryVariables> {
    document = GetConfirmedRentRequestsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetDiscountByCodeDocument = gql`
    query getDiscountByCode($carrierId: Float!, $code: String!) {
  getDiscountByCode(carrierId: $carrierId, code: $code) {
    id
    code
    discountValue
    discountType
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetDiscountByCodeGQL extends Apollo.Query<GetDiscountByCodeQuery, GetDiscountByCodeQueryVariables> {
    document = GetDiscountByCodeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetHistoryRentRequestsDocument = gql`
    query getHistoryRentRequests {
  getMyRentRequests {
    history {
      id
      createdAt
      modifiedAt
      adminNote
      departure {
        lat
        lng
        googlePlaceId
        name
      }
      destination {
        lat
        lng
        googlePlaceId
        name
      }
      departureDate
      returnDate
      numberOfPassengers
      baggageSize
      transportType
      note
      contactFirstName
      contactLastName
      contactPhoneNumber
      contactEmail
      totalOffers
      status
      offers {
        id
        status
        price
        createdAt
        canBePaidTo
        carrier {
          id
          name
          webPage
          address
          email
          phone
          logotypeUrl
          avgReview
          options {
            generalMargin
          }
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetHistoryRentRequestsGQL extends Apollo.Query<GetHistoryRentRequestsQuery, GetHistoryRentRequestsQueryVariables> {
    document = GetHistoryRentRequestsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetMyRentRequestsCountDocument = gql`
    query getMyRentRequestsCount {
  getMyRentRequests {
    new {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetMyRentRequestsCountGQL extends Apollo.Query<GetMyRentRequestsCountQuery, GetMyRentRequestsCountQueryVariables> {
    document = GetMyRentRequestsCountDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetMyRentRequestsDocument = gql`
    query getMyRentRequests {
  getMyRentRequests {
    new {
      id
      createdAt
      modifiedAt
      adminNote
      departure {
        lat
        lng
        googlePlaceId
        name
      }
      destination {
        lat
        lng
        googlePlaceId
        name
      }
      departureDate
      returnDate
      numberOfPassengers
      baggageSize
      transportType
      note
      contactFirstName
      contactLastName
      contactPhoneNumber
      contactEmail
      totalOffers
      status
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetMyRentRequestsGQL extends Apollo.Query<GetMyRentRequestsQuery, GetMyRentRequestsQueryVariables> {
    document = GetMyRentRequestsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetMyTicketsDocument = gql`
    query getMyTickets($query: ListInput!) {
  getMyTickets(query: $query) {
    records {
      id
      status
      price
      review
      comments
      token
      additionalPassengers {
        id
      }
      departureData {
        rideDate
        availableSeats
        pathway {
          id
          price
          polyline
          toAirport
          company {
            id
            name
            webPage
            address
            email
            phone
            avgReview
            logotypeUrl
          }
        }
        start {
          id
          departureTime
          dayOfTheWeek
          busStop {
            id
            name
            city
            address
            latitude
            longitude
            isAirport
          }
        }
        end {
          id
          departureTime
          dayOfTheWeek
          busStop {
            id
            name
            city
            address
            latitude
            longitude
            isAirport
          }
        }
        allDepartures {
          id
          departureTime
          dayOfTheWeek
          busStop {
            id
            name
            city
            address
            latitude
            longitude
            isAirport
          }
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetMyTicketsGQL extends Apollo.Query<GetMyTicketsQuery, GetMyTicketsQueryVariables> {
    document = GetMyTicketsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetNearestPathwaysDocument = gql`
    query getNearestPathways($listInput: PathwaysSearchListInput!) {
  getNearestPathways(listInput: $listInput) {
    records {
      rideDate
      availableSeats
      price
      saleEnabled
      pathway {
        id
        price
        polyline
        toAirport
        pickupNoteEnabled
        bus {
          id
          options {
            bikesNumber
            animalsNumber
            extraBaggage
          }
        }
        company {
          id
          name
          webPage
          address
          phone
          email
          avgReview
          logotypeUrl
          options {
            discChild04
            discChild46
            discChild618
            discPensioner
            bikesFee
            animalsFee
            baggageFee
          }
        }
      }
      start {
        id
        departureTime
        dayOfTheWeek
        busStop {
          id
          name
          city
          address
          latitude
          longitude
          isAirport
        }
      }
      end {
        id
        departureTime
        dayOfTheWeek
        busStop {
          id
          name
          city
          address
          latitude
          longitude
          isAirport
        }
      }
      allDepartures {
        id
        departureTime
        dayOfTheWeek
        busStop {
          id
          name
          city
          address
          latitude
          longitude
          isAirport
        }
      }
    }
    count
    page
    perPage
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetNearestPathwaysGQL extends Apollo.Query<GetNearestPathwaysQuery, GetNearestPathwaysQueryVariables> {
    document = GetNearestPathwaysDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetPassengerSearchHistoryDocument = gql`
    query getPassengerSearchHistory {
  getPassengerSearchHistory {
    createdAt
    toAirport
    address
    latitude
    longitude
    airport {
      id
      name
      city
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetPassengerSearchHistoryGQL extends Apollo.Query<GetPassengerSearchHistoryQuery, GetPassengerSearchHistoryQueryVariables> {
    document = GetPassengerSearchHistoryDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetPaymentDocument = gql`
    query getPayment($token: String!) {
  getPayment(token: $token) {
    ticket {
      id
      token
      status
      departureData {
        rideDate
        start {
          id
          busStop {
            id
            name
            address
            latitude
            longitude
          }
        }
        end {
          id
          busStop {
            id
            name
            address
            latitude
            longitude
          }
        }
        pathway {
          id
          toAirport
          company {
            id
            name
            avgReview
          }
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetPaymentGQL extends Apollo.Query<GetPaymentQuery, GetPaymentQueryVariables> {
    document = GetPaymentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetProfileDataDocument = gql`
    query getProfileData {
  getProfile {
    id
    email
    name
    phone
    phoneCountryCode
    lastName
    sendInvoice
    invoiceCompanyName
    invoiceAddress
    invoiceTaxNumber
    invoiceAddress
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetProfileDataGQL extends Apollo.Query<GetProfileDataQuery, GetProfileDataQueryVariables> {
    document = GetProfileDataDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetRentByIdDocument = gql`
    query getRentById($rentId: Float!) {
  getRentById(rentId: $rentId) {
    id
    createdAt
    modifiedAt
    departure {
      lat
      lng
      googlePlaceId
      name
    }
    destination {
      lat
      lng
      googlePlaceId
      name
    }
    departureDate
    returnDate
    numberOfPassengers
    baggageSize
    transportType
    note
    contactFirstName
    contactLastName
    contactPhoneNumber
    contactEmail
    totalOffers
    status
    offers {
      id
      status
      price
      createdAt
      canBePaidTo
      carrier {
        id
        name
        webPage
        address
        email
        phone
        logotypeUrl
        avgReview
        options {
          generalMargin
          allowedPaymentMethods
        }
      }
    }
    confirmedOffer {
      id
      status
      price
      createdAt
      canBePaidTo
      carrier {
        id
        name
        webPage
        address
        email
        phone
        logotypeUrl
        avgReview
        options {
          generalMargin
          allowedPaymentMethods
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetRentByIdGQL extends Apollo.Query<GetRentByIdQuery, GetRentByIdQueryVariables> {
    document = GetRentByIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetRentPaymentDocument = gql`
    query getRentPayment($token: String!) {
  getRentPayment(token: $token) {
    status
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetRentPaymentGQL extends Apollo.Query<GetRentPaymentQuery, GetRentPaymentQueryVariables> {
    document = GetRentPaymentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetTicketByIdDocument = gql`
    query getTicketById($ticketId: Int!) {
  getTicket(ticketId: $ticketId) {
    id
    token
    status
    comments
    review
    departureData {
      rideDate
      allDepartures {
        id
        departureTime
        dayOfTheWeek
        busStop {
          id
          name
          city
          address
          latitude
          longitude
          isAirport
        }
      }
      pathway {
        id
        name
        price
        polyline
        toAirport
        company {
          id
          name
          webPage
          address
          email
          phone
          logotypeUrl
          avgReview
        }
      }
      start {
        id
        departureTime
        dayOfTheWeek
        busStop {
          id
          name
          address
          city
        }
      }
      end {
        id
        departureTime
        dayOfTheWeek
        busStop {
          id
          name
          address
          city
        }
      }
    }
    additionalPassengers {
      id
      name
      email
      phone
      lastName
      age
      isMain
    }
    qrToken
    price
    passengersPrice
    bikesCount
    bikesPrice
    animalsCount
    animalsPrice
    baggageCount
    baggagePrice
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTicketByIdGQL extends Apollo.Query<GetTicketByIdQuery, GetTicketByIdQueryVariables> {
    document = GetTicketByIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetTicketByTokenDocument = gql`
    query getTicketByToken($ticketToken: String!) {
  getTicketByToken(ticketToken: $ticketToken) {
    id
    token
    status
    review
    comments
    discount {
      id
      code
      discountValue
      discountType
    }
    departureData {
      rideDate
      allDepartures {
        id
        departureTime
        dayOfTheWeek
        busStop {
          id
          name
          city
          address
          latitude
          longitude
          isAirport
        }
      }
      pathway {
        id
        name
        price
        polyline
        toAirport
        company {
          id
          name
          webPage
          address
          phone
          email
          logotypeUrl
          avgReview
        }
        additionalInformation
      }
      start {
        id
        departureTime
        dayOfTheWeek
        busStop {
          id
          name
          address
          city
        }
      }
      end {
        id
        departureTime
        dayOfTheWeek
        busStop {
          id
          name
          address
          city
        }
      }
    }
    additionalPassengers {
      id
      name
      email
      phone
      lastName
      age
      ageRange
      isMain
      phoneCountryCode
    }
    qrToken
    price
    passengersPrice
    bikesCount
    bikesPrice
    animalsCount
    animalsPrice
    baggageCount
    baggagePrice
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTicketByTokenGQL extends Apollo.Query<GetTicketByTokenQuery, GetTicketByTokenQueryVariables> {
    document = GetTicketByTokenDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetTicketsToReviewDocument = gql`
    query getTicketsToReview {
  getTicketsToReview {
    id
    status
    price
    review
    token
    comments
    additionalPassengers {
      id
    }
    departureData {
      rideDate
      availableSeats
      pathway {
        id
        price
        polyline
        toAirport
        company {
          id
          name
          webPage
          address
          email
          phone
          avgReview
          logotypeUrl
        }
      }
      start {
        id
        departureTime
        dayOfTheWeek
        busStop {
          id
          name
          city
          address
          latitude
          longitude
          isAirport
        }
      }
      end {
        id
        departureTime
        dayOfTheWeek
        busStop {
          id
          name
          city
          address
          latitude
          longitude
          isAirport
        }
      }
      allDepartures {
        id
        departureTime
        dayOfTheWeek
        busStop {
          id
          name
          city
          address
          latitude
          longitude
          isAirport
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTicketsToReviewGQL extends Apollo.Query<GetTicketsToReviewQuery, GetTicketsToReviewQueryVariables> {
    document = GetTicketsToReviewDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }