<header class="bnl-top-bar" [class.transparent]="!!config.isTransparent" [class.light]="config.lightBackground">
  <div class="bnl-top-bar-left-icon">
    <ng-container *ngIf="!!config.leftIcon">
      <img
        [src]="config.leftIcon.src"
        [alt]="config.leftIcon.alt"
        (click)="!!config.leftIcon ? config.leftIcon.clickHandler() : null"
      >
    </ng-container>
  </div>
  <div class="bnl-top-bar-content">
    <p class="bnl-top-bar-content-title">{{config.title}}</p>
    <p class="bnl-top-bar-content-subtitle" *ngIf="config.subtitle">{{config.subtitle}}</p>
  </div>
  <div class="bnl-top-bar-right-icon">
    <ng-container *ngIf="!!config.rightIcon">
      <img
        [src]="config.rightIcon.src" [alt]="config.rightIcon.alt"
        (click)="!!config.rightIcon ? config.rightIcon.clickHandler() : null"
      >
    </ng-container>
  </div>
</header>
