<div class="search-page has-bottom-nav">
  <div class="background">
    <div class="container container--flat">
      <div class="header header--big d-md-none">
        <div class="header-brand">
          <img src="/app/shared/assets/svg/bnl-logo.svg" alt="" />
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-between pb-4 flex-column flex-md-row">
        <h1
          class="search-page-title"
          [innerHTML]="
            selectedTab === SearchPageTab.SEARCH
              ? ('SearchPage.title' | translate)
              : ('SearchPage.titleRent' | translate)
          "
        ></h1>
      </div>
      <div class="search-page-form-tabs">
        <img class="bus-image" src="assets/img/bus.png" alt="{{ 'SearchPage.RentBus' | translate }}" />
        <img class="plane-image" src="assets/img/plane.png" alt="{{ 'SearchPage.RentBus' | translate }}" />
        <div
          class="search-page-form-tabs-item"
          [class.is-selected]="selectedTab === SearchPageTab.RENT"
          (click)="selectTab(SearchPageTab.RENT)"
        >
          <div class="search-page-form-tabs-item-title">{{ "SearchPage.RentBus" | translate }}</div>
          <div class="search-page-form-tabs-item-icon">
            <img src="assets/svg/bus.svg" alt="{{ 'SearchPage.RentBus' | translate }}" />
          </div>
        </div>
        <div
          class="search-page-form-tabs-item search-page-form-tabs-item-rent"
          [class.is-selected]="selectedTab === SearchPageTab.SEARCH"
          (click)="selectTab(SearchPageTab.SEARCH)"
        >
          <div class="search-page-form-tabs-item-title">{{ "SearchPage.TripToAirport" | translate }}</div>
          <div class="search-page-form-tabs-item-icon">
            <img src="assets/svg/plane.svg" alt="{{ 'SearchPage.TripToAirport' | translate }}" />
          </div>
        </div>
      </div>
      <div class="search-page-form-wrapper" [hidden]="selectedTab === SearchPageTab.RENT">
        <div class="search-page-form-title">{{ "SearchPage.FindAndBuy" | translate }}</div>
        <div
          class="search-page-form"
          [class.search-page-form--reversed]="!searchParams.get('toAirport').value"
          [formGroup]="searchForm"
        >
          <div
            class="search-page-form-input"
            (click)="WindowHelper.isMobile ? onSearchDialogOpen(SearchType.LOCALIZATION) : null"
          >
            <div class="search-page-form-input-swap" (click)="onTripDirectionSwap(); $event.stopPropagation()">
              <img src="/app/shared/assets/svg/swap.svg" [alt]="'Global.IconAlts.swapIcon' | translate" />
            </div>
            <gbxsoft-input
              #placeInput
              [class.pointer-events-none]="WindowHelper.isMobile"
              formControlName="place"
              [invalid]="
                searchParams.get('localization').get('address').touched &&
                searchParams.get('localization').get('address').invalid
              "
              [disableTabIndex]="WindowHelper.isMobile"
              (keyupEmit)="navigateSuggestionsByArrows($event, SearchType.LOCALIZATION)"
              [config]="{
                name: getInputTitle(SearchType.LOCALIZATION, searchParams.get('toAirport').value),
                placeholder: getInputTitle(SearchType.LOCALIZATION, searchParams.get('toAirport').value),
                iconUrl: '/app/shared/assets/svg/target-location.svg',
                type: GbxsoftInputTypes.TEXT
              }"
            >
            </gbxsoft-input>
            <bnl-search-suggestions
              class="search-page-form-input-suggestions"
              [class.search-page-form-input-suggestions--active]="showPlaceSuggestions"
              [suggestions]="placeSuggestions"
              [allowLocalize]="true"
              [selectedSuggestionIndex]="selectedSuggestionIndex"
              (localize)="onLocalize()"
              (suggestionPicked)="onPlacePicked($event)"
            >
            </bnl-search-suggestions>
            <ngx-spinner
              [name]="LOCALIZE_LOADER"
              [fullScreen]="false"
              size="small"
              type="ball-clip-rotate"
              bdColor="rgba(0, 0, 0, .1)"
              color="#5051FF"
            ></ngx-spinner>
          </div>

          <div
            class="search-page-form-input"
            (click)="WindowHelper.isMobile ? onSearchDialogOpen(SearchType.AIRPORT) : null"
          >
            <gbxsoft-input
              #airportInput
              [class.pointer-events-none]="WindowHelper.isMobile"
              [invalid]="searchParams.get('airportId').touched && searchParams.get('airportId').invalid"
              [disableTabIndex]="WindowHelper.isMobile"
              (keyupEmit)="navigateSuggestionsByArrows($event, SearchType.AIRPORT)"
              formControlName="airport"
              [config]="{
                name: getInputTitle(SearchType.AIRPORT, searchParams.get('toAirport').value),
                placeholder: getInputTitle(SearchType.AIRPORT, searchParams.get('toAirport').value),
                iconUrl: searchParams.get('toAirport').value
                  ? '/app/shared/assets/svg/plane-in.svg'
                  : '/app/shared/assets/svg/plane-out.svg',
                type: GbxsoftInputTypes.TEXT
              }"
            >
            </gbxsoft-input>
            <bnl-search-suggestions
              class="search-page-form-input-suggestions"
              [class.search-page-form-input-suggestions--active]="showAirportSuggestions"
              [suggestions]="airportSuggestions"
              [selectedSuggestionIndex]="selectedSuggestionIndex"
              (suggestionPicked)="onAirportPicked($event)"
            >
            </bnl-search-suggestions>
          </div>

          <div class="search-page-form-input search-page-form-input--datetime">
            <ng-container *ngIf="!DeviceHelper.isMobile">
              <div class="search-page-form-input-trigger" (click)="isCalendarOpen = true"></div>
              <div
                class="search-page-form-input-backdrop"
                [class.search-page-form-input-backdrop--active]="isCalendarOpen"
                (click)="isCalendarOpen = false"
              ></div>
              <dl-date-time-picker
                #dateTimePicker
                class="search-page-form-input-calendar"
                [class.search-page-form-input-calendar--active]="isCalendarOpen"
                [selectFilter]="dateTimeSelectFilter"
                startView="day"
                maxView="day"
                minView="hour"
                minuteStep="5"
                (change)="onDatePick($event)"
              >
              </dl-date-time-picker>
            </ng-container>

            <div class="datetime-input d-md-none" *ngIf="DeviceHelper.isMobile">
              <input
                type="datetime-local"
                [formControl]="nativeDatetimePickerControl"
                [min]="nativeDatetimePickerMinValue"
              />
            </div>

            <gbxsoft-input
              class="search-page-form-input"
              [invalid]="searchParams.get('dateFrom').touched && searchParams.get('dateFrom').invalid"
              [formControl]="tripDisplayDate"
              [disableTabIndex]="WindowHelper.isMobile"
              [config]="{
                name: this.t.instant('SearchPage.date'),
                placeholder: this.t.instant('SearchPage.date'),
                iconUrl: '/app/shared/assets/svg/calendar.svg',
                type: GbxsoftInputTypes.TEXT
              }"
            >
            </gbxsoft-input>
          </div>

          <bnl-button
            (click)="onSearchFormSubmit()"
            class="search-page-form-submit"
            iconSrc="/app/shared/assets/svg/continue.svg"
            iconAlt="{{ 'SearchPage.search' | translate }}"
            align="between"
            [text]="'SearchPage.search' | translate"
            [reverse]="true"
            [showLoader]="isRequestPending"
          >
          </bnl-button>
        </div>
      </div>
      <div class="search-page-rent-wrapper" [hidden]="selectedTab === SearchPageTab.SEARCH">
        <div class="search-page-rent-title">{{ "SearchPage.FindBus" | translate }}</div>
        <div class="search-page-rent-description">
          {{ "SearchPage.FindBusDesc" | translate }}
        </div>
        <div class="search-page-rent-form">
          <bnl-rental-form [isSearchPage]="true" [redirectToOrders]="true"></bnl-rental-form>
        </div>
      </div>
    </div>
  </div>

  <div class="search-page-history" [class.is-rent-open]="selectedTab === SearchPageTab.RENT">
    <div class="search-page-history-image d-none d-lg-block">
      <img src="/assets/img/presentation-image.png" [alt]="'Global.ImagesAlts.travel' | translate" />
    </div>
    <div class="search-page-history-details">
      <h2
        *ngIf="!(WindowHelper.isMobile && auth.isSignedIn())"
        class="search-page-history-details-title"
        [innerHTML]="'SearchPage.History.header' | translate"
      ></h2>
      <bnl-search-history-list
        *ngIf="auth.isSignedIn()"
        class="search-page-history-details"
        [searchHistory]="searchService.searchHistory"
        (searchQueryPick)="onHistorySearchQueryPick($event)"
      ></bnl-search-history-list>

      <p class="search-page-history-details-info">
        <span *ngIf="!auth.isSignedIn()" [innerHTML]="'SearchPage.History.loginToSee' | translate"></span>
        <span
          *ngIf="auth.isSignedIn() && searchService.searchHistory?.length === 0"
          [innerHTML]="'SearchPage.History.noHistory' | translate"
        ></span>
      </p>

      <bnl-button
        class="search-page-history-details-login"
        *ngIf="!auth.isSignedIn()"
        (click)="auth.openAuthDialog()"
        [boldText]="'SearchPage.History.createAccount' | translate"
        align="center"
        iconSrc="/app/shared/assets/svg/user-small.svg"
      >
      </bnl-button>
    </div>
  </div>

  <ng-container *ngIf="!WindowHelper.isMobile">
    <bnl-description-section></bnl-description-section>
    <bnl-download-app></bnl-download-app>
  </ng-container>
</div>
