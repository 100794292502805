import { EventEmitter, Injectable } from '@angular/core';
import {
  AdditionalPassengerAgeEnum,
  ConfirmLoginGQL,
  ConfirmLoginMutation,
  DeleteAccountGQL,
  DeleteAccountMutation,
  GetProfileDataGQL,
  GetProfileDataQuery,
  LoginGQL,
  LoginMutation,
  PassengerProfileInput,
  ProfileUpdateGQL,
  ProfileUpdateMutation,
  ResendCodeGQL,
  ResendCodeMutation,
  UpdateAgeGQL,
  UpdateAgeMutation,
} from '@modules/graphql/graphql.service';
import { StorageItem } from '@shared/enums/storage-item.enum';
import { StorageService } from '@shared/services/storage.service';
import { Apollo } from 'apollo-angular';
import { FetchResult } from 'apollo-link';
import { Observable, Subscriber } from 'rxjs';
import { fromPromise } from 'rxjs/internal-compatibility';

@Injectable()
export class AuthService {
  public onLogin = new EventEmitter<void>();
  public onLogout = new EventEmitter<void>();

  constructor(
    private apollo: Apollo,
    private storageService: StorageService,
    private loginGQL: LoginGQL,
    private confirmLoginGQL: ConfirmLoginGQL,
    private profileUpdateGQL: ProfileUpdateGQL,
    private deleteAccountGQL: DeleteAccountGQL,
    private getProfileDataGQL: GetProfileDataGQL,
    private resendCodeGQL: ResendCodeGQL,
    private updateAgeGQL: UpdateAgeGQL
  ) {}

  /** @description emits true if auth dialog turns into visible state and false on invisible state */
  public authDialogStateChanged = new EventEmitter<boolean>();

  public openAuthDialog(): void {
    this.authDialogStateChanged.emit(true);
  }

  public closeAuthDialog(): void {
    this.authDialogStateChanged.emit(false);
  }

  public login(phoneCountryCode: string, phone: string): Observable<FetchResult<LoginMutation>> {
    return this.loginGQL.mutate({ phoneCountryCode, phone });
  }

  public confirmLogin(
    phoneCountryCode: string,
    phone: string,
    code: string
  ): Observable<FetchResult<ConfirmLoginMutation>> {
    return this.confirmLoginGQL.mutate({ phoneCountryCode, phone, code });
  }

  public resendCode(phoneCountryCode: string, phone: string): Observable<FetchResult<ResendCodeMutation>> {
    return this.resendCodeGQL.mutate({ phoneCountryCode, phone });
  }

  public logout(): Observable<boolean> {
    return new Observable<boolean>((subscriber: Subscriber<boolean>) => {
      this.storageService.clearStorage();
      fromPromise(this.apollo.client.resetStore()).subscribe(
        () => {
          subscriber.next(true);
          subscriber.complete();
          this.onLogout.emit();
        },
        (error) => {
          subscriber.error(error);
          subscriber.complete();
        }
      );
    });
  }

  public saveAuthToken(token: string): void {
    this.storageService.setItem(StorageItem.AUTH_TOKEN, token);
  }

  public getAuthToken(): string {
    return this.storageService.getItem(StorageItem.AUTH_TOKEN);
  }

  public removeAuthToken(): void {
    this.storageService.removeItem(StorageItem.AUTH_TOKEN);
  }

  public isSignedIn(): boolean {
    return this.storageService.getItem(StorageItem.AUTH_TOKEN) !== null;
  }

  public getProfileData(): Observable<FetchResult<GetProfileDataQuery>> {
    return this.getProfileDataGQL.fetch(
      {},
      {
        fetchPolicy: 'network-only',
      }
    );
  }

  public updateProfileData(profileInput: PassengerProfileInput): Observable<FetchResult<ProfileUpdateMutation>> {
    return this.profileUpdateGQL.mutate({ profileInput });
  }

  public deleteAccount(): Observable<FetchResult<DeleteAccountMutation>> {
    return this.deleteAccountGQL.mutate();
  }

  public updateAge(age: AdditionalPassengerAgeEnum): Observable<FetchResult<UpdateAgeMutation>> {
    return this.updateAgeGQL.mutate({ age });
  }
}
