import { MediaQuery } from '@shared/configs/media-query';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

export class WindowHelper {
  constructor() {
  }

  public static disableBodyScroll(): void {
    disableBodyScroll(document.body);
  }

  public static enableBodyScroll(): void {
    enableBodyScroll(document.body);
  }

  static get isMobile() {
    return window.innerWidth < MediaQuery.SM;
  }
}
