import { Component, OnDestroy, OnInit } from '@angular/core';
import { SplashScreenService } from '@shared/services/splash-screen.service';
import { Subscription, timer } from 'rxjs';
import { FadeOutAnimation } from '@shared/animations/fade-out.animation';
import { WindowHelper } from '@shared/helpers/window.helper';

@Component({
  selector: 'bnl-splash-screen',
  templateUrl: './splash-screen.component.html',
  styleUrls: ['./splash-screen.component.scss'],
  animations: [FadeOutAnimation]
})
export class SplashScreenComponent implements OnInit, OnDestroy {
  isSplashScreenVisible = WindowHelper.isMobile;
  sub: Subscription;

  constructor(private splashScreenService: SplashScreenService) {
  }

  ngOnInit(): void {
    this.sub = this.splashScreenService.splashScreenStateChanged.subscribe((isVisible: boolean) => {
      this.isSplashScreenVisible = isVisible;
    });

    if (WindowHelper.isMobile) {
      timer(1250).subscribe(() => this.isSplashScreenVisible = false);
    }
  }

  ngOnDestroy(): void {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }
}
