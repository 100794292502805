import { CustomRoutes } from '@shared/consts/custom-routes.const';
import { JoinUsPageComponent } from '@modules/tabs/join-us-tab/pages/join-us-page/join-us-page.component';

export const JoinUsTabRoutes: CustomRoutes = [
  {
    path: '',
    component: JoinUsPageComponent,
    data: {
      metaTitle: 'MetaTitle.Join us',
    },
  },
];
