import { AdditionalPassengerAgeEnum, AdditionalPassengerAgeRangeEnum } from '@modules/graphql/graphql.service';

export class AdditionalPassenger {
  constructor(
    public age: AdditionalPassengerAgeEnum,
    public isMain: boolean = false,
    public name: string = '',
    public lastName: string = '',
    public phone: string = '',
    public email: string = '',
    public ageRange: AdditionalPassengerAgeRangeEnum = null
  ) {
  }
}
