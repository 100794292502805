<div class="orderPathDetails">
  <div class="orderPathDetails-path">
    <div class="orderPathDetails-path-wrapper">
      <div class="orderPathDetails-path-from">
        <p class="orderPathDetails-path-title">
          {{ "OrdersPage.Arrival" | translate }}
        </p>
        <p class="orderPathDetails-path-date">
          {{ order.departureDate | formatDate : Config.TICKET_DATE_FORMAT }}
        </p>
        <bnl-order-ride-path
          [order]="order"
          [rideTime]="ordersService.getDuration(order)?.text"
        >
          <bnl-button
            *ngIf="showMapButtons"
            mapButton
            class="orderPathDetails-path-button"
            (click)="mapPreviewOpen.emit(order)"
            size="sm"
            [rounded]="true"
            buttonStyle="blue"
            [text]="'OrdersPage.ShowOnMap' | translate"
            iconSrc="/app/shared/assets/svg/folded-map.svg"
            [iconAlt]="'IconAlts.mapIcon' | translate"
          >
          </bnl-button>
        </bnl-order-ride-path>
      </div>
    </div>
    <div class="orderPathDetails-path-wrapper" *ngIf="order.returnDate">
      <div class="orderPathDetails-path-to">
        <p class="orderPathDetails-path-title">
          {{ "OrdersPage.Return" | translate }}
        </p>
        <p class="orderPathDetails-path-date">
          {{ order.returnDate | formatDate : "dddd, DD MMM HH:mm" }}
        </p>
        <bnl-order-ride-path
          [order]="order"
          [isReturn]="true"
          [rideTime]="ordersService.getDuration(order)?.text"
        >
          <bnl-button
            *ngIf="showMapButtons"
            mapButton
            class="orderPathDetails-path-button"
            (click)="mapPreviewOpen.emit(order)"
            size="sm"
            [rounded]="true"
            buttonStyle="blue"
            [text]="'OrdersPage.ShowOnMap' | translate"
            iconSrc="/app/shared/assets/svg/folded-map.svg"
            [iconAlt]="'IconAlts.mapIcon' | translate"
          >
          </bnl-button
        ></bnl-order-ride-path>
      </div>
    </div>
  </div>
</div>
