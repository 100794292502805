import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { BaseComponent } from '@shared/components/base-component';
import { interval, Subscription } from 'rxjs';
import { TopBarConfig } from '@shared/interfaces/top-bar-config.interface';
import { FormBuilder, Validators } from '@angular/forms';
import { SnackBarState } from '@shared/enums/snack-bar-state.enum';
import { FadeAnimation } from '@shared/animations/fade-in-out.animation';
import { FetchResult } from 'apollo-link';
import { ConfirmLoginMutation, LoginMutation, LoginRequestStatusEnum, ResendCodeMutation } from '@modules/graphql/graphql.service';
import { finalize, takeUntil } from 'rxjs/operators';
import { StorageItem } from '@shared/enums/storage-item.enum';
import { StorageService } from '@shared/services/storage.service';

@Component({
  selector: 'bnl-auth-dialog',
  templateUrl: './auth-dialog.component.html',
  animations: [FadeAnimation]
})
export class AuthDialogComponent extends BaseComponent implements OnInit, OnDestroy {
  readonly RESEND_TIMEOUT = 60;
  readonly CODE_CONFIRM_ATTEMPTS = 3;

  isAuthDialogVisible = false;
  isFormPending = false;
  isResendPending = false;
  resendTimeLeft = 0;

  private resendTimeIntervalSub: Subscription;

  Step = Step;
  activeStep = Step.FORM;
  formStepIcon = {
    src: '/app/shared/assets/svg/close.svg',
    alt: this.t.instant('Global.IconAlts.closeIcon'),
    clickHandler: () => this.auth.closeAuthDialog()
  };
  confirmStepIcon = {
    src: '/app/shared/assets/svg/back-darker.svg',
    alt: this.t.instant('Global.IconAlts.backIcon'),
    clickHandler: () => this.onCancel()
  };
  formStepTitle = this.t.instant('AuthDialog.login');

  topBarConfig: TopBarConfig = {
    title: this.formStepTitle,
    isTransparent: true,
    leftIcon: this.formStepIcon
  };

  loginForm = this.fb.group({
    userCountry: [''],
    userPhoneNumber: ['', [Validators.required]],
    confirmCode: ['']
  });

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    this.auth.closeAuthDialog();
  }

  constructor(private fb: FormBuilder, private storage: StorageService) {
    super();
  }

  ngOnInit(): void {
    this.auth.authDialogStateChanged.pipe(takeUntil(this.destroyed)).subscribe((isVisible: boolean) => {
      if (!isVisible) {
        this.activeStep = Step.FORM;
      } else {
        this.loginForm.reset();
      }

      this.isAuthDialogVisible = isVisible;
    });
  }

  onPhoneNumberSubmit(): void {
    if (this.isFormPending) {
      return;
    }

    this.isFormPending = true;
    this.loginForm.markAllAsTouched();

    this.auth.login(
      this.getPhonePrefix(this.loginForm.get('userCountry').value),
      this.loginForm.get('userPhoneNumber').value
    ).pipe(
      takeUntil(this.destroyed),
      finalize(() => this.isFormPending = false)
    ).subscribe((res: FetchResult<LoginMutation>) => {
      this.handleLoginResult(res);
    }, (error) => {
      this.snackbar.open(this.t.instant('AuthDialog.wrongNumber'), SnackBarState.ERROR);
    });
  }

  private handleLoginResult(res: FetchResult<LoginMutation>): void {
    switch (res.data.login.status) {
      case LoginRequestStatusEnum.Success: {
        this.resetResendTimer();
        this.activeStep = Step.CONFIRM;
        this.topBarConfig.leftIcon = this.confirmStepIcon;
        this.topBarConfig.title = '';
        break;
      }

      case LoginRequestStatusEnum.TooManySms: {
        const duration = this.moment.duration(this.moment().diff(this.moment(res.data.login.lastResend))).asSeconds();
        const message = `${this.t.instant('AuthDialog.tooManySms')} ${this.RESEND_TIMEOUT - Math.ceil(duration)}s`;
        this.snackbar.open(message, SnackBarState.ERROR);
        break;
      }
    }
  }

  onVerificationCodeSubmit(code: string): void {
    if (this.isFormPending) {
      return;
    }

    this.isFormPending = true;
    this.loginForm.get('confirmCode').setValue(code);

    this.auth.confirmLogin(
      this.getPhonePrefix(this.loginForm.get('userCountry').value),
      this.loginForm.get('userPhoneNumber').value,
      this.loginForm.get('confirmCode').value
    ).pipe(
      takeUntil(this.destroyed),
      finalize(() => this.isFormPending = false)
    ).subscribe((res: FetchResult<ConfirmLoginMutation>) => {
      this.handleConfirmLoginResult(res);
      this.loginForm.get('confirmCode').setValue('');
    }, (error) => {
    });
  }

  private handleConfirmLoginResult(res: FetchResult<ConfirmLoginMutation>): void {
    switch (res.data.confirmLogin.status) {
      case LoginRequestStatusEnum.Success: {
        this.storage.setItem(StorageItem.USER_DATA, res.data.confirmLogin.passenger, true);
        this.auth.saveAuthToken(res.data.confirmLogin.accessToken);
        this.auth.closeAuthDialog();
        this.snackbar.open(this.t.instant('Global.Messages.successfulLogin'), SnackBarState.SUCCESS);
        this.activeStep = Step.FORM;

        this.auth.onLogin.emit();
        break;
      }

      case LoginRequestStatusEnum.WrongCode: {
        const message = `${this.t.instant('AuthDialog.wrongCode')} ${this.CODE_CONFIRM_ATTEMPTS - res.data.confirmLogin.codeAttempts}`;
        this.snackbar.open(message, SnackBarState.WARNING);
        break;
      }

      case LoginRequestStatusEnum.NoMoreAttempts: {
        this.activeStep = Step.FORM;
        this.snackbar.open(this.t.instant('AuthDialog.noMoreAttempts'), SnackBarState.ERROR);
        break;
      }

      case LoginRequestStatusEnum.NotRequested: {
        this.snackbar.open(this.t.instant('AuthDialog.notRequested'), SnackBarState.ERROR);
        break;
      }
    }
  }

  onResendCode(): void {
    if (this.resendTimeLeft === 0) {
      this.isResendPending = true;
      this.resendTimeLeft = this.RESEND_TIMEOUT;

      this.auth.resendCode(
        this.getPhonePrefix(this.loginForm.get('userCountry').value),
        this.loginForm.get('userPhoneNumber').value
      ).pipe(
        takeUntil(this.destroyed)
      ).subscribe((res: FetchResult<ResendCodeMutation>) => {
        this.isResendPending = false;
        this.snackbar.open(this.t.instant('AuthDialog.codeSent'), SnackBarState.INFO);

        this.resendTimeIntervalSub = interval(1000).subscribe((n: number) => {
          this.resendTimeLeft = this.RESEND_TIMEOUT - n - 1;
          if (this.resendTimeLeft === 0) {
            this.resendTimeIntervalSub.unsubscribe();
          }
        });
      }, (error) => {
      });
    }
  }

  onCancel(): void {
    this.activeStep = Step.FORM;
    this.topBarConfig.leftIcon = this.formStepIcon;
    this.topBarConfig.title = this.formStepTitle;
  }

  private resetResendTimer(): void {
    this.resendTimeIntervalSub?.unsubscribe();
    this.resendTimeLeft = 0;
  }

  private getPhonePrefix(dialCode: string): string {
    return `+${dialCode}`;
  }
}

enum Step {
  FORM,
  CONFIRM
}
