import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DepartureGraphql } from '@modules/graphql/graphql.service';

@Component({
  selector: 'bnl-bus-stop-info-window',
  templateUrl: './bus-stop-info-window.component.html',
  styleUrls: ['./bus-stop-info-window.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BusStopInfoWindowComponent {
  @Input() departure: DepartureGraphql;
}
