import { CustomRoutes } from '@shared/consts/custom-routes.const';
import { SearchPageComponent } from '@modules/tabs/search-tab/pages/search-page/search-page.component';
import { ResultsPageComponent } from '@modules/tabs/search-tab/pages/results-page/results-page.component';
import { TicketPageComponent } from '@modules/tabs/search-tab/pages/ticket-page/ticket-page.component';

export const SearchTabRoutes: CustomRoutes = [
  {
    path: '',
    component: SearchPageComponent,
    data: { metaTitle: 'MetaTitle.Find the cheapest...' },
  },
  {
    path: 'results',
    name: 'search-results',
    component: ResultsPageComponent,
    data: {
      isBottomMenuHidden: true,
      metaTitle: 'MetaTitle.Tickets',
    },
  },
  {
    path: 'ticket',
    name: 'ticket',
    component: TicketPageComponent,
    data: {
      isBottomMenuHidden: true,
      metaTitle: 'MetaTitle.Ticket',
    },
  },
];
