<div class="join-us-page">
    <div class="background">
        <div class="container container--flat">
          <div class="header header--big d-md-none">
            <div class="header-brand">
              <img src="/app/shared/assets/svg/bnl-logo.svg" alt="">
            </div>
          </div>
          <h1 class="search-page-title" [innerHTML]="'JoinUsPage.title' | translate"></h1>
        </div>
    </div>

    <bnl-info-section></bnl-info-section>
</div>