import { Injectable } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { AuthService } from "@core/modules/authorization/shared/services/auth.service";
import {
  AdditionalPassengerAgeEnum,
  PassengerGraphql,
  UpdateAgeMutation,
} from "@modules/graphql/graphql.service";
import { TranslateService } from "@ngx-translate/core";
import { SnackbarService } from "@shared/services/snackbar.service";
import { StorageService } from "@shared/services/storage.service";
import { Observable, of, Subscriber } from "rxjs";
import { map } from "rxjs/operators";
import { FetchResult } from "apollo-link";
import { SnackBarState } from "@shared/enums/snack-bar-state.enum";

export enum AgeFormField {
  AGE = "age",
}

const UPDATE_AGE_SUCCESS_MESSAGE: string = "Ticket.updateAgeSuccess";
const UPDATE_AGE_ERROR_MESSAGE: string = "Ticket.updateAgeError";

const _DROPDOWN_OPTIONS: AdditionalPassengerAgeEnum[] = [
  AdditionalPassengerAgeEnum.Adult,
  AdditionalPassengerAgeEnum.Pensioner,
];

@Injectable({
  providedIn: "root",
})
export class PassengerTypeSelectService {
  private _form: FormGroup = this.initializeForm();

  get form(): FormGroup {
    return this._form;
  }

  get dropdownOptions(): AdditionalPassengerAgeEnum[] {
    return _DROPDOWN_OPTIONS;
  }

  constructor(
    private readonly authService: AuthService,
    private readonly storageService: StorageService,
    private readonly snackbarService: SnackbarService,
    private readonly translateService: TranslateService
  ) {}

  private initializeForm(): FormGroup {
    return new FormGroup({
      [AgeFormField.AGE]: new FormControl(null, [Validators.required]),
    });
  }

  initializeFormValue(): void {
    const resolvedValue = this.authService.isSignedIn() && this.storageService.getUserData().age
      ? this.storageService.getUserData().age
      : AdditionalPassengerAgeEnum.Adult;

    this.form
      .get(AgeFormField.AGE)
      .setValue(resolvedValue, { emitEvent: false });
  }

  submit(): Observable<boolean> {
    if (this.form.invalid) return of(false);
    if (!this.authService.isSignedIn()) return of(true);
    return new Observable<boolean>((subscriber: Subscriber<boolean>) => {
      this.updateAge(this.getFormValue()).subscribe({
        next: (u: PassengerGraphql) => this.handleUpdateAgeSuccess(u),
        error: (error) => this.handleUpdateAgeError(subscriber),
        complete: () => this.handleUpdateAgeComplete(subscriber),
      });
    });
  }

  getFormValue(): AdditionalPassengerAgeEnum {
    return this.form.value.age;
  }

  private handleUpdateAgeSuccess(userData: PassengerGraphql): void {
    if (!userData) return;
    this.storageService.saveUserData(userData);
    this.snackbarService.open(
      this.translateService.instant(UPDATE_AGE_SUCCESS_MESSAGE),
      SnackBarState.SUCCESS
    );
  }

  private handleUpdateAgeError(subscriber: Subscriber<boolean>): void {
    this.snackbarService.open(
      this.translateService.instant(UPDATE_AGE_ERROR_MESSAGE),
      SnackBarState.ERROR
    );
    subscriber.next(false);
  }

  private handleUpdateAgeComplete(subscriber: Subscriber<boolean>): void {
    subscriber.next(true);
  }

  private updateAge(
    age: AdditionalPassengerAgeEnum
  ): Observable<PassengerGraphql> {
    return this.authService
      .updateAge(age)
      .pipe(
        map(
          (res: FetchResult<UpdateAgeMutation>) =>
            res.data?.updateAge as PassengerGraphql
        )
      );
  }
}
