import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { AuthService } from '@core/modules/authorization/shared/services/auth.service';
import { OrdersService } from '@core/services/orders.service';
import { CategorizedBusRentsGraphql } from '@modules/graphql/graphql.service';
import { Observable, Subscriber, of } from 'rxjs';

@Injectable()
export class RentalCounterGuard implements CanActivate {
  constructor(private ordersService: OrdersService, private router: Router, private authService: AuthService) {}

  canActivate(): Observable<boolean> {
    if (this.router.url.includes('bus-rental') || !this.authService.isSignedIn()) {
      return of(true);
    }
    return new Observable<boolean>((subscriber: Subscriber<boolean | UrlTree>) => {
      this.ordersService.getMyRentRequestsCount().subscribe((value: CategorizedBusRentsGraphql) => {
        value.new.length === 0 ? subscriber.next(true) : subscriber.next(this.router.parseUrl(`bus-rental/my-orders`));
      });
    });
  }
}
