import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { OrdersService } from "@core/services/orders.service";
import {
  BusRentGraphql,
  BusRentGraphqlForCarrier,
  BusRentStatus,
  CarrierCompanyGraphql,
  RentOfferStatus,
} from "@modules/graphql/graphql.service";
import { BaseComponent } from "@shared/components/base-component";
import { ReservationTimerService } from "@shared/services/reservation-timer.service";

@Component({
  selector: "bnl-bus-order-card",
  templateUrl: "./bus-order-card.component.html",
  styleUrls: ["./bus-order-card.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BusOrderCardComponent extends BaseComponent implements OnInit {
  @Input() isOrderCard = false;
  @Input() isPaymentCard = false;
  @Input() isConfirmed = false;
  @Input() isHistoryOrder = false;
  @Input() order: BusRentGraphql;
  @Input() orderCarrier: BusRentGraphqlForCarrier;
  @Input() isDetailsPartOpen = false;
  @Input() isCarrierView = false;
  @Input() showTimer = true;
  @Input() showOpenDetailsButton = false;
  @Input() reportChangesButtonDisabled = false;

  @Output() mapPreviewOpen = new EventEmitter<BusRentGraphql>();
  @Output() payReservationEmit = new EventEmitter();
  @Output() onRejectOfferEmit = new EventEmitter<number>();
  @Output() onDiscardOfferEmit = new EventEmitter<number>();
  @Output() onMakeOfferEmit = new EventEmitter<BusRentGraphqlForCarrier>();
  @Output() onReportChangesEmit = new EventEmitter<number>();
  @Output() onShowInvoiceEmit = new EventEmitter<number>();

  RentOfferStatus = RentOfferStatus;
  BusRentStatus = BusRentStatus;

  constructor(
    public ordersService: OrdersService,
    public reservationTimerService: ReservationTimerService,
    private cd: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit(): void {
    if (this.order.departure && this.order.destination) {
      this.initDistances();
    }
  }

  get confirmedOfferCarrier(): CarrierCompanyGraphql {
    return this.order?.offers?.find(
      (offer) => offer.status === RentOfferStatus.Confirmed
    )?.carrier;
  }

  initDistances(): void {
    this.ordersService.createDistanceMatrix(
      this.order.departure.googlePlaceId,
      this.order.destination.googlePlaceId
    );
    this.cd.detectChanges();
  }

  onPathDetailsOpen(): void {
    this.isDetailsPartOpen = !this.isDetailsPartOpen;
  }

  showOffers(): void {
    this.router.navigate([`/bus-rental/my-orders/${this.order.id}`]);
  }

  showOrderDetails(): void {
    this.router.navigate([
      `/bus-rental/${
        this.isHistoryOrder ? "orders-history" : "confirmed-orders"
      }/${this.order.id}`,
    ]);
  }
}
