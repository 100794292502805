import { CustomRoutes } from '@shared/consts/custom-routes.const';
import { TicketsPageComponent } from '@modules/tabs/tickets-tab/pages/tickets-page/tickets-page.component';
import { SummaryPageComponent } from '@modules/tabs/tickets-tab/pages/summary-page/summary-page.component';
import { PaymentPageComponent } from '@modules/tabs/tickets-tab/pages/payment-page/payment-page.component';
import { TicketPageComponent } from '@modules/tabs/tickets-tab/pages/ticket-page/ticket-page.component';
import { CurrentTicketsListComponent } from '@modules/tabs/tickets-tab/pages/tickets-page/current-tickets-list/current-tickets-list.component';
import { HistoryTicketsListComponent } from '@modules/tabs/tickets-tab/pages/tickets-page/history-tickets-list/history-tickets-list.component';
import { AuthGuard } from '@core/modules/authorization/shared/guards/auth-guard';

export const TicketsTabRoutes: CustomRoutes = [
  {
    path: '',
    canActivate: [AuthGuard],
    component: TicketsPageComponent,
    children: [
      { path: '', pathMatch: 'full', redirectTo: 'list' },
      {
        path: 'list',
        component: CurrentTicketsListComponent,
        data: { metaTitle: 'MetaTitle.Your reservations' },
      },
      {
        path: 'history',
        component: HistoryTicketsListComponent,
        data: { metaTitle: 'MetaTitle.Ticket history' },
      },
    ],
  },
  {
    path: 'summary/:ticketToken',
    name: 'summary',
    component: SummaryPageComponent,
    data: {
      isBottomMenuHidden: true,
      metaTitle: 'MetaTitle.Summary',
    },
  },
  {
    path: 'payment/:paymentToken',
    name: 'payment',
    component: PaymentPageComponent,
    data: {
      isBottomMenuHidden: true,
      metaTitle: 'MetaTitle.Payment',
    },
  },
  {
    path: 'ticket/:ticketToken',
    name: 'ticket-pdf',
    component: TicketPageComponent,
    data: {
      isBottomMenuHidden: true,
      metaTitle: 'MetaTitle.Ticket',
    },
  },
];
