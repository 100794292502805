<div class="gbxsoft-input" [ngClass]="inputCSS" [class.has-focus]="isFocused" [class.invalid]="invalid">
  <div class="gbxsoft-input-icon" *ngIf="config.iconUrl">
    <img [src]="config.iconUrl" />
  </div>
  <label>
    <input
      #gbxsoftInput
      trim
      [tabIndex]="disableTabIndex ? -1 : 0"
      [autocomplete]="config?.autocomplete"
      [autocapitalize]="config?.autocapitalize"
      [spellcheck]="config?.spellcheck"
      [ctrl]="errorCtrl?.control"
      [type]="config?.type"
      [value]="value ? value : ''"
      [placeholder]="placeholder"
      [attr.disabled]="disabled ? '' : null"
      onmousewheel="return false;"
    />

    <span [innerText]="config?.name && config.isRequired ? config.name + ' *' : config.name"></span>
  </label>
  <!--  <span *ngIf="errorCtrl.isError" class="form-control-error-msg" [innerHTML]="errorCtrl.errorMessage"></span>-->
</div>
