<div class="gbxsoft__select" clickOutside [ngClass]="selectClasses" (clickOutside)="clickOutside()">
  <!-- #region SEARCHBOX with Options -->
  <div class="gbxsoft__select__wrapper">
    <label class="gbxsoft__select__input" (click)="openDropdown()">
      <!-- #region Single option -->
      <div
        *ngIf="!searchControl?.value && !config?.multiple"
        class="gbxsoft__select_items gbxsoft__select_items__single"
      >
        <span [innerHTML]="selectedOption" class="gbxsoft__select_selected"></span>
      </div>
      <!-- #endregion -->

      <!-- #region Multiple-->
      <ul *ngIf="config?.multiple" class="gbxsoft__select_items gbxsoft__select_items__multiple">
        <li *ngFor="let o of selectedOptions">
          <span [innerHTML]="o[config.label]"></span>
          <span class="close" (click)="unselect(o)">&#10006;</span>
        </li>
      </ul>
      <!-- #endregion -->

      <input
        type="text"
        [formControl]="searchControl"
        [attr.disabled]="!config?.searching ? '' : null"
        [placeholder]="!selectedOption ? config?.placeholder : ''"
      />
      <span *ngIf="config?.labelName" [innerHTML]="config?.labelName"></span>
    </label>
    <span *ngIf="isClearAllowed" class="gbxsoft__select_items__clear" (click)="clearAll()">&#10006;</span>
  </div>
  <!-- #endregion -->

  <!-- #region DROPDOWN -->
  <ul class="gbxsoft__select__dropdown" *ngIf="isOpened">
    <li
      *ngFor="let o of options"
      (click)="selectEvent(o)"
      class="gbxsoft__select__option"
      [ngClass]="optionClass(o)"
      [innerHTML]="o[config.label]"
    ></li>
    <li
      *ngIf="config?.addTag && !options?.length && searchControl.value?.length"
      (click)="addNewTag()"
      class="gbxsoft__select__option">
      Add tag "{{ searchControl.value }}"
    </li>
    <li
      *ngIf="!options.length"
      class="gbxsoft__select__option__not_found"
      [innerHTML]="config?.notFoundText"
    ></li>
  </ul>
  <!-- #endregion -->
  <span *ngIf="errorCtrl.isError" class="form-control-error-msg" [innerHTML]="errorCtrl.errorMessage"></span>
</div>
