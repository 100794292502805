<div class="bus-rental-page">
  <div class="background">
    <div class="bus-rental-page-header">
      <h2
        class="bus-rental-page-header-title"
        [class.is-logged-in]="authService.isSignedIn()"
        [innerHTML]="'BusRentalPage.title' | translate"
      ></h2>

      <nav mat-tab-nav-bar class="bus-rental-page-header-tabs" *ngIf="authService.isSignedIn()">
        <a
          class="bus-rental-page-header-tabs-tab"
          mat-tab-link
          routerLinkActive="bus-rental-page-header-tabs-tab--active"
          *ngFor="let tab of tabs"
          [routerLink]="[tab.link]"
        >
          <div class="bus-rental-page-header-tabs-tab-inner">
            <p class="bus-rental-page-header-tabs-tab-inner-text">
              {{ tab.name | translate }}
            </p>
          </div>
        </a>
      </nav>
    </div>
  </div>

  <div class="bus-rental-page-content">
    <router-outlet></router-outlet>
  </div>
</div>
