<div class="carrier-info">
  <div class="carrier-info-badge" *ngIf="showCarrierInfo">{{ "OrdersPage.YourCarrier" | translate }}</div>
  <p class="carrier-info-name">
    {{ carrier.name }}
  </p>
  <div class="carrier-info-review">
    <bnl-ticket-rating *ngIf="carrier.avgReview; else noReviews" [rate]="carrier.avgReview"></bnl-ticket-rating>
    <ng-template #noReviews>
      <span class="carrier-info-review-empty">{{ "OrdersPage.NoReview" | translate }}</span>
    </ng-template>
  </div>
  <div class="carrier-info-about" *ngIf="showCarrierInfo">
    <div class="carrier-info-about-phone">{{ "OrdersPage.Tel" | translate }} {{ carrier.phone }}</div>
    <p class="carrier-info-about-text" (click)="onCarrierInfoDialogOpen()">
      {{ "Ticket.aboutCarrier" | translate }}
    </p>
  </div>
</div>
