import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { BaseComponent } from "@shared/components/base-component";
import { Theme } from "@shared/configs/theme";
import * as uuid from "uuid";

@Component({
  selector: "bnl-button",
  templateUrl: "./button.component.html",
  styleUrls: ["./button.component.scss"],
})
export class ButtonComponent extends BaseComponent implements OnChanges {
  @Input() text = "";
  @Input() boldText = "";

  @Input() secondaryTitle = "";
  @Input() secondaryText = "";

  @Input() iconSrc = "";
  @Input() iconAlt = "";
  @Input() buttonStyle: ButtonStyle = "primary";
  @Input() transparent = false;
  @Input() align: Align = "left";
  @Input() size: Size = "lg";
  @Input() reverse = false;
  @Input() leftPadding = false;
  @Input() rounded = false;
  @Input() showLoader = false;
  @Input() disabled: boolean = false;
  @Input() showBadge: boolean = false;
  @Input() badgeValue: string;

  buttonLoaderId = `button-spinner-${uuid.v4()}`;

  constructor() {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.showLoader) {
      this.spinner.show(this.buttonLoaderId);
    } else {
      this.spinner.hide(this.buttonLoaderId);
    }
  }

  getButtonBackgroundColor(): string {
    switch (this.buttonStyle) {
      case "primary": {
        return Theme.colors.primaryGreen500;
      }

      case "secondary": {
        return Theme.colors.navy100;
      }

      case "danger": {
        return Theme.colors.systemRed500;
      }
    }
  }
}

type ButtonStyle =
  | "primary"
  | "secondary"
  | "tertiary-white"
  | "tertiary-navy"
  | "danger"
  | "delete"
  | "blue"
  | "white";
type Align = "left" | "center" | "between" | "right";
type Size = "sm" | "md" | "lg";
