import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { BaseComponent } from '@shared/components/base-component';
import { FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'bnl-confirm-part',
  templateUrl: './confirm-part.component.html'
})
export class ConfirmPartComponent extends BaseComponent implements OnChanges, AfterViewInit {
  @Input() phoneNumber = '';
  @Input() code = '';
  @Input() isFormPending = false;
  @Input() isResendPending = false;
  @Output() verificationCodeSubmit = new EventEmitter<string>();
  @Output() resendCode = new EventEmitter<void>();
  @Input() resendTimeLeft = 0;

  @ViewChild('codeInput', {read: ElementRef}) codeInputRef: ElementRef;

  readonly CODE_LENGTH = 4;

  verificationCode = new FormControl('', [
    Validators.required, Validators.minLength(this.CODE_LENGTH)
  ]);

  constructor(private ref: ChangeDetectorRef) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('code' in changes) {
      this.verificationCode.setValue(changes.code.currentValue);
      if (this.verificationCode.value === '') {
        this.focusCodeInput();
      }
    }
  }

  ngAfterViewInit(): void {
    this.focusCodeInput();
  }

  onCodeChanged(code: string): void {
    this.verificationCode.setValue(code);
  }

  onCodeCompleted(): void {
    this.onCodeSubmit();
  }

  onCodeSubmit(): void {
    if (this.verificationCode.valid) {
      this.verificationCodeSubmit.emit(this.verificationCode.value);
    }
  }

  onCodeResend(): void {
    this.resendCode.emit();
    this.verificationCode.setValue('');
    this.focusCodeInput();
  }

  private focusCodeInput(): void {
    this.ref.detectChanges();
    this.codeInputRef.nativeElement.querySelector('span input').focus();
  }
}
