<h2 class="bnl-auth-dialog-confirm-title">{{'AuthDialog.enterCode' | translate}}</h2>
<p
  class="bnl-auth-dialog-confirm-description"
  [innerHTML]="('AuthDialog.enterCodeDescription' | translate) + ' <b>' + phoneNumber + '</b>'">
</p>

<code-input
  #codeInput
  class="bnl-auth-dialog-confirm-code"
  [class.disabled]="isFormPending || isResendPending"
  [isCodeHidden]="false"
  [isNonDigitsCode]="false"
  [codeLength]="CODE_LENGTH"
  [code]="verificationCode.value"
  (codeCompleted)="onCodeCompleted()"
  (codeChanged)="onCodeChanged($event)">
</code-input>

<bnl-button
  (click)="onCodeResend();"
  [class.disabled]="isFormPending || resendTimeLeft > 0"
  [showLoader]="isResendPending"
  class="bnl-auth-dialog-confirm-resend"
  buttonStyle="secondary"
  align="center"
  size="md"
  [text]="resendTimeLeft === 0 ? ('AuthDialog.resendButton' | translate) : resendTimeLeft + 's'">
</bnl-button>

<bnl-button
  (click)="onCodeSubmit()"
  [class.disabled]="verificationCode.invalid || isResendPending"
  [showLoader]="isFormPending"
  class="bnl-auth-dialog-confirm-submit"
  iconSrc="/app/shared/assets/svg/continue.svg"
  iconAlt="{{'Global.IconAlts.continueIcon' | translate}}"
  align="between"
  [leftPadding]="true"
  [reverse]="true"
  [text]="'AuthDialog.submitButton' | translate">
</bnl-button>
