<div class="pageNotfound">
  <div class="background"></div>

  <div class="pageNotfound-content">
    <h2 class="pageNotfound-content-title">{{'PageNotFound.title' | translate}}</h2>
    <p class="pageNotfound-content-desc">{{'PageNotFound.desc' | translate}}</p>
    <bnl-button
      routerLink="/"
      [boldText]="'PageNotFound.button' | translate"
      buttonStyle="primary"
      size="md"
      [rounded]="true">
    </bnl-button>
  </div>
</div>
