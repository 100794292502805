import { Component, Input } from "@angular/core";
import { RentOfferStatus } from "@modules/graphql/graphql.service";

export enum InfoAlertEnum {
  WARNING = "warning",
  INFO = "info",
}

@Component({
  selector: "bnl-order-status",
  templateUrl: "./order-status.component.html",
  styleUrls: ["./order-status.component.scss"],
})
export class OrderStatusComponent {
  @Input() status: RentOfferStatus;
}
