<div class="carrierInfo dialog">
  <bnl-top-bar [config]="topBarConfig" class="sticky-top d-md-none"></bnl-top-bar>

  <div class="dialog-close d-none d-md-block">
    <img (click)="dialogRef.close()" src="/app/shared/assets/svg/close.svg" [alt]="'Global.IconAlts.closeIcon' | translate">
  </div>

  <div class="dialog-content">
    <h2 class="carrierInfo-title">{{'Ticket.carrier' | translate}}</h2>
    <h3 class="carrierInfo-subtitle">{{carrierInfo?.name}}</h3>
    <div class="carrierInfo-review">
      <bnl-ticket-rating *ngIf="hasAverageReview" [rate]="averageReview"></bnl-ticket-rating>

    </div>

    <div class="carrierInfo-contact">
      <ng-container *ngIf="carrierInfo?.phone">
        <span class="carrierInfo-contact-name">{{'CarrierInfoDialog.phone' | translate}}</span>
        <a [href]="'tel:' + carrierInfo?.phone" class="carrierInfo-contact-value">
          {{carrierInfo?.phone}}
        </a>
      </ng-container>

      <ng-container *ngIf="carrierInfo?.email">
        <span class="carrierInfo-contact-name">{{'CarrierInfoDialog.email' | translate}}</span>
        <a [href]="'mailto:' + carrierInfo?.email" class="carrierInfo-contact-value">
          {{carrierInfo?.email}}
        </a>
      </ng-container>

      <ng-container *ngIf="carrierInfo?.webPage">
        <span class="carrierInfo-contact-name">{{'CarrierInfoDialog.website' | translate}}</span>
        <a [href]="carrierInfo?.webPage" target="_blank" rel="noopener" class="carrierInfo-contact-value">
          {{carrierInfo?.webPage}}
        </a>
      </ng-container>

      <ng-container *ngIf="carrierInfo?.address">
        <span class="carrierInfo-contact-name">{{'CarrierInfoDialog.address' | translate}}</span>
        <span class="carrierInfo-contact-value">{{carrierInfo?.address}}</span>
      </ng-container>
    </div>

    <bnl-button
      class="carrierInfo-backButton"
      (click)="dialogRef.close()"
      buttonStyle="secondary"
      [text]="'CarrierInfoDialog.back' | translate"
      align="center"
      size="md">
    </bnl-button>
  </div>
</div>
