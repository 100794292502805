import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  OnInit,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TicketsService } from '@core/services/tickets.service';
import {
  PathwaysListElementGraphql,
  RemoveTicketMutation,
  TicketGraphql,
} from '@modules/graphql/graphql.service';
import { TicketMapService } from '@modules/tabs/tickets-tab/shared/services/ticket-map.service';
import { BaseComponent } from '@shared/components/base-component';
import { ConfirmDialogComponent } from '@shared/components/confirm-dialog/confirm-dialog.component';
import { debounce } from '@shared/decorators/debounce.decodator';
import { SnackBarState } from '@shared/enums/snack-bar-state.enum';
import { TicketStatus } from '@shared/enums/ticket-status.enum';
import { WindowHelper } from '@shared/helpers/window.helper';
import { ConfirmDialogData } from '@shared/interfaces/confirm-dialog-input.interface';
import { FetchResult } from 'apollo-link';
import { CupertinoPane } from 'cupertino-pane';
import { Observable } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'bnl-current-tickets-list',
  templateUrl: './current-tickets-list.component.html',
  styleUrls: ['./current-tickets-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CurrentTicketsListComponent
  extends BaseComponent
  implements OnInit
{
  TicketStatus = TicketStatus;
  WindowHelper = WindowHelper;

  private ridePathPane: CupertinoPane;
  selectedTicket: TicketGraphql;
  isRidePathPaneOpen = false;

  @HostListener('window:resize')
  @debounce(30)
  onResize() {
    if (this.isRidePathPaneOpen) {
      this.onRidePathOpen(this.selectedTicket, false);
    }
  }

  get unpaidTickets$(): Observable<TicketGraphql[]> {
    return this.ticketsService.unpaidTickets$;
  }

  get paidTickets$(): Observable<TicketGraphql[]> {
    return this.ticketsService.paidTickets$;
  }

  get loading$(): Observable<boolean> {
    return this.ticketsService.loading$;
  }

  constructor(
    public ticketsService: TicketsService,
    private ticketMapService: TicketMapService,
    private dialog: MatDialog,
    private ref: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit(): void {
    this.setupRidePathPane();
  }

  onTicketPay(ticket: TicketGraphql): void {
    this.n.navigate('summary', {}, { ticketToken: ticket.token });
  }

  onMapPreviewOpen(pathway: PathwaysListElementGraphql): void {
    this.ticketMapService.openTicketMapPreview.emit(pathway);
  }

  onTicketDelete(ticket: TicketGraphql): void {
    const dialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: this.t.instant('ProfileTickets.DeleteTicket.title'),
        actionDesc: this.t.instant('ProfileTickets.DeleteTicket.desc'),
        resultDesc: this.t.instant('ProfileTickets.DeleteTicket.subDesc'),
        confirmText: this.t.instant('ProfileTickets.DeleteTicket.confirm'),
        cancelText: this.t.instant('ProfileTickets.DeleteTicket.cancel'),
        style: 'danger',
      } as ConfirmDialogData,
    });

    dialog
      .afterClosed()
      .pipe(takeUntil(this.destroyed))
      .subscribe((confirmed: boolean) => {
        if (!!confirmed) {
          this.isRequestPending = true;
          this.ticketsService
            .removeTicket(ticket.id)
            .pipe(
              finalize(() => {
                this.isRequestPending = false;
              })
            )
            .subscribe(
              (res: FetchResult<RemoveTicketMutation>) => {
                this.snackbar.open(
                  this.t.instant('ProfileTickets.DeleteTicket.success'),
                  SnackBarState.SUCCESS
                );
                this.ticketsService.removeUnpaidTicketFromCollection(ticket);
                this.ref.detectChanges();
              },
              (error) => {
                this.snackbar.open(
                  this.t.instant('ProfileTickets.DeleteTicket.error'),
                  SnackBarState.ERROR
                );
              }
            );
        }
      });
  }

  onRidePathOpen(ticket: TicketGraphql, animated: boolean = true): void {
    this.selectedTicket = ticket;

    let safeAreaInsetBottomStyle = getComputedStyle(
      document.documentElement
    ).getPropertyValue('--saib');
    const safeAreaInsetBottomStyleHeight = parseInt(
      safeAreaInsetBottomStyle.replace('px', '')
    );

    const constantHeights = 136;
    const totalHeight =
      constantHeights +
      this.selectedTicket.departureData.allDepartures.length * 60 +
      safeAreaInsetBottomStyleHeight;

    this.ridePathPane.destroy();
    this.ridePathPane = undefined;
    this.setupRidePathPane();

    // check if viewport is portrait view
    if (window.innerHeight > window.innerWidth) {
      this.ridePathPane.settings.breaks.top.height =
        totalHeight > window.innerHeight - 64
          ? window.innerHeight - 64
          : totalHeight;
    } else {
      this.ridePathPane.settings.breaks.top.height = window.innerHeight - 64;
    }

    this.ridePathPane.present({ animate: animated });
    this.isRidePathPaneOpen = true;
  }

  private setupRidePathPane(): void {
    this.ridePathPane = new CupertinoPane('.ride-path-pane', {
      parentElement: '.ticketsPage',
      buttonClose: false,
      backdrop: true,
      bottomClose: true,
      initialBreak: 'top',
      dragBy: ['.ride-path-pane-header'],
      bottomOffset: 50,
      breaks: {
        top: { enabled: true, height: window.innerHeight - 64 },
        middle: { enabled: false },
        bottom: { enabled: false },
      },
      onBackdropTap: () => {
        this.ridePathPane.hide();
        this.isRidePathPaneOpen = false;
        WindowHelper.enableBodyScroll();
      },
      onDidDismiss: () => {
        this.isRidePathPaneOpen = false;
        WindowHelper.enableBodyScroll();
      },
    });
  }
}
