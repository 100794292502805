<div class="connection-card" [class.connection-card--bg-gray]="background === 'gray'">
  <div class="connection-card-content" (click)="onPathDetailsOpen()">
    <div class="connection-card-content-date connection-card-content-date--start">
      {{ pathway.rideDate | formatDate: (WindowHelper.isMobile ? "D MMM." : "D MMMM") }}
    </div>
    <div class="connection-card-content-date connection-card-content-date--end">
      {{ ticketsService.getArrivalDate(pathway) | formatDate: (WindowHelper.isMobile ? "D MMM." : "D MMMM") }}
    </div>

    <div class="connection-card-content-hour connection-card-content-hour--start">
      <span>{{ ticketsService.getDisplayDepartureTime(pathway.start.departureTime) }}</span>
      <span class="connection-card-content-hour-diff d-lg-none">{{
        ticketsService.getDisplayRideDuration(pathway)
      }}</span>
    </div>
    <div class="connection-card-content-hour connection-card-content-hour--end">
      {{ ticketsService.getDisplayDepartureTime(pathway.end.departureTime) }}
    </div>

    <div class="connection-card-content-duration d-none d-lg-flex">
      <span class="connection-card-content-duration-line"></span>
      <div class="connection-card-content-duration-inner">
        <p class="connection-card-content-duration-inner-time">
          {{ ticketsService.getDisplayRideDuration(pathway) }}
        </p>
        <p class="connection-card-content-duration-inner-stops">
          {{ ticketsService.getDisplayBusStopsNumber(pathway) }}
        </p>
      </div>
      <span class="connection-card-content-duration-line"></span>
    </div>

    <div class="connection-card-content-station connection-card-content-station--start">
      {{ pathway.start.busStop.name || pathway.start.busStop.address }},
      <br class="d-lg-none" />
      {{ pathway.start.busStop.city }}
    </div>
    <div class="connection-card-content-station connection-card-content-station--end">
      {{ pathway.end.busStop.name || pathway.end.busStop.address }}
      <br class="d-lg-none" />
      {{ pathway.end.busStop.city }}
    </div>

    <div class="connection-card-content-carrier">
      <div class="connection-card-content-carrier-container">
        <p class="connection-card-content-carrier-container-name">
          {{ pathway.pathway.company.name }}
        </p>
        <div class="connection-card-content-carrier-container-review" *ngIf="hasAverageReview">
          <bnl-ticket-rating [rate]="averageReview"></bnl-ticket-rating>
        </div>
        <bnl-button
          *ngIf="isProfileTicket"
          class="d-lg-none"
          (click)="ticketDelete.emit(ticket); $event.stopPropagation()"
          [boldText]="'ProfileTickets.CurrentTickets.delete' | translate"
          size="sm"
          [rounded]="true"
          iconSrc="/assets/svg/delete.svg"
          [iconAlt]="'Global.IconAlts.deleteIcon' | translate"
          buttonStyle="delete"
        ></bnl-button>

        <bnl-button
          *ngIf="isProfileTicket"
          class="connection-card-content-carrier-button d-none d-lg-block"
          [text]="ticketsService.getDisplayBusStopsNumber(pathway)"
          iconSrc="/app/shared/assets/svg/caret-down-blue.svg"
          [iconAlt]="'IconAlts.dropdownIcon' | translate"
          size="sm"
          align="between"
          [buttonStyle]="background === 'white' ? 'tertiary-navy' : 'tertiary-white'"
          [rounded]="true"
          [reverse]="true"
        ></bnl-button>

        <bnl-button
          *ngIf="!isProfileTicket"
          class="connection-card-content-carrier-button connection-card-content-carrier-button-amount"
          [text]="ticketsService.getDisplayBusStopsNumber(pathway)"
          iconSrc="/app/shared/assets/svg/caret-down-blue.svg"
          [iconAlt]="'IconAlts.dropdownIcon' | translate"
          size="sm"
          align="between"
          [buttonStyle]="background === 'white' ? 'tertiary-navy' : 'tertiary-white'"
          [rounded]="true"
          [reverse]="true"
        ></bnl-button>
      </div>
    </div>

    <div class="connection-card-content-actions">
      <div class="connection-card-content-actions-container">
        <div class="button-wrappar">
          <bnl-button
            class="connection-card-content-actions-container-button connection-card-content-actions-container-button--delete d-none d-lg-block"
            *ngIf="isProfileTicket"
            (click)="ticketDelete.emit(ticket); $event.stopPropagation()"
            [boldText]="'ProfileTickets.CurrentTickets.delete' | translate"
            size="sm"
            [rounded]="true"
            iconSrc="/assets/svg/delete.svg"
            [iconAlt]="'Global.IconAlts.deleteIcon' | translate"
            buttonStyle="delete"
          ></bnl-button>

          <bnl-button
            (click)="isProfileTicket ? ticketPay.emit(ticket) : ticketBuy.emit(pathway); $event.stopPropagation()"
            class="connection-card-content-actions-container-button"
            [class.disabled]="
              pathway.availableSeats <= 0 ||
              ticketsService.didDepartureTimePassed(pathway.rideDate) ||
              !pathway.saleEnabled
            "
            [text]="pathway.availableSeats > 0 ? ('Ticket.buyTicket' | translate) : ('Ticket.noSeats' | translate)"
            [boldText]="
              pathway.availableSeats > 0 && (isProfileTicket ? (ticket.price | price) : (pathway.price | price))
            "
            [iconSrc]="pathway.availableSeats > 0 && '/app/shared/assets/svg/ticket-navy.svg'"
            [iconAlt]="pathway.availableSeats > 0 && ('IconAlts.ticketIcon' | translate)"
            size="sm"
            align="center"
            [rounded]="true"
          ></bnl-button>
        </div>
        <p class="connection-card-content-actions-container-available-seats" *ngIf="pathway.saleEnabled">
          {{ "Ticket.availableSeats" | translate }} {{ availableSeats }}
        </p>
        <p class="connection-card-content-actions-container-available-seats" *ngIf="!pathway.saleEnabled">
          {{ "Ticket.saleEnabledInfo" | translate }}
        </p>
      </div>
    </div>
  </div>

  <div class="connection-card-details d-none d-md-flex" *ngIf="isDetailsPartOpen">
    <bnl-path-map-details
      class="connection-card-details-content"
      [pathway]="pathway"
      (mapPreviewOpen)="mapPreviewOpen.emit($event)"
    ></bnl-path-map-details>
  </div>
</div>
