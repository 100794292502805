import { Component, Input } from '@angular/core';
import { InfoBoxStyle } from '@shared/enums/info-box-style.enum';

@Component({
  selector: 'bnl-info-box',
  templateUrl: './info-box.component.html',
  styleUrls: ['./info-box.component.scss']
})
export class InfoBoxComponent {
  @Input() textContent: string;
  @Input() boxStyle: InfoBoxStyle = InfoBoxStyle.INFO;

  InfoBoxStyle = InfoBoxStyle;
}
