import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OrdersService } from '@core/services/orders.service';
import { BusRentGraphql } from '@modules/graphql/graphql.service';
import { BaseComponent } from '@shared/components/base-component';
import { InfoAlertEnum } from '@shared/components/order-info-alert/order-info-alert.component';
import { SnackBarState } from '@shared/enums/snack-bar-state.enum';
import { TopBarConfig } from '@shared/interfaces/top-bar-config.interface';
import { finalize, takeUntil } from 'rxjs/operators';

const ERROR_MESSAGE: string = 'OrdersPage.CantGetOrder';
const NOT_FOUND_MESSAGE: string = 'OrdersPage.OrderNotFound';

@Component({
  selector: 'bnl-bus-order-page',
  templateUrl: './bus-order-page.component.html',
  styleUrls: ['./bus-order-page.component.scss'],
})
export class BusOrderPageComponent extends BaseComponent implements OnInit {
  readonly ORDER_LOADER = 'fetching-order-loader';
  topBarConfig: TopBarConfig;
  isMapPreviewOpen = false;
  order: BusRentGraphql;
  InfoAlertEnum = InfoAlertEnum;

  constructor(private cd: ChangeDetectorRef, private ordersService: OrdersService, private route: ActivatedRoute) {
    super();
  }

  ngOnInit() {
    this.getOrder();
  }

  getOrder(): void {
    const id = parseInt(this.route.snapshot.paramMap.get('id'));
    if (!id) {
      this.router.navigate(['/bus-rental/my-orders']);
      this.snackbar.open(this.t.instant(NOT_FOUND_MESSAGE), SnackBarState.ERROR);
      return;
    }
    this.isRequestPending = true;
    this.spinner.show(this.ORDER_LOADER);
    this.ordersService
      .getOrder(id)
      .pipe(
        takeUntil(this.destroyed),
        finalize(() => {
          this.isRequestPending = false;
          this.spinner.hide(this.ORDER_LOADER);
        })
      )
      .subscribe(
        (result: BusRentGraphql) => {
          this.order = result;
          this.setupTopBar();
          this.cd.detectChanges();
        },
        () => this.snackbar.open(this.t.instant(ERROR_MESSAGE), SnackBarState.ERROR)
      );
  }

  private setupTopBar(): void {
    const departure = this.order.departure.name;
    const destination = this.order.destination.name;
    const departureDate = this.moment(this.order.departureDate).format(this.config.TICKET_DATE_FORMAT);

    this.topBarConfig = {
      title: `${departure} - ${destination}`,
      subtitle: departureDate,
      leftIcon: {
        src: '/app/shared/assets/svg/back.svg',
        alt: this.t.instant('Global.IconAlts.backIcon'),
        clickHandler: () => (this.isMapPreviewOpen ? this.onMapClose() : this.goToOrders()),
      },
    };
  }

  goToOrders(): void {
    if (this.router.url.includes('confirmed-orders')) {
      this.router.navigate(['/bus-rental/confirmed-orders']);
    }
    if (this.router.url.includes('orders-history')) {
      this.router.navigate(['/bus-rental/orders-history']);
    }
    if (this.router.url.includes('my-orders')) {
      this.router.navigate(['/bus-rental/my-orders']);
    }
  }

  onMapClose(): void {
    this.isMapPreviewOpen = false;
  }

  onMapOpen(): void {
    this.isMapPreviewOpen = true;
  }
}
