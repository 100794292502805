import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { OrdersService } from '@core/services/orders.service';
import { BusRentGraphql, CategorizedBusRentsGraphql } from '@modules/graphql/graphql.service';
import { BaseComponent } from '@shared/components/base-component';
import { SnackBarState } from '@shared/enums/snack-bar-state.enum';
import { takeUntil } from 'rxjs/operators';

const ERROR_MESSAGE: string = 'OrdersPage.CantGetOrders';

@Component({
  selector: 'bnl-orders-history-page-page',
  templateUrl: './orders-history-page.component.html',
  styleUrls: ['./orders-history-page.component.scss'],
})
export class OrdersHistoryPageComponent extends BaseComponent implements OnInit {
  readonly ORDERS_LOADER = 'fetching-orders-loader';
  private lastScrollPos: number;
  orders: BusRentGraphql[] = [];
  selectedOrder: BusRentGraphql;
  isMapPreviewOpen = false;

  constructor(private cd: ChangeDetectorRef, public ordersService: OrdersService) {
    super();
  }

  ngOnInit() {
    this.getOrdersHistory();
  }

  get isNoResults(): boolean {
    return !this.orders.length;
  }

  getOrdersHistory(): void {
    this.spinner.show(this.ORDERS_LOADER);
    this.isRequestPending = true;
    this.ordersService
      .getHistoryRentRequests()
      .pipe(takeUntil(this.destroyed))
      .subscribe(
        (results: CategorizedBusRentsGraphql) => {
          this.orders = results.history;
          this.cd.detectChanges();
        },
        () => this.snackbar.open(this.t.instant(ERROR_MESSAGE), SnackBarState.ERROR),
        () => {
          this.isRequestPending = false;
          this.spinner.hide(this.ORDERS_LOADER);
        }
      );
  }

  onFullMapOpen(order: BusRentGraphql) {
    this.selectedOrder = order;
    this.isMapPreviewOpen = true;
    this.lastScrollPos = window.scrollY;
    window.scrollTo({ left: 0, top: 0, behavior: 'auto' });
  }

  onFullMapClose() {
    this.isMapPreviewOpen = false;
    this.cd.detectChanges();
    window.scrollTo({ left: 0, top: this.lastScrollPos, behavior: 'auto' });
  }
}
