import { animate, state, style, transition, trigger } from '@angular/animations';

export const FadeOutAnimation = [
  trigger('fadeOut', [
    state('void', style({opacity: 0})),
    transition(':leave', [
      animate(250)
    ])
  ])
];
