import { Directive, ElementRef, Input, OnDestroy, OnInit } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { filter } from "rxjs/operators";

@Directive({
  selector: "[customRouterLinkActive]",
})
export class CustomRouterLinkActiveDirective implements OnInit, OnDestroy {
  @Input() customRouterLinkMatchUrls: string[] = [];
  @Input() customRouterLinkMatchedClasses: string[] = [];

  private readonly subscription: Subscription = new Subscription();

  constructor(private element: ElementRef, private readonly router: Router) {}

  ngOnInit(): void {
    this.initializeSubscription();
  }

  private initializeSubscription(): void {
    const sub = this.router.events
      .pipe(
        filter(
          (event): event is NavigationEnd => event instanceof NavigationEnd
        )
      )
      .subscribe({
        next: (event: NavigationEnd) => this.handleUrlChanged(event.url),
      });
    this.subscription.add(sub);
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  private handleUrlChanged(newUrl: string): void {
    this.customRouterLinkMatchUrls.includes(newUrl)
      ? this.addClasses()
      : this.removeClasses();
  }

  private addClasses(): void {
    for (const className of this.customRouterLinkMatchedClasses)
      this.element?.nativeElement?.classList.add(className);
  }

  private removeClasses(): void {
    for (const className of this.customRouterLinkMatchedClasses)
      this.element?.nativeElement?.classList.remove(className);
  }
}
