<div class="bnl-ride-path bnl-ride-path-order">
  <ul class="bnl-ride-path-list">
    <li class="bnl-ride-path-list-item">
      <div class="bnl-ride-path-list-item-time">
        <span
          class="bnl-ride-path-list-item-time-hour bnl-ride-path-list-item-time-hour--active"
        >
          {{
            isReturn
              ? (order.returnDate | formatDate : "HH:mm")
              : (order.departureDate | formatDate : "HH:mm")
          }}
        </span>
        <span class="bnl-ride-path-list-item-time-duration"></span>
      </div>

      <div class="bnl-ride-path-list-item-length" *ngIf="rideTime">
        {{ rideTime }}
      </div>

      <div class="bnl-ride-path-list-item-path">
        <div class="bnl-ride-path-list-item-path-icon">
          <img
            src="/app/shared/assets/svg/ride-path-start.svg"
            [alt]="'Global.IconAlts.mapPin' | translate"
          />
        </div>
        <span class="bnl-ride-path-list-item-path-line"></span>
      </div>

      <div class="bnl-ride-path-list-item-station">
        <span class="bnl-ride-path-list-item-station-name">{{
          isReturn ? order.destination?.name : order.departure?.name
        }}</span>
      </div>
    </li>

    <li class="bnl-ride-path-list-item is-last">
      <div class="bnl-ride-path-list-item-time">
        <span
          class="bnl-ride-path-list-item-time-hour bnl-ride-path-list-item-time-hour--active"
        >
          {{ ordersService.getArrivalDateTime(order, "HH:mm", isReturn) }}
        </span>
        <span class="bnl-ride-path-list-item-time-duration"></span>
      </div>

      <div class="bnl-ride-path-list-item-path">
        <div class="bnl-ride-path-list-item-path-icon">
          <img
            src="/app/shared/assets/svg/ride-path-end.svg"
            [alt]="'Global.IconAlts.mapPin' | translate"
          />
        </div>
        <span class="bnl-ride-path-list-item-path-line"></span>
      </div>

      <div class="bnl-ride-path-list-item-station">
        <span class="bnl-ride-path-list-item-station-name">{{
          isReturn ? order.departure?.name : order.destination?.name
        }}</span>
      </div>

      <ng-content select="[mapButton]"></ng-content>
    </li>
  </ul>
</div>
