<div class="ticket-page" [class.ticket-page--mapPreview]="isMapPreviewOpen">
  <bnl-top-bar *ngIf="topBarConfig" [config]="topBarConfig" class="sticky-top d-md-none"></bnl-top-bar>

  <div class="background d-none d-md-block">
    <div class="container container--flat ticket-page-header">
      <h3 class="ticket-page-header-stations">
        {{ ticketCreator.selectedPathway.start.busStop.address }} -
        {{ ticketCreator.selectedPathway.end.busStop.address }}
      </h3>
      <h2 class="ticket-page-header-date">
        {{ moment(ticketCreator.selectedPathway.rideDate).format(config.TICKET_DATE_FORMAT) }}
      </h2>
      <bnl-ticket-preview [pathway]="ticketCreator.selectedPathway" (mapOpen)="onMapOpen()"></bnl-ticket-preview>
    </div>
  </div>

  <bnl-full-screen-map
    *ngIf="isMapPreviewOpen && ticketCreator.selectedPathway"
    class="ticket-page-map"
    [pathway]="ticketCreator.selectedPathway"
    (mapClose)="onMapClose()"
  >
    <bnl-button
      (click)="onMapClose()"
      class="ticket-page-mapAction-button"
      [text]="'Ticket.goBack' | translate"
      align="center"
      size="md"
    >
    </bnl-button>
  </bnl-full-screen-map>

  <div class="ticket-page-preview d-md-none">
    <bnl-ticket-preview
      [pathway]="ticketCreator.selectedPathway"
      (mapOpen)="onMapOpen()"
      class="d-md-none"
    ></bnl-ticket-preview>
  </div>

  <div class="ticket-page-form" [formGroup]="ticketForm" (ngSubmit)="onTicketFormSubmit()">
    <div class="ticket-page-form-part">
      <div class="ticket-page-form-part-header">
        <h3 class="ticket-page-form-part-header-title">{{ "Ticket.mainPassenger" | translate }}</h3>
        <bnl-passenger-type-select (updateAge)="setMainPassengerAge($event)"></bnl-passenger-type-select>
      </div>
      <div class="ticket-page-form-part-content">
        <bnl-info-box
          class="ticket-page-form-part-content-infobox"
          [textContent]="'Ticket.documentData' | translate"
        ></bnl-info-box>
        <div *ngIf="!auth.isSignedIn()" class="ticket-page-form-part-content-createAccount">
          <p
            class="ticket-page-form-part-content-createAccount-text"
            [innerHTML]="'Ticket.createAccount' | translate"
          ></p>
          <mat-slide-toggle
            (click)="auth.openAuthDialog(); $event.preventDefault()"
            class="ticket-page-form-part-content-createAccount-toggle"
            [checked]="auth.isSignedIn()"
          ></mat-slide-toggle>
        </div>
        <div class="ticket-page-form-group" [formGroup]="mainPassengerForm">
          <h4 class="ticket-page-form-group-title">{{ "Ticket.emailTarget" | translate }}</h4>
          <div
            class="ticket-page-form-group-input mb-3"
            [class.invalid]="FormsHelper.isTouchedAndInvalid(mainPassengerForm.get('email'))"
          >
            <gbxsoft-input
              [class.disabled]="isRequestPending"
              formControlName="email"
              [invalid]="FormsHelper.isTouchedAndInvalid(mainPassengerForm.get('email'))"
              [config]="{
                name: this.t.instant('Global.Inputs.email'),
                placeholder: this.t.instant('Global.Inputs.email'),
                type: GbxsoftInputTypes.TEXT,
                isRequired: true,
                updateOnFocusOut: true
              }"
            >
            </gbxsoft-input>
            <bnl-validation-error [control]="mainPassengerForm.get('email')"></bnl-validation-error>
          </div>
          <div
            class="ticket-page-form-group-input mb-3"
            [class.invalid]="FormsHelper.isTouchedAndInvalid(mainPassengerForm.get('name'))"
          >
            <gbxsoft-input
              [class.disabled]="isRequestPending"
              formControlName="name"
              [invalid]="FormsHelper.isTouchedAndInvalid(mainPassengerForm.get('name'))"
              [config]="{
                name: this.t.instant('Global.Inputs.firstName'),
                placeholder: this.t.instant('Global.Inputs.firstName'),
                type: GbxsoftInputTypes.TEXT,
                isRequired: true,
                updateOnFocusOut: true
              }"
            >
            </gbxsoft-input>
            <bnl-validation-error [control]="mainPassengerForm.get('name')"></bnl-validation-error>
          </div>
          <div
            class="ticket-page-form-group-input mb-3"
            [class.invalid]="FormsHelper.isTouchedAndInvalid(mainPassengerForm.get('lastName'))"
          >
            <gbxsoft-input
              [class.disabled]="isRequestPending"
              formControlName="lastName"
              [invalid]="FormsHelper.isTouchedAndInvalid(mainPassengerForm.get('lastName'))"
              [config]="{
                name: this.t.instant('Global.Inputs.lastName'),
                placeholder: this.t.instant('Global.Inputs.lastName'),
                type: GbxsoftInputTypes.TEXT,
                isRequired: true,
                updateOnFocusOut: true
              }"
            >
            </gbxsoft-input>
            <bnl-validation-error [control]="mainPassengerForm.get('lastName')"></bnl-validation-error>
          </div>
          <div
            class="ticket-page-form-group-input mb-3"
            [class.invalid]="FormsHelper.isTouchedAndInvalid(mainPassengerForm.get('phone'))"
          >
            <bnl-phone-input
              [class.disabled]="isRequestPending"
              [form]="mainPassengerForm"
              [invalid]="FormsHelper.isTouchedAndInvalid(mainPassengerForm.get('phone'))"
              phoneName="phone"
              phoneFlagName="phoneCountryCode"
              [inputConfig]="{
                name: this.t.instant('Global.Inputs.phoneNumber'),
                placeholder: this.t.instant('Global.Inputs.phoneNumber'),
                type: GbxsoftInputTypes.TEXT,
                isRequired: true
              }"
            >
            </bnl-phone-input>
            <bnl-validation-error [control]="mainPassengerForm.get('phone')"></bnl-validation-error>
          </div>

          <div
            *ngIf="!!isPickupNoteEnabled"
            class="ticket-page-form-group-input mb-3"
            [class.invalid]="FormsHelper.isTouchedAndInvalid(mainPassengerForm.get('pickupNote'))"
          >
            <h4 class="ticket-page-form-group-title">{{ "Ticket.examplePickup" | translate }}</h4>
            <gbxsoft-input
              [class.disabled]="isRequestPending"
              formControlName="pickupNote"
              [invalid]="FormsHelper.isTouchedAndInvalid(mainPassengerForm.get('pickupNote'))"
              [config]="{
                name: ticketCreator.selectedPathway.pathway.toAirport
                  ? this.t.instant('Global.Inputs.pickup')
                  : this.t.instant('Global.Inputs.pickupDestination'),
                placeholder: ticketCreator.selectedPathway.pathway.toAirport
                  ? this.t.instant('Global.Inputs.pickup')
                  : this.t.instant('Global.Inputs.pickupDestination'),
                type: GbxsoftInputTypes.TEXT,
                isRequired: true,
                updateOnFocusOut: true
              }"
            >
            </gbxsoft-input>
            <bnl-validation-error [control]="mainPassengerForm.get('pickupNote')"></bnl-validation-error>
          </div>
        </div>
      </div>
    </div>

    <div class="ticket-page-form-part" *ngFor="let passenger of additionalPassengersFormControls; index as i">
      <div class="ticket-page-form-part-header">
        <h3 class="ticket-page-form-part-header-title">{{ i + 1 }} {{ "Ticket.passenger" | translate }}</h3>
        <p class="ticket-page-form-part-header-desc">
          <span>{{ "Passenger.Age." + passenger.get("age").value | translate }}</span>
          <span *ngIf="passenger.get('age').value === AdditionalPassengerAgeEnum.Child">
            {{ ticketCreator.getDisplayAge(passenger.get("ageRange").value) }} {{ "Ticket.years" | translate }}
          </span>
        </p>
      </div>
      <div class="ticket-page-form-part-content">
        <div class="ticket-page-form-group" [formGroup]="passenger">
          <div
            class="ticket-page-form-group-input"
            [class.invalid]="FormsHelper.isTouchedAndInvalid(passenger.get('name'))"
          >
            <gbxsoft-input
              [class.disabled]="isRequestPending"
              formControlName="name"
              [invalid]="FormsHelper.isTouchedAndInvalid(passenger.get('name'))"
              [config]="{
                name: this.t.instant('Global.Inputs.firstName'),
                placeholder: this.t.instant('Global.Inputs.firstName'),
                type: GbxsoftInputTypes.TEXT,
                isRequired: true,
                updateOnFocusOut: true
              }"
            >
            </gbxsoft-input>
            <bnl-validation-error [control]="passenger.get('name')"></bnl-validation-error>
          </div>
          <div
            class="ticket-page-form-group-input"
            [class.invalid]="FormsHelper.isTouchedAndInvalid(passenger.get('lastName'))"
          >
            <gbxsoft-input
              [class.disabled]="isRequestPending"
              formControlName="lastName"
              [invalid]="FormsHelper.isTouchedAndInvalid(passenger.get('lastName'))"
              [config]="{
                name: this.t.instant('Global.Inputs.lastName'),
                placeholder: this.t.instant('Global.Inputs.lastName'),
                type: GbxsoftInputTypes.TEXT,
                isRequired: true,
                updateOnFocusOut: true
              }"
            >
            </gbxsoft-input>
            <bnl-validation-error [control]="passenger.get('lastName')"></bnl-validation-error>
          </div>
        </div>
      </div>
    </div>

    <div class="ticket-page-form-part">
      <div class="ticket-page-form-part-header d-none d-md-flex">
        <h3 class="ticket-page-form-part-header-title">{{ "TicketPage.additionalPassengers" | translate }}</h3>
        <p class="ticket-page-form-part-header-desc">{{ "Ticket.optional" | translate }}</p>
      </div>
      <div class="ticket-page-form-part-passengers">
        <img
          class="ticket-page-form-part-passengers-icon"
          src="/app/shared/assets/svg/passengers.svg"
          [alt]="'Global.IconAlts.passengersIcon' | translate"
        />
        <span class="ticket-page-form-part-passengers-title">{{ "Ticket.passengersCount" | translate }}: </span>
        <span class="ticket-page-form-part-passengers-count">
          {{ ticketCreator.getAdditionalPassengersCount(ticketCreator.additionalPassengers) }}
        </span>

        <bnl-button
          [class.disabled]="isRequestPending"
          (click)="onPassengersAdd()"
          class="ticket-page-form-part-passengers-button"
          size="sm"
          iconSrc="/app/shared/assets/svg/add-circle.svg"
          [iconAlt]="'Global.IconAlts.addIcon' | translate"
          [boldText]="passengerButtonLabel | translate"
          [rounded]="true"
        >
        </bnl-button>
      </div>
    </div>

    <div class="ticket-page-form-part">
      <div class="ticket-page-form-part-header">
        <h3 class="ticket-page-form-part-header-title">{{ "Ticket.invoice" | translate }}</h3>
        <p class="ticket-page-form-part-header-desc">{{ "Ticket.optional" | translate }}</p>
      </div>
      <div class="ticket-page-form-part-content">
        <div
          class="ticket-page-form-part-content-invoice"
          [class.ticket-page-form-part-content-invoice--expanded]="ticketForm.get('sendInvoice').value"
        >
          <mat-checkbox
            [class.disabled]="isRequestPending"
            class="ticket-page-form-part-content-invoice-checkbox"
            formControlName="sendInvoice"
          ></mat-checkbox>
          <p class="ticket-page-form-part-content-invoice-text">{{ "Ticket.toggleInvoice" | translate }}</p>
        </div>
        <div class="ticket-page-form-group" [class.d-none]="!ticketForm.get('sendInvoice').value">
          <h4 class="ticket-page-form-group-title">{{ "Ticket.invoiceData" | translate }}</h4>
          <div
            class="ticket-page-form-group-input"
            [class.invalid]="FormsHelper.isTouchedAndInvalid(ticketForm.get('invoiceCompanyName'))"
          >
            <gbxsoft-input
              [class.disabled]="isRequestPending"
              formControlName="invoiceCompanyName"
              [invalid]="FormsHelper.isTouchedAndInvalid(ticketForm.get('invoiceCompanyName'))"
              [config]="{
                name: this.t.instant('Global.Inputs.companyName'),
                placeholder: this.t.instant('Global.Inputs.companyName'),
                type: GbxsoftInputTypes.TEXT,
                isRequired: ticketForm.get('sendInvoice').value,
                updateOnFocusOut: true
              }"
            >
            </gbxsoft-input>
            <bnl-validation-error [control]="ticketForm.get('invoiceCompanyName')"></bnl-validation-error>
          </div>
          <div
            class="ticket-page-form-group-input"
            [class.invalid]="FormsHelper.isTouchedAndInvalid(ticketForm.get('invoiceTaxNumber'))"
          >
            <gbxsoft-input
              [class.disabled]="isRequestPending"
              formControlName="invoiceTaxNumber"
              [invalid]="FormsHelper.isTouchedAndInvalid(ticketForm.get('invoiceTaxNumber'))"
              [config]="{
                name: this.t.instant('Global.Inputs.nip'),
                placeholder: this.t.instant('Global.Inputs.nip'),
                type: GbxsoftInputTypes.TEXT,
                isRequired: ticketForm.get('sendInvoice').value,
                updateOnFocusOut: true
              }"
            >
            </gbxsoft-input>
            <bnl-validation-error [control]="ticketForm.get('invoiceTaxNumber')"></bnl-validation-error>
          </div>
          <div
            class="ticket-page-form-group-input"
            [class.invalid]="FormsHelper.isTouchedAndInvalid(ticketForm.get('invoiceAddress'))"
          >
            <gbxsoft-input
              [class.disabled]="isRequestPending"
              formControlName="invoiceAddress"
              [invalid]="FormsHelper.isTouchedAndInvalid(ticketForm.get('invoiceAddress'))"
              [config]="{
                name: this.t.instant('Global.Inputs.address'),
                placeholder: this.t.instant('Global.Inputs.companyAddressPlaceholder'),
                type: GbxsoftInputTypes.TEXT,
                isRequired: ticketForm.get('sendInvoice').value,
                updateOnFocusOut: true
              }"
            >
            </gbxsoft-input>
            <bnl-validation-error [control]="ticketForm.get('invoiceAddress')"></bnl-validation-error>
          </div>
        </div>
      </div>
    </div>

    <div class="ticket-page-form-part">
      <div class="ticket-page-form-part-header">
        <h3 class="ticket-page-form-part-header-title">{{ "Ticket.otherLuggage" | translate }}</h3>
        <p class="ticket-page-form-part-header-desc d-none d-md-block">{{ "Ticket.optional" | translate }}</p>
        <bnl-button
          *ngIf="ticketCreator.isBaggageAvailable"
          class="d-md-none"
          [class.disabled]="isRequestPending"
          (click)="onLuggageAdd()"
          size="sm"
          [iconSrc]="
            ticketCreator.getAdditionalLuggageCount(ticketCreator.additionalLuggage) > 0
              ? '/assets/svg/edit.svg'
              : '/app/shared/assets/svg/add-circle.svg'
          "
          [iconAlt]="
            ticketCreator.getAdditionalLuggageCount(ticketCreator.additionalLuggage) > 0
              ? ('Global.IconAlts.editIcon' | translate)
              : ('Global.IconAlts.addIcon' | translate)
          "
          [boldText]="
            ticketCreator.getAdditionalLuggageCount(ticketCreator.additionalLuggage) > 0
              ? ('Ticket.editLuggage' | translate)
              : ('Ticket.addLuggage' | translate)
          "
          [rounded]="true"
        >
        </bnl-button>
      </div>
      <div
        class="ticket-page-form-part-content"
        *ngIf="
          !WindowHelper.isMobile ||
          !ticketCreator.isBaggageAvailable ||
          ticketCreator.getAdditionalLuggageCount(ticketCreator.additionalLuggage) > 0
        "
      >
        <bnl-button
          *ngIf="ticketCreator.isBaggageAvailable"
          class="d-none d-md-inline-block"
          [class.disabled]="isRequestPending"
          (click)="onLuggageAdd()"
          size="sm"
          [iconSrc]="
            ticketCreator.getAdditionalLuggageCount(ticketCreator.additionalLuggage) > 0
              ? '/assets/svg/edit.svg'
              : '/app/shared/assets/svg/add-circle.svg'
          "
          [iconAlt]="
            ticketCreator.getAdditionalLuggageCount(ticketCreator.additionalLuggage) > 0
              ? ('Global.IconAlts.editIcon' | translate)
              : ('Global.IconAlts.addIcon' | translate)
          "
          [boldText]="
            ticketCreator.getAdditionalLuggageCount(ticketCreator.additionalLuggage) > 0
              ? ('Ticket.editLuggage' | translate)
              : ('Ticket.addLuggage' | translate)
          "
          [rounded]="true"
        >
        </bnl-button>

        <p *ngIf="!ticketCreator.isBaggageAvailable">{{ "Ticket.unavailableBaggage" | translate }}</p>

        <div
          class="ticket-page-form-part-luggage"
          *ngIf="ticketCreator.getAdditionalLuggageCount(ticketCreator.additionalLuggage) > 0"
        >
          <bnl-ticket-luggage-item
            *ngIf="ticketCreator.additionalLuggage.bikesCount > 0"
            class="ticket-page-form-part-luggage-item"
            [type]="LuggageType.BIKE"
            [count]="ticketCreator.additionalLuggage.bikesCount"
            [price]="ticketCreator.getAdditionalBikesPrice(ticketCreator.additionalLuggage) | price"
          >
          </bnl-ticket-luggage-item>

          <bnl-ticket-luggage-item
            *ngIf="ticketCreator.additionalLuggage.animalsCount > 0"
            class="ticket-page-form-part-luggage-item"
            [type]="LuggageType.ANIMAL"
            [count]="ticketCreator.additionalLuggage.animalsCount"
            [price]="ticketCreator.getAdditionalAnimalsPrice(ticketCreator.additionalLuggage) | price"
          >
          </bnl-ticket-luggage-item>

          <bnl-ticket-luggage-item
            *ngIf="ticketCreator.additionalLuggage.luggageCount > 0"
            class="ticket-page-form-part-luggage-item"
            [type]="LuggageType.LUGGAGE"
            [count]="ticketCreator.additionalLuggage.luggageCount"
            [price]="ticketCreator.getAdditionalLuggagePrice(ticketCreator.additionalLuggage) | price"
          >
          </bnl-ticket-luggage-item>
        </div>
      </div>
    </div>

    <div class="ticket-page-form-part">
      <div class="ticket-page-form-part-header">
        <h3 class="ticket-page-form-part-header-title">
          {{ "Ticket.comments" | translate }}
          <small class="ticket-page-form-part-header-subtitle">{{ "Ticket.commentsDescription" | translate }}</small>
        </h3>
        <p class="ticket-page-form-part-header-desc">{{ "Ticket.optional" | translate }}</p>
      </div>
      <div class="ticket-page-form-part-content">
        <div class="comments-wrapper">
          <gbxsoft-textarea
            formControlName="comments"
            [class.disabled]="isRequestPending"
            [config]="{
              name: '',
              placeholder: 'Global.Inputs.comments' | translate,
              type: GbxsoftInputTypes.TEXT
            }"
          >
          </gbxsoft-textarea>
          <bnl-validation-error [control]="ticketForm.get('comments')"></bnl-validation-error>
        </div>
      </div>
    </div>

    <div class="ticket-page-form-submit">
      <p class="ticket-page-form-submit-text">
        <span>{{ "Ticket.ticketFor" | translate }} </span>
        <span>{{ ticketCreator.getAdditionalPassengersCount(ticketCreator.additionalPassengers) + 1 }} </span>
        <span>{{
          ticketCreator.getAdditionalPassengersCount(ticketCreator.additionalPassengers) > 0
            ? ("Ticket.multiplePassengers" | translate)
            : ("Ticket.singlePassenger" | translate)
        }}</span>
      </p>
      <bnl-button
        class="ticket-page-form-submit-button"
        [showLoader]="isRequestPending"
        (click)="onTicketFormSubmit()"
        [text]="'Ticket.buyTicket' | translate"
        [boldText]="
          ticketCreator.getTotalTicketPrice(ticketCreator.additionalPassengers, ticketCreator.additionalLuggage) | price
        "
        align="between"
        size="md"
      >
      </bnl-button>
    </div>
  </div>
</div>
