<div class="bnl-snackbar">
  <div [ngSwitch]="data.state" class="bnl-snackbar-icon">
    <img *ngSwitchCase="SnackBarState.SUCCESS" src="/app/shared/assets/svg/snackbar-success.svg" alt="">
    <img *ngSwitchCase="SnackBarState.ERROR" src="/app/shared/assets/svg/snackbar-error.svg" alt="">
    <img *ngSwitchCase="SnackBarState.WARNING" src="/app/shared/assets/svg/snackbar-warning.svg" alt="">
    <img *ngSwitchCase="SnackBarState.INFO" src="/app/shared/assets/svg/snackbar-info.svg" alt="">
  </div>

  <span class="bnl-snackbar-message" [innerHTML]="data.message"></span>

  <button class="bnl-snackbar-close" (click)="snackBarRef.dismiss()">
    <img src="/app/shared/assets/svg/snackbar-close.svg" alt="">
  </button>
</div>
