import { Injectable } from "@angular/core";

@Injectable()
export class MapService {
  private readonly autocomplete: google.maps.places.AutocompleteService;

  constructor() {
    this.autocomplete = new google.maps.places.AutocompleteService();
  }

  public getAutocomplete(): google.maps.places.AutocompleteService {
    return this.autocomplete;
  }
}
