<div class="scanTicketDetails">
  <div class="scanTicketDetails-details">
    <div class="scanTicketDetails-row">
      <div class="scanTicketDetails-passenger">
        <p class="scanTicketDetails-passenger-main">
          {{ mainPassenger?.name }} {{ mainPassenger?.lastName }}
        </p>
        <p *ngIf="ticket.additionalPassengers.length - 1 > 0">
          &nbsp;+ {{ ticket.additionalPassengers.length - 1 }}
          {{ "Ticket.personShort" | translate }}
        </p>
      </div>
      <p class="scanTicketDetails-phone">
        <span>{{ mainPassenger?.phoneCountryCode }} </span>
        <span>{{ mainPassenger?.phone }}</span>
      </p>
    </div>

    <div class="scanTicketDetails-row">
      <p class="scanTicketDetails-bought">
        <span>{{ "ScanPage.bought" | translate }}: </span>
        <span>{{
          ticket.createdAt | formatDate : Config.DISPLAY_DATE_FORMAT
        }}</span>
      </p>
      <p class="scanTicketDetails-email">
        {{ mainPassenger?.email }}
      </p>
    </div>
  </div>

  <div class="scanTicketDetails-icon">
    <img
      src="/assets/svg/caret-right.svg"
      [alt]="'Global.IconAlts.continueIcon' | translate"
    />
  </div>
</div>
