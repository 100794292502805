import { animate, state, style, transition, trigger } from '@angular/animations';

export const FadeAnimation = [
  trigger('fade', [
    state('void', style({opacity: 0})),
    transition(':enter, :leave', [
      animate(250)
    ])
  ])
];
