import { ChangeDetectorRef, Component, HostListener } from '@angular/core';
import { BaseComponent } from '@shared/components/base-component';
import { TopBarConfig } from '@shared/interfaces/top-bar-config.interface';
import { Location } from '@angular/common';
import { debounce } from '@shared/decorators/debounce.decodator';
import { WindowHelper } from '@shared/helpers/window.helper';

@Component({
  selector: 'bnl-profile-edit-page',
  templateUrl: './profile-edit-page.component.html'
})
export class ProfileEditPageComponent extends BaseComponent {
  topBarConfig: TopBarConfig = {
    title: this.t.instant('ProfileEdit.title'),
    leftIcon: {
      src: '/app/shared/assets/svg/back.svg',
      alt: this.t.instant('Global.IconAlts.backIcon'),
      clickHandler: () => this.location.back()
    }
  };

  WindowHelper = WindowHelper;

  @HostListener('window:resize')
  @debounce(30)
  onWindowResize() {
    this.ref.detectChanges();
  }

  constructor(private location: Location, private ref: ChangeDetectorRef) {
    super();
  }
}
