import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { TicketsService } from "@core/services/tickets.service";
import { PathwaysListElementGraphql } from "@modules/graphql/graphql.service";

@Component({
  selector: "bnl-ride-path",
  templateUrl: "./ride-path.component.html",
  styleUrls: ["./ride-path.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RidePathComponent {
  @Input() pathway: PathwaysListElementGraphql;
  @Input() showOnlyEdgeStops = false;
  @Input() customClass: string;
  @Input() rideTime: string;

  constructor(public ticketsService: TicketsService) {}
}
