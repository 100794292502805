import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Config } from '@core/config';
import { TicketsService } from '@core/services/tickets.service';
import { DiscountGraphql, GetTicketByTokenQuery, PurchaseTicketByTokenMutation, TicketGraphql } from '@modules/graphql/graphql.service';
import { BaseComponent } from '@shared/components/base-component';
import { SnackBarState } from '@shared/enums/snack-bar-state.enum';
import { TicketStatus } from '@shared/enums/ticket-status.enum';
import { TopBarConfig } from '@shared/interfaces/top-bar-config.interface';
import { FetchResult } from 'apollo-link';
import { GraphQLError } from 'graphql';
import { finalize, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'bnl-summary-page',
  templateUrl: './summary-page.component.html',
  styleUrls: ['./summary-page.component.scss'],
})
export class SummaryPageComponent extends BaseComponent implements OnInit {
  topBarConfig: TopBarConfig;
  ticket: TicketGraphql;
  discountCode: DiscountGraphql = null;
  Config = Config;

  readonly SUMMARY_LOADER = 'summary-loader';

  isMapPreviewOpen = false;

  get totalPrice(): number {
    return this.ticket?.price;
  }

  constructor(private location: Location, private route: ActivatedRoute, private ticketsService: TicketsService) {
    super();
  }

  ngOnInit(): void {
    this.auth.onLogout.pipe(takeUntil(this.destroyed)).subscribe(() => {
      this.n.navigate('search');
    });

    this.isRequestPending = true;
    this.spinner.show(this.SUMMARY_LOADER);

    const ticketToken = this.route.snapshot.paramMap.get('ticketToken');
    this.ticketsService
      .getTicketByToken(ticketToken)
      .pipe(
        takeUntil(this.destroyed),
        finalize(() => {
          this.isRequestPending = false;
          this.spinner.hide(this.SUMMARY_LOADER);
        })
      )
      .subscribe(
        (res: FetchResult<GetTicketByTokenQuery>) => {
          this.setTicket(res.data.getTicketByToken as TicketGraphql, ticketToken);
        },
        (error) => {
          this.snackbar.open(this.t.instant('TicketPage.noSuchTicket'), SnackBarState.ERROR);
          this.n.navigate('search');
        }
      );
  }

  private setTicket(ticket: TicketGraphql, ticketToken: string): void {
    this.ticket = ticket as TicketGraphql;
    this.setupTopBar();
    if (this.ticket.status === TicketStatus.PURCHASED) {
      this.snackbar.open(this.t.instant('TicketPage.alreadyPurchased'), SnackBarState.SUCCESS);
      this.n.navigate('ticket-pdf', {}, { ticketToken: ticketToken });
    } else {
      this.setupTopBar();
    }
  }

  onTicketPurchase(): void {
    this.isRequestPending = true;
    this.ticketsService
      .purchaseTicketByToken(this.ticket.token)
      .pipe(takeUntil(this.destroyed))
      .subscribe({
        next: (res: FetchResult<PurchaseTicketByTokenMutation>) => {
          setTimeout(() => {
            window.location.replace(res.data.purchaseTicketByToken.paymentUrl);
          }, 500);
        },
        complete: () => (this.isRequestPending = false),
        error: (error) => {
          error.graphQLErrors.map((e: GraphQLError) => {
            this.snackbar.open(this.ticketsService.getTicketCreateErrorMessage(e), SnackBarState.ERROR);
          });
        },
      });
  }

  private setupTopBar(): void {
    const startBusStopName =
      this.ticket.departureData.start.busStop.name || this.ticket.departureData.start.busStop.address;
    const endBusStopName = this.ticket.departureData.end.busStop.name || this.ticket.departureData.end.busStop.address;
    const rideDate = this.moment(this.ticket.departureData.rideDate).format(this.config.TICKET_DATE_FORMAT);

    this.topBarConfig = {
      title: `${startBusStopName} - ${endBusStopName}`,
      subtitle: rideDate,
      leftIcon: {
        src: '/app/shared/assets/svg/back.svg',
        alt: this.t.instant('Global.IconAlts.backIcon'),
        clickHandler: () => (this.isMapPreviewOpen ? (this.isMapPreviewOpen = false) : this.location.back()),
      },
    };
  }

  onAddDiscountCode(discountCode: DiscountGraphql): void {
    this.discountCode = discountCode;
  }

  onUpdateTicket(ticket: TicketGraphql): void {
    this.setTicket(ticket, ticket.token);
  }
}
