import { Injectable } from '@angular/core';
import {
  AddReviewGQL,
  AddReviewMutation,
  AdditionalPassengerAgeRangeEnum,
  GetMyTicketsGQL,
  GetMyTicketsQuery,
  GetPaymentGQL,
  GetPaymentQuery,
  GetTicketByIdGQL,
  GetTicketByIdQuery,
  GetTicketByTokenGQL,
  GetTicketByTokenQuery,
  GetTicketsToReviewGQL,
  GetTicketsToReviewQuery,
  MarkTicketNotificationViewedGQL,
  MarkTicketNotificationViewedMutation,
  PathwaysListElementGraphql,
  PurchaseTicketByTokenGQL,
  PurchaseTicketByTokenMutation,
  RemoveTicketGQL,
  RemoveTicketMutation,
  TicketGraphql,
  TicketReviewInput,
} from '@modules/graphql/graphql.service';
import { CreateTicketError } from '@modules/tabs/search-tab/shared/enums/create-ticket-error.enum';
import { TranslateService } from '@ngx-translate/core';
import { Config } from '@shared/configs/config';
import { TicketStatus } from '@shared/enums/ticket-status.enum';
import { FetchResult } from 'apollo-link';
import { GraphQLError } from 'graphql';
import moment from 'moment';
import { BehaviorSubject, Observable, Subscriber } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class TicketsService {
  private readonly _historyTickets$: BehaviorSubject<TicketGraphql[]> = new BehaviorSubject<TicketGraphql[]>([]);
  readonly historyTickets$: Observable<TicketGraphql[]> = this._historyTickets$.asObservable();

  private readonly _paidTickets$: BehaviorSubject<TicketGraphql[]> = new BehaviorSubject<TicketGraphql[]>([]);
  readonly paidTickets$: Observable<TicketGraphql[]> = this._paidTickets$.asObservable();

  private readonly _unpaidTickets$: BehaviorSubject<TicketGraphql[]> = new BehaviorSubject<TicketGraphql[]>([]);
  readonly unpaidTickets$: Observable<TicketGraphql[]> = this._unpaidTickets$.asObservable();

  private readonly _loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  readonly loading$: Observable<boolean> = this._loading$.asObservable();

  get historyTicketsCollection(): TicketGraphql[] {
    return this._historyTickets$.getValue();
  }

  get unpaidTicketsCollection(): TicketGraphql[] {
    return this._unpaidTickets$.getValue();
  }

  get paidTicketsCollection(): TicketGraphql[] {
    return this._paidTickets$.getValue();
  }

  constructor(
    private t: TranslateService,
    private getMyTicketsGQL: GetMyTicketsGQL,
    private removeTicketGQL: RemoveTicketGQL,
    private addReviewGQL: AddReviewGQL,
    private purchaseTicketByTokenGQL: PurchaseTicketByTokenGQL,
    private getTicketByIdGQL: GetTicketByIdGQL,
    private getTicketByTokenGQL: GetTicketByTokenGQL,
    private getPaymentGQL: GetPaymentGQL,
    private markTicketNotificationViewedGQL: MarkTicketNotificationViewedGQL,
    private getTicketsToReviewGQL: GetTicketsToReviewGQL
  ) {}

  public removeTicket(ticketId: number): Observable<FetchResult<RemoveTicketMutation>> {
    return this.removeTicketGQL.mutate({ ticketId });
  }

  public purchaseTicketByToken(ticketToken: string): Observable<FetchResult<PurchaseTicketByTokenMutation>> {
    return this.purchaseTicketByTokenGQL.mutate({ ticketToken });
  }

  public getPayment(token: string): Observable<FetchResult<GetPaymentQuery>> {
    return this.getPaymentGQL.fetch(
      { token },
      {
        fetchPolicy: 'network-only',
      }
    );
  }

  public getTicketById(ticketId: number): Observable<FetchResult<GetTicketByIdQuery>> {
    return this.getTicketByIdGQL.fetch(
      { ticketId },
      {
        fetchPolicy: 'network-only',
      }
    );
  }

  public getTicketByToken(ticketToken: string): Observable<FetchResult<GetTicketByTokenQuery>> {
    return this.getTicketByTokenGQL.fetch(
      { ticketToken },
      {
        fetchPolicy: 'network-only',
      }
    );
  }

  public isHistoryTicket(ticket: TicketGraphql): boolean {
    const rideDate = new Date();
    const currentDay = rideDate.getDay();
    const daysDifference = (ticket.departureData.end.dayOfTheWeek + (7 - currentDay)) % 7;
    const departureHour = Math.floor(ticket.departureData.end.departureTime / 3600);
    const departureMinutes = Math.floor((ticket.departureData.end.departureTime % 3600) / 60);
    rideDate.setDate(rideDate.getDate() + daysDifference);
    rideDate.setHours(departureHour, departureMinutes, 60, 0);

    const momentArrivalDate = moment(rideDate);
    return momentArrivalDate.diff(moment(), 'millisecond') < 0;
  }

  public isRecentTicket(ticket: TicketGraphql): boolean {
    const momentArrivalDate = moment(ticket.departureData.rideDate)
      .startOf('day')
      .add(ticket.departureData.end.departureTime, 'seconds')
      .unix();
    const timeBound = moment(ticket.departureData.rideDate)
      .startOf('day')
      .add(ticket.departureData.end.departureTime, 'seconds')
      .add(24, 'hours')
      .unix();
    const now = moment().unix();
    return now >= momentArrivalDate && now <= timeBound;
  }

  public didDepartureTimePassed(date: string): boolean {
    return moment(date).diff(moment(), 'milliseconds') < 0;
  }

  public fetchProfileTickets(): Observable<void> {
    this._loading$.next(true);
    return new Observable<void>((subscriber: Subscriber<void>) => {
      this.getMyTicketsGQL.fetch({ query: { records: 999 } }, { fetchPolicy: 'network-only' }).subscribe(
        (res: FetchResult<GetMyTicketsQuery>) => {
          const unpaidTickets: TicketGraphql[] = [];
          const paidTickets: TicketGraphql[] = [];
          const historyTickets: TicketGraphql[] = [];

          res.data.getMyTickets.records.forEach((ticket: TicketGraphql) => {
            if (this.isHistoryTicket(ticket)) {
              return ticket.status === TicketStatus.PURCHASED ? historyTickets.push(ticket) : null;
            }

            switch (ticket.status) {
              case TicketStatus.CREATED: {
                return unpaidTickets.push(ticket);
              }

              case TicketStatus.PENDING: {
                return unpaidTickets.push(ticket);
              }

              case TicketStatus.PURCHASED: {
                return paidTickets.push(ticket);
              }
            }
          });

          this._unpaidTickets$.next(unpaidTickets);
          this._paidTickets$.next(paidTickets);
          this._historyTickets$.next(historyTickets);

          this._loading$.next(false);
          subscriber.next();
          subscriber.complete();
        },
        (error) => {
          this._loading$.next(false);
          subscriber.error(error);
          subscriber.complete();
        }
      );
    });
  }

  public getDisplayAge(age: AdditionalPassengerAgeRangeEnum): string {
    switch (age) {
      case AdditionalPassengerAgeRangeEnum.Age_0_4:
        return '0-4';
      case AdditionalPassengerAgeRangeEnum.Age_4_6:
        return '4-6';
      case AdditionalPassengerAgeRangeEnum.Age_6_18:
        return '6-18';
    }
  }

  public getDisplayDepartureTime(time: number): string {
    return moment().startOf('day').add(time, 'seconds').format(Config.DISPLAY_TIME_FORMAT);
  }

  public getDisplayRideDuration(pathway: PathwaysListElementGraphql): string {
    const rideDuration = pathway.end.departureTime - pathway.start.departureTime;
    return moment().startOf('day').add(rideDuration, 'seconds').format('H[h] m[min]');
  }

  public getArrivalDate(pathway: PathwaysListElementGraphql): string {
    const dayDiff = (pathway.end.dayOfTheWeek + 7 - pathway.start.dayOfTheWeek) % 7;
    const timeDiff = pathway.end.departureTime - pathway.start.departureTime;
    return moment(pathway.rideDate).add(dayDiff, 'days').add(timeDiff, 'seconds').format();
  }

  public getDisplayBusStopsNumber(pathway: PathwaysListElementGraphql): string {
    const numberOfStops = pathway.allDepartures.length - 2;

    if (numberOfStops === 0) {
      return `0 ${this.t.instant('Ticket.lotStops')}`;
    } else if (numberOfStops === 1) {
      return `1 ${this.t.instant('Ticket.oneStop')}`;
    } else if (numberOfStops < 5) {
      return `${numberOfStops} ${this.t.instant('Ticket.fewStops')}`;
    } else {
      return `${numberOfStops} ${this.t.instant('Ticket.lotStops')}`;
    }
  }

  public getTicketCreateErrorMessage(error: GraphQLError): string {
    switch (error.extensions.exception.type as CreateTicketError) {
      case CreateTicketError.DATE_FROM_PAST: {
        return this.t.instant('TicketPage.dateFromPast');
      }

      case CreateTicketError.NOT_ENOUGH_ANIMALS: {
        return this.t.instant('TicketPage.noMoreAnimals');
      }

      case CreateTicketError.NOT_ENOUGH_BIKES: {
        return this.t.instant('TicketPage.noMoreBikes');
      }

      case CreateTicketError.CANNOT_BUY_BAGGAGE: {
        return this.t.instant('TicketPage.baggageUnavailable');
      }

      case CreateTicketError.NOT_ENOUGH_SEATS: {
        return this.t.instant('TicketPage.noEnoughSeats');
      }

      default: {
        return this.t.instant('TicketPage.unableToBuy');
      }
    }
  }

  public addReview(input: TicketReviewInput): Observable<boolean> {
    return this.addReviewGQL
      .mutate({ input })
      .pipe(map((res: FetchResult<AddReviewMutation>) => res.data.addReview as boolean));
  }

  public markTicketNotificationViewed(id: number): Observable<boolean> {
    return this.markTicketNotificationViewedGQL
      .mutate({ id })
      .pipe(
        map(
          (res: FetchResult<MarkTicketNotificationViewedMutation>) => res.data.markTicketNotificationViewed as boolean
        )
      );
  }

  public updateTicketCollection(input: TicketReviewInput): void {
    const collection: TicketGraphql[] = this._historyTickets$.getValue();
    for (let index = 0; index < collection.length; index++) {
      if (collection[index].id == input.ticketId) {
        collection[index] = this.updateReview(collection[index], input.review);
        break;
      }
    }
    this._historyTickets$.next(collection);
  }

  public removeUnpaidTicketFromCollection(ticket: TicketGraphql): void {
    const collection: TicketGraphql[] = this._unpaidTickets$.getValue();
    for (let index = 0; index < collection.length; index++) {
      if (collection[index].token == ticket.token) {
        collection.splice(index, 1);
        break;
      }
    }
    this._unpaidTickets$.next(collection);
  }

  private updateReview(ticket: TicketGraphql, review: number): TicketGraphql {
    const newTicket = JSON.parse(JSON.stringify(ticket));
    newTicket.review = review;
    return newTicket;
  }

  public getTicketsToReview(): Observable<TicketGraphql[]> {
    return this.getTicketsToReviewGQL
      .fetch()
      .pipe(map((res: FetchResult<GetTicketsToReviewQuery>) => res.data.getTicketsToReview as TicketGraphql[]));
  }
}
