import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { OrdersService } from "@core/services/orders.service";
import { BusRentGraphql } from "@modules/graphql/graphql.service";

@Component({
  selector: "bnl-order-ride-path",
  templateUrl: "./order-ride-path.component.html",
  styleUrls: ["./order-ride-path.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderRidePathComponent {
  @Input() rideTime: string;
  @Input() order: BusRentGraphql;
  @Input() isReturn: false;

  constructor(public ordersService: OrdersService) {}
}
