<div class="gbxsoft-phonepicker" [class.gbxsoft-phonepicker--invalid]="invalid">
  <form [formGroup]="form" class="gbxsoft-phonepicker-form">
    <bnl-country-picker
      class="gbxsoft-phonepicker-form-picker"
      [phoneFlagName]="phoneFlagName"
      [form]="form"
      [emitter]="changesEmitter">
    </bnl-country-picker>
    <gbxsoft-input
      class="gbxsoft-phonepicker-form-input"
      formControlName="{{ phoneName }}"
      [config]="inputConfig"
      #input>
    </gbxsoft-input>
  </form>
</div>
