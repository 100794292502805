import { NgModule } from '@angular/core';
import { AuthService } from './shared/services/auth.service';
import { AuthDialogComponent } from './shared/components/auth-dialog/auth-dialog.component';
import { FormPartComponent } from './shared/components/auth-dialog/form-part/form-part.component';
import { ConfirmPartComponent } from './shared/components/auth-dialog/confirm-part/confirm-part.component';
import { SharedModule } from '@shared/shared.module';
import { AuthGuard } from '@core/modules/authorization/shared/guards/auth-guard';

@NgModule({
  declarations: [AuthDialogComponent, FormPartComponent, ConfirmPartComponent],
  imports: [SharedModule],
  exports: [AuthDialogComponent],
  providers: [AuthService, AuthGuard]
})
export class AuthorizationModule {
}
