import { ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";
import { BusRentGraphql, DepartureGraphql, PathwaysListElementGraphql } from "@modules/graphql/graphql.service";

const min: number = 1000000;
const max: number = 9999999;

@Component({
  selector: "bnl-map-ride-path",
  templateUrl: "./map-ride-path.component.html",
  styleUrls: ["./map-ride-path.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapRidePathComponent implements OnInit {
  @Input() pathway: PathwaysListElementGraphql;
  @Input() order: BusRentGraphql;

  ridePathPoints: google.maps.LatLng[];
  mapBounds: google.maps.LatLngBounds;

  readonly uniqueId: number = Math.floor(Math.random() * (max - min + 1) + min);

  ngOnInit(): void {
    if (!this.pathway?.allDepartures?.length && !this.order) return;
    setTimeout(() => this.initializeMap());
  }

  private initializeMap() {
    const myOptions: google.maps.MapOptions = {
      zoom: 7,
      mapTypeControl: false,
      streetViewControl: false,
      fullscreenControl: false,
    };
    const map = new google.maps.Map(document.getElementById(`map-${this.uniqueId}`), myOptions);
    const directionsService = new google.maps.DirectionsService();
    const directionsDisplay = new google.maps.DirectionsRenderer({ map: map, suppressMarkers: !!this.order });
    this.calculateAndDisplayRoute(directionsService, directionsDisplay, map);
  }

  private calculateAndDisplayRoute(
    directionsService: google.maps.DirectionsService,
    directionsDisplay: google.maps.DirectionsRenderer,
    map: google.maps.Map<HTMLElement>
  ) {
    directionsService.route(
      {
        origin: this.pathway ? this.prepareOrigin(this.pathway.allDepartures) : this.prepareOrderOrigin(),
        destination: this.pathway
          ? this.prepareDestination(this.pathway.allDepartures)
          : this.prepareOrderDestination(),
        waypoints: this.pathway ? this.prepareWaypoints(this.pathway.allDepartures) : null,
        travelMode: google.maps.TravelMode.DRIVING,
      },
      (response, status) => {
        if (status == google.maps.DirectionsStatus.OK) {
          directionsDisplay.setDirections(response);
          if (this.order) {
            const route = response.routes[0];
            addOrderCustomMarker(route.legs[0].start_location);
            for (let i = 0; i < route.legs.length; i++) {
              addOrderCustomMarker(route.legs[i].end_location);
            }
          }
        } else {
          window.alert("Directions request failed due to " + status);
        }
      }
    );
    const addOrderCustomMarker = (position: google.maps.LatLng) => {
      return new google.maps.Marker({
        icon: "/app/shared/assets/img/pin.png",
        position: position,
        map: map,
      });
    };
  }

  private prepareOrigin(departures: DepartureGraphql[]): google.maps.LatLng {
    return new google.maps.LatLng(departures[0].busStop.latitude, departures[0].busStop.longitude, false);
  }

  private prepareDestination(departures: DepartureGraphql[]): google.maps.LatLng {
    return new google.maps.LatLng(
      departures[departures.length - 1].busStop.latitude,
      departures[departures.length - 1].busStop.longitude
    );
  }

  private prepareWaypoints(departures: DepartureGraphql[]): google.maps.DirectionsWaypoint[] {
    const collection: google.maps.DirectionsWaypoint[] = [];
    if (departures?.length <= 2) return collection;
    const array = [...departures].slice(1, -1);
    for (const stop of array) {
      collection.push({
        location: new google.maps.LatLng(stop.busStop.latitude, stop.busStop.longitude),
      });
    }
    return collection;
  }

  private prepareOrderOrigin(): google.maps.LatLng {
    return new google.maps.LatLng(this.order.departure.lat, this.order.departure.lng, false);
  }

  private prepareOrderDestination(): google.maps.LatLng {
    return new google.maps.LatLng(this.order.destination.lat, this.order.destination.lng, false);
  }
}
