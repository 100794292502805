import { Injector, isDevMode } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LOCATION_INITIALIZED } from '@angular/common';
import { Config } from '@shared/configs/config';
import { MapsAPILoader } from '@agm/core';

export function appInitializerFactory(translate: TranslateService, mapsAPILoader: MapsAPILoader, injector: Injector) {
  return () => new Promise<any>((resolve: any) => {
    const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
    locationInitialized.then(async () => {
      const langToSet = Config.DEFAULT_LANGUAGE;
      translate.setDefaultLang(langToSet);

      try {
        await translate.use(langToSet).toPromise();
        if (isDevMode()) {
          console.log(`Successfully initialized '${langToSet}' language.`);
        }
      } catch (err) {
        if (isDevMode()) {
          console.error(`Problem with '${langToSet}' language initialization.`);
        }
      }

      try {
        await mapsAPILoader.load();
        if (isDevMode()) {
          console.log(`Successfully initialized maps api`);
        }
      } catch (err) {
        if (isDevMode()) {
          console.error(`Problem with initializing maps api`);
        }
      }

      resolve(null);
    });
  });
}
