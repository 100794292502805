import { Injectable } from "@angular/core";
import {
  END_REMAINING_TIME,
  ReservationTimerPipe,
} from "@shared/pipes/reservation-timer.pipe";
import * as moment from "moment";
import {
  BehaviorSubject,
  Observable,
  Subject,
  Subscription,
  timer,
} from "rxjs";
import { map, takeUntil, takeWhile } from "rxjs/operators";

const SCHEDULER = 1000;
const DEFAULT_REMAINING_TIME = "15:00";

@Injectable({
  providedIn: "root",
})
export class ReservationTimerService {
  private timer$: Subscription = new Subscription();
  private destroy$: Subject<void> = new Subject<void>();

  private readonly _remainingTime$: BehaviorSubject<string> =
    new BehaviorSubject<string>(DEFAULT_REMAINING_TIME);
  readonly remainingTime$: Observable<string> =
    this._remainingTime$.asObservable();

  private readonly _timerStopped$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  readonly timerStopped$: Observable<boolean> =
    this._timerStopped$.asObservable();

  constructor(private reservationTimerPipe: ReservationTimerPipe) {}

  startCountdown(reservationLiveTime: number): void {
    this.stopCountdown();

    this.timer$ = timer(0, SCHEDULER)
      .pipe(
        map((tick: number) => reservationLiveTime - tick * SCHEDULER - 1000),
        takeWhile((remainingTime: number) => remainingTime >= 0),
        takeUntil(this.destroy$)
      )
      .subscribe({
        next: (remainingTime: number) => {
          this._timerStopped$.next(false);
          const time = this.reservationTimerPipe.transform(remainingTime);
          this._remainingTime$.next(time);
        },
        complete: () => {
          this._remainingTime$.next(END_REMAINING_TIME);
          this._timerStopped$.next(true);
          this.stopCountdown();
        },
      });
  }

  public stopCountdown(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.timer$?.unsubscribe();
  }

  public getReservationTimeDiff(date: string): number {
    const targetDate = moment(date);
    const now = moment();
    return targetDate.diff(now).valueOf();
  }
}
