import { CustomRoutes } from '@shared/consts/custom-routes.const';
import { GlobalRoutes } from '@core/routes/global.routes';
import { SearchTabRoutes } from '@core/routes/search-tab.routes';
import { TicketsTabRoutes } from '@core/routes/tickets-tab.routes';
import { AccountTabRoutes } from '@core/routes/account-tab.routes';
import { JoinUsTabRoutes } from './routes/join-us-tab.routes';
import { BusRentalTabRoutes } from './routes/bus-rental-tab.routes';

export interface AppRoutes {
  [key: string]: AppRoute;
}

export interface AppRoute {
  routes: CustomRoutes;
  startPath: string;
}

export const Routes: AppRoutes = {
  global: {
    routes: GlobalRoutes,
    startPath: ''
  },
  search: {
    routes: SearchTabRoutes,
    startPath: 'search'
  },
  tickets: {
    routes: TicketsTabRoutes,
    startPath: 'tickets'
  },
  account: {
    routes: AccountTabRoutes,
    startPath: 'account'
  },
  joinUs: {
    routes: JoinUsTabRoutes,
    startPath: 'join-us'
  },
  busRental: {
    routes: BusRentalTabRoutes,
    startPath: 'bus-rental'
  }
};
