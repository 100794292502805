import { Injectable } from "@angular/core";
import {
  TicketGraphql,
  TicketReviewInput,
} from "@modules/graphql/graphql.service";
import { MatDialog } from "@angular/material/dialog";
import { RateYourRideDialogComponent } from "@shared/components/rate-your-ride-dialog/rate-your-ride-dialog.component";
import { TicketsService } from "../../../core/services/tickets.service";
import { SnackbarService } from "@shared/services/snackbar.service";
import { TranslateService } from "@ngx-translate/core";
import { SnackBarState } from "@shared/enums/snack-bar-state.enum";

const VALIDATE_ITEMS_INTERVAL_MS: number = 1000 * 60;

@Injectable({
  providedIn: "root",
})
export class TicketForReviewService {
  private collection: TicketGraphql[] = [];
  private interval: any;

  constructor(
    private readonly dialog: MatDialog,
    private readonly ticketsService: TicketsService,
    private readonly snackbarService: SnackbarService,
    private readonly translateService: TranslateService
  ) {}

  initializeService(): void {
    this.ticketsService.getTicketsToReview().subscribe({
      next: (tickets: TicketGraphql[]) => {
        tickets.forEach((t) => this.collection.push(t));
        this.initializeInterval();
      },
    });
  }

  terminateService(): void {
    this.clear();
    this.destroyInterval();
  }

  private clear(): void {
    this.collection = [];
  }

  private destroyInterval(): void {
    clearInterval(this.interval);
  }

  private initializeInterval(): void {
    clearInterval(this.interval);
    this.interval = setInterval(
      this.validateItems.bind(this),
      VALIDATE_ITEMS_INTERVAL_MS
    );
  }

  private validateItems(): void {
    if (this.collection.length === 0) {
      this.destroyInterval();
      return;
    }
    const ticket: TicketGraphql = this.collection.shift();
    this.showPopup(ticket);
  }

  private showPopup(ticket: TicketGraphql): void {
    if (ticket.review !== null) return;
    this.ticketsService.markTicketNotificationViewed(ticket.id).subscribe();
    const dialogRef = this.dialog.open(RateYourRideDialogComponent, {
      panelClass: "carrier-info-dialog",
      data: {
        ticket: ticket,
      },
    });
    dialogRef.afterClosed().subscribe((value) => {
      if (value === null || value === undefined) return;
      const inputData: TicketReviewInput = {
        review: value,
        ticketId: ticket.id,
      };
      this.showAddReviewSuccessMessage();
      this.ticketsService.addReview(inputData).subscribe();
      this.ticketsService.updateTicketCollection(inputData);
    });
  }

  private showAddReviewSuccessMessage(): void {
    this.snackbarService.open(
      this.translateService.instant("RideRating.Rate added"),
      SnackBarState.SUCCESS
    );
  }
}
