import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { AdditionalPassengerAgeEnum, AdditionalPassengerGraphql, TicketGraphql } from '@modules/graphql/graphql.service';
import { toCanvas } from 'qrcode';
import { Location } from '@angular/common';
import { environment } from '@environments/environment';
import { Config } from '@shared/configs/config';
import { TicketsService } from '@core/services/tickets.service';

@Component({
  selector: 'bnl-ticket-pdf-preview',
  templateUrl: './ticket-pdf-preview.component.html',
  styleUrls: ['./ticket-pdf-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TicketPdfPreviewComponent implements AfterViewInit {
  @Input() ticket: TicketGraphql;
  @ViewChild('qrCode', {read: ElementRef}) qrCodeRef: ElementRef;

  AdditionalPassengerAgeEnum = AdditionalPassengerAgeEnum;
  env = environment;
  config = Config;

  constructor(public location: Location, public ticketsService: TicketsService) {
  }

  ngAfterViewInit(): void {
    toCanvas(this.qrCodeRef.nativeElement, this.ticket.qrToken, {width: 152});
  }

  onTicketPdfDownload(): void {
    window.open(`${environment.ticketApi}/${this.ticket.token}/pdf`, '_blank');
  }

  get mainPassenger(): AdditionalPassengerGraphql {
    return this.ticket.additionalPassengers.find((passenger: AdditionalPassengerGraphql) => passenger.isMain);
  }
}
