import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'bnl-counter',
  templateUrl: './counter.component.html',
  styleUrls: ['./counter.component.scss']
})
export class CounterComponent {
  @Input() minVal: number;
  @Input() maxVal: number;

  @Input() currentVal: number;
  @Output() currentValChange: number;

  @Input() increaseDisabled = false;
  @Input() decreaseDisabled = false;

  @Output() increased = new EventEmitter<number>();
  @Output() decreased = new EventEmitter<number>();

  onIncrease(): void {
    if (this.currentVal < this.maxVal) {
      this.increased.emit(this.currentVal++);
    }
  }

  onDecrease(): void {
    if (this.currentVal > this.minVal) {
      this.decreased.emit(this.currentVal--);
    }
  }
}
