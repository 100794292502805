import { AfterViewInit, Component, Input, OnDestroy, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";

import { GbxsoftInputTypes } from "@form/src/lib/gbxsoft-input/gbxsoft-input.types";
import { TranslateService } from "@ngx-translate/core";
import { Config } from "@shared/configs/config";
import { SnackBarState } from "@shared/enums/snack-bar-state.enum";
import { DeviceHelper } from "@shared/helpers/device.helper";
import { WindowHelper } from "@shared/helpers/window.helper";
import { SnackbarService } from "@shared/services/snackbar.service";
import { DateButton, DlDateTimePickerChange } from "angular-bootstrap-datetimepicker";
import * as moment from "moment";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "app-date-time-picker",
  templateUrl: "./date-time-picker.component.html",
  styleUrls: ["./date-time-picker.component.scss"],
})
export class DateTimePickerComponent implements OnInit, AfterViewInit, OnDestroy {
  DeviceHelper = DeviceHelper;
  GbxsoftInputTypes = GbxsoftInputTypes;
  WindowHelper = WindowHelper;
  isCalendarOpen: boolean = false;
  public destroyed = new Subject<void>();

  public moment = moment;
  readonly HOURS_PICK_DELAY = 10;

  tripDisplayDate = new FormControl("");

  nativeDatetimePickerMinValue: string;
  nativeDatetimePickerControl = new FormControl("");

  @Input() form: FormGroup;
  @Input() controlName: string = "date";
  @Input() loading: boolean = false;
  @Input() placeholder: string = "SearchPage.date";

  constructor(public t: TranslateService, private snackbar: SnackbarService) {}

  ngOnInit() {
    this.setupDateTimePickers();
  }

  ngAfterViewInit(): void {
    this.nativeDatetimePickerControl.valueChanges.pipe(takeUntil(this.destroyed)).subscribe((date: string) => {
      this.handleDatePick(date);
    });
  }

  public onDatePick(event: DlDateTimePickerChange<any>): void {
    this.handleDatePick(event.value);
    this.isCalendarOpen = false;
  }

  dateTimeSelectFilter(dateButton: DateButton, viewName: string): boolean {
    if (viewName === "day") {
      return dateButton.value >= Date.now() - 1000 * 60 * 60 * 24;
    } else if (viewName === "hour") {
      return dateButton.value >= Date.now() - 1000 * 60 * 60 * 10;
    }
  }

  private handleDatePick(date: string) {
    if (!date) {
      this.form.get(this.controlName).setValue("");
      this.tripDisplayDate.setValue("");
      return;
    }

    let momentDate = this.moment(date);

    if (this.moment().diff(momentDate, "hours") > this.HOURS_PICK_DELAY) {
      this.snackbar.open(
        this.t.instant("SearchPage.dateTimeDelayError", { time: 10 }),
        SnackBarState.ERROR,
        undefined,
        "top"
      );

      momentDate = this.moment().subtract(this.HOURS_PICK_DELAY, "hours");
    }

    this.form.get(this.controlName).setValue(momentDate.format());
    this.tripDisplayDate.setValue(momentDate.format(Config.DISPLAY_DATETIME_FORMAT));
  }

  private setupDateTimePickers(): void {
    const momentDate = this.moment().subtract(this.HOURS_PICK_DELAY, "hours");
    this.nativeDatetimePickerMinValue = momentDate.format(Config.DATETIME_LOCAL_INPUT_FORMAT);
  }

  clearForm() {
    this.tripDisplayDate.setValue("");
  }

  ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
  }
}
