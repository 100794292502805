<div class="historyTicketsList">
  <ngx-spinner
    name="tickets-loader"
    class="historyTicketsList-loader"
    [fullScreen]="false"
    size="default"
    type="ball-clip-rotate"
    bdColor="rgba(0, 0, 0, .15)"
    color="#5051FF"
    *ngIf="loading$ | async"
  ></ngx-spinner>

  <bnl-empty-current-tickets
    class="historyTicketsList-empty"
    *ngIf="!(historyTickets$ | async)?.length && !(loading$ | async)"
    [title]="'ProfileTickets.noHistory' | translate"
    [description]="'ProfileTickets.emptyHistory' | translate"
    [buttonText]="'ProfileTickets.searchTicket' | translate"
  ></bnl-empty-current-tickets>

  <ng-container *ngIf="(historyTickets$ | async)?.length > 0 && !(loading$ | async)">
    <h3 class="historyTicketsList-title">
      {{ "ProfileTickets.HistoryTickets.subtitle" | translate }}
    </h3>

    <ul class="historyTicketsList-list">
      <li
        class="historyTicketsList-list-item"
        *ngFor="let ticket of historyTickets$ | async; index as i"
      >
        <bnl-paid-ticket
          [isHistoryTicket]="true"
          [ticket]="ticket"
          [background]="i % 2 === 0 ? 'white' : 'gray'"
          (mapPreviewOpen)="ticketMapService.openTicketMapPreview.emit($event)"
          (ticketOpen)="
            n.navigate('ticket-pdf', {}, { ticketToken: ticket.token })
          "
        ></bnl-paid-ticket>
      </li>
    </ul>
  </ng-container>
</div>
