<div class="ticket dialog">
  <bnl-top-bar
    [config]="topBarConfig"
    class="sticky-top d-md-none"
  ></bnl-top-bar>

  <div class="dialog-close d-none d-md-block">
    <img
      (click)="dialogRef.close()"
      src="/app/shared/assets/svg/close.svg"
      [alt]="'Global.IconAlts.closeIcon' | translate"
    />
  </div>

  <div class="dialog-content">
    <div class="ticket-details">
      <div class="company-details">
        <img *ngIf="hasLogo" [src]="companyLogo" alt="{{ companyName }} Logo" />
        <h3 class="ticket-subtitle">{{ companyName }}</h3>
        <p class="departure-date">
          {{ ticket.departureData.rideDate | formatDate : "dddd, D MMMM" }}
        </p>
        <bnl-ride-path
          [pathway]="departureData"
          [showOnlyEdgeStops]="true"
        ></bnl-ride-path>
      </div>
    </div>

    <div>
      <h2 class="ticket-title">
        {{ "RideRating.Rate your ride" | translate }}
      </h2>
      <bnl-ticket-rating
        [inputMode]="true"
        [disabled]="showLoader"
        (ticketRated)="onTicketRated($event)"
      ></bnl-ticket-rating>
    </div>

    <bnl-button
      class="ticket-backButton"
      [disabled]="newRating === null"
      (click)="submit()"
      buttonStyle="primary"
      [showLoader]="showLoader"
      [text]="'RideRating.Send opinion' | translate"
      align="center"
      size="md"
    >
    </bnl-button>
  </div>
</div>
