import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { PathwaysListElementGraphql } from '@modules/graphql/graphql.service';

@Component({
  selector: 'bnl-path-map-details',
  templateUrl: './path-map-details.component.html',
  styleUrls: ['./path-map-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PathMapDetailsComponent {
  @Input() pathway: PathwaysListElementGraphql;
  @Output() mapPreviewOpen = new EventEmitter<PathwaysListElementGraphql>();
}
