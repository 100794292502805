import { Component, Input } from "@angular/core";

export enum InfoAlertEnum {
  WARNING = "warning",
  INFO = "info",
  DANGER = "danger",
}

@Component({
  selector: "bnl-order-info-alert",
  templateUrl: "./order-info-alert.component.html",
  styleUrls: ["./order-info-alert.component.scss"],
})
export class OrderInfoAlertComponent {
  @Input() title: string;
  @Input() description: string;
  @Input() type: InfoAlertEnum = InfoAlertEnum.WARNING;

  InfoAlertEnum = InfoAlertEnum;
}
