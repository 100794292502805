<div class="ticketPdfPreview">
  <img
    style="max-width: 70%; width: 100%"
    class="ticketPdfPreview-brand"
    src="/app/shared/assets/svg/bnl-logo-pdf.svg"
    alt="Busynalotniska"
  />

  <div class="ticketPdfPreview-qrcode">
    <canvas #qrCode></canvas>
  </div>

  <p class="ticketPdfPreview-price">{{ "TicketPage.TicketPdf.price" | translate }}: {{ ticket.price | price }}</p>
  <p class="ticketPdfPreview-date">{{ ticket.departureData.rideDate | formatDate : config.DISPLAY_DATETIME_FORMAT }}</p>

  <div class="ticketPdfPreview-carrier">
    <div class="ticketPdfPreview-carrier-brand" *ngIf="ticket.departureData.pathway.company.logotypeUrl">
      <img
        [src]="env.companyLogotypes + '/' + ticket.departureData.pathway.company.logotypeUrl"
        [alt]="ticket.departureData.pathway.company.name"
      />
    </div>

    <div class="ticketPdfPreview-carrier-info">
      <p class="ticketPdfPreview-carrier-info-name">{{ ticket.departureData.pathway.company.name }}</p>

      <ul class="ticketPdfPreview-carrier-info-data">
        <li class="ticketPdfPreview-carrier-info-data-item" *ngIf="ticket.departureData.pathway.company.phone">
          <p class="ticketPdfPreview-carrier-info-data-item-key">{{ "TicketPage.TicketPdf.phone" | translate }}</p>
          <a
            class="ticketPdfPreview-carrier-info-data-item-val"
            [href]="'tel:' + ticket.departureData.pathway.company.phone"
          >
            {{ ticket.departureData.pathway.company.phone }}
          </a>
        </li>

        <li class="ticketPdfPreview-carrier-info-data-item" *ngIf="ticket.departureData.pathway.company.email">
          <p class="ticketPdfPreview-carrier-info-data-item-key">{{ "TicketPage.TicketPdf.email" | translate }}</p>
          <a
            class="ticketPdfPreview-carrier-info-data-item-val"
            [href]="'mailto:' + ticket.departureData.pathway.company.email"
          >
            {{ ticket.departureData.pathway.company.email }}
          </a>
        </li>

        <li class="ticketPdfPreview-carrier-info-data-item" *ngIf="ticket.departureData.pathway.company.webPage">
          <p class="ticketPdfPreview-carrier-info-data-item-key">{{ "TicketPage.TicketPdf.website" | translate }}</p>
          <a
            class="ticketPdfPreview-carrier-info-data-item-val"
            [href]="ticket.departureData.pathway.company.webPage"
            rel="noopener"
            target="_blank"
          >
            {{ ticket.departureData.pathway.company.webPage }}
          </a>
        </li>

        <li class="ticketPdfPreview-carrier-info-data-item" *ngIf="ticket.departureData.pathway.company.address">
          <p class="ticketPdfPreview-carrier-info-data-item-key">{{ "TicketPage.TicketPdf.address" | translate }}</p>
          <p class="ticketPdfPreview-carrier-info-data-item-val">
            {{ ticket.departureData.pathway.company.address }}
          </p>
        </li>
      </ul>
    </div>
  </div>

  <div class="ticketPdfPreview-path">
    <bnl-ride-path [pathway]="ticket.departureData" [showOnlyEdgeStops]="true"></bnl-ride-path>
  </div>

  <div class="ticketPdfPreview-passengers">
    <ul class="ticketPdfPreview-passengers-list">
      <li
        class="ticketPdfPreview-passengers-list-item"
        *ngIf="ticket.bikesCount + ticket.animalsCount + ticket.baggageCount > 0"
      >
        <div class="luggage">
          <p class="luggage-title">{{ "TicketPage.TicketPdf.luggage" | translate }}</p>
          <ul class="luggage-list">
            <li class="luggage-list-item" *ngIf="ticket.bikesCount > 0">
              <span class="luggage-list-item-key">{{ "TicketPage.TicketPdf.bikes" | translate }}</span>
              <span class="luggage-list-item-val">{{ ticket.bikesCount }}</span>
            </li>

            <li class="luggage-list-item" *ngIf="ticket.animalsCount > 0">
              <span class="luggage-list-item-key">{{ "TicketPage.TicketPdf.animals" | translate }}</span>
              <span class="luggage-list-item-val">{{ ticket.animalsCount }}</span>
            </li>

            <li class="luggage-list-item" *ngIf="ticket.baggageCount > 0">
              <span class="luggage-list-item-key">{{ "TicketPage.TicketPdf.additionalLuggage" | translate }}</span>
              <span class="luggage-list-item-val">{{ ticket.baggageCount }}</span>
            </li>
          </ul>
        </div>
      </li>
      <li class="ticketPdfPreview-passengers-list-item">
        <div class="passenger">
          <div class="passenger-info">
            <p class="passenger-info-name">{{ mainPassenger.name }}</p>
            <p class="passenger-info-age">
              <span>{{ "Passenger.Age." + mainPassenger.age | translate }}</span>
              <span *ngIf="mainPassenger.ageRange"> {{ ticketsService.getDisplayAge(mainPassenger.ageRange) }}</span>
            </p>
          </div>
          <ul class="passenger-contact">
            <li class="passenger-contact-item">
              <span class="passenger-contact-item-key">{{ "TicketPage.TicketPdf.phone" | translate }}</span>
              <a
                class="passenger-contact-item-val"
                [href]="
                  'tel:' + (mainPassenger.phoneCountryCode ? mainPassenger.phoneCountryCode : '') + mainPassenger.phone
                "
                >{{ mainPassenger.phoneCountryCode ? mainPassenger.phoneCountryCode : "" }}{{ mainPassenger.phone }}</a
              >
            </li>

            <li class="passenger-contact-item">
              <span class="passenger-contact-item-key">{{ "TicketPage.TicketPdf.email" | translate }}</span>
              <a class="passenger-contact-item-val" [href]="'mailto:' + mainPassenger.email">{{
                mainPassenger.email
              }}</a>
            </li>
            <li class="passenger-contact-item" *ngIf="ticket.comments">
              <span class="passenger-contact-item-key">{{ "TicketPage.TicketPdf.Comments" | translate }}</span>
              <a class="passenger-contact-item-val">{{ ticket.comments }}</a>
            </li>
          </ul>
        </div>
      </li>
      <ng-container *ngFor="let passenger of ticket.additionalPassengers">
        <li class="ticketPdfPreview-passengers-list-item" *ngIf="!passenger.isMain">
          <div class="passenger">
            <div class="passenger-info">
              <p class="passenger-info-name">{{ passenger.name }}</p>
              <p class="passenger-info-age">
                <span>{{ "Passenger.Age." + passenger.age | translate }}</span>
                <span *ngIf="passenger.ageRange">
                  {{ ticketsService.getDisplayAge(passenger.ageRange) }} {{ "Ticket.years" | translate }}</span
                >
              </p>
            </div>
          </div>
        </li>
      </ng-container>
    </ul>
  </div>

  <div class="ticketPdfPreview-actions">
    <bnl-button
      (click)="location.back()"
      class="ticketPdfPreview-actions-button"
      buttonStyle="secondary"
      size="md"
      [text]="'TicketPage.TicketPdf.goBack' | translate"
      align="center"
    >
    </bnl-button>

    <bnl-button
      (click)="onTicketPdfDownload()"
      class="ticketPdfPreview-actions-button"
      buttonStyle="primary"
      size="md"
      [text]="'TicketPage.TicketPdf.downloadPdf' | translate"
      align="center"
    >
    </bnl-button>
  </div>
</div>
