import { CustomRoutes } from '@shared/consts/custom-routes.const';
import { PageNotFundComponent } from '@shared/components/page-not-fund/page-not-fund.component';

export const GlobalRoutes: CustomRoutes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'search',
  },
  {
    path: 'search',
    name: 'search',
    loadChildren: () =>
      import('@modules/tabs/search-tab/search-tab.module').then(
        (m) => m.SearchTabModule
      ),
  },
  {
    path: 'tickets',
    name: 'tickets',
    loadChildren: () =>
      import('@modules/tabs/tickets-tab/tickets-tab.module').then(
        (m) => m.TicketsTabModule
      ),
  },
  {
    path: 'account',
    name: 'account',
    loadChildren: () =>
      import('@modules/tabs/account-tab/account-tab.module').then(
        (m) => m.AccountTabModule
      ),
  },
  {
    path: 'join-us',
    name: 'join-us',
    loadChildren: () =>
      import('@modules/tabs/join-us-tab/join-us-tab.module').then(
        (m) => m.JoinUsTabModule
      ),
  },
  {
    path: 'bus-rental',
    name: 'bus-rental',
    loadChildren: () =>
      import('@modules/tabs/bus-rental-tab/bus-rental-tab.module').then(
        (m) => m.BusRentalTabModule
      ),
  },
  {
    path: '404',
    component: PageNotFundComponent,
    data: { metaTitle: 'MetaTitle.404' },
  },
  { path: '**', redirectTo: '/404' },
];
