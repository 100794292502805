<div class="my-orders-page" [class.my-orders-page-map-preview]="isMapPreviewOpen">
  <div class="container container--rental">
    <div class="results-page-empty wow fadeIn" *ngIf="isNoResults && !isRequestPending">
      <div class="results-page-empty-icon">
        <img src="/app/shared/assets/svg/no-results.svg" alt="{{ 'Global.ImagesAlts.busStop' | translate }}" />
      </div>
      <p class="results-page-empty-title" [innerHTML]="'OrdersPage.Empty.title' | translate"></p>
      <p class="results-page-empty-desc" [innerHTML]="'OrdersPage.Empty.subtitleHistory' | translate"></p>
      <bnl-button
        (click)="router.navigate(['/bus-rental/order-bus'])"
        class="results-page-empty-back"
        [boldText]="'OrdersPage.Empty.orderBus' | translate"
        [iconAlt]="'IconAlts.searchIcon' | translate"
        size="sm"
        align="center"
        [rounded]="true"
      ></bnl-button>
    </div>

    <div class="results-page-content">
      <ngx-spinner
        class="results-page-results-loader"
        [name]="ORDERS_LOADER"
        [fullScreen]="false"
        size="default"
        type="ball-clip-rotate"
        bdColor="rgba(0, 0, 0, 0)"
        color="#5051FF"
      ></ngx-spinner>
    </div>

    <ng-container *ngIf="!isRequestPending && !isNoResults">
      <div class="orders-list">
        <div class="orders-list-item" *ngFor="let order of orders; let i = index">
          <bnl-bus-order-card
            [order]="order"
            [isConfirmed]="false"
            [isHistoryOrder]="true"
            [showOpenDetailsButton]="true"
            (onShowInvoiceEmit)="ordersService.downloadInvoice($event)"
            (mapPreviewOpen)="onFullMapOpen($event)"
          ></bnl-bus-order-card>
        </div>
      </div>
    </ng-container>
  </div>

  <bnl-full-screen-map
    *ngIf="isMapPreviewOpen && selectedOrder"
    class="results-page-map"
    [order]="selectedOrder"
    (mapClose)="onFullMapClose()"
  >
  </bnl-full-screen-map>
</div>
