import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TopBarConfig } from '@shared/interfaces/top-bar-config.interface';
import { BaseComponent } from '@shared/components/base-component';
import { getRange } from '@shared/utils/global.utils';
import { LuggageConfig } from '@modules/tabs/search-tab/shared/interfaces/luggage-config.interface';
import { TicketCreatorService } from '@modules/tabs/search-tab/shared/services/ticket-creator.service';

@Component({
  selector: 'bnl-add-passenger-dialog',
  templateUrl: './add-luggage-dialog.component.html',
  styleUrls: ['./add-luggage-dialog.component.scss']
})
export class AddLuggageDialogComponent extends BaseComponent implements OnInit {
  topBarConfig: TopBarConfig;
  getRange = getRange;

  additionalLuggage = JSON.parse(JSON.stringify(this.ticketCreator.additionalLuggage)) as LuggageConfig;

  constructor(
    public dialogRef: MatDialogRef<AddLuggageDialogComponent>,
    public ticketCreator: TicketCreatorService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    super();
  }

  ngOnInit(): void {
    this.setupTopBar();
  }

  onLuggageSubmit(): void {
    this.ticketCreator.additionalLuggage = this.additionalLuggage;
    this.dialogRef.close();
  }

  private setupTopBar(): void {
    this.topBarConfig = {
      title: this.t.instant('Ticket.Luggage.addLuggage'),
      isTransparent: true,
      leftIcon: {
        src: '/app/shared/assets/svg/close.svg',
        alt: this.t.instant('Global.IconAlts.closeIcon'),
        clickHandler: () => this.dialogRef.close()
      }
    };
  }
}
