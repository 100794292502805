import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'bnl-order-bus-page',
  templateUrl: './order-bus-page.component.html',
  styleUrls: ['./order-bus-page.component.scss'],
})
export class OrderBusPageComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
