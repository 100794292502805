<div [class]="'bnl-ride-path ' + customClass">
  <ul class="bnl-ride-path-list">
    <ng-container *ngFor="let departure of pathway?.allDepartures; index as i; last as isLast; first as isFirst">
      <li
        class="bnl-ride-path-list-item"
        [class.is-last]="isLast"
        *ngIf="!showOnlyEdgeStops || (showOnlyEdgeStops && i === 0) || i === pathway?.allDepartures.length - 1"
      >
        <div class="bnl-ride-path-list-item-time">
          <span class="bnl-ride-path-list-item-time-hour bnl-ride-path-list-item-time-hour--active">
            {{ ticketsService.getDisplayDepartureTime(departure.departureTime) }}
          </span>
          <span class="bnl-ride-path-list-item-time-duration"></span>
        </div>

        <div class="bnl-ride-path-list-item-length" *ngIf="rideTime && isFirst">{{ rideTime }}</div>

        <div class="bnl-ride-path-list-item-path">
          <div class="bnl-ride-path-list-item-path-icon">
            <img
              *ngIf="i === 0"
              src="/app/shared/assets/svg/ride-path-start.svg"
              [alt]="'Global.IconAlts.mapPin' | translate"
            />
            <img
              *ngIf="i > 0 && i < pathway.allDepartures.length - 1"
              src="/app/shared/assets/svg/ride-path-stop.svg"
              [alt]="'Global.IconAlts.mapPin' | translate"
            />
            <img
              *ngIf="i === pathway.allDepartures.length - 1"
              src="/app/shared/assets/svg/ride-path-end.svg"
              [alt]="'Global.IconAlts.mapPin' | translate"
            />
          </div>
          <span class="bnl-ride-path-list-item-path-line"></span>
        </div>

        <div class="bnl-ride-path-list-item-station">
          <span class="bnl-ride-path-list-item-station-name" *ngIf="departure.busStop.name">{{
            departure.busStop.name
          }}</span>
          <span class="bnl-ride-path-list-item-station-description">{{ departure.busStop.address }}</span>
        </div>

        <ng-content select="[mapButton]" *ngIf="isLast"></ng-content>
      </li>
    </ng-container>
  </ul>
</div>
