<div class="ticketsPage has-bottom-nav" [class.ticketsPage--mapPreview]="isMapPreviewOpen">
  <bnl-top-bar class="d-md-none" *ngIf="isMapPreviewOpen" [config]="topBarConfig"></bnl-top-bar>

  <div class="background">
    <div class="ticketsPage-mapHeader d-none d-md-block" *ngIf="isMapPreviewOpen">
      <h2 class="ticketsPage-mapHeader-stations">
        {{ pathwayPreview.start.busStop.name || pathwayPreview.start.busStop.address }},
        {{ pathwayPreview.start.busStop.city }} -
        {{ pathwayPreview.end.busStop.name || pathwayPreview.end.busStop.address }},
        {{ pathwayPreview.end.busStop.city }}
      </h2>
      <p class="ticketsPage-mapHeader-date">
        {{ pathwayPreview.rideDate | formatDate : config.TICKET_DATE_FORMAT }}
      </p>
    </div>

    <div class="ticketsPage-header" *ngIf="!isMapPreviewOpen">
      <h2 class="ticketsPage-header-title">{{ "ProfileTickets.title" | translate }}</h2>

      <nav mat-tab-nav-bar class="ticketsPage-header-tabs">
        <a
          class="ticketsPage-header-tabs-tab"
          mat-tab-link
          routerLinkActive="ticketsPage-header-tabs-tab--active"
          *ngFor="let tab of tabs"
          [routerLink]="[tab.link]"
        >
          <div class="ticketsPage-header-tabs-tab-inner">
            <p class="ticketsPage-header-tabs-tab-inner-text">
              {{ tab.name | translate }}
            </p>
          </div>
        </a>
      </nav>

      <!-- <div class="ticketsPage-header-tabs">
        <div class="ticketsPage-header-tabs-tab" routerLinkActive="ticketsPage-header-tabs-tab--active" [routerLink]="['list']">
          <div class="ticketsPage-header-tabs-tab-inner">
            <p class="ticketsPage-header-tabs-tab-inner-text">{{'ProfileTickets.CurrentTickets.title' | translate}}</p>
            <div class="ticketsPage-header-tabs-tab-inner-badge" *ngIf="(unpaidTickets$ | async)?.length"></div>
          </div>
        </div>
        <div class="ticketsPage-header-tabs-tab" routerLinkActive="ticketsPage-header-tabs-tab--active" [routerLink]="['history']">
          <div class="ticketsPage-header-tabs-tab-inner">
            <p class="ticketsPage-header-tabs-tab-inner-text">{{'ProfileTickets.HistoryTickets.title' | translate}}</p>
          </div>
        </div>
      </div> -->
    </div>
  </div>

  <bnl-full-screen-map
    class="ticketsPage-map"
    *ngIf="isMapPreviewOpen"
    [pathway]="pathwayPreview"
    (mapClose)="onMapClose()"
  >
  </bnl-full-screen-map>

  <div class="ticketsPage-content">
    <router-outlet></router-outlet>
  </div>
</div>
