import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { SearchService } from '@core/services/search.service';
import { TicketsService } from '@core/services/tickets.service';
import {
  BusStopGraphql,
  GetAirportsQuery,
  GetNearestPathwaysQuery,
  OrderTypes,
  PathwaysListElementGraphql,
  PathwaysSearchListInput,
} from '@modules/graphql/graphql.service';
import { SortField } from '@modules/tabs/search-tab/shared/enums/sort-field.enum';
import { SearchQueryParams } from '@modules/tabs/search-tab/shared/interfaces/search-query-params.interface';
import { PathwayWrapper } from '@modules/tabs/search-tab/shared/models/pathway-wrapper.model';
import { TicketCreatorService } from '@modules/tabs/search-tab/shared/services/ticket-creator.service';
import { BaseComponent } from '@shared/components/base-component';
import { debounce } from '@shared/decorators/debounce.decodator';
import { SnackBarState } from '@shared/enums/snack-bar-state.enum';
import { WindowHelper } from '@shared/helpers/window.helper';
import { TopBarConfig } from '@shared/interfaces/top-bar-config.interface';
import { FetchResult } from 'apollo-link';
import { CupertinoPane } from 'cupertino-pane';
import { finalize, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'bnl-results-page',
  templateUrl: './results-page.component.html',
  styleUrls: ['./results-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResultsPageComponent extends BaseComponent implements OnInit {
  SortField = SortField;
  WindowHelper = WindowHelper;

  private ridePathPane: CupertinoPane;
  selectedPathway: PathwaysListElementGraphql;

  searchParams: SearchQueryParams;
  searchedAirport: BusStopGraphql;

  foundPathways: PathwayWrapper[];
  foundPathwaysCounter = 0;

  topBarConfig: TopBarConfig;

  isRidePathPaneOpen = false;
  isMapPreviewOpen = false;
  private lastScrollPos: number;

  private topSearchDateRange: string;
  private botSearchDateRange: string;
  private fetchingNewerAirports = false;
  private isFirstLoad = true;

  // readonly FETCHING_EARLIER_PATHWAYS_LOADER = 'fetching-earlier-pathways-loader';
  readonly PATHWAYS_LOADER = 'fetching-pathways-loader';
  readonly NEWER_PATHWAYS_LOADER = 'fetching-newer-pathways-loader';

  constructor(
    public ticketsService: TicketsService,
    private searchService: SearchService,
    private ticketCreator: TicketCreatorService,
    private route: ActivatedRoute,
    private ref: ChangeDetectorRef
  ) {
    super();
  }

  @HostListener('window:resize')
  @debounce(30)
  onResize() {
    if (this.isRidePathPaneOpen) {
      this.onRidePathOpen(this.selectedPathway, false);
    }
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(event: any): void {
    const windowHeight = 'innerHeight' in window ? window.innerHeight : document.documentElement.offsetHeight;
    const body = document.body,
      html = document.documentElement;
    const docHeight = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    );
    const windowBottom = windowHeight + window.pageYOffset;
    if (windowBottom >= docHeight - 100) {
      this.loadMorePathways();
    }
  }

  ngOnInit(): void {
    this.setupSearchParams(this.route.snapshot.queryParamMap);
    this.setupDateSearchRanges();
    this.setupRidePathPane();
    this.setupTopBar();
    this.loadPathways();
  }

  loadMorePathways(callback?: Function): void {
    if (!this.fetchingNewerAirports) {
      this.onLoadNewerPathways(callback);
    }
  }

  onFilterChange(sortField: SortField): void {
    this.searchParams.sortField = sortField;
    this.foundPathways = undefined;
    this.loadPathways();
  }

  onRidePathOpen(pathway: PathwaysListElementGraphql, animated: boolean = true): void {
    this.selectedPathway = pathway;

    let safeAreaInsetBottomStyle = getComputedStyle(document.documentElement).getPropertyValue('--saib');
    const safeAreaInsetBottomStyleHeight = parseInt(safeAreaInsetBottomStyle.replace('px', ''));
    const constantHeights = 136;
    const totalHeight =
      constantHeights + this.selectedPathway.allDepartures.length * 60 + safeAreaInsetBottomStyleHeight;

    this.ridePathPane.destroy();
    this.ridePathPane = undefined;
    this.setupRidePathPane();

    // check if viewport is portrait view
    if (window.innerHeight > window.innerWidth) {
      this.ridePathPane.settings.breaks.top.height =
        totalHeight > window.innerHeight - 64 ? window.innerHeight - 64 : totalHeight;
    } else {
      this.ridePathPane.settings.breaks.top.height = window.innerHeight - 64;
    }

    this.ridePathPane.present({ animate: animated });
    this.isRidePathPaneOpen = true;
  }

  onFullMapOpen(pathway: PathwaysListElementGraphql) {
    this.selectedPathway = pathway;
    this.isMapPreviewOpen = true;
    this.lastScrollPos = window.scrollY;
    window.scrollTo({ left: 0, top: 0, behavior: 'auto' });
  }

  onFullMapClose() {
    this.isMapPreviewOpen = false;
    this.ref.detectChanges();
    window.scrollTo({ left: 0, top: this.lastScrollPos, behavior: 'auto' });
  }

  onTicketBuy(pathway: PathwaysListElementGraphql): void {
    if (this.isRequestPending || this.ticketsService.didDepartureTimePassed(pathway.rideDate)) {
      return;
    }

    this.isRequestPending = true;
    this.ticketCreator
      .initTicket(pathway)
      .pipe(
        takeUntil(this.destroyed),
        finalize(() => {
          this.isRequestPending = false;
        })
      )
      .subscribe(() => {
        this.n.navigate('ticket', {}, {}, true);
      });
  }

  // if in the future it was necessary to add loading older connections
  // onLoadEarlierPathways(): void {
  //   this.spinner.show(this.FETCHING_EARLIER_PATHWAYS_LOADER);
  //
  //   if (this.isFirstLoad) {
  //     this.topSearchDateRange = this.moment(this.topSearchDateRange).startOf('day').format();
  //   } else {
  //     this.topSearchDateRange = this.moment(this.topSearchDateRange).subtract(1, 'days').startOf('day').format();
  //   }
  //
  //   this.searchService.getPathways({
  //     ...this.getQueryInput(),
  //     dateFrom: this.topSearchDateRange,
  //     dateTo: this.isFirstLoad ? this.searchParams.dateFrom : this.moment(this.topSearchDateRange).endOf('day')
  //   }).subscribe((res: FetchResult<GetNearestPathwaysQuery>) => {
  //     this.foundPathways.unshift(...res.data.getNearestPathways.records as PathwaysListElementGraphql[]);
  //     this.spinner.hide(this.FETCHING_EARLIER_PATHWAYS_LOADER);
  //     this.isFirstLoad = false;
  //   });
  // }

  onLoadNewerPathways(callback?: Function): void {
    this.fetchingNewerAirports = true;
    this.spinner.show(this.NEWER_PATHWAYS_LOADER);
    this.botSearchDateRange = this.moment(this.botSearchDateRange).add(1, 'days').endOf('day').format();
    this.ref.detectChanges();
    this.searchService
      .getPathways({
        ...this.getQueryInput(false),
        dateFrom: this.moment(this.botSearchDateRange).startOf('day').format(),
        dateTo: this.botSearchDateRange,
      })
      .pipe(
        takeUntil(this.destroyed),
        finalize(() => {
          this.fetchingNewerAirports = false;
          this.spinner.hide(this.NEWER_PATHWAYS_LOADER);
        })
      )
      .subscribe(
        (res: FetchResult<GetNearestPathwaysQuery>) => {
          this.addResults(res.data.getNearestPathways.records as PathwaysListElementGraphql[]);
          callback ? callback() : null;
          this.ref.detectChanges();
        },
        () => {
          this.handleLoadingPathwaysError();
          this.ref.detectChanges();
        }
      );
  }

  private setupTopBar(): void {
    this.topBarConfig = {
      title: '',
      subtitle: this.moment(this.searchParams.dateFrom).format(this.config.TICKET_DATE_FORMAT),
      leftIcon: {
        src: '/app/shared/assets/svg/back.svg',
        alt: this.t.instant('Global.IconAlts.backIcon'),
        clickHandler: () => (!this.isMapPreviewOpen ? this.n.navigate('search') : this.onFullMapClose()),
      },
    };

    this.searchService
      .getAirports()
      .pipe(takeUntil(this.destroyed))
      .subscribe((results: FetchResult<GetAirportsQuery>) => {
        const airport = results.data.getAirports.find(
          (value) => value.id === this.searchParams.airportId
        ) as BusStopGraphql;
        this.searchedAirport = airport;
        this.topBarConfig.title = this.searchParams.toAirport
          ? `${this.searchParams.address} - ${airport.name}`
          : `${airport.name} - ${this.searchParams.address}`;
      });
  }

  private setupRidePathPane(): void {
    this.ridePathPane = new CupertinoPane('.ride-path-pane', {
      parentElement: '.results-page',
      buttonClose: false,
      backdrop: true,
      bottomClose: true,
      initialBreak: 'top',
      dragBy: ['.ride-path-pane-header'],
      breaks: {
        top: { enabled: true, height: window.innerHeight - 64 },
        middle: { enabled: false },
        bottom: { enabled: false },
      },
      onBackdropTap: () => {
        this.ridePathPane.hide();
        this.isRidePathPaneOpen = false;
        WindowHelper.enableBodyScroll();
      },
      onDidDismiss: () => {
        this.isRidePathPaneOpen = false;
        WindowHelper.enableBodyScroll();
      },
    });
  }

  private setupDateSearchRanges(): void {
    this.topSearchDateRange = this.searchParams.dateFrom;
    this.botSearchDateRange = this.moment(this.searchParams.dateFrom).endOf('day').format();
  }

  private setupSearchParams(queryParamMap: ParamMap): void {
    this.searchParams = {
      lat: Number(queryParamMap.get('lat')),
      lng: Number(queryParamMap.get('lng')),
      address: queryParamMap.get('address'),
      airportId: Number(queryParamMap.get('airportId')),
      dateFrom: queryParamMap.get('dateFrom'),
      toAirport: queryParamMap.get('toAirport') === 'true',
      page: Number(queryParamMap.get('page')),
      records: Number(queryParamMap.get('records')),
      search: queryParamMap.get('search'),
      sortDirection: queryParamMap.get('sortDirection') as OrderTypes,
      sortField: queryParamMap.get('sortField') || SortField.TIME,
    };
  }

  private loadPathways(): void {
    const recursiveLoad = () => {
      setTimeout(() => {
        const container = document.querySelector('.results-page-results');
        const content = document.querySelector('.results-page-content');
        if (container.scrollHeight - 100 > content.clientHeight) {
          this.loadMorePathways(recursiveLoad);
        }
      }, 100);
    };

    this.isRequestPending = true;
    this.spinner.show(this.PATHWAYS_LOADER);
    this.ref.detectChanges();
    this.searchService
      .getPathways(this.getQueryInput(true))
      .pipe(
        takeUntil(this.destroyed),
        finalize(() => {
          this.isFirstLoad = false;
          this.spinner.hide(this.PATHWAYS_LOADER);
          this.isRequestPending = false;
        })
      )
      .subscribe(
        (pathways: FetchResult<GetNearestPathwaysQuery>) => {
          this.addResults(pathways.data.getNearestPathways.records as PathwaysListElementGraphql[]);
          recursiveLoad();
          this.ref.detectChanges();
        },
        () => {
          this.handleLoadingPathwaysError();
          this.ref.detectChanges();
        }
      );
  }

  private addResults(records: PathwaysListElementGraphql[], prepend: boolean = false): void {
    if (!this.foundPathways) {
      this.foundPathways = [];
    }
    if (records.length) {
      records.forEach((pathway: PathwaysListElementGraphql) => {
        let isSeparatorVisible: boolean;

        if (this.foundPathways.length === 0) {
          isSeparatorVisible = false;
        } else {
          const prevDate = this.moment(this.foundPathways.slice(-1)[0].pathway.rideDate).get('date');
          const currentDate = this.moment(pathway.rideDate).get('date');
          isSeparatorVisible = prevDate !== currentDate;
        }

        // show last path based od end path from all departures
        const customPathway = Object.assign({}, pathway);
        const lastPathwayIndex = customPathway.allDepartures.findIndex(departure => departure.id === pathway.end.id);
        if (lastPathwayIndex !== -1) {
          customPathway.allDepartures = customPathway.allDepartures.slice(0, lastPathwayIndex + 1);
        }

        this.foundPathways.push(new PathwayWrapper(customPathway, isSeparatorVisible));
        this.foundPathwaysCounter = this.foundPathways.filter((item) => !item.isEmpty).length;
      });
    } else {
      const pathway: PathwaysListElementGraphql = {
        availableSeats: 0,
        seatsLimit: 0,
        price: 0,
        rideDate: this.botSearchDateRange,
        saleEnabled: true,
      };
      this.foundPathways.push(new PathwayWrapper(pathway, true, true));
    }
  }

  private handleLoadingPathwaysError(): void {
    this.snackbar.open(this.t.instant('ResultsPage.paramsError'), SnackBarState.ERROR);
    this.n.navigate('search', {}, {}, true);
  }

  private getQueryInput(loadEarlierDepartures: boolean = false): PathwaysSearchListInput {
    return {
      loadEarlierDepartures: loadEarlierDepartures,
      localization: {
        address: this.searchParams.address,
        latitude: this.searchParams.lat,
        longitude: this.searchParams.lng,
      },
      dateTo: this.botSearchDateRange,
      dateFrom: this.searchParams.dateFrom,
      toAirport: this.searchParams.toAirport,
      airportId: this.searchParams.airportId,
      sortField: this.searchParams.sortField,
      saveHistory: this.isFirstLoad,
      records: 99999,
    };
  }

  get isNoResults(): boolean {
    return !!this.foundPathways && this.foundPathways.length === 0;
  }
}
