<div class="order-card-payments">
  <div class="order-card-payments-order-info">
    <!-- <div class="order-card-payments-order-info-date">
      {{ order?.departureDate | formatDate : Config.ORDER_DATE_FORMAT }}
    </div> -->
  </div>
  <div class="order-card-payments-prices">
    <div class="order-card-payments-prices-item">
      <div class="order-card-payments-prices-item-label">
        {{ "OrdersPage.PricePaid" | translate }}:
      </div>
      <div class="order-card-payments-prices-item-value">
        {{ order?.offer.totalAmountPaid | price }}
      </div>
    </div>
    <div class="order-card-payments-prices-item">
      <div class="order-card-payments-prices-item-label">
        {{ "OrdersPage.PriceLeft" | translate }}:
      </div>
      <div class="order-card-payments-prices-item-value">
        {{ order?.offer.price - order.offer.totalAmountPaid | price }}
      </div>
    </div>
    <div class="order-card-payments-prices-item">
      <div class="order-card-payments-prices-item-label">
        {{ "OrdersPage.TotalPrice" | translate }}:
      </div>
      <div class="order-card-payments-prices-item-value">
        {{ order?.offer.price | price }}
      </div>
    </div>
  </div>
</div>
