import { OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "@core/modules/authorization/shared/services/auth.service";
import { NavigateService } from "@core/services/navigate.service";
import { TranslateService } from "@ngx-translate/core";
import { SnackbarService } from "@shared/services/snackbar.service";
import * as moment from "moment";
import { NgxSpinnerService } from "ngx-spinner";
import { Subject } from "rxjs";
import scrollIntoView from "smooth-scroll-into-view-if-needed";
import { Config } from "../configs/config";
import { AppInjectorService } from "../services/app-injector.service";

export class BaseComponent implements OnDestroy {
  private readonly injector = AppInjectorService.getInjector();

  public moment = moment;
  public config = Config;
  public scrollIntoView = scrollIntoView;
  public t: TranslateService;
  public n: NavigateService;
  public auth: AuthService;
  public router: Router;
  public spinner: NgxSpinnerService;
  public snackbar: SnackbarService;

  public isRequestPending: boolean;
  public destroyed = new Subject<void>();

  constructor() {
    this.initServices();
  }

  private initServices(): void {
    this.t = this.injector.get(TranslateService);
    this.n = this.injector.get(NavigateService);
    this.auth = this.injector.get(AuthService);
    this.router = this.injector.get(Router);
    this.spinner = this.injector.get(NgxSpinnerService);
    this.snackbar = this.injector.get(SnackbarService);
  }

  ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
  }
}
