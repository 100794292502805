import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { FormsHelper } from '@shared/helpers/forms.helper';

@Component({
  selector: 'bnl-validation-error',
  templateUrl: './validation-error.component.html',
  styleUrls: ['./validation-error.component.scss']
})
export class ValidationErrorComponent {
  @Input() control: AbstractControl;
  FormsHelper = FormsHelper;
}
