<div class="summary-page" [class.summary-page--mapPreview]="isMapPreviewOpen">
  <bnl-top-bar *ngIf="topBarConfig" [config]="topBarConfig" class="sticky-top d-md-none"></bnl-top-bar>

  <div class="background d-none d-md-block">
    <div class="summary-page-header container container--flat" *ngIf="ticket">
      <h3 class="summary-page-header-stations">
        {{ ticket.departureData.start.busStop.name || ticket.departureData.start.busStop.address }} -
        {{ ticket.departureData.end.busStop.name || ticket.departureData.end.busStop.address }}
      </h3>
      <h2 class="summary-page-header-date">
        {{ ticket.departureData.rideDate | formatDate : config.TICKET_DATE_FORMAT }}
      </h2>
    </div>
  </div>

  <bnl-full-screen-map
    *ngIf="ticket && isMapPreviewOpen"
    class="summary-page-map"
    [pathway]="ticket.departureData"
    (mapClose)="isMapPreviewOpen = false"
  >
    <bnl-button
      (click)="isMapPreviewOpen = false"
      class="ticket-page-mapAction-button"
      [text]="'Ticket.goBack' | translate"
      align="center"
      size="md"
    >
    </bnl-button>
  </bnl-full-screen-map>

  <div class="summary-page-content">
    <bnl-ticket-preview
      *ngIf="ticket"
      class="summary-page-preview d-none d-md-block"
      [pathway]="ticket?.departureData"
      (mapOpen)="isMapPreviewOpen = true"
    ></bnl-ticket-preview>

    <div class="summary-page-part summary-page-path">
      <bnl-pathway-summary
        *ngIf="ticket"
        [ticket]="ticket"
        (mapOpen)="isMapPreviewOpen = true"
      ></bnl-pathway-summary>
      <ngx-spinner
        [name]="SUMMARY_LOADER"
        [fullScreen]="false"
        size="default"
        type="ball-clip-rotate"
        bdColor="#FFF"
        color="#5051FF"
      ></ngx-spinner>
    </div>

    <div class="summary-page-part summary-page-passengers">
      <bnl-passengers-summary *ngIf="ticket" [passengers]="ticket.additionalPassengers"></bnl-passengers-summary>
      <ngx-spinner
        [name]="SUMMARY_LOADER"
        [fullScreen]="false"
        size="default"
        type="ball-clip-rotate"
        bdColor="#FFF"
        color="#5051FF"
      ></ngx-spinner>
    </div>

    <div class="summary-page-part summary-page-payment">
      <bnl-payment-summary *ngIf="ticket"></bnl-payment-summary>
      <ngx-spinner
        [name]="SUMMARY_LOADER"
        [fullScreen]="false"
        size="default"
        type="ball-clip-rotate"
        bdColor="#FFF"
        color="#5051FF"
      ></ngx-spinner>
    </div>

    <div class="summary-page-part summary-page-price">
      <bnl-price-summary
        *ngIf="ticket"
        [ticket]="ticket"
        (addDiscountCode)="onAddDiscountCode($event)"
        (updateTicket)="onUpdateTicket($event)"
      ></bnl-price-summary>
      <ngx-spinner
        [name]="SUMMARY_LOADER"
        [fullScreen]="false"
        size="default"
        type="ball-clip-rotate"
        bdColor="rgba(0, 0, 0, 0)"
        color="#5051FF"
      ></ngx-spinner>
    </div>

    <div class="summary-page-part summary-page-submit" *ngIf="ticket">
      <bnl-button
        (click)="onTicketPurchase()"
        [showLoader]="isRequestPending"
        class="summary-page-submit-button"
        [text]="'Ticket.Summary.pay' | translate"
        [boldText]="totalPrice | price"
        size="md"
        align="between"
      >
      </bnl-button>
      <p class="summary-page-submit-description">
        {{ "Ticket.Summary.byClickAccept" | translate }}
        <a title="{{ 'Ticket.Summary.regulations' | translate }}" (click)="n.navigate('regulations')">{{
          "Ticket.Summary.regulations" | translate
        }}</a>
        {{ "Ticket.Summary.and" | translate }}
        <a
          class="bnl-footer-links-item"
          title="{{ 'Ticket.Summary.privacyPolicy' | translate }}"
          (click)="n.navigate('privacy-policy')"
          >{{ "Ticket.Summary.privacyPolicy" | translate }}</a
        >
      </p>
    </div>
  </div>
</div>
