import { EventEmitter, Injectable } from '@angular/core';

@Injectable()
export class SplashScreenService {
  /**
   * @description emits true if splash screen turns into visible state
   * and emits false on invisible state
   */
  public splashScreenStateChanged = new EventEmitter<boolean>();

  public showSplashScreen(): void {
    this.splashScreenStateChanged.emit(true);
  }

  public hideSplashScreen(): void {
    this.splashScreenStateChanged.emit(false);
  }
}
