import { Location } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { TicketsService } from '@core/services/tickets.service';
import { GbxsoftInputTypes } from '@form/src/lib/gbxsoft-input/gbxsoft-input.types';
import {
  AdditionalPassengerAgeEnum,
  CreateTicketMutation,
  PassengerProfileInput,
  ProfileUpdateMutation,
  TicketInput,
} from '@modules/graphql/graphql.service';
import { AddLuggageDialogComponent } from '@modules/tabs/search-tab/shared/components/add-luggage-dialog/add-luggage-dialog.component';
import { AddPassengerDialogComponent } from '@modules/tabs/search-tab/shared/components/add-passenger-dialog/add-passenger-dialog.component';
import { LuggageType } from '@modules/tabs/search-tab/shared/enums/luggage-type.enum';
import { AdditionalPassenger } from '@modules/tabs/search-tab/shared/models/additional-passenger.model';
import { TicketCreatorService } from '@modules/tabs/search-tab/shared/services/ticket-creator.service';
import { BaseComponent } from '@shared/components/base-component';
import { ConfirmDialogComponent } from '@shared/components/confirm-dialog/confirm-dialog.component';
import { Config } from '@shared/configs/config';
import { SnackBarState } from '@shared/enums/snack-bar-state.enum';
import { FormsHelper } from '@shared/helpers/forms.helper';
import { handlePhoneNumberIllegalChars } from '@shared/helpers/phone-number-replace.helper';
import { WindowHelper } from '@shared/helpers/window.helper';
import { ConfirmDialogData } from '@shared/interfaces/confirm-dialog-input.interface';
import { TopBarConfig } from '@shared/interfaces/top-bar-config.interface';
import { StorageService } from '@shared/services/storage.service';
import { getRange } from '@shared/utils/global.utils';
import { CustomValidators } from '@shared/validators/custom-validators';
import { FetchResult } from 'apollo-link';
import { GraphQLError } from 'graphql';
import { Subscription } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';

const COMMENT_MAX_LENGTH: number = 255;

@Component({
  selector: 'bnl-ticket-page',
  templateUrl: './ticket-page.component.html',
  styleUrls: ['./ticket-page.component.scss'],
})
export class TicketPageComponent extends BaseComponent implements OnInit, OnDestroy {
  GbxsoftInputTypes = GbxsoftInputTypes;
  AdditionalPassengerAgeEnum = AdditionalPassengerAgeEnum;
  WindowHelper = WindowHelper;
  LuggageType = LuggageType;
  FormsHelper = FormsHelper;
  topBarConfig: TopBarConfig;

  private readonly subscription: Subscription = new Subscription();

  ticketForm = this.fb.group({
    additionalAdultsPassengers: new FormArray([]),
    additionalChildsPassengers: new FormArray([]),
    additionalPensionersPassengers: new FormArray([]),
    sendInvoice: [false],
    invoiceCompanyName: [''],
    invoiceAddress: [''],
    invoiceTaxNumber: [''],
    comments: ['', [Validators.maxLength(COMMENT_MAX_LENGTH)]],
  });

  mainPassengerForm = this.fb.group({
    age: AdditionalPassengerAgeEnum.Adult,
    email: [
      '',
      [CustomValidators.isEmailValidator, Validators.required, Validators.maxLength(Config.DEFAULT_TEXT_INPUT_LENGTH)],
    ],
    name: [
      '',
      [Validators.required, Validators.minLength(2), Validators.maxLength(Config.FIRST_NAME_DEFAULT_INPUT_LENGTH)],
    ],
    lastName: [
      '',
      [Validators.required, Validators.minLength(2), Validators.maxLength(Config.LAST_NAME_DEFAULT_INPUT_LENGTH)],
    ],
    phone: ['', [Validators.required, Validators.maxLength(Config.DEFAULT_TEXT_INPUT_LENGTH)]],
    pickupNote: [null, [Validators.required, Validators.maxLength(Config.DEFAULT_TEXT_INPUT_LENGTH)]],
    phoneCountryCode: [''],
    isMain: true,
  });

  isMapPreviewOpen = false;

  private readonly PAYMENT_MINUTES_TIME = 10;

  get additionalAdultsForm(): FormArray {
    return this.ticketForm.get('additionalAdultsPassengers') as FormArray;
  }

  get additionalChildsForm(): FormArray {
    return this.ticketForm.get('additionalChildsPassengers') as FormArray;
  }

  get additionalPensionersForm(): FormArray {
    return this.ticketForm.get('additionalPensionersPassengers') as FormArray;
  }

  get additionalPassengersFormControls(): AbstractControl[] {
    return [
      ...this.additionalAdultsForm.controls,
      ...this.additionalChildsForm.controls,
      ...this.additionalPensionersForm.controls,
    ];
  }

  get passengerButtonLabel(): string {
    return this.ticketCreator.getAdditionalPassengersCount(this.ticketCreator.additionalPassengers) > 0
      ? 'Ticket.editPassengers'
      : 'Ticket.addPassengers';
  }

  get isPickupNoteEnabled() {
    return this.ticketCreator?.selectedPathway?.pathway?.pickupNoteEnabled;
  }

  constructor(
    public ticketCreator: TicketCreatorService,
    private ticketsService: TicketsService,
    private storage: StorageService,
    private location: Location,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private el: ElementRef,
    private ref: ChangeDetectorRef
  ) {
    super();

    if (!this.ticketCreator.selectedPathway) {
      this.n.navigate('search');
      return;
    }

    if (!this.isPickupNoteEnabled) {
      this.mainPassengerForm.get('pickupNote').setValidators([]);
      this.mainPassengerForm.get('pickupNote').updateValueAndValidity();
    }
  }

  ngOnInit(): void {
    this.setupTopBar();
    this.setupFormListeners();

    if (this.auth.isSignedIn()) {
      this.fillFormWithUserData();
    }

    this.auth.onLogin.pipe(takeUntil(this.destroyed)).subscribe(() => {
      this.fillFormWithUserData();
    });
    this.initalizeMainPassengerAge();
    this.initializePhoneNumberChangeSub();
  }

  private initializePhoneNumberChangeSub(): void {
    const sub = this.mainPassengerForm
      .get('phone')
      ?.valueChanges.subscribe((value) => handlePhoneNumberIllegalChars(this.mainPassengerForm.get('phone'), value));
    this.subscription.add(sub);
  }

  private initalizeMainPassengerAge(): void {
    const age: AdditionalPassengerAgeEnum =
      this.auth.isSignedIn() && this.storage.getUserData().age
        ? this.storage.getUserData().age
        : AdditionalPassengerAgeEnum.Adult;
    this.setMainPassengerAge(age);
  }

  setMainPassengerAge(age: AdditionalPassengerAgeEnum): void {
    this.ticketCreator.setMainPassengerAge(age);
  }

  onPassengersAdd(): void {
    const dialog = this.dialog.open(AddPassengerDialogComponent, {
      panelClass: 'add-passenger-dialog',
    });
    dialog
      .afterClosed()
      .pipe(takeUntil(this.destroyed))
      .subscribe((confirmed: boolean) => {
        if (confirmed) {
          this.updatePassengersForm(
            AdditionalPassengerAgeEnum.Adult,
            this.additionalAdultsForm,
            this.ticketCreator.additionalPassengers.adult
          );
          this.updatePassengersForm(
            AdditionalPassengerAgeEnum.Child,
            this.additionalChildsForm,
            this.ticketCreator.additionalPassengers.child
          );
          this.updatePassengersForm(
            AdditionalPassengerAgeEnum.Pensioner,
            this.additionalPensionersForm,
            this.ticketCreator.additionalPassengers.pensioner
          );
          this.ref.detectChanges();
        }
      });
  }

  onLuggageAdd(): void {
    const dialog = this.dialog.open(AddLuggageDialogComponent, {
      panelClass: 'add-luggage-dialog',
    });
    dialog
      .afterClosed()
      .pipe(takeUntil(this.destroyed))
      .subscribe(() => {
        this.ref.detectChanges();
      });
  }

  onTicketFormSubmit(): void {
    if (this.isRequestPending) {
      return;
    }

    this.ticketForm.markAllAsTouched();
    this.mainPassengerForm.markAllAsTouched();
    if (this.ticketForm.invalid || this.mainPassengerForm.invalid) {
      this.ref.detectChanges();
      this.scrollIntoView(this.el.nativeElement.querySelector('.ticket-page-form-group-input.invalid'));
      return;
    }

    const dialog = this.dialog.open(ConfirmDialogComponent, {
      panelClass: 'confirm-dialog',
      data: {
        title: this.t.instant('Ticket.ConfirmDialog.title'),
        actionDesc: this.t.instant('Ticket.ConfirmDialog.message'),
        resultDesc: this.t.instant('Ticket.ConfirmDialog.desc'),
        alertDesc: this.t.instant('Ticket.ConfirmDialog.subDesc', {
          minutes: this.PAYMENT_MINUTES_TIME,
        }),
        confirmText: this.t.instant('Ticket.ConfirmDialog.confirm'),
        cancelText: this.t.instant('Ticket.ConfirmDialog.cancel'),
        style: 'primary',
        reverseButtons: true,
      } as ConfirmDialogData,
    });

    dialog
      .afterClosed()
      .pipe(takeUntil(this.destroyed))
      .subscribe((confirmed: boolean) => {
        if (!!confirmed) {
          this.createNewTicket();
        }
      });
  }

  onMapOpen(): void {
    this.ref.detach();
    this.isMapPreviewOpen = true;
    this.ref.detectChanges();
  }

  onMapClose(): void {
    this.isMapPreviewOpen = false;
    this.ref.reattach();
  }

  private createNewTicket(): void {
    if (this.auth.isSignedIn()) {
      this.updateNewUserData();
    }

    this.isRequestPending = true;
    this.ticketCreator
      .submitTicket(this.getTicketInput())
      .pipe(
        takeUntil(this.destroyed),
        finalize(() => (this.isRequestPending = false))
      )
      .subscribe(
        (res: FetchResult<CreateTicketMutation>) => {
          this.snackbar.open(this.t.instant('Ticket.ticketCreated'), SnackBarState.INFO);
          this.n.navigate('summary', {}, { ticketToken: res.data.createTicket.token });
        },
        (error) => {
          error.graphQLErrors.map((e: GraphQLError) => {
            this.snackbar.open(this.ticketsService.getTicketCreateErrorMessage(e), SnackBarState.ERROR);
          });
        }
      );
  }

  private setMainPassangerAge(): void {
    this.mainPassengerForm.get('age').setValue(this.ticketCreator.mainPassengerAge, { emitEvent: false });
  }

  private getTicketInput(): TicketInput {
    this.setMainPassangerAge();
    const mainUser = Object.assign({}, this.mainPassengerForm.value);
    const pickupNote = mainUser?.pickupNote || null;
    delete mainUser?.pickupNote;

    const mainPassengerData = {
      ...mainUser,
      phoneCountryCode: `+${this.mainPassengerForm.get('phoneCountryCode').value}`,
    };

    return {
      additionalPassengers: [
        ...this.additionalAdultsForm.value,
        ...this.additionalChildsForm.value,
        ...this.additionalPensionersForm.value,
        mainPassengerData,
      ],
      pickupNote,
      bikesCount: Number(this.ticketCreator.additionalLuggage.bikesCount),
      animalsCount: Number(this.ticketCreator.additionalLuggage.animalsCount),
      additionalBaggage: Number(this.ticketCreator.additionalLuggage.luggageCount),
      term: this.ticketCreator.selectedPathway.rideDate,
      departureId: this.ticketCreator.selectedPathway.start.id,
      endDepartureId: this.ticketCreator.selectedPathway.end.id,
      sendInvoice: this.ticketForm.get('sendInvoice').value,
      invoiceAddress: this.ticketForm.get('invoiceAddress').value,
      invoiceCompanyName: this.ticketForm.get('invoiceCompanyName').value,
      invoiceTaxNumber: this.ticketForm.get('invoiceTaxNumber').value,
      comments: this.ticketForm.get('comments')?.value ?? null,
    };
  }

  private setupFormListeners(): void {
    this.ticketForm
      .get('sendInvoice')
      .valueChanges.pipe(takeUntil(this.destroyed))
      .subscribe((checked: boolean) => {
        if (checked) {
          if (this.auth.isSignedIn()) {
            this.fillInvoiceData();
          }

          FormsHelper.setFieldsValidator(
            [
              this.ticketForm.get('invoiceCompanyName'),
              this.ticketForm.get('invoiceAddress'),
              this.ticketForm.get('invoiceTaxNumber'),
            ],
            Validators.required
          );
        } else {
          this.ticketForm.get('invoiceCompanyName').clearValidators();
          this.ticketForm.get('invoiceAddress').clearValidators();
          this.ticketForm.get('invoiceTaxNumber').clearValidators();
          this.clearInvoiceData();
        }
      });
  }

  private setupTopBar(): void {
    const startBusStopName =
      this.ticketCreator.selectedPathway.start.busStop.name || this.ticketCreator.selectedPathway.start.busStop.address;
    const endBusStopName =
      this.ticketCreator.selectedPathway.end.busStop.name || this.ticketCreator.selectedPathway.end.busStop.address;
    const rideDate = this.moment(this.ticketCreator.selectedPathway.rideDate).format(this.config.TICKET_DATE_FORMAT);

    this.topBarConfig = {
      title: `${startBusStopName} - ${endBusStopName}`,
      subtitle: rideDate,
      leftIcon: {
        src: '/app/shared/assets/svg/back.svg',
        alt: this.t.instant('Global.IconAlts.backIcon'),
        clickHandler: () => (this.isMapPreviewOpen ? this.onMapClose() : this.location.back()),
      },
    };
  }

  private fillFormWithUserData(): void {
    const userData = this.storage.getUserData();
    const fieldsToCheck = ['email', 'name', 'lastName', 'phone', 'phoneCountryCode'];
    fieldsToCheck.forEach((fieldName: string) => {
      if (!this.mainPassengerForm.get(fieldName).value) {
        this.mainPassengerForm.get(fieldName).setValue(userData[fieldName]);
      }
    });
  }

  private fillInvoiceData(): void {
    const userData = this.storage.getUserData();
    this.ticketForm.get('invoiceCompanyName').setValue(userData.invoiceCompanyName);
    this.ticketForm.get('invoiceAddress').setValue(userData.invoiceAddress);
    this.ticketForm.get('invoiceTaxNumber').setValue(userData.invoiceTaxNumber);
  }

  private clearInvoiceData(): void {
    this.ticketForm.get('invoiceCompanyName').setValue('');
    this.ticketForm.get('invoiceAddress').setValue('');
    this.ticketForm.get('invoiceTaxNumber').setValue('');
  }

  private updateNewUserData(): void {
    const userData = this.storage.getUserData();
    const newUserData: PassengerProfileInput = {
      name: !userData.name ? this.mainPassengerForm.get('name').value : userData.name,
      lastName: !userData.lastName ? this.mainPassengerForm.get('lastName').value : userData.lastName,
      email: !userData.email ? this.mainPassengerForm.get('email').value : userData.email,
      sendInvoice: this.ticketForm.get('sendInvoice').value,
      invoiceAddress: !userData.invoiceAddress ? this.ticketForm.get('invoiceAddress').value : userData.invoiceAddress,
      invoiceCompanyName: !userData.invoiceCompanyName
        ? this.ticketForm.get('invoiceCompanyName').value
        : userData.invoiceCompanyName,
      invoiceTaxNumber: !userData.invoiceTaxNumber
        ? this.ticketForm.get('invoiceTaxNumber').value
        : userData.invoiceTaxNumber,
    };

    this.auth.updateProfileData(newUserData).subscribe((res: FetchResult<ProfileUpdateMutation>) => {
      this.storage.saveUserData(res.data.updateProfile);
    });
  }

  private updatePassengersForm(
    age: AdditionalPassengerAgeEnum,
    passengers: FormArray,
    newPassengers: AdditionalPassenger[]
  ): void {
    const diff = passengers.length - newPassengers.length;

    if (diff < 0) {
      newPassengers.slice(passengers.length).forEach((passenger: AdditionalPassenger) => {
        passengers.push(
          this.fb.group({
            age: [age],
            isMain: [false],
            name: [
              '',
              [Validators.required, Validators.minLength(2), Validators.maxLength(Config.DEFAULT_TEXT_INPUT_LENGTH)],
            ],
            lastName: [
              '',
              [Validators.required, Validators.minLength(2), Validators.maxLength(Config.DEFAULT_TEXT_INPUT_LENGTH)],
            ],
            ...(age === AdditionalPassengerAgeEnum.Child && {
              ageRange: [passenger.ageRange],
            }),
          })
        );
      });
    } else if (diff > 0) {
      getRange(0, Math.abs(diff)).forEach(() => {
        passengers.removeAt(passengers.length - 1);
      });
    }

    // update age ranges if changed
    if (age === AdditionalPassengerAgeEnum.Child) {
      passengers.controls.forEach((passenger: AbstractControl, i: number) => {
        passenger.get('ageRange').setValue(newPassengers[i].ageRange);
      });
    }
  }

  ngOnDestroy(): void {
    this.ticketCreator.clearTicketData();
    this.subscription.unsubscribe();
  }
}
