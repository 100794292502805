import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  Output,
  EventEmitter,
} from "@angular/core";
import { FormGroup } from "@angular/forms";
import { AdditionalPassengerAgeEnum } from "@modules/graphql/graphql.service";
import { Subscription } from "rxjs";
import {
  AgeFormField,
  PassengerTypeSelectService,
} from "./passenger-type-select.service";

@Component({
  selector: "bnl-passenger-type-select",
  templateUrl: "./passenger-type-select.component.html",
  styleUrls: ["./passenger-type-select.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PassengerTypeSelectComponent implements OnInit, OnDestroy {
  AgeFormField = AgeFormField;
  private readonly subscription: Subscription = new Subscription();
  AdditionalPassengerAgeEnum = AdditionalPassengerAgeEnum;
  @Output() updateAge = new EventEmitter<AdditionalPassengerAgeEnum>();

  get form(): FormGroup {
    return this.passengerTypeSelectService.form;
  }

  get dropdownOptions(): any[] {
    return this.passengerTypeSelectService.dropdownOptions;
  }

  constructor(
    private readonly passengerTypeSelectService: PassengerTypeSelectService,
    private readonly changes: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.initialzieFormChangeSubscription();
    this.passengerTypeSelectService.initializeFormValue();
    this.changes.detectChanges();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private initialzieFormChangeSubscription(): void {
    const sub = this.form.valueChanges.subscribe(() => this.submit());
    this.subscription.add(sub);
  }

  private submit(): void {
    this.passengerTypeSelectService.submit().subscribe((success) => {
      if (!success) return;
      this.updateAge.emit(this.passengerTypeSelectService.getFormValue());
    });
  }
}
