import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { NgwWowService } from 'ngx-wow';

@Injectable()
export class AnimationsService {
  private routerSub: Subscription;

  constructor(private router: Router, private wowService: NgwWowService) {
  }

  public initAnimations(): void {
    this.routerSub = this.router.events.pipe(
      filter((event: RouterEvent) => event instanceof NavigationEnd)
    ).subscribe(() => {
      this.wowService.init({mobile: false});
    });
  }
}
