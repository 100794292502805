import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from "@angular/core";

const MIN_RATE: number = 0;
const MAX_RATE: number = 5;

@Component({
  selector: "bnl-ticket-rating",
  templateUrl: "./ticket-rating.component.html",
  styleUrls: ["./ticket-rating.component.scss"],
})
export class TicketRatingComponent implements OnInit {
  @Input() rate: number | null = null;
  @Input() inputMode: boolean = false;
  @Input() disabled: boolean = false;
  @Output() ticketRated: EventEmitter<number> = new EventEmitter<number>();
  stars: number[] = this.resolveStars();

  basePath: string = "/app/shared/assets/svg/";
  regularStarPath: string = this.basePath + "star.svg";
  goldenStarPath: string = this.basePath + "golden-star.svg";

  currentRate: number = 0;

  constructor(private readonly changes: ChangeDetectorRef){}

  ngOnInit(): void {
    this.resolveDisaplyRating(this.rate);
  }

  private resolveStars(): number[] {
    return new Array(MAX_RATE).fill(0).map((n, index) => index + 1);
  }

  private resolveDisaplyRating(rate: number): void {
    if (rate == null) return;
    const roundedRate: number = Math.round(rate);
    this.currentRate = roundedRate >= MIN_RATE && roundedRate <= MAX_RATE ? roundedRate : 0;
  }

  onRate(rate: number): void {
    if (!this.inputMode || this.disabled) return;
    this.resolveDisaplyRating(rate);
    this.ticketRated.emit(rate);
  }
}
