<div class="results-page" [class.results-page--mapPreview]="isMapPreviewOpen">
  <bnl-top-bar [config]="topBarConfig" class="sticky-top d-md-none"></bnl-top-bar>

  <div class="background d-none d-md-block">
    <div class="container container--flat results-page-header">
      <h3 class="results-page-header-stations">
        {{ searchParams.toAirport ? searchParams.address : searchedAirport?.name }}
        – <br [class.d-none]="isMapPreviewOpen" />
        {{ searchParams.toAirport ? searchedAirport?.name : searchParams.address }}
      </h3>
      <h2 class="results-page-header-date">
        {{ searchParams.dateFrom | formatDate : config.TICKET_DATE_FORMAT }}
      </h2>
    </div>
  </div>

  <div class="container container--flat results-page-empty wow fadeIn" *ngIf="isNoResults">
    <div class="results-page-empty-icon">
      <img src="/app/shared/assets/svg/no-results.svg" alt="{{ 'Global.ImagesAlts.busStop' | translate }}" />
    </div>
    <p class="results-page-empty-title" [innerHTML]="'ResultsPage.Empty.title' | translate"></p>
    <p class="results-page-empty-desc" [innerHTML]="'ResultsPage.Empty.subtitle' | translate"></p>
    <bnl-button
      (click)="n.navigate('search', {}, {}, true)"
      class="results-page-empty-back"
      [boldText]="'ResultsPage.Empty.backToSearch' | translate"
      iconSrc="/app/shared/assets/svg/search-navy.svg"
      [iconAlt]="'IconAlts.searchIcon' | translate"
      size="sm"
      align="center"
      [rounded]="true"
    ></bnl-button>
  </div>

  <bnl-full-screen-map
    *ngIf="isMapPreviewOpen && selectedPathway"
    class="results-page-map"
    [pathway]="selectedPathway"
    (mapClose)="onFullMapClose()"
  >
    <bnl-button
      (click)="onTicketBuy(selectedPathway)"
      class="results-page-mapAction-button"
      [text]="'PathDetails.pickPath' | translate"
      [boldText]="selectedPathway?.price | price"
      align="between"
      size="md"
      [class.disabled]="
        selectedPathway?.availableSeats <= 0 ||
        ticketsService.didDepartureTimePassed(selectedPathway?.rideDate) ||
        !selectedPathway?.saleEnabled
      "
    >
    </bnl-button>
  </bnl-full-screen-map>

  <div class="container container--flat results-page-results">
    <div class="results-page-content">
      <ngx-spinner
        class="results-page-results-loader"
        [name]="PATHWAYS_LOADER"
        [fullScreen]="false"
        size="default"
        type="ball-clip-rotate"
        bdColor="rgba(0, 0, 0, 0)"
        color="#5051FF"
      ></ngx-spinner>
      <div class="results-page-results-sorting" *ngIf="!!foundPathways && !isNoResults">
        <p class="results-page-results-sorting-type">
          {{ "ResultsPage.sortBy" | translate }}
        </p>
        <div class="results-page-results-sorting-dropdown" [matMenuTriggerFor]="sortTypeMenu">
          <span class="results-page-results-sorting-dropdown-label" [ngSwitch]="searchParams.sortField">
            <ng-container *ngSwitchCase="SortField.TIME">{{ "ResultsPage.Sorting.earliest" | translate }}</ng-container>
            <ng-container *ngSwitchCase="SortField.PRICE">{{
              "ResultsPage.Sorting.cheapest" | translate
            }}</ng-container>
            <ng-container *ngSwitchCase="SortField.DURATION">{{
              "ResultsPage.Sorting.fastest" | translate
            }}</ng-container>
          </span>
          <mat-menu #sortTypeMenu>
            <button mat-menu-item (click)="onFilterChange(SortField.TIME)">
              {{ "ResultsPage.Sorting.earliest" | translate }}
            </button>
            <button mat-menu-item (click)="onFilterChange(SortField.PRICE)">
              {{ "ResultsPage.Sorting.cheapest" | translate }}
            </button>
            <button mat-menu-item (click)="onFilterChange(SortField.DURATION)">
              {{ "ResultsPage.Sorting.fastest" | translate }}
            </button>
          </mat-menu>
          <div class="results-page-results-sorting-dropdown-icon">
            <img src="/app/shared/assets/svg/caret-down-blue.svg" alt="{{ 'Global.Icons.dropdownIcon' | translate }}" />
          </div>
        </div>
        <p class="results-page-results-sorting-count d-none d-md-block">
          {{ "ResultsPage.resultsFound" | translate }}: {{ foundPathwaysCounter }}
        </p>
      </div>

      <ng-container *ngIf="!isNoResults && !isRequestPending">
        <!--      if in the future it was necessary to add loading older connections -->
        <!--      <button class="results-page-results-loadMore d-none d-md-block" *ngIf="!isNoResults && !isRequestPending" (click)="onLoadEarlierPathways()">-->
        <!--        {{'ResultsPage.loadEarlier' | translate}}-->
        <!--        <ngx-spinner-->
        <!--          [name]="FETCHING_EARLIER_PATHWAYS_LOADER"-->
        <!--          [fullScreen]="false"-->
        <!--          size="small"-->
        <!--          type="ball-clip-rotate"-->
        <!--          bdColor="#e4e4f0"-->
        <!--          color="#5051FF"-->
        <!--        ></ngx-spinner>-->
        <!--      </button>-->

        <ul class="results-page-results-list" *ngIf="!isNoResults && !isRequestPending">
          <li
            class="results-page-results-list-item wow fadeIn"
            *ngFor="let pathwayWrapper of foundPathways; index as i"
          >
            <bnl-results-separator
              class="results-page-results-list-item-separator"
              [date]="pathwayWrapper.pathway.rideDate"
              *ngIf="pathwayWrapper.isSeparatorVisible"
            >
            </bnl-results-separator>
            <bnl-connection-card
              *ngIf="!pathwayWrapper.isEmpty; else emptyPathway"
              [pathway]="pathwayWrapper.pathway"
              (ridePathOpen)="WindowHelper.isMobile && onRidePathOpen($event)"
              (mapPreviewOpen)="onFullMapOpen($event)"
              (ticketBuy)="onTicketBuy($event)"
              [background]="i % 2 === 0 ? 'white' : 'gray'"
            >
            </bnl-connection-card>
            <ng-template #emptyPathway>
              <div class="empty-pathway text-center">
                {{ "PathDetails.noPathwaysInThisDay" | translate }}
              </div>

              <div (click)="n.navigate('bus-rental')" class="text-center help24h">
                {{ "PathDetails.contactUs24h" | translate }}
              </div>
            </ng-template>
          </li>
        </ul>
      </ng-container>

      <div class="results-page-load-more text-center" *ngIf="!isNoResults && !isRequestPending">
        <ngx-spinner
          [name]="NEWER_PATHWAYS_LOADER"
          [fullScreen]="false"
          size="small"
          type="ball-clip-rotate"
          bdColor="#F4F4F9"
          color="#5051FF"
        ></ngx-spinner>
        <bnl-button
          (click)="loadMorePathways()"
          class="d-inline-block"
          buttonStyle="tertiary-navy"
          size="md"
          boldText="{{ 'PathDetails.showMorePathways' | translate }}"
        >
        </bnl-button>
      </div>
    </div>
  </div>
</div>

<div class="ride-path-pane d-md-none">
  <div class="ride-path-pane-inner">
    <div class="ride-path-pane-header">
      <div class="ride-path-pane-header-details">
        <h3 class="ride-path-pane-header-details-title">
          {{ "PathDetails.details" | translate }}
        </h3>
        <p class="ride-path-pane-header-details-subtitle" *ngIf="selectedPathway">
          {{ ticketsService.getDisplayBusStopsNumber(selectedPathway) }},
          {{ ticketsService.getDisplayRideDuration(selectedPathway) }}
        </p>
      </div>
      <bnl-button
        (click)="onFullMapOpen(selectedPathway)"
        class="ride-path-pane-header-button"
        size="sm"
        buttonStyle="blue"
        [rounded]="true"
        [boldText]="'PathDetails.showOnMap' | translate"
        iconSrc="/app/shared/assets/svg/folded-map.svg"
        [iconAlt]="'IconAlts.mapIcon' | translate"
      >
      </bnl-button>
    </div>
    <div class="ride-path-pane-content">
      <bnl-ride-path [pathway]="selectedPathway"></bnl-ride-path>
    </div>
    <div class="ride-path-pane-action">
      <bnl-button
        (click)="onTicketBuy(selectedPathway)"
        class="ride-path-pane-action-button"
        [text]="'PathDetails.pickPath' | translate"
        [boldText]="selectedPathway?.price | price"
        align="between"
        size="md"
        [class.disabled]="
          selectedPathway?.availableSeats <= 0 ||
          ticketsService.didDepartureTimePassed(selectedPathway?.rideDate) ||
          !selectedPathway?.saleEnabled
        "
      >
      </bnl-button>
    </div>
  </div>
</div>
