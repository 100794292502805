<div class="ticketPage" [class.ticketPage--historyTicket]="isHistoryTicket">
  <bnl-top-bar *ngIf="topBarConfig" [config]="topBarConfig" class="sticky-top d-md-none"></bnl-top-bar>

  <div class="background d-none d-md-block">
    <div class="ticketPage-header container container--flat" *ngIf="ticket">
      <h3 class="ticketPage-header-stations">
        {{ticket.departureData.start.busStop.name || ticket.departureData.start.busStop.address}}, {{ticket.departureData.start.busStop.city}} -
        {{ticket.departureData.end.busStop.name || ticket.departureData.end.busStop.address}}, {{ticket.departureData.end.busStop.city}}
      </h3>
      <h2 class="ticketPage-header-date">{{ticket.departureData.rideDate | formatDate:config.TICKET_DATE_FORMAT}}</h2>
      <bnl-ticket-preview
        class="ticketPage-header-preview d-none d-md-block"
        *ngIf="isHistoryTicket"
        [pathway]="ticket.departureData"
        [isHistoryPreview]="true"
      ></bnl-ticket-preview>
    </div>
  </div>

  <div class="ticketPage-historyTicket d-md-none" *ngIf="isHistoryTicket && ticket">
    <bnl-ticket-preview
      [pathway]="ticket.departureData"
      [isHistoryPreview]="true">
    </bnl-ticket-preview>
  </div>

  <div class="ticketPage-content">
    <bnl-ticket-pdf-preview class="ticketPage-content-pdfTicket" *ngIf="!isHistoryTicket && ticket" [ticket]="ticket"></bnl-ticket-pdf-preview>
    <ng-container *ngIf="isHistoryTicket">
      <bnl-info-box class="ticketPage-content-infobox" [textContent]="'TicketPage.outdatedTicket' | translate"></bnl-info-box>
      <div class="ticketPage-content-search">
        <bnl-button
          class="d-md-none"
          (click)="onReturnPathSearch()"
          [text]="'TicketPage.searchAgain' | translate"
          size="md"
          buttonStyle="primary"
          align="center"
        ></bnl-button>

        <bnl-button
          class="d-none d-md-block"
          (click)="onReturnPathSearch()"
          [text]="'TicketPage.searchAgain' | translate"
          size="md"
          iconSrc="/app/shared/assets/svg/continue.svg"
          [iconAlt]="'Global.IconAlts.continueIcon' | translate"
          [reverse]="true"
          buttonStyle="primary"
          align="between"
        ></bnl-button>
      </div>
    </ng-container>
  </div>
</div>
