<div class="addPassenger dialog">
  <bnl-top-bar [config]="topBarConfig" class="d-md-none"></bnl-top-bar>

  <div class="dialog-close d-none d-md-block">
    <img (click)="dialogRef.close(false)" src="/app/shared/assets/svg/close.svg" alt="{{'Global.IconAlts.closeIcon' | translate}}">
  </div>

  <div class="dialog-content">
    <h2 class="dialog-content-title">{{'Ticket.addPassengers' | translate}}</h2>
    <p class="dialog-content-seatsError" *ngIf="seatsLeft <= 0">{{'Ticket.noMoreSeats' | translate}}</p>
    <div class="addPassenger-list">
      <div class="addPassenger-list-item">
        <p class="addPassenger-list-item-name">{{'Ticket.adult' | translate}}</p>
        <bnl-counter
          [minVal]="0" [maxVal]="10" [currentVal]="additionalPassengers.adult.length"
          (decreased)="additionalPassengers.adult.pop()"
          (increased)="onPassengerAdd(additionalPassengers.adult, AdditionalPassengerAgeEnum.Adult)"
          [increaseDisabled]="seatsLeft <= 0"
        ></bnl-counter>
      </div>

      <div class="addPassenger-list-item">
        <p class="addPassenger-list-item-name">{{'Ticket.child' | translate}}</p>
        <bnl-counter
          [minVal]="0" [maxVal]="10" [currentVal]="additionalPassengers.child.length"
          (decreased)="additionalPassengers.child.pop()"
          (increased)="onPassengerAdd(additionalPassengers.child, AdditionalPassengerAgeEnum.Child)"
          [increaseDisabled]="seatsLeft <= 0"
        ></bnl-counter>
      </div>

      <div class="addPassenger-list-subitem" *ngFor="let child of additionalPassengers.child; index as i">
        <p class="addPassenger-list-subitem-name">{{'Ticket.passenger' | translate}} {{i + 1}}</p>
        <div
          class="addPassenger-list-subitem-age"
          [class.addPassenger-list-subitem-age--invalid]="!child.ageRange"
        >
          <mat-select class="addPassenger-list-subitem-age-select" [(value)]="child.ageRange">
            <mat-option [value]="AdditionalPassengerAgeRangeEnum.Age_0_4">
              {{ticketCreator.getDisplayAge(AdditionalPassengerAgeRangeEnum.Age_0_4)}}
            </mat-option>
            <mat-option [value]="AdditionalPassengerAgeRangeEnum.Age_4_6">
              {{ticketCreator.getDisplayAge(AdditionalPassengerAgeRangeEnum.Age_4_6)}}
            </mat-option>
            <mat-option [value]="AdditionalPassengerAgeRangeEnum.Age_6_18">
              {{ticketCreator.getDisplayAge(AdditionalPassengerAgeRangeEnum.Age_6_18)}}
            </mat-option>
          </mat-select>
          <bnl-button
            class="addPassenger-list-subitem-age-button"
            buttonStyle="tertiary-navy"
            size="sm"
            [rounded]="true"
            [text]="child.ageRange ? ticketCreator.getDisplayAge(child.ageRange) : ('Ticket.age' | translate)"
            iconSrc="/app/shared/assets/svg/caret-down-blue.svg"
            [iconAlt]="'Global.IconAlts.dropdownIcon' | translate"
            [reverse]="true"
          ></bnl-button>
        </div>
      </div>

      <div class="addPassenger-list-item">
        <p class="addPassenger-list-item-name">{{'Ticket.pensioner' | translate}}</p>
        <bnl-counter
          [minVal]="0" [maxVal]="10" [currentVal]="additionalPassengers.pensioner.length"
          (decreased)="additionalPassengers.pensioner.pop()"
          (increased)="onPassengerAdd(additionalPassengers.pensioner, AdditionalPassengerAgeEnum.Pensioner)"
          [increaseDisabled]="seatsLeft <= 0"
        ></bnl-counter>
      </div>
    </div>

    <div class="dialog-checkout">
      <h3 class="dialog-checkout-title">{{'Ticket.discount' | translate}}</h3>

      <div class="dialog-checkout-summary">
        <div class="dialog-checkout-summary-discount">
          <p class="dialog-checkout-summary-discount-desc">
            {{'Ticket.ticket' | translate}}
            (<span>{{ 'Ticket.mainPassenger' | translate }}</span>           
            <span *ngIf="totalAdults > 0">, 
              {{totalAdults}}
              <span *ngIf="totalAdults > 1">{{ 'Ticket.adults' | translate}}</span>
              <span *ngIf="totalAdults === 1">{{ 'Ticket.adult' | translate}}</span>            
            </span>
            <span *ngIf="totalChildren > 0">,
              {{totalChildren}}
              <span *ngIf="totalChildren > 1">{{ 'Ticket.children' | translate}}</span>
              <span *ngIf="totalChildren === 1">{{ 'Ticket.child' | translate}}</span>       
            </span>
            <span *ngIf="totalPensioners > 0">, 
              {{totalPensioners}}
              <span *ngIf="totalPensioners > 1">{{ 'Ticket.pensioners' | translate}}</span>
              <span *ngIf="totalPensioners === 1">{{ 'Ticket.pensioner' | translate}}</span>       
            </span>)
          </p>
          <p class="dialog-checkout-summary-discount-value ticket-total-value">
            {{(ticketCreator.getAdditionalPassengersPrice(additionalPassengers) + ticketCreator.getMainPassengerPrice()) | price}}
          </p>
        </div>

        <div class="dialog-checkout-summary-total">
          <p class="dialog-checkout-summary-total-desc">{{'Ticket.total' | translate}}</p>
          <p class="dialog-checkout-summary-total-value">
            {{ticketCreator.getTotalTicketPrice(additionalPassengers, ticketCreator.additionalLuggage) | price}}
          </p>
        </div>
      </div>
    </div>

    <div class="addPassenger-buttons">
      <bnl-button
        class="addPassenger-buttons-button d-none d-md-block"
        (click)="dialogRef.close(false)"
        buttonStyle="secondary"
        [text]="'Ticket.dismissPassengers' | translate"
        align="center"
        size="md">
      </bnl-button>

      <bnl-button
        [class.disabled]="!isPassengersConfigValid"
        class="addPassenger-buttons-button"
        (click)="onPassengersSubmit()"
        buttonStyle="primary"
        [text]="'Ticket.savePassengers' | translate"
        align="center"
        size="md">
      </bnl-button>
    </div>
  </div>
</div>
