<div class="privacy-policy-page">
  <bnl-top-bar [config]="topBarConfig" class="sticky-top d-md-none"></bnl-top-bar>

  <div class="background d-none d-md-block">
    <div class="container container--flat">
      <h1 class="profile-details-page-title">
        {{ "AccountPage.Regulations.tabBar" | translate }}
      </h1>
    </div>
  </div>

  <div class="container">
    <div class="terms-wrapper">
      <h2 class="text-center">REGULAMIN PLATFORMY</h2>
      <h2 class="text-center">„BUSY NA LOTNISKA”</h2>

      <h3 class="text-center">§1. Postanowienia ogólne:</h3>
      <ul class="list-numeric">
        <li>Użyte w regulaminie pojęcia mają następujące znaczenie:</li>
        <ul class="list-alpha-numeric">
          <li>
            <b>Platforma</b> - strona internetowa www.busynalotniska.pl oraz aplikacja „Busy na Lotniska”; zapewniająca
            możliwość:
            <ul class="list-lower-roman">
              <li>
                bezpłatnego informowania osób odwiedzających stronę internetową www.busynalotniska.pl lub aplikację Busy
                na Lotniska o działalności przewoźników, w tym umożliwiająca zakup biletu na zorganizowany i zaplanowany
                przez przewoźników przewóz (działalność serwisu sprzedaży)
              </li>
              <li>
                publikowania przez osoby odwiedzające stronę internetową www.busynalotniska.pl lub aplikację Busy na
                Lotniska zapytań ofertowych o przewóz oraz umożliwiająca zapoznanie się z zapytaniami przez przewoźników
                (działalność bazy ogłoszeń)
              </li>
            </ul>
          </li>
          <li><b>Serwis</b> – strona internetowa www.busynalotniska.pl</li>
          <li><b>Aplikacja</b> aplikacja mobilna pod nazwą Busy na lotniska</li>
          <li>
            <b>Pasażer</b> - osoba fizyczna, która nabyła bilet poprzez system sprzedaży biletów znajdujący się na
            stronie internetowej www.busynalotniska.pl lub w aplikacji pod nazwą Busy na lotniska
          </li>
          <li><b>Przewoźnik</b> - odmiot, który w ramach prowadzonego przedsiębiorstwa realizuje przewozy osobowe</li>
          <li>
            <b>Właściciel serwisu – Busy na lotniska spółka z ograniczoną odpowiedzialnością</b>
            z siedzibą w Przewłoce na ul. Staffa 37, 76-270 Ustka
          </li>
          <li>
            <b>Użytkownik</b> osoba fizyczna, która ukończyła 13 lat i posiada co najmniej ograniczoną zdolność do
            czynności prawnych, osoba prawna oraz jednostka organizacyjna nieposiadająca osobowości prawnej, której
            odrębne przepisy nadają zdolność prawną
          </li>
          <li>
            <b>Konto Użytkownika</b> indywidualne konto użytkownika w Serwisie i Aplikacji, do którego dostęp jest
            możliwy po rejestracji oraz zalogowaniu się
          </li>
          <li>
            <b>Konto Przewoźnika</b> indywidualne konto przewoźnika do którego dostęp jest możliwy po rejestracji oraz
            zalogowaniu się
          </li>
          <li>
            <b>Zapytanie ofertowe</b> – ogłoszenie zamieszczone na platformie przez użytkownika, zawierające informacje
            o zapotrzebowaniu na przewóz, składane na udostępnianym przez właściciela platformy formularzu;
          </li>
          <li><b>Pytający</b> - autor zapytania ofertowego, użytkownik zgłaszający zapotrzebowanie na przewóz</li>
          <li>
            <b>Opłata rezerwacyjna</b> - opłata uiszczana przez pytającego po zaakceptowaniu oferty złożonej przez
            przewoźnika, stanowiąca 10% wynagrodzenia za przewóz wskazanego w złożonej przez przewoźnika ofercie
          </li>
          <li>
            <b>Serwis Transakcyjny</b> – spółka PayPro Spółka Akcyjna z siedzibą w Poznaniu, podmiot zajmujący się
            obsługą procesu płatności, działający na podstawie zezwolenia na świadczenie usług płatniczych w charakterze
            krajowej instytucji płatniczej, mający podpisaną z Właścicielem umowę o przyjmowanie zapłaty przy użyciu
            instrumentów płatniczych
          </li>
          <li>
            <b>Wyszukiwarka połączeń</b> – funkcja dostępna bezpłatnie w Serwisie i Aplikacji umożliwiająca bez
            konieczności zakładania Konta wyszukiwanie przewozów zorganizowanych przez Przewoźników.
          </li>
          <li>
            <b>Dane Osobowe</b> - informacje o zidentyfikowanej lub możliwej do zidentyfikowania osobie fizycznej w
            rozumieniu Rozporządzenia 2016/679 Parlamentu Europejskiego i Rady z dnia 27kwietnia 2016 r. w sprawie
            ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich
            danych oraz uchylenia dyrektywy 95/46/WE (RODO).
          </li>
          <li>
            <b>Bilet</b> - imienne potwierdzenie zawarcia przez Pasażera umowy z Przewoźnikiem, zawierające szczegóły
            zorganizowanego przez Przewoźnika przewozu, tj. termin i trasę, dane Przewoźnika
          </li>
        </ul>
        <li>
          Regulamin określa zasady korzystania z Serwisu oraz Aplikacji oraz jest udostępniony nieodpłatnie w Serwisie
          oraz w Aplikacji w formie, która umożliwia jego pobranie, a także udostępniany jest Użytkownikom w trakcie
          procesu rejestracji.
        </li>
        <li>
          Korzystając z Serwisu i Aplikacji Użytkownicy i Przewoźnicy potwierdzają, że zapoznali się z treścią
          Regulaminu, akceptują wszystkie jego postanowienia oraz zobowiązują się do ich przestrzegania.
        </li>
        <li>Zakazane jest zarówno w stosunku do Serwisu jak i Aplikacji:</li>
        <ul class="list-alpha-numeric">
          <li>dostarczanie treści o charakterze nieprawdziwym, bezprawnym, mogących wprowadzić w błąd,</li>
          <li>
            podejmowanie zachowań mogących wywołać zakłócenia, uszkodzenia systemów teleinformatycznych lub naruszenia
            zasobów systemowych Usługodawcy,
          </li>
          <li>
            korzystanie bez zgody Usługodawcy z botów, crawlerów i innych narzędzi automatyzujących dostęp lub
            wpływających na działanie Strony www lub Aplikacji
          </li>
          <li>korzystanie w sposób sprzeczny z ich przeznaczeniem.</li>
          <li>
            Użytkownicy oraz Przewoźnicy są zobowiązani do zachowania w tajemnicy danych potrzebnych do logowania do
            własnych kont. Zakazane jest współdzielenie kont przez więcej niż jeden podmiot.
          </li>
        </ul>
        <li>Korzystanie z Serwisu wymaga spełnienia następujących warunków technicznych:</li>
        <ul class="list-alpha-numeric">
          <li>posiadania urządzenia z dostępem do Internetu,</li>
          <li>posługiwania się oprogramowaniem w postaci aktualnej wersji przeglądarki internetowej, w tym:</li>
          <ul class="list-inside">
            <li>Firefox w wersji >75.0,</li>
            <li>Google Chrome w wersji >80.0</li>
            <li>Opera w wersji >70</li>
            <li>IE w wersji >11.0.210</li>
            <li>Edge w wersji min. 80.0</li>
            <li>Safari w wersji min. 14.0</li>
          </ul>
        </ul>
        <li>Korzystanie z Aplikacji wymaga spełnienia następujących warunków technicznych:</li>
        <ul class="list-alpha-numeric">
          <li>posiadania urządzenia mobilnego z dostępem do Internetu,</li>
          <li>zainstalowania Aplikacji,</li>
        </ul>
      </ul>

      <h3 class="text-center">§2. Konto Użytkownika:</h3>
      <ul class="list-numeric">
        <li>
          Założenie Konta Użytkownika wymaga podania przez Użytkownika w formularzu rejestracyjnym numeru telefonu oraz
          wpisania otrzymanego przez SMS kodu weryfikacyjnego.
        </li>
        <li>
          Logowanie do Konta Użytkownika wymaga podania przez Użytkownika w formularzu logowania numeru telefonu oraz
          wpisania otrzymanego przez SMS kodu weryfikacyjnego.
        </li>
        <li>
          Za pośrednictwem Konta Użytkownika możliwe jest korzystanie z:
          <ul class="list-alpha-numeric">
            <li>
              Serwisu sprzedaży, tj. wyszukiwanie połączeń, zakup Biletu na zorganizowany przez Przewoźnika przewóz,
              uiszczenie należności za Bilet, przeglądanie wykupionych Biletów, śledzenie trasy przejazdu;
            </li>
            <li>
              Bazy ogłoszeń, tj. publikowania zapytań ofertowych o przewóz, powadzenia korespondencji elektronicznej z
              Przewoźnikami, płatność opłaty rezerwacyjnej bądź całości wynagrodzenia za przewóz wskazanego w złożonej
              przez Przewoźnika ofercie.
            </li>
          </ul>
        </li>
        <li>
          Zakup Biletu bądź zamieszczenie ogłoszenia z użyciem Konta Użytkownika wymaga uzupełnienia przez Użytkownika
          wymaganych przez formularz rejestracyjny Danych Osobowych.
        </li>
        <li>
          Użytkownik może w każdym czasie usunąć Konto Użytkownika. Konto może zostać usunięte przez Właściciela serwisu
          w przypadku nieprzestrzegania Regulaminu.
        </li>
        <li>
          Osoba zakładająca Konto Użytkownika oświadcza, że jest ona uprawniona do korzystania z podanego numeru
          telefonu i założenia Konta Użytkownika.
        </li>
      </ul>

      <h3 class="text-center">§3. Konto Przewoźnika:</h3>
      <ul class="list-numeric">
        <li>
          Założenie Konta Przewoźnika wymaga podania przez Użytkownika w formularzu rejestracyjnym adresu e-mail,
          wpisania otrzymanego przez e-mail kodu weryfikacyjnego oraz ustanowienia hasła.
        </li>
        <li>
          Logowanie do Konta Przewoźnika wymaga podania przez Przewoźnika w formularzu logowania adresu e-mail oraz
          podania hasła.
        </li>
        <li>
          Za pośrednictwem Konta Przewoźnika możliwe jest korzystanie z:
          <ul class="list-alpha-numeric">
            <li>
              Bazy ogłoszeń, tj. przeglądanie treści dostępnych na Platformie, w szczególności zamieszczanych na
              Platformie zapytań ofertowych, otrzymywania zamieszczonych na Platformie zapytań ofertowych, prowadzenie
              korespondencji elektronicznej z Pytającym, w tym złożenia Pytającemu oferty przewozu;
            </li>
            <li>
              Serwisu sprzedaży, tj. informowanie o zorganizowanych przewozach, dokonanie sprzedaży Biletów na
              zorganizowany przez Przewoźnika przewóz;
            </li>
          </ul>
        </li>
        <li>
          Warunkiem umożliwienia Użytkownikom zakupu biletu na organizowany przez Przewoźnika przejazd, jak i
          przekazywania Przewoźnikowi Zapytań ofertowych jest zawarcie z Właścicielem Serwisu odrębnej umowy.
        </li>
        <li>
          Osoba zakładająca Konto Przewoźnika oświadcza, że jest ona uprawniona do korzystania z podanego adresu e-mail
          i założenia Konta Przewoźnika.
        </li>
      </ul>

      <h3 class="text-center">§4. Sprzedaż biletów:</h3>
      <h4>I. Wyszukiwarka Połączeń:</h4>
      <ul class="list-numeric">
        <li>
          Wyszukiwarka połączeń umożliwia po podaniu miejsca rozpoczęcia przewozu oraz miejsca destynacji wyszukanie
          połączeń zorganizowanych przez Przewoźników, którzy zawarli z Właścicielem Serwisu odrębną umowę zgodnie z § 3
          ust. 4.
        </li>
        <li>
          Za zgodą osoby korzystającej z Wyszukiwarki połączeń na korzystanie z geolokalizacji Wyszukiwarka połączeń
          umożliwia lokalizację najbliższego lotniska.
        </li>
      </ul>
      <h4>II. Zakup Biletu:</h4>
      <ul class="list-numeric">
        <li>
          Bilet stanowi potwierdzenie zawarcia umowy przewozu. Umowa przewozu zawierana jest bezpośrednio pomiędzy
          Przewoźnikiem, a Pasażerem.
        </li>
        <li>
          Warunkiem zawarcia umowy przewozu jest zapłata należności za Bilet. Należność jest każdorazowo wskazana w
          Serwisie lub Aplikacji.
        </li>
        <li>
          Przewoźnik upoważnia Właściciela serwisu do przyjmowania od Pasażerów wynagrodzenia z tytułu umowy przewozu.
          Rozliczenie pomiędzy Właścicielem serwisu i Przewoźnikiem następuje na warunkach określonych odrębną umową.
        </li>
        <li>
          Na żądanie Użytkownika Przewoźnik wystawi fakturę VAT obejmującą wynagrodzenia z tytułu umowy przewozu.
          Warunkiem wystawienia faktury VAT jest uzupełnienie przez Użytkownika w Koncie Użytkownika wszystkich danych
          koniecznych do wystawienia faktury VAT. Użytkownik wyraża zgodę na otrzymywanie faktur VAT drogą elektroniczną
          na podany w Koncie Użytkownika adres e-mail.
        </li>
        <li>
          Zapłata za Bilet możliwa jest wyłącznie za pośrednictwem metod płatności określonych w Serwisie i Aplikacji.
        </li>
        <li>
          Pasażer może odstąpić od umowy przewozu przed rozpoczęciem podróży, albo na stacji zatrzymania środka
          transportu na drodze przejazdu.
        </li>
        <li>
          Odstępując od umowy przewozu Pasażer ma prawo do zwrotu należności za częściowo lub całkowicie niezrealizowaną
          usługę przewozu pomniejszonej o odstępne w wysokości:
          <ul class="list-alpha-numeric">
            <li>20% wartości biletu przy zwrocie powyżej 7 dni od planowanej daty wyjazdu</li>
            <li>50% wartości biletu przy zwrocie od 48 do 24 godzin od planowanej daty wyjazdu</li>
            <li>90% wartości biletu przy zwrocie poniżej 24 godzin od planowanej daty wyjazdu</li>
          </ul>
        </li>
        <li>
          Warunkiem zwrotu należności za częściowo lub całkowicie niezrealizowaną usługę przewozu jest wniosek Pasażera
          o zwrot należności przesłany do Przewoźnika oraz zweryfikowanie przez Przewoźnika niezrealizowana usługi
          przewozu w całości lub w części.
        </li>
        <li>
          Okazanie Biletu przed wejściem na pokład środka transportu jest warunkiem skorzystania przez Pasażera z usługi
          przewozu.
        </li>
        <li>
          Nieposiadanie przez Pasażera Biletu lub niemożność okazania przez Pasażera Biletu w momencie wejścia na pokład
          środka transportu uprawnia Przewoźnika do niewykonania usługi przewozu, bez prawa Pasażera do zwrotu opłaty za
          bilet, z zastrzeżeniem, iż pasażer w takiej sytuacji będzie mógł kontynuować podróż pod warunkiem
          zweryfikowania przez przewoźnika w aplikacji, iż dana osoba po okazaniu dowodu tożsamości ze zdjęciem
          faktycznie dokonała zakupu.
        </li>
        <li>Warunki przewozu bagażu i zwierząt ustala przewoźnik.</li>
      </ul>

      <h3 class="text-center">§5. Baza ogłoszeń:</h3>
      <h4>I. Działalność bazy ogłoszeń</h4>
      <ul class="list-numeric">
        <li>Zapytania ofertowe składane są przy pomocy umieszczonego w Platformie Formularza zapytania ofertowego.</li>
        <li>
          Właściciel nie odpowiada za zgodność Zapytania ze stanem faktycznym, a także za umieszczenie w Zapytaniu
          ofertowym wszystkich informacji istotnych dla złożenia Oferty przez Wykonawcę.
        </li>
        <li>
          Zapytanie ofertowe widoczne jest w Platformie dla Przewoźników, którzy zawarli z Właścicielem umowę, o której
          mowa w § 3 ust.4 powyżej, dopasowanych pod kątem miejsca wykonania Zapytania przez okres 24 godzin.
        </li>
        <li>
          Przewoźnik po pojawieniu się w Platformie Zapytania ofertowego może złożyć Pytającemu Ofertę wypełniając
          udostępniony formularz.
        </li>
        <li>
          Dla jednego Zapytania ofertowego możliwe jest złożenie Oferty przez maksymalnie trzech przewoźników, którzy w
          najkrótszym czasie po opublikowaniu Zapytania ofertowego złożą Pytającemu ofertę.
        </li>
        <li>
          Przewoźnik celem uzyskania od Pytającego dodatkowych informacji, koniecznych do złożenia oferty, może
          prowadzić z Pytającym poprzez Platformę korespondencję elektroniczną.
        </li>
        <li>
          Pytający w terminie 24 godzin od złożenia oferty przez Przewoźnika może przyjąć ofertę poprzez jej akceptację
          oraz uiszczenie, według wyboru Pytającego - opłaty rezerwacyjnej bądź całości wynagrodzenia za przewóz w
          ramach programu „Bezpieczna płatność”. Płatność następuje za pośrednictwem serwisu Przelewy 24. Nieuiszczenie
          przez Pytającego opłaty rezerwacyjnej jednoznaczne będzie z nieprzyjęciem oferty.
        </li>
        <li>
          Po akceptacji przez Pytającego oferty oraz uiszczeniu opłaty rezerwacyjnej bądź całości wynagrodzenia za
          przewóz w ramach programu „Bezpieczna płatność”, Przewoźnik otrzymuje elektroniczne potwierdzenie transakcji
          wraz z danymi Pytającego, udostępnionymi przy składaniu Zapytania ofertowego.
        </li>
        <li>
          W przypadku uiszczenia przez Pytającego przy akceptacji oferty jedynie Opłaty rezerwacyjnej, pozostała część
          wynagrodzenia uiszczona zostanie w terminie i na warunkach ustalonych przez Pytającego i Przewoźnika.
        </li>
        <li>
          W przypadku wpłaty przez Pytającego opłaty rezerwacyjnej bądź całego wynagrodzenia w ramach programu
          „Bezpieczna płatność”, Rozliczenia pomiędzy Właścicielem serwisu i Przewoźnikiem następują na warunkach
          określonych odrębną umową.
        </li>
        <li>
          Kwestia odstąpienia przez Pytającego od umowy zawartej z Przewoźnikiem możliwa jest wtedy gdy przewiduje to
          zawarta przez Pytającego i Przewoźnika umowa.
        </li>
      </ul>
      <h4>II. Bezpieczna płatność w ramach „Busy na Lotniska”</h4>
      <ul class="list-numeric">
        <li>
          W ramach Platformy Właściciel udostępnia użytkownikom możliwość płatności całości wynagrodzenia za przewóz za
          pośrednictwem Serwisu transakcyjnego. Rozliczenia pomiędzy Właścicielem serwisu i Przewoźnikiem następują na
          warunkach określonych odrębną umową.
        </li>
        <li>
          Wybór sposobu płatności, w tym decyzja o realizacja płatności poprzez funkcjonalność „Bezpieczna Płatność”
          należy do Pytającego.
        </li>
        <li>
          W przypadku wyboru przez Pytającego rozliczenia w ramach funkcjonalności „Bezpieczna płatność” , całość
          wynagrodzenia za przewóz zostanie wypłacone Przewoźnikowi przez Właściciela w dniu roboczym następującym po
          dniu wykonania przewozu bądź w przypadku gdy przewóz trwa więcej niż jeden dzień, w dniu następującym po dniu,
          w którym rozpoczęte zostało wykonywanie przewozu.
        </li>
        <li>
          W przypadku poinformowania Właściciela przez Pytającego o niewykonaniu przewozu przez Przewoźnika, Właściciel
          po pozytywnej weryfikacji zgłoszenia, ma prawo dokonać na rzecz Pytającego zwrotu wynagrodzenia za przewóz.
          Weryfikacja zgłoszenia obejmować będzie kontakt z Przewoźnikiem celem zajęcia przez niego stanowiska w sprawie
          zgłoszonych przez Pytającego nieprawidłowości.
        </li>
        <li>
          Właściciel nie jest stroną umowy zawartej przez Pytającego i Przewoźnika, wszelkich roszczeń wynikających z
          zawartej umowy, Pytający i Przewoźnik obowiązani są dochodzić bezpośrednio od drugiej strony.
        </li>
        <li>
          W przypadkach, o których mowa w ust. 4 powyżej, Właścicielowi przysługuje względem Przewoźnika roszczenie o
          zapłatę opłaty rezerwacyjnej, pobranej od Pytającego, a zwróconej mu z uwagi na niewykonanie umowy przez
          Przewoźnika.
        </li>
      </ul>

      <h3 class="text-center">§6. Opłaty:</h3>
      <ul class="list-numeric">
        <li>
          Za korzystanie ze Strony www i Aplikacji Użytkownik nie ponosi opłat, z wyjątkiem kosztów połączenia
          internetowego związanego z korzystaniem z Strony www.
        </li>
        <li>
          Opłaty ponoszone przez Przewoźnika z tytułu korzystania z Platformy określa odrębna umowa, o której mowa w § 3
          ust. 4.
        </li>
      </ul>

      <h3 class="text-center">§7. Reklamacje:</h3>
      <ul class="list-numeric">
        <li>
          Użytkownik i Przewoźnik może złożyć Właścicielowi reklamację dotyczącą Serwisu, Aplikacji oraz Usług w formie
          pisemnej na adres siedziby Właściciela lub adres poczty elektronicznej: <b>bok@busynalotniska.pl</b>
        </li>
        <li>
          Reklamacja powinna być złożona nie później niż w terminie 14 dni od dnia, w którym przyczyna reklamacji stała
          się znana podmiotowi składającemu reklamację.
        </li>
        <li>
          Zgłoszenie reklamacji powinno zawierać oznaczenie osoby zgłaszającej reklamację (imię, nazwisko i w zależności
          od formy złożonej reklamacji: adres zamieszkania lub adres e-mail), opis zdarzenia będącego przyczyną
          reklamacji oraz inne dane konieczne do rozpatrzenia reklamacji.
        </li>
        <li>Wszelkie reklamacje, które nie spełniają wymogów, określonych powyżej nie będą rozpatrywane.</li>
        <li>
          Usługodawca rozpatruje reklamację w terminie 30 dni od dnia otrzymania reklamacji, przekazując odpowiedź w
          formie pisemnej lub elektronicznie. W przypadku wydłużenia terminu odpowiedzi, w szczególności gdy dla
          rozpatrzenia reklamacji wymagane będzie przedstawienie dodatkowych informacji, Usługodawca poinformuje
          składającego reklamację o wydłużonym, przewidywanym terminie udzielenia odpowiedzi.
        </li>
        <li>
          Reklamacje dotyczące realizacji usług przewozu należy kierować bezpośrednio do Przewoźników. Usługodawca nie
          pośredniczy w rozwiązywaniu ewentualnych sporów dotyczących realizacji usług przewozu.
        </li>
      </ul>

      <h3 class="text-center">§8. Odpowiedzialność Właściciela:</h3>
      <ul class="list-numeric">
        <li>
          Właściciel będzie podejmował wszelkie starania, aby zapewnić prawidłowe i ciągłe funkcjonowanie Strony www i
          Aplikacji.
        </li>
        <li>
          Właściciel będzie podejmował starania, aby prace techniczne i aktualizacyjne były prowadzone w sposób jak
          najmniej uciążliwy dla Użytkowników i Przewoźników, w miarę możliwości po ich wcześniejszym poinformowaniu o
          utrudnieniach z co najmniej 24 godzinnym wyprzedzeniem.
        </li>
        <li>
          Właściciel nie ponosi odpowiedzialności za niedostępność Strony www i Aplikacji spowodowaną przerwami
          technicznymi, zakłóceniami technicznymi, działaniem lub zaniechaniem osób trzecich, a także działaniem siły
          wyższej.
        </li>
        <li>
          Właściciel nie ponosi odpowiedzialności za wykonanie lub sposób wykonania usług przewozu. Podmiotem
          odpowiedzialnym z tego tytułu jest Przewoźnik wskazany na Bilecie lub też Przewoźnik, którego ofertę przyjął
          Pytający.
        </li>
      </ul>

      <h3 class="text-center">§9. Postanowienia końcowe:</h3>
      <ul class="list-numeric">
        <li>Regulamin podlega prawu polskiemu.</li>
        <li>Zasady przetwarzania danych osobowych określa polityka prywatności</li>
        <li>W sprawach nieuregulowanych dotyczących usług przewozu stosuje się ustawę prawo przewozowe.</li>
        <li>
          Zmiana Regulaminu następuje poprzez opublikowanie nowej wersji Regulaminu na Stronie www i Aplikacji, o czym
          Usługodawca poinformuje Użytkowników oraz Przewoźników co najmniej 14 dni przed wprowadzeniem zmian. Zmieniony
          Regulamin jest wiążący dla Użytkowników i Przewoźników, którzy nie wypowiedzieli umowy przed datą wskazaną w
          powiadomieniu o zmianie jako data wejścia w życie zmienionego Regulaminu.
        </li>
        <li>
          Sądem właściwym dla rozpoznawania sporów wynikających z realizacji postanowień Regulaminu, będzie sąd właściwy
          dla siedziby Właściciela, chyba że inna właściwość wynika z przepisów prawa.
        </li>
        <li>Regulamin wchodzi w życie z dniem <b>13.12.2023r.</b></li>
      </ul>
    </div>
  </div>
</div>
