<div class="fullScreenMap">
  <bnl-map-ride-path class="fullScreenMap-map" [pathway]="pathway" [order]="order"></bnl-map-ride-path>
  <div class="fullScreenMap-container">
    <button class="fullScreenMap-closeButton" (click)="mapClose.emit()">
      <img
        class="fullScreenMap-closeButton-icon"
        src="/app/shared/assets/svg/back-arrow.svg"
        [alt]="'Global.IconAlts.backIcon' | translate"
      />
      <span class="fullScreenMap-closeButton-text">{{ "MapPreview.close" | translate }}</span>
    </button>
  </div>

  <div class="fullScreenMap-footer">
    <ng-content></ng-content>
  </div>
</div>
