<p class="bnl-auth-dialog-description" [innerHTML]="'AuthDialog.description' | translate"></p>

<form (ngSubmit)="onSubmit()" (keydown.enter)="onSubmit()" class="bnl-auth-dialog-form">
  <bnl-phone-input
    #phoneInput
    class="bnl-auth-dialog-form-input"
    [class.disabled]="isFormPending"
    phoneName="userPhoneNumber"
    phoneFlagName="userCountry"
    [form]="loginForm"
    [inputConfig]="inputConfig">
  </bnl-phone-input>

  <bnl-button
    (click)="onSubmit()"
    [class.disabled]="loginForm.invalid"
    [showLoader]="isFormPending"
    class="bnl-auth-dialog-form-submit"
    iconSrc="/app/shared/assets/svg/continue.svg"
    iconAlt="{{'Global.IconAlts.continueIcon' | translate}}"
    align="between"
    [leftPadding]="true"
    [reverse]="true"
    [text]="'AuthDialog.submitButton' | translate">
  </bnl-button>
</form>

<p class="bnl-auth-dialog-privacy-policy" [innerHTML]="'AuthDialog.privacyPolicy' | translate | sanitizeHTML"></p>
