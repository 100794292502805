<div class="bnl-search-dialog">
  <ngx-spinner
    [name]="LOCALIZE_LOADER"
    size="default"
    type="ball-clip-rotate"
    bdColor="rgba(0, 0, 0, .15)"
    color="#5051FF"
  ></ngx-spinner>

  <div class="bnl-search-dialog-search">
    <bnl-top-bar [config]="topBarConfig"></bnl-top-bar>

    <div class="container container--flat bnl-search-dialog-search-form">
      <gbxsoft-input
        class="bnl-search-dialog-search-form-input"
        [formControl]="searchField"
        [config]="{
        name: data.searchDialogTitle,
        placeholder: data.searchDialogTitle,
        iconUrl: '/app/shared/assets/svg/target-location.svg',
        type: GbxsoftInputTypes.TEXT
      }">
      </gbxsoft-input>
    </div>
  </div>

  <div class="container container--flat bnl-search-dialog-results">
    <div class="bnl-search-dialog-results-list">
      <div
        class="bnl-search-dialog-results-list-item bnl-search-dialog-results-list-item--localize"
        *ngIf="data.searchType === SearchType.LOCALIZATION"
        (click)="onLocalize()"
      >
        <div class="bnl-search-dialog-results-list-item-icon">
          <img src="/app/shared/assets/svg/gps.svg" alt="">
        </div>
        <div class="bnl-search-dialog-results-list-item-content">
          <span class="bnl-search-dialog-results-list-item-content-target">{{'SearchPage.myLocalization' | translate}}</span>
        </div>
      </div>

      <ng-container *ngFor="let searchResult of filteredSearchResults">
        <div class="bnl-search-dialog-results-list-item" (click)="onSearchResultPick(searchResult)" *ngIf="searchResult.isSearched">
          <div class="bnl-search-dialog-results-list-item-icon">
            <img src="/app/shared/assets/svg/map-pin.svg" alt="{{'Global.IconAlts.mapPin' | translate}}">
          </div>
          <div class="bnl-search-dialog-results-list-item-content">
            <span class="bnl-search-dialog-results-list-item-content-target">{{searchResult.name}}</span>
            <span class="bnl-search-dialog-results-list-item-content-city">{{searchResult.description}}</span>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
