import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { OrdersService } from "@core/services/orders.service";
import { BusRentGraphqlForCarrier } from "@modules/graphql/graphql.service";
import { Config } from "@shared/configs/config";

@Component({
  selector: "bnl-order-card-payments",
  templateUrl: "./order-card-payments.component.html",
  styleUrls: ["./order-card-payments.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderCardPaymentsComponent {
  @Input() order: BusRentGraphqlForCarrier;
  Config = Config;

  constructor(public ordersService: OrdersService) {}
}
