import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { AdditionalPassengerGraphql, TicketGraphql } from '@modules/graphql/graphql.service';
import { Config } from '@shared/configs/config';

@Component({
  selector: 'bnl-scan-ticket-details',
  templateUrl: './scan-ticket-details.component.html',
  styleUrls: ['./scan-ticket-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScanTicketDetailsComponent implements OnInit {
  @Input() ticket: TicketGraphql;
  Config = Config;

  mainPassenger: AdditionalPassengerGraphql;

  ngOnInit(): void {
    this.mainPassenger = this.ticket.additionalPassengers.find((passenger: AdditionalPassengerGraphql) => passenger.isMain);
  }
}
