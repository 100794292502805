<div [ngClass]="['bnl-info-box', 'bnl-info-box--' + boxStyle]">
  <ng-container [ngSwitch]="boxStyle">
    <img
      *ngSwitchCase="InfoBoxStyle.INFO"
      class="bnl-info-box-icon"
      src="/app/shared/assets/svg/info-blue.svg"
      alt="{{'Global.IconAlts.infoIcon' | translate}}"
    >
    <img
      *ngSwitchCase="InfoBoxStyle.DANGER"
      class="bnl-info-box-icon"
      src="/app/shared/assets/svg/danger-red.svg"
      alt="{{'Global.IconAlts.errorIcon' | translate}}"
    >
  </ng-container>
  <p class="bnl-info-box-text">{{textContent}}</p>
</div>
