import { AbstractControl, FormControl, ValidatorFn } from "@angular/forms";

export class FormsHelper {
  public static setFieldsValidator(
    fields: AbstractControl[],
    validator: ValidatorFn
  ): void {
    fields.forEach((field: FormControl) => {
      field.setValidators([validator]);
      field.updateValueAndValidity();
    });
  }

  public static isTouchedAndInvalid(field: AbstractControl): boolean {
    return field.touched && field.invalid;
  }

  public static hasRequiredError(field: AbstractControl): boolean {
    return field.hasError("required");
  }

  public static hasEmailError(field: AbstractControl): boolean {
    return field.hasError("isEmail");
  }

  public static hasLowerDateError(field: AbstractControl): boolean {
    return field.hasError("isLower");
  }

  public static hasLowerHourError(field: AbstractControl): boolean {
    return field.hasError("isHourLower");
  }

  public static hasMinlengthError(field: AbstractControl): boolean {
    return field.hasError("minlength");
  }

  public static getRequiredMinLength(field: AbstractControl): number {
    return field.getError("minlength").requiredLength;
  }

  public static hasMaxlengthError(field: AbstractControl): boolean {
    return field.hasError("maxlength");
  }

  public static getRequiredMaxLength(field: AbstractControl): number {
    return field.getError("maxlength").requiredLength;
  }

  public static hasNumberError(field: AbstractControl): number {
    return field.getError("isNumber");
  }

  public static hasMinError(field: AbstractControl): number {
    return field.getError("min");
  }

  public static hasMaxError(field: AbstractControl): number {
    return field.getError("max");
  }

  public static hasMaxOfferError(field: AbstractControl): number {
    return field.getError("maxOffer");
  }
}
