import { Component, Input } from '@angular/core';
import { TopBarConfig } from '@shared/interfaces/top-bar-config.interface';

@Component({
  selector: 'bnl-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss']
})
export class TopBarComponent {
  @Input() config: TopBarConfig;
}
