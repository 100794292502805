import { NgModule } from '@angular/core';
import { Routes } from '@core/routes';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [RouterModule.forRoot(Routes.global.routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
