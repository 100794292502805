// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  hmr: false,
  serverAddress: 'https://dev-bnl.develtio.dev',
  ticketApi: 'https://dev-bnl.develtio.dev/api/ticket',
  graphqlApi: 'https://dev-bnl.develtio.dev/api/graphql',
  companyLogotypes: 'https://dev-bnl.develtio.dev/cms/assets/company-logo',
  googleApiKey: 'AIzaSyCnsUUaORQc18yko4KYC9NWBlDyhyiqQeM',
  pageTitlePrefix: 'Busynalotniska',
  captchaKey: '6LcKyHAnAAAAAAxHh3jaaXR_wQv9k-nHq3ZhH9AA',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
