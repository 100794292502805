import {
  ChangeDetectorRef,
  Component,
  HostListener,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { GbxsoftInputTypes } from '@form/src/lib/gbxsoft-input/gbxsoft-input.types';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { BaseComponent } from '@shared/components/base-component';
import { WindowHelper } from '@shared/helpers/window.helper';
import { debounce } from '@shared/decorators/debounce.decodator';
import { SnackBarState } from '@shared/enums/snack-bar-state.enum';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '@shared/components/confirm-dialog/confirm-dialog.component';
import { FetchResult } from 'apollo-link';
import { ProfileUpdateMutation } from '@modules/graphql/graphql.service';
import { finalize } from 'rxjs/operators';
import { StorageItem } from '@shared/enums/storage-item.enum';
import { StorageService } from '@shared/services/storage.service';
import { ConfirmDialogData } from '@shared/interfaces/confirm-dialog-input.interface';
import { CustomValidators } from '@shared/validators/custom-validators';
import { Config } from '@shared/configs/config';
import { FormsHelper } from '@shared/helpers/forms.helper';
import { GbxsoftInputAutocompleteTypes } from '@form/src/lib/gbxsoft-input/gbxsoft-input-autocomplete.types';
import { Subscription } from 'rxjs';
import { handlePhoneNumberIllegalChars } from '@shared/helpers/phone-number-replace.helper';

@Component({
  selector: 'bnl-profile-edit-form',
  templateUrl: './profile-edit-form.component.html',
  styleUrls: ['./profile-edit-form.component.scss'],
})
export class ProfileEditFormComponent
  extends BaseComponent
  implements OnInit, OnDestroy
{
  GbxsoftInputTypes = GbxsoftInputTypes;
  GbxsoftInputAutocompleteTypes = GbxsoftInputAutocompleteTypes;
  FormsHelper = FormsHelper;
  private readonly subscription: Subscription = new Subscription();

  /** @description do not edit, it has to match graphql input */
  userDataForm = this.fb.group({
    email: [
      '',
      [
        CustomValidators.isEmailValidator,
        Validators.maxLength(Config.DEFAULT_TEXT_INPUT_LENGTH),
      ],
    ],
    name: [
      '',
      [
        Validators.minLength(2),
        Validators.maxLength(Config.FIRST_NAME_DEFAULT_INPUT_LENGTH),
      ],
    ],
    lastName: [
      '',
      [
        Validators.minLength(2),
        Validators.maxLength(Config.LAST_NAME_DEFAULT_INPUT_LENGTH),
      ],
    ],
    sendInvoice: false,
    invoiceCompanyName: [''],
    invoiceTaxNumber: [''],
    invoiceAddress: [''],
  });

  phoneInputForm = this.fb.group({
    phone: [''],
    phoneCountryCode: [''],
  });

  check = new FormControl(false);

  WindowHelper = WindowHelper;

  @HostListener('window:resize')
  @debounce(30)
  onWindowResize() {
    this.ref.detectChanges();
  }

  constructor(
    private fb: FormBuilder,
    private ref: ChangeDetectorRef,
    private dialog: MatDialog,
    private storage: StorageService
  ) {
    super();
  }

  ngOnInit(): void {
    const userData = this.storage.getUserData();
    this.userDataForm.patchValue(userData);
    this.phoneInputForm.patchValue(userData);
    this.initializePhoneNumberChangeSub();
  }

  private initializePhoneNumberChangeSub(): void {
    const sub = this.phoneInputForm
      .get('phone')
      ?.valueChanges.subscribe((value) =>
        handlePhoneNumberIllegalChars(this.phoneInputForm.get('phone'), value)
      );
    this.subscription.add(sub);
  }

  onUserDataFormSubmit(): void {
    if (this.isRequestPending) {
      return;
    }

    this.userDataForm.markAllAsTouched();
    if (this.userDataForm.valid) {
      this.isRequestPending = true;

      if (!this.userDataForm.get('sendInvoice').value) {
        this.userDataForm.get('invoiceCompanyName').setValue('');
        this.userDataForm.get('invoiceTaxNumber').setValue('');
        this.userDataForm.get('invoiceAddress').setValue('');
      }

      this.auth
        .updateProfileData(this.userDataForm.value)
        .pipe(finalize(() => (this.isRequestPending = false)))
        .subscribe(
          (res: FetchResult<ProfileUpdateMutation>) => {
            this.storage.setItem(
              StorageItem.USER_DATA,
              res.data.updateProfile,
              true
            );
            this.snackbar.open(
              this.t.instant('ProfileEdit.dataSaved'),
              SnackBarState.SUCCESS
            );
          },
          (error) => {}
        );
    }
  }

  onAccountDelete(): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      panelClass: 'confirm-dialog',
      data: {
        title: this.t.instant('ProfileEdit.deleteAccount'),
        actionDesc: this.t.instant('ProfileEdit.deleteAccountMessage'),
        resultDesc: this.t.instant('ProfileEdit.deleteAccountResult'),
        confirmText: this.t.instant('ProfileEdit.deleteAccountConfirm'),
        cancelText: this.t.instant('ProfileEdit.deleteAccountCancel'),
        style: 'danger',
      } as ConfirmDialogData,
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (!!confirmed) {
        this.auth.deleteAccount().subscribe(
          () => {
            this.snackbar.open(
              this.t.instant('ProfileEdit.accountDeleted'),
              SnackBarState.SUCCESS
            );
            this.auth.logout().subscribe(() => {
              this.n.navigate('search');
            });
          },
          (error) => {}
        );
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
