import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Config } from '@core/config';
import { BaseComponent } from '@shared/components/base-component';

@Component({
  selector: 'bnl-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterComponent extends BaseComponent {
  Config = Config;
  constructor() {
    super();
  }

  getCurrentYear(): number {
    return new Date().getFullYear();
  }
}
