import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { BusRentGraphql, PathwaysListElementGraphql } from "@modules/graphql/graphql.service";

@Component({
  selector: "bnl-full-screen-map",
  templateUrl: "./full-screen-map.component.html",
  styleUrls: ["./full-screen-map.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FullScreenMapComponent {
  @Input() pathway: PathwaysListElementGraphql;
  @Input() order: BusRentGraphql;
  @Output() mapClose = new EventEmitter<void>();
}
