import { AgmGeocoder } from '@agm/core';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MatDialogState } from '@angular/material/dialog';
import { SearchService } from '@core/services/search.service';
import { GbxsoftInputKeycodeTypes } from '@form/src/lib/gbxsoft-input/gbxsoft-input-keycode.types';
import { GbxsoftInputTypes } from '@form/src/lib/gbxsoft-input/gbxsoft-input.types';
import { GetPassengerSearchHistoryQuery, SearchQueryGraphql } from '@modules/graphql/graphql.service';
import { SearchDialogComponent } from '@modules/tabs/search-tab/shared/components/search-dialog/search-dialog.component';
import { SearchType } from '@modules/tabs/search-tab/shared/enums/search-type.enum';
import { SearchResult } from '@modules/tabs/search-tab/shared/interfaces/search-result.interface';
import { LocalizationService } from '@modules/tabs/search-tab/shared/services/localization.service';
import { BaseComponent } from '@shared/components/base-component';
import { Config } from '@shared/configs/config';
import { debounce } from '@shared/decorators/debounce.decodator';
import { SnackBarState } from '@shared/enums/snack-bar-state.enum';
import { DeviceHelper } from '@shared/helpers/device.helper';
import { MapsHelper } from '@shared/helpers/maps.helper';
import { WindowHelper } from '@shared/helpers/window.helper';
import { LatLng } from '@shared/interfaces/lat-lng.interface';
import { MapService } from '@shared/services/map.service';
import { DateButton, DlDateTimePickerChange, DlDateTimePickerComponent } from 'angular-bootstrap-datetimepicker';
import { FetchResult } from 'apollo-link';
import { Observable, fromEvent } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import GeocoderResult = google.maps.GeocoderResult;

enum SearchPageTab {
  RENT = 'rent',
  SEARCH = 'search',
}

@Component({
  selector: 'bnl-search-view',
  styleUrls: ['./search-page.component.scss'],
  templateUrl: './search-page.component.html',
})
export class SearchPageComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {
  GbxsoftInputTypes = GbxsoftInputTypes;
  WindowHelper = WindowHelper;
  DeviceHelper = DeviceHelper;
  SearchType = SearchType;
  SearchPageTab = SearchPageTab;

  isCalendarOpen = false;
  selectedDate: string;

  @ViewChild('placeInput', { read: ElementRef }) placeInputRef: ElementRef;
  @ViewChild('airportInput', { read: ElementRef }) airportInputRef: ElementRef;
  @ViewChild('dateTimePicker') dateTimePicker: DlDateTimePickerComponent<any>;
  @ViewChild('dateTimePicker', { read: ElementRef }) dateTimePickerRef: ElementRef;

  readonly HOURS_PICK_DELAY = 10;
  datetimePickerMinValue: string;
  dateTimePickerControl = new FormControl('');

  nativeDatetimePickerMinValue: string;
  nativeDatetimePickerControl = new FormControl('');

  searchParams = this.fb.group({
    localization: this.fb.group({
      latitude: [''],
      longitude: [''],
      address: ['', [Validators.required]],
      placeId: [''],
    }),
    airportId: [null, [Validators.required]],
    dateFrom: ['', [Validators.required]],
    dateTo: [''],
    toAirport: [true, [Validators.required]],
  });

  tripDisplayDate = new FormControl('');
  searchForm = this.fb.group({
    place: [''],
    airport: [''],
    tripDate: [''],
  });

  selectedTab: SearchPageTab = SearchPageTab.RENT;

  private searchDialog: MatDialogRef<SearchDialogComponent>;

  searchHistory: SearchQueryGraphql[];

  showPlaceSuggestions = false;
  showAirportSuggestions = false;

  placeSuggestions: SearchResult[];
  airportSuggestions: SearchResult[];

  readonly LOCALIZE_LOADER = 'localize-loader';
  selectedSuggestionIndex = -1;

  @HostListener('window:resize')
  @debounce(30)
  onWindowResize() {
    this.ref.detectChanges();
  }

  mapsHelper = new MapsHelper();

  constructor(
    public searchService: SearchService,
    private ref: ChangeDetectorRef,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private mapService: MapService,
    private localizationService: LocalizationService,
    private geocoder: AgmGeocoder
  ) {
    super();
  }

  ngOnInit(): void {
    this.loadAirports().subscribe(() => {
      this.setupFormListeners();
      this.setupLastSearchData();
    });

    this.setupDateTimePickers();
    this.setupAuthListeners();

    if (this.auth.isSignedIn()) {
      this.loadSearchHistory();
    }
  }

  ngAfterViewInit(): void {
    fromEvent(this.placeInputRef.nativeElement.querySelector('input'), 'focus')
      .pipe(takeUntil(this.destroyed))
      .subscribe(() => {
        this.showPlaceSuggestions = true;
      });

    fromEvent(this.placeInputRef.nativeElement.querySelector('input'), 'blur')
      .pipe(takeUntil(this.destroyed))
      .subscribe(() => {
        this.showPlaceSuggestions = false;
      });

    fromEvent(this.airportInputRef.nativeElement.querySelector('input'), 'focus')
      .pipe(takeUntil(this.destroyed))
      .subscribe(() => {
        this.showAirportSuggestions = true;
      });

    fromEvent(this.airportInputRef.nativeElement.querySelector('input'), 'blur')
      .pipe(takeUntil(this.destroyed))
      .subscribe(() => {
        this.showAirportSuggestions = false;
      });

    this.removeDatepickerHoverLabels();
  }

  onPlacePicked(searchResult: SearchResult): void {
    this.searchForm.get('place').setValue(searchResult.name, { emitEvent: false });
    this.searchParams.get('localization').get('address').setValue(searchResult.name);
    this.searchParams.get('localization').get('placeId').setValue(searchResult.placeId);
  }

  onAirportPicked(searchResult: SearchResult): void {
    this.searchForm.get('airport').setValue(searchResult.name, { emitEvent: false });
    this.searchParams.get('airportId').setValue(searchResult.busStopId);
  }

  onLocalize(): void {
    this.spinner.show(this.LOCALIZE_LOADER);
    this.localizationService
      .getCurrentPosition(true)
      .pipe(takeUntil(this.destroyed))
      .subscribe(
        (position: Position) => {
          this.handleLocalizationResult(position);
        },
        (error: PositionError) => {
          this.handleLocalizationError(error);
        }
      );
  }

  onSearchDialogOpen(searchType: SearchType): void {
    const searchDialogTitle = this.getInputTitle(searchType, this.searchParams.get('toAirport').value);

    if (!this.searchDialog || this.searchDialog.getState() !== MatDialogState.OPEN) {
      this.searchDialog = this.dialog.open(SearchDialogComponent, {
        panelClass: 'search-dialog',
        data: { searchType, searchDialogTitle },
      });

      this.searchDialog.afterClosed().subscribe((searchResult: SearchResult) => {
        if (!!searchResult) {
          this.handlePickResult(searchType, searchResult);
        }
      });
    }
  }

  onTripDirectionSwap(): void {
    this.setSuggestionIndex(0);
    this.searchParams.get('toAirport').setValue(!this.searchParams.get('toAirport').value);
  }

  onDatePick(event: DlDateTimePickerChange<any>): void {
    this.handleDatePick(event.value);
    this.isCalendarOpen = false;
  }

  dateTimeSelectFilter(dateButton: DateButton, viewName: string): boolean {
    if (viewName === 'day') {
      return dateButton.value >= Date.now() - 1000 * 60 * 60 * 24;
    } else if (viewName === 'hour') {
      return dateButton.value >= Date.now() - 1000 * 60 * 60 * 10;
    }
  }

  onSearchFormSubmit(): void {
    this.searchParams.markAllAsTouched();
    if (this.searchParams.invalid) {
      this.snackbar.open(this.t.instant('SearchPage.mustSelectFromList'), SnackBarState.ERROR);
      return;
    }

    this.searchService.searchFromState = this.searchForm.value;
    this.searchService.searchParamsFormState = this.searchParams.value;

    const lat = this.searchParams.get('localization').get('latitude').value;
    const lng = this.searchParams.get('localization').get('longitude').value;

    this.isRequestPending = true;
    if (lat === '' || lng === '') {
      this.mapsHelper
        .getLatLngFromAddress(this.searchParams.get('localization').get('placeId').value)
        .then((value: LatLng) => {
          this.searchParams.get('localization').get('latitude').setValue(value.lat);
          this.searchParams.get('localization').get('longitude').setValue(value.lng);

          this.navigateToSearchResults();
        })
        .catch(() => {
          this.isRequestPending = false;
        });
    } else {
      // this.searchParams
      //   .get('localization')
      //   .get('latitude')
      //   .setValue(this.searchParams.get('localization').get('latitude').value);
      // this.searchParams
      //   .get('localization')
      //   .get('longitude')
      //   .setValue(this.searchParams.get('localization').get('longitude').value);

      this.navigateToSearchResults();
      // this.geocoder
      //   .geocode({
      //     location: {
      //       lat: this.searchParams.get('localization').get('latitude').value,
      //       lng: this.searchParams.get('localization').get('longitude').value,
      //     },
      //   })
      //   .pipe(takeUntil(this.destroyed))
      //   .subscribe(
      //     (results: GeocoderResult[]) => {
      //       this.searchParams.get('localization').get('latitude').setValue(results[0].geometry.location.lat());
      //       this.searchParams.get('localization').get('longitude').setValue(results[0].geometry.location.lng());

      //       this.navigateToSearchResults();
      //     },
      //     (error) => {
      //       console.log('🚀 ~ error:', error);
      //       this.isRequestPending = false;
      //     }
      //   );
    }
  }

  navigateToSearchResults(): void {
    this.isRequestPending = false;
    this.n.navigate(
      'search-results',
      {
        lat: this.searchParams.get('localization').get('latitude').value,
        lng: this.searchParams.get('localization').get('longitude').value,
        address: this.searchParams.get('localization').get('address').value,
        airportId: this.searchParams.get('airportId').value,
        dateFrom: this.searchParams.get('dateFrom').value,
        toAirport: this.searchParams.get('toAirport').value,
      },
      {},
      true
    );
  }

  onHistorySearchQueryPick(searchQuery: SearchQueryGraphql): void {
    // console.log('🚀 ~ searchQuery:', searchQuery);
    this.dateTimePickerControl.setValue('');

    this.searchParams.get('airportId').setValue(searchQuery.airport.id);
    this.searchParams.get('localization').get('address').setValue(searchQuery.address);
    this.searchParams.get('localization').get('latitude').setValue(searchQuery.latitude);
    this.searchParams.get('localization').get('longitude').setValue(searchQuery.longitude);
    this.searchParams.get('toAirport').setValue(searchQuery.toAirport);
    this.searchParams.get('dateFrom').setValue('');

    this.searchForm.get('place').setValue(searchQuery.address, { emitEvent: false });
    this.searchForm.get('airport').setValue(searchQuery.airport.name, { emitEvent: false });
    this.searchForm.get('tripDate').setValue('');

    this.tripDisplayDate.setValue('');

    this.snackbar.open(this.t.instant('SearchPage.History.selectDate'), SnackBarState.INFO);
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }

  getInputTitle(searchType: SearchType, isToAirport: boolean): string {
    switch (searchType) {
      case SearchType.LOCALIZATION: {
        return isToAirport ? this.t.instant('SearchPage.from') : this.t.instant('SearchPage.where');
      }

      case SearchType.AIRPORT: {
        return isToAirport ? this.t.instant('SearchPage.toAirport') : this.t.instant('SearchPage.fromAirport');
      }
    }
  }

  navigateSuggestionsByArrows(key: GbxsoftInputKeycodeTypes, type: SearchType): void {
    if (this.isOnlyLocalizeSuggestionVisible(key)) {
      this.onLocalize();
    } else {
      const searchResult = this.selectListBySearchType(type);
      if (searchResult === undefined) {
        this.setSuggestionIndex(-1);
        return;
      }
      switch (key) {
        case GbxsoftInputKeycodeTypes.ARROW_UP:
          this.navigateSuggestionUp(type);
          break;
        case GbxsoftInputKeycodeTypes.ARROW_DOWN:
          this.navigateSuggestionDown(searchResult);
          break;
        case GbxsoftInputKeycodeTypes.ENTER:
          this.selectSuggestion(type);
          break;
        default:
          this.setSuggestionIndex(0);
          break;
      }
    }
  }

  private selectListBySearchType(type: SearchType): SearchResult[] {
    return type === SearchType.LOCALIZATION
      ? this.placeSuggestions
      : this.airportSuggestions.filter((item: SearchResult) => item.isSearched);
  }

  private isOnlyLocalizeSuggestionVisible(key: GbxsoftInputKeycodeTypes): boolean {
    return this.showPlaceSuggestions && key === GbxsoftInputKeycodeTypes.ENTER && this.selectedSuggestionIndex === -1;
  }

  private navigateSuggestionUp(type: SearchType): void {
    this.selectedSuggestionIndex--;
    if (this.selectedSuggestionIndex < 0) {
      if (type === SearchType.LOCALIZATION) {
        this.setSuggestionIndex(-1);
      } else {
        this.setSuggestionIndex(0);
      }
    }
  }

  private navigateSuggestionDown(searchResult: SearchResult[]) {
    this.selectedSuggestionIndex++;
    if (this.selectedSuggestionIndex === searchResult.length) {
      this.setSuggestionIndex(searchResult.length - 1);
    }
  }

  private selectSuggestion(type: SearchType): void {
    const isSwitched = !this.searchParams.get('toAirport').value;
    const placeInputRef = this.placeInputRef.nativeElement.querySelector('input');
    const airportInputRef = this.airportInputRef.nativeElement.querySelector('input');
    switch (type) {
      case SearchType.LOCALIZATION:
        this.onPlacePicked(this.placeSuggestions[this.selectedSuggestionIndex]);
        if (isSwitched) {
          placeInputRef.blur();
          this.isCalendarOpen = true;
        } else {
          airportInputRef.focus();
        }
        break;
      case SearchType.AIRPORT:
        this.onAirportPicked(this.airportSuggestions[this.selectedSuggestionIndex]);
        if (isSwitched) {
          placeInputRef.focus();
        } else {
          airportInputRef.blur();
          this.isCalendarOpen = true;
        }
        break;
    }
    this.setSuggestionIndex(0);
  }

  private setSuggestionIndex(suggestionIndex: number): number {
    return (this.selectedSuggestionIndex = suggestionIndex);
  }

  private loadAirports(): Observable<void> {
    return new Observable((subscriber) => {
      this.searchService
        .getAirportsAsSearchResults()
        .pipe(takeUntil(this.destroyed))
        .subscribe((res: SearchResult[]) => {
          this.airportSuggestions = res;
          subscriber.next();
          subscriber.complete();
        });
    });
  }

  private loadSearchHistory(): void {
    this.searchService
      .getSearchHistory()
      .pipe(takeUntil(this.destroyed))
      .subscribe((res: FetchResult<GetPassengerSearchHistoryQuery>) => {
        this.searchService.searchHistory = res.data.getPassengerSearchHistory as SearchQueryGraphql[];
      });
  }

  private setupLastSearchData(): void {
    if (this.searchService.searchParamsFormState) {
      this.searchParams.patchValue(this.searchService.searchParamsFormState);
      this.handleDatePick(this.searchParams.get('dateFrom').value);
      this.nativeDatetimePickerControl.setValue(
        this.moment(this.searchParams.get('dateFrom').value).format(Config.DATETIME_LOCAL_INPUT_FORMAT),
        { emitEvent: false }
      );

      if (this.searchParams.get('dateFrom').value && !DeviceHelper.isMobile) {
        this.dateTimePicker.value = this.moment(this.searchParams.get('dateFrom').value).toDate();
      }
    }

    if (this.searchService.searchFromState) {
      this.searchForm.patchValue(this.searchService.searchFromState, { emitEvent: false });
      this.loadPlaceSuggestions(this.searchForm.get('place').value);
    }
  }

  private setupDateTimePickers(): void {
    const momentDate = this.moment().subtract(this.HOURS_PICK_DELAY, 'hours');
    this.datetimePickerMinValue = momentDate.format();
    this.nativeDatetimePickerMinValue = momentDate.format(Config.DATETIME_LOCAL_INPUT_FORMAT);
  }

  private setupFormListeners(): void {
    this.searchForm
      .get('place')
      .valueChanges.pipe(takeUntil(this.destroyed), debounceTime(200))
      .subscribe((val: string) => {
        this.handlePlaceInputChange(val);
      });

    this.searchForm
      .get('airport')
      .valueChanges.pipe(takeUntil(this.destroyed))
      .subscribe((val: string) => {
        this.searchParams.get('airportId').markAsTouched();
        this.searchParams.get('airportId').setValue('');
        this.airportSuggestions = this.searchService.filterBusStops(this.airportSuggestions, val);
      });

    this.nativeDatetimePickerControl.valueChanges.pipe(takeUntil(this.destroyed)).subscribe((date: string) => {
      this.handleDatePick(date);
    });
  }

  private setupAuthListeners(): void {
    this.auth.onLogin.pipe(takeUntil(this.destroyed)).subscribe(() => {
      this.loadSearchHistory();
    });

    this.auth.onLogout.pipe(takeUntil(this.destroyed)).subscribe(() => {
      this.searchService.searchHistory = [];
    });
  }

  private handleLocalizationResult(position: Position): void {
    this.geocoder
      .geocode({
        location: new google.maps.LatLng(position.coords.latitude, position.coords.longitude),
      })
      .pipe(takeUntil(this.destroyed))
      .subscribe((geocoderResults: GeocoderResult[]) => {
        const address = geocoderResults[0].formatted_address.split(',').slice(0, -1).join(',');
        this.searchParams.get('localization').get('address').setValue(address);
        this.searchForm.get('place').setValue(geocoderResults[0].formatted_address, { emitEvent: false });
        this.spinner.hide(this.LOCALIZE_LOADER);
      });
  }

  private handleLocalizationError(error: PositionError): void {
    this.snackbar.open(this.localizationService.getLocalizationErrorMessage(error), SnackBarState.ERROR);
    this.spinner.hide(this.LOCALIZE_LOADER);
  }

  private handlePickResult(searchType: SearchType, searchResult: SearchResult): void {
    switch (searchType) {
      case SearchType.LOCALIZATION: {
        this.searchParams.get('localization').get('address').setValue(searchResult.name);
        this.searchParams.get('localization').get('placeId').setValue(searchResult.placeId);
        this.searchForm.get('place').setValue(searchResult.name, { emitEvent: false });
        break;
      }

      case SearchType.AIRPORT: {
        this.searchParams.get('airportId').setValue(searchResult.busStopId);
        this.searchForm.get('airport').setValue(searchResult.name, { emitEvent: false });
        break;
      }
    }
  }

  private handlePlaceInputChange(val: string) {
    this.searchParams.get('localization').get('address').markAsTouched();
    this.searchParams.get('localization').get('address').setValue('');

    if (!!val) {
      this.loadPlaceSuggestions(val);
    } else {
      this.searchParams.get('localization').get('address').setValue('');
      this.placeSuggestions.length = 0;
    }
  }

  private handleDatePick(date: string) {
    if (!date) {
      this.searchParams.get('dateFrom').setValue('');
      this.tripDisplayDate.setValue('');
      return;
    }

    let momentDate = this.moment(date);

    if (this.moment().diff(momentDate, 'hours') > this.HOURS_PICK_DELAY) {
      this.snackbar.open(
        this.t.instant('SearchPage.dateTimeDelayError', { time: 10 }),
        SnackBarState.ERROR,
        undefined,
        'top'
      );

      momentDate = this.moment().subtract(this.HOURS_PICK_DELAY, 'hours');
    }

    this.searchParams.get('dateFrom').setValue(momentDate.format());
    this.searchForm.get('tripDate').setValue(momentDate.format());
    this.tripDisplayDate.setValue(momentDate.format(Config.DISPLAY_DATETIME_FORMAT));
  }

  private loadPlaceSuggestions(searchVal: string): void {
    this.mapService
      .getAutocomplete()
      .getPlacePredictions({ input: searchVal }, (res: google.maps.places.AutocompletePrediction[]) => {
        if (res) {
          this.placeSuggestions = res.map((prediction: google.maps.places.AutocompletePrediction) => ({
            isSearched: true,
            name: prediction.description,
            placeId: prediction.place_id,
          }));
        }
      });
  }

  private removeDatepickerHoverLabels(): void {
    this.dateTimePickerRef?.nativeElement.querySelector('.dl-abdtp-right-button')?.removeAttribute('title');
    this.dateTimePickerRef?.nativeElement.querySelector('.dl-abdtp-left-button')?.removeAttribute('title');
  }

  selectTab(tab: SearchPageTab): void {
    this.selectedTab = tab;
  }
}
