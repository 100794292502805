<div class="privacy-policy-page">
  <bnl-top-bar
    [config]="topBarConfig"
    class="sticky-top d-md-none"
  ></bnl-top-bar>

  <div class="background d-none d-md-block">
    <div class="container container--flat">
      <h1 class="profile-details-page-title">
        {{ "AccountPage.PrivacyPolicy.tabBar" | translate }}
      </h1>
    </div>
  </div>

  <div class="container">
    <div class="terms-wrapper">
      <h2 class="text-center">POLITYKA PRYWATNOŚCI</h2>
      <h2 class="text-center">Busy Na Lotniska</h2>
      <br /><br />

      <p>
        Niniejszy dokument zwany Polityką prywatności określa zasady związane z
        przetwarzaniem danych osobowych za pomocą strony internetowej:
        <b>www.busynalotniska.pl</b> i za pomocą aplikacji mobilnej pod nazwą
        <b>BUSY NA LOTNISKA.</b>
      </p>

      <p><b>Administrator danych:</b></p>

      <p>
        Administratorem Pani/Pana danych osobowych gromadzonych za pośrednictwem
        strony internetowej oraz aplikacji mobilnej jest
        <b>Busy na lotniska spółka z ograniczoną odpowiedzialnością</b> z
        siedzibą w Przewłoce na ul. Staffa 37, 76-270 Ustka
      </p>

      <p>
        W zakresie wykonania usług przewozu odrębnym administratorem pozostaje
        przewoźnik wskazany na bilecie. W związku z zakupieniem biletu za
        pośrednictwem strony internetowej lub aplikacji Pani/Pana niezbędne dane
        zostaną przekazane wskazanemu przewoźnikowi.
      </p>

      <p>Postanowienia ogólne:</p>
      <ul class="list-numeric">
        <li>
          Niniejsza Polityka Prywatności określa sposób zbierania, przetwarzania
          i przechowywania danych osobowych koniecznych do realizacji usług
          świadczonych za pośrednictwem aplikacji mobilnej Busy Na Lotniska
          (zwanej dalej „Aplikacją”) przez spółkę Busy na lotniska Sp. z o.o.
        </li>
        <li>
          Korzystający z Aplikacji przyjmuje do wiadomości, że korzystającym
          jest każda osoba fizyczna korzystająca z usług świadczonych za
          pośrednictwem Aplikacji.
        </li>
        <li>
          Korzystający z Aplikacji przyjmuje do wiadomości, że udostępnianie
          przez niego danych osobowych jest dobrowolne. Udostępnianie
          Administratorowi danych osobowych przez Korzystającego nastąpi po
          zaakceptowaniu Polityki Prywatności podczas rejestracji w Aplikacji.
        </li>
        <li>
          Niniejszym Korzystający z Aplikacji akceptuje zasady zawarte w
          Polityce Prywatności i w Regulaminie.
        </li>
        <li>
          Administrator nie zbiera bez zgody Korzystającego z Aplikacji danych
          osobowych, a wyłącznie dane nie posiadające takiego przymiotu.
        </li>
        <li>
          Dane zbierane automatycznie nie umożliwiają jednoznacznej
          identyfikacji Korzystającego z Aplikacji.
        </li>
        <li>
          Dane zbierane automatycznie mogą służyć Administratorowi do poprawy
          jakości świadczonych usług, w szczególności w przypadku wystąpienia
          błędu Aplikacji. W sytuacji opisanej powyżej, dane zbierane
          automatycznie będą dotyczyły błędu Aplikacji, w tym stanu urządzenia
          mobilnego Użytkownika w chwili wystąpienia błędu, identyfikacji
          urządzenia mobilnego Użytkownika, fizycznej lokalizacji urządzenia
          mobilnego Użytkownika w chwili wystąpienia błędu.
        </li>
        <li>
          Nie ma możliwości zmiany bądź usunięcia danych zbieranych
          automatycznie.
        </li>
      </ul>
      <br /><br />

      <p>
        <b
          >Cele przetwarzania danych osobowych oraz podstawa prawna
          przetwarzania:</b
        >
      </p>

      <table>
        <thead>
          <tr>
            <th>Cel przetwarzania</th>
            <th>Zakres danych</th>
            <th>Podstawa prawna</th>
            <th>Czas przetwarzania</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td data-label="Cel przetwarzania">Wyszukiwanie połączeń</td>
            <td data-label="Zakres">Dane geolokalizacyjne</td>
            <td data-label="Podstawa prawna">
              Zgoda – art. 6 ust. 1 lit. a RODO
            </td>
            <td data-label="Czas przetwarzania">Do czasu wygaśnięcia sesji</td>
          </tr>
          <tr>
            <td data-label="Cel przetwarzania">
              Świadczenie usług Konta Użytkownika
            </td>
            <td data-label="Zakres">
              Numer telefonu, adres e-mail, imię, nazwisko, dane fakturowe,
              historia wykupionych biletów
            </td>
            <td data-label="Podstawa prawna">
              Wykonanie umowy – art. 6 ust. 1 lit. b RODO
            </td>
            <td data-label="Czas przetwarzania">
              3 lata od momentu usunięcia Konta
            </td>
          </tr>
          <tr>
            <td data-label="Cel przetwarzania">
              Świadczenie usług Konta Przewoźnika
            </td>
            <td data-label="Zakres">
              Numer telefonu, adres e-mail, imię, nazwisko, nazwa firmy dane
              fakturowe, historia zrealizowanych usług na rzecz pasażerów,
              historia rozliczeń
            </td>
            <td data-label="Podstawa prawna">
              Wykonanie umowy – art. 6 ust. 1 lit. b RODO
            </td>
            <td data-label="Czas przetwarzania">
              3 lata od momentu usunięcia Konta
            </td>
          </tr>
          <tr>
            <td data-label="Cel przetwarzania">Reklamacje</td>
            <td data-label="Zakres">
              Imię, nazwisko i w zależności od formy złożonej reklamacji: adres
              zamieszkania lub adres e-mail, a także dane ujęte w opisie
              zdarzenia będącego podstawą reklamacji
            </td>
            <td data-label="Podstawa prawna">
              Wykonanie umowy – art. 6 ust. 1 lit. b RODO
            </td>
            <td data-label="Czas przetwarzania">
              3 lata od momentu zakończenia postępowania reklamacyjnego
            </td>
          </tr>
          <tr>
            <td data-label="Cel przetwarzania">Analityka i optymalizacja</td>
            <td data-label="Zakres">
              Dane o sposobie korzystania z aplikacji mobilnej i strony
              internetowej
            </td>
            <td data-label="Podstawa prawna">
              Uzasadniony interes administratora w postaci analizy
              funkcjonowania aplikacji mobilnej i strony internetowej oraz ich
              optymalizacji – art. 6 ust. 1 lit. f RODO
            </td>
            <td data-label="Czas przetwarzania">
              Do czasu ustania przydatności, nie dłużej niż 2 lata.
            </td>
          </tr>
          <tr>
            <td data-label="Cel przetwarzania">Marketing usług własnych</td>
            <td data-label="Zakres">Imię, adres e-mail, numer telefonu</td>
            <td data-label="Podstawa prawna">
              Uzasadniony interes administratora w postaci marketingu usług
              własnych
            </td>
            <td data-label="Czas przetwarzania">Do czasu usunięcia Konta</td>
          </tr>
        </tbody>
      </table>
      <br /><br />

      <p><b>Prawa związane z ochroną danych osobowych</b></p>

      <p>
        Posiada Pani/Pan prawo żądania dostępu do treści swoich danych - w
        granicach art. 15 RODO, ich sprostowania – w granicach art. 16 RODO, ich
        usunięcia - w granicach art. 17 RODO, ograniczenia przetwarzania - w
        granicach art. 18 RODO, wniesienia sprzeciwu wobec przetwarzania
        Pana/Pani danych osobowych - w granicach art. 21 RODO. Nadto jeżeli
        Pani/Pan uzna, że dane osobowe są przetwarzane przez Administratora
        niezgodnie z wymogami prawa ma Pani/Pan prawo wnieść skargę do organu
        nadzorczego, którym jest Prezes Urzędu Ochrony Danych Osobowych.
        Szczegóły dotyczące swoich uprawnień wynikających z RODO możesz uzyskać
        bezpośrednio u Administratora;
      </p>

      <p>
        W celu wykonania swoich praw prosimy o kontakt pod adres email:
        <b>bok@busynalotniska.pl</b> lub w formie tradycyjnej na adres
        Administratora podany powyżej.
      </p>

      <p><b>Odbiorcy danych:</b></p>

      <p>
        Jeśli jesteś pasażerem Twoje dane osobowe mogą zostać przez nas
        przekazane:
      </p>
      <ul class="list-numeric">
        <li>
          przewoźnikom, którzy świadczą usługę przewozu na podstawie nabytego
          przez Panią/Pana biletu
        </li>
        <li>
          naszym podwykonawcom oraz partnerom handlowym, czyli podmiotom z
          których korzystamy przy przetwarzaniu danych osobowych, są to w
          szczególności firmy informatyczne, programistyczne, hostingowe, firmy
          świadczące usługi księgowe, płatnicze, kancelarie prawne, agencje
          marketingowe, partnerzy świadczący usługi techniczne.
        </li>
      </ul>
      <br /><br />

      <p>
        Jeśli jesteś przewoźnikiem będącym osobą fizyczną, Twoje dane mogą
        zostać przez nas przekazane:
      </p>
      <ul class="list-numeric">
        <li>
          pasażerom, którzy zakupili bilet na usługę przewozu, a także osobom
          korzystającym z wyszukiwarki połączeń
        </li>
        <li>
          naszym podwykonawcom oraz partnerom handlowym, czyli podmiotom z
          których korzystamy przy przetwarzaniu danych osobowych, są to w
          szczególności firmy informatyczne, programistyczne, hostingowe, firmy
          świadczące usługi księgowe, płatnicze, kancelarie prawne, agencje
          marketingowe, partnerzy świadczący usługi techniczne.
        </li>
      </ul>
      <br /><br />

      <p>
        <b
          >Przekazanie danych do państwa trzeciego/organizacji
          międzynarodowej:</b
        >
      </p>

      <p>
        Przy prowadzeniu strony internetowej oraz aplikacji mobilnej
        Administrator korzysta z narzędzi dostarczanych przez podmioty, które
        znajdują się poza Europejskim Obszarem Gospodarczym, a niektórzy z
        podwykonawców mogą przechowywać Pani/Pana dane poza ww. terytorium (np.
        Google). Ilekroć mogłoby dochodzić do przekazywania Pani/Pana danych
        poza Europejski Obszar Gospodarczy, odbywa się to wyłącznie w ramach
        procedur zgodnych z przepisami o ochronie danych osobowych (dane są
        przekazywane do takich krajów, co do których Komisja Europejska wydała
        decyzję stwierdzającą, że zapewniają one odpowiedni stopień ochrony).
      </p>

      <p><b>Polityka cookies</b></p>

      <p>
        W celu jak najlepszego działania strony internetowej Administrator
        wykorzystuje pliki cookies. Pliki cookies (tzw. „ciasteczka”) stanowią
        dane informatyczne, w szczególności pliki tekstowe, które przechowywane
        są w urządzeniu końcowym użytkowników portali internetowych, które
        następnie serwery mogą odczytać przy każdorazowym połączeniu się z tego
        urządzenia.
      </p>

      <p>
        Akceptacja polityki cookies oznacza zgodę na użycie tych plików. W
        każdym momencie jednak możecie Państwo zmienić ustawienia przeglądarki
        decydujące o tym użyciu.
      </p>

      <p>
        Do stosowanych plików cookies należą następujące typy plików cookies:
      </p>
      <ul class="list-numeric">
        <li>
          Tymczasowe pliki cookies związane z sesją pozostają w przeglądarce
          tylko przez czas sesji, tzn. do momentu wyjścia z danej witryny.
        </li>
        <li>
          Trwałe pliki cookies pozostają w przeglądarce po zakończeniu sesji (o
          ile nie zostaną usunięte przez użytkownika).
        </li>
        <li>
          Analityczne pliki cookies zbierają informacje o korzystaniu z danej
          witryny, takie jak strony odwiedzone przez danego użytkownika oraz
          wszelkie komunikaty o błędach; nie zbierają informacji umożliwiających
          identyfikację użytkownika, a zebrane dane są agregowane w taki sposób,
          że stają się anonimowe. Analityczne pliki cookies służą do ulepszania
          działania witryny.
        </li>
        <li>
          Funkcjonalne pliki cookies umożliwiają witrynie zapamiętanie wszelkich
          wyborów dokonywanych na stronach (takich jak zmiana rozmiaru czcionki,
          dostosowanie strony).
        </li>
      </ul>
      <br /><br />

      <p>
        Pliki cookies są powszechnie stosowane przez większość administratorów
        serwisów i stron internetowych. Administrator korzysta z plików cookies
        własnych oraz podmiotów współpracujących w celach lepszej prezentacji
        wybranych elementów witryn, wyświetlania specjalnych komunikatów
        informacyjnych i reklamowych, prowadzenia statystyk o korzystaniu ze
        strony, optymalizacji i poprawnego wyświetlania treści zawartych na
        stronie.
      </p>

      <p>
        Pliki cookies podmiotów współpracujących są wykorzystywane zgodnie z
        zasadami określonymi przez te podmioty w politykach tych podmiotów.
      </p>
      <p>
        Zarządzanie plikami cookies przez użytkowników stron internetowych
        odbywa się przez zmianę ustawień przeglądarki. Wszystkie przeglądarki
        pozwalają na kontrolę działania mechanizmu cookies, włącznie z jego
        całkowitym wyłączeniem. Domyślnym ustawieniem przeglądarek w zakresie
        plików cookies jest to, że będą one zezwalały na zapisywanie ich na
        urządzeniu końcowym.
      </p>
      <p>
        Każdy użytkownik strony może w dowolnym momencie zablokować dostęp do
        plików cookies poprzez wykasowanie plików cookies zapisanych na jego
        urządzeniu/urządzeniach końcowych lub zmianę ustawień przeglądarki
        internetowej. Aby dokonać zmian ustawień aktualnie używanej przez
        użytkownika przeglądarki internetowej, konieczne jest zapoznanie się z
        zasadami dokonywania tych zmian odmiennymi dla każdej z dostępnych
        przeglądarek.
      </p>
      <p>
        Szczegółowe informacje o plikach cookies możesz znaleźć na stronie
        internetowej: http://wszystkoociasteczkach.pl/.
      </p>

      <p><b>Gromadzenie danych poprzez Aplikację Mobilną</b></p>

      <p>
        Zbierane przez Aplikację informacje o Użytkownikach służą jak
        najlepszemu zaspokojeniu potrzeb Użytkowników oraz jak najlepszemu
        świadczeniu usług poprzez Aplikację.
      </p>

      <p><b>Zmiany Polityki Prywatności</b></p>

      <p>
        Administrator zastrzega sobie prawo do wprowadzania zmian w Polityce
        Prywatności. Zmiany w Polityce prywatności odbywają się na zasadach
        odpowiadających zmianom w Regulaminie.
      </p>
    </div>
  </div>
</div>
