export class GlobalHelper {
  /**
   * calculates and rounds with Math.floor discount for given percentage value
   * @param price base ticket price, for example: 3600
   * @param discount percentage discount value, for example: 40, means 40%
   */
  public static discount(price: number, discount: number): number {
    return Math.floor(((100 - discount) / 100) * price);
  }
}
