import { RentalCounterGuard } from '@core/guards/rental-counter.guard';
import { AuthGuard } from '@core/modules/authorization/shared/guards/auth-guard';
import { BusOrderPageComponent } from '@modules/tabs/bus-rental-tab/pages/bus-order-page/bus-order-page.component';
import { BusOrderPaymentPageComponent } from '@modules/tabs/bus-rental-tab/pages/bus-order-payment-page/bus-order-payment-page.component';
import { BusRentalPageComponent } from '@modules/tabs/bus-rental-tab/pages/bus-rental-page/bus-rental-page.component';
import { MyOrdersPageComponent } from '@modules/tabs/bus-rental-tab/pages/my-orders-page/my-orders-page.component';
import { OrderBusPageComponent } from '@modules/tabs/bus-rental-tab/pages/order-bus-page/order-bus-page.component';
import { OrderPaymentPageComponent } from '@modules/tabs/bus-rental-tab/pages/order-payment-page/order-payment-page.component';
import { OrdersConfirmedPageComponent } from '@modules/tabs/bus-rental-tab/pages/orders-confirmed-page/orders-confirmed-page.component';
import { OrdersHistoryPageComponent } from '@modules/tabs/bus-rental-tab/pages/orders-history-page/orders-history-page.component';
import { CustomRoutes } from '@shared/consts/custom-routes.const';

export const BusRentalTabRoutes: CustomRoutes = [
  {
    path: '',
    component: BusRentalPageComponent,
    children: [
      { path: '', pathMatch: 'full', redirectTo: 'order-bus' },
      {
        path: 'order-bus',
        component: OrderBusPageComponent,
        data: { metaTitle: 'MetaTitle.Order bus' },
        canActivate: [RentalCounterGuard],
      },
      {
        path: 'my-orders',
        component: MyOrdersPageComponent,
        data: { metaTitle: 'MetaTitle.My orders' },
        canActivate: [AuthGuard],
      },
      {
        path: 'confirmed-orders',
        component: OrdersConfirmedPageComponent,
        data: { metaTitle: 'MetaTitle.ConfirmedOrders' },
        canActivate: [AuthGuard],
      },
      {
        path: 'orders-history',
        component: OrdersHistoryPageComponent,
        data: { metaTitle: 'MetaTitle.Orders history' },
        canActivate: [AuthGuard],
      },
    ],
  },
  {
    path: 'my-orders/:id',
    component: BusOrderPageComponent,
    data: { metaTitle: 'MetaTitle.My orders' },
    canActivate: [AuthGuard],
  },
  {
    path: 'confirmed-orders/:id',
    component: BusOrderPageComponent,
    data: { metaTitle: 'MetaTitle.ConfirmedOrders' },
    canActivate: [AuthGuard],
  },
  {
    path: 'orders-history/:id',
    component: BusOrderPageComponent,
    data: { metaTitle: 'MetaTitle.Orders history' },
    canActivate: [AuthGuard],
  },
  {
    path: 'my-orders/:id/payment/:offerId',
    component: BusOrderPaymentPageComponent,
    data: { metaTitle: 'MetaTitle.My orders' },
    canActivate: [AuthGuard],
  },
  {
    path: 'payment/:paymentToken',
    name: 'payment',
    component: OrderPaymentPageComponent,
    data: {
      isBottomMenuHidden: true,
      metaTitle: 'MetaTitle.Payment',
    },
  },
];
